import { THEMES } from 'Config'

import GlobalStyle from './GlobalStyle'
import ThemeProvider from './ThemeProvider'
import themes from './themes'

const theme = themes[THEMES.LIGHT]

const darkTheme = themes[THEMES.DARK]

export { darkTheme, GlobalStyle, theme, ThemeProvider }

export default themes
