import get from 'lodash/get'

import UserFragment from 'GraphQL/Fragments/User.graphql'
import UserProfileFragment from 'GraphQL/Fragments/UserProfile.graphql'
import userFollowersQuery from 'GraphQL/Queries/User/userFollowers.graphql'
import userFollowedSubscription from 'GraphQL/Subscriptions/userFollowed.graphql'
import userProfileUpdatesSubscription from 'GraphQL/Subscriptions/userProfileUpdates.graphql'
import userUpdatesSubscription from 'GraphQL/Subscriptions/userUpdates.graphql'

import { useSubscription } from 'Services/Apollo'

function UserUpdates() {
  useSubscription(userUpdatesSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const user = get(subscriptionData, ['data', 'userUpdates'])

      const { id } = user

      const fragment = {
        id: `User:${id}`,
        fragmentName: 'UserFields',
        fragment: UserFragment,
      }

      const data = client.readFragment(fragment)

      if (data) {
        client.writeFragment({
          ...fragment,
          data: { ...data, ...(user || []) },
        })
      }
    },
  })

  useSubscription(userProfileUpdatesSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const userProfile = get(subscriptionData, ['data', 'userProfileUpdates'])

      const { userId } = userProfile

      const fragment = {
        id: `UserProfile:${userId}`,
        fragmentName: 'UserProfileFields',
        fragment: UserProfileFragment,
      }

      const data = client.readFragment(fragment)

      if (data) {
        client.writeFragment({
          ...fragment,
          data: { ...data, ...(userProfile || []) },
        })
      }
    },
  })

  useSubscription(userFollowedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const user = get(subscriptionData, ['data', 'userFollowed'])

      const { id } = user

      const fragment = {
        id: `User:${id}`,
        fragmentName: 'UserFields',
        fragment: UserFragment,
      }

      const followersOptions = {
        query: userFollowersQuery,
        variables: { id },
      }

      const userData = client.readFragment(fragment)
      const followersData = client.readQuery(followersOptions)

      if (followersData && userData) {
        client.writeQuery({
          ...followersOptions,
          data: {
            ...followersData,
            userFollowers: [...(followersData?.userFollowers || []), ...user],
          },
        })
        client.writeFragment({
          ...fragment,
          data: {
            ...userData,
            followersCount: (userData?.followersCount || 0) + 1,
          },
        })
      }
    },
  })

  return null
}

export default UserUpdates
