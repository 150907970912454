import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import noop from 'lodash/noop'

import { Column, Text } from 'Components/UI'

import LayeredRoom from './LayeredRoom'
import SingleRoom from './SingleRoom'

function Category({
  darkMode,
  domain,
  isCollapsed,
  isSubdomainMainRoom,
  category,
  onFavorite,
  onJoin,
  onUnread,
  pathname,
}) {
  return (
    <Column mt={4}>
      {!isCollapsed && !category?.hideName && (
        <Text bold middle>
          {category?.name}
        </Text>
      )}
      {map(category?.rooms, room => {
        const roomRegex = new RegExp(`(?:\\b${room?.slug}\\b)`, 'g')

        if (room?.groupRooms?.length > 0 && room?.isDefaultLayered) {
          return (
            <LayeredRoom
              darkMode={darkMode}
              domain={domain}
              isCollapsed={isCollapsed}
              isSubdomainMainRoom={isSubdomainMainRoom}
              key={room?.id}
              room={room}
              onFavorite={onFavorite}
              onJoin={onJoin}
              onUnread={onUnread}
            />
          )
        }
        return (
          <SingleRoom
            darkMode={darkMode}
            isCollapsed={isCollapsed}
            isSelected={
              pathname.includes(room?.id) ||
              pathname.match(roomRegex) ||
              (domain === room?.slug && isSubdomainMainRoom) ||
              (domain === room?.slug &&
                pathname.includes('post') &&
                !pathname.includes('room'))
            }
            key={room?.id}
            room={room}
            onFavorite={onFavorite}
            onJoin={onJoin}
            onUnread={onUnread}
          />
        )
      })}
    </Column>
  )
}

Category.defaultProps = {
  category: {},
  darkMode: false,
  domain: null,
  isCollapsed: false,
  isSubdomainMainRoom: false,
  pathname: '',
  onFavorite: noop,
  onJoin: noop,
  onUnread: noop,
}

Category.propTypes = {
  category: PropTypes.object,
  darkMode: PropTypes.bool,
  domain: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isSubdomainMainRoom: PropTypes.bool,
  pathname: PropTypes.string,
  onFavorite: PropTypes.func,
  onJoin: PropTypes.func,
  onUnread: PropTypes.func,
}

export default Category
