import { useCallback, useState } from 'react'

import { useQuery } from '@apollo/client'

import blockedUsersQuery from 'GraphQL/Queries/User/blockedUsers.graphql'

import { useDirectChannel } from 'Hooks'

export default function useBlockedUsers({ searchQuery }) {
  const { unblockChannel: unblockChannelHandler } = useDirectChannel()
  const [unblockingUser, setUnblockingUser] = useState(null)
  const { data, loading } = useQuery(blockedUsersQuery, {
    variables: {
      limit: 100,
      page: 0,
      search: searchQuery || '',
    },
  })

  const handleUnblock = useCallback(
    (userId, channelId) => {
      if (userId && channelId) {
        setUnblockingUser(userId)
        unblockChannelHandler(channelId, userId).finally(() => {
          setUnblockingUser(null)
        })
      }
    },
    [unblockChannelHandler],
  )

  return {
    blockedList: data?.getBlockedUsers?.rows || [],
    loading,
    unblockingUser,
    unblockChannel: handleUnblock,
  }
}
