import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const InviteMessageWrapper = styled.div`
  a {
    color: ${themeGet('colors.customDefault')};
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: ${themeGet('colors.customMedium')};
    }

    :active {
      color: ${themeGet('colors.customDefault')};
    }
  }
`
