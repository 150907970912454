import { USER_ROLE } from './ids'

export const PASSWORD_REGEX =
  '^(?=.*?[A-Za-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_-]).{8,}$'

export const USERNAME_REGEX = '^(?![\\d_]+$)[a-zA-Z0-9_]*$'

export const DOMAIN_REGEX = '^[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$'

export const ROLE_NAMES = {
  [USER_ROLE.USER]: 'User',
  [USER_ROLE.ADMIN]: 'Admin',
  [USER_ROLE.SUPER_ADMIN]: 'Super Admin',
  [USER_ROLE.HOST]: 'Host',
}

export const LONG_DASH = '—'

export const APP_FALLBACK = 'app'
