import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    height: 75px;
    padding: ${themeGet('space.3')}px ${themeGet('space.4')}px;
  `

const collapsedContentCss = ({ collapsedContent }) =>
  collapsedContent &&
  css`
    align-items: center;
  `

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 66px;
  border: 1px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.secondary')};
  padding: ${themeGet('space.4')}px;

  ${margin}
  ${collapsedCss}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${collapsedContentCss}
`
