import React from 'react'
import { components } from 'react-select'

import { Indicators } from './styles'

const IndicatorsContainer = props => (
  <Indicators as={components.IndicatorsContainer} {...props} />
)

IndicatorsContainer.propTypes = {
  ...components.IndicatorsContainer.propTypes,
}

export default IndicatorsContainer
