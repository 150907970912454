import { useMemo } from 'react'

import get from 'lodash/get'

import systemSettingsQuery from 'GraphQL/Queries/Settings/systemSettings.graphql'

import { useQuery } from 'Services/Apollo'

export default function useMaintenance() {
  const { data, loading } = useQuery(systemSettingsQuery)

  const settings = get(data, 'systemSettings') || {}

  return useMemo(
    () => [settings?.maintenanceMode, loading],
    [settings?.maintenanceMode, loading],
  )
}
