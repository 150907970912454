import React from 'react'
import { components } from 'react-select'

import { GroupWrapper } from './styles'

const Group = props => <GroupWrapper as={components.Group} {...props} />

Group.propTypes = {
  ...components.Group.propTypes,
}

export default Group
