import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { BsThreeDotsVertical } from 'react-icons/bs'
import { FiPlus } from 'react-icons/fi'
import { MdDelete, MdEdit } from 'react-icons/md'

import noop from 'lodash/noop'

import { Button, Dropdown, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import Channels from './Channels'
import {
  CategoryTitle,
  DraggableContainer,
  IconWrapper,
  SelectArrow,
} from './styles'

function Category({
  category,
  index,
  isOpened,
  canChangeChannels,
  room,
  provided,
  onAddChannel,
  onDeleteCategory,
  onOpenCategory,
  onEditCategory,
  onResponsiveCollapse,
}) {
  const s = useScopedI18n('room.channels')

  const dropdownOptions = useMemo(
    () => [
      {
        type: Dropdown.ItemType.Button,
        Icon: MdEdit,
        content: 'Edit',
        action: () =>
          onEditCategory({
            id: category?.id,
            name: category?.name,
            collapse: category?.collapse,
          }),
      },
      {
        type: Dropdown.ItemType.Button,
        Icon: MdDelete,
        content: 'Delete',
        action: () => onDeleteCategory(category?.id),
      },
    ],
    [
      category?.collapse,
      category?.id,
      category?.name,
      onDeleteCategory,
      onEditCategory,
    ],
  )

  return (
    <DraggableContainer ref={provided.innerRef} {...provided.draggableProps}>
      <Row center mt={index === 0 ? 0 : 6} spaceBetween width={1}>
        <CategoryTitle
          onClick={() => onOpenCategory(category?.id)}
          {...provided.dragHandleProps}
        >
          <SelectArrow arrowSelected={isOpened} />
          <Text bold ml={2} small>
            {category?.name}
          </Text>
        </CategoryTitle>

        {canChangeChannels && (
          <Dropdown items={dropdownOptions} offset={[-60, 4]}>
            <Button height={14} iconFill medium secondary width={14}>
              <BsThreeDotsVertical color="#14171A" />
            </Button>
          </Dropdown>
        )}
      </Row>

      <Channels
        canChangeChannels={canChangeChannels}
        categoryId={category?.id}
        categoryNum={index}
        channels={category?.channels}
        isOpened={isOpened}
        roomSlug={room?.slug}
        onResponsiveCollapse={onResponsiveCollapse}
      />

      {canChangeChannels && (
        <Button
          iconButton
          mt={3}
          noEffectsIcon
          small
          spaceBetween
          stroke
          width={93}
          onClick={() =>
            onAddChannel({ roomId: room?.id, categoryId: category?.id })
          }
        >
          <IconWrapper smaller>
            <FiPlus />
          </IconWrapper>
          {s('actions.addChannel')}
        </Button>
      )}
    </DraggableContainer>
  )
}

Category.defaultProps = {
  canChangeChannels: false,
  category: {},
  index: 0,
  isOpened: false,
  provided: null,
  room: null,
  onAddChannel: noop,
  onDeleteCategory: noop,
  onEditCategory: noop,
  onOpenCategory: noop,
  onResponsiveCollapse: noop,
}

Category.propTypes = {
  canChangeChannels: PropTypes.bool,
  category: PropTypes.object,
  index: PropTypes.number,
  isOpened: PropTypes.bool,
  provided: PropTypes.object,
  room: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onAddChannel: PropTypes.func,
  onDeleteCategory: PropTypes.func,
  onEditCategory: PropTypes.func,
  onOpenCategory: PropTypes.func,
  onResponsiveCollapse: PropTypes.func,
}

export default Category
