import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { BiChat } from 'react-icons/bi'

import Aside from 'Components/Blocks/Admin/Aside'
import { Flex } from 'Components/UI'

import { ADMIN_ROOMS } from 'Router/routes'

import { Container, Content, Menu, MenuLink, PageContent } from './styles'

function Host({ route }) {
  return (
    <Container>
      <Aside />
      <Content>
        <Menu>
          <Flex>
            <MenuLink to={ADMIN_ROOMS}>
              <BiChat /> Rooms
            </MenuLink>
          </Flex>
        </Menu>
        <PageContent>{renderRoutes(route.routes)}</PageContent>
      </Content>
    </Container>
  )
}

Host.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Host
