import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { border, flexCenter, text } from 'Components/Styles'

export const Container = styled.div`
  ${flexCenter};
  flex-direction: column;
  width: 100%;
  padding: ${themeGet('space.3')}px;
  ${border()};
  border-style: dashed;
  outline: none;
  cursor: pointer;

  > svg {
    color: ${themeGet('colors.font.secondary')};
  }

  ${layout.width}
  ${margin}
`

export const Text = styled.div`
  ${text()};
  color: ${themeGet('colors.font.secondary')};

  ${margin}
`

export const SelectFile = styled.div`
  ${text()};
  color: ${themeGet('colors.primary')};

  ${margin}
`
