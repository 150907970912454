import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { BiBlock } from 'react-icons/bi'
import { RiSearchLine } from 'react-icons/ri'

import debounce from 'lodash/debounce'
import noop from 'lodash/noop'

import { BlockedUsersModal } from 'Components/Blocks/Modals'
import { Button, Loader, Row } from 'Components/UI'
import { Input } from 'Components/UI/Forms'

import directChannelsQuery from 'GraphQL/Queries/Channels/directChannels.graphql'
import { updateDirectChannelsQueryUpdater } from 'GraphQL/Updaters/channels'

import { GoBackButton, StyledGoBackIcon } from 'Pages/UIKit/styles'

import { useLazyQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import Channels from './Channels'

import { BlockedListButton, Content, ResponsiveRow } from '../styles'

const TABS = {
  ALL: 'all',
  FOLLOWING: 'following',
  HOLDERS: 'holders',
}

function Inbox({ isCollapsed, onCollapsed, onResponsiveCollapse }) {
  const s = useScopedI18n('user.inbox')
  const [displayedTab, setDisplayedTab] = useState(TABS.ALL)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [isBlockedUsersModalOpen, setIsBlockedUsersModalOpen] = useState(false)

  const [loadDirectChannels, { data, loading, fetchMore }] = useLazyQuery(
    directChannelsQuery,
    { fetchPolicy: 'network-only' },
  )

  const channelsData = useMemo(() => data?.directChannels?.rows ?? {}, [data])

  const totalPages = useMemo(() => data?.directChannels?.pages ?? 0, [data])

  const fetchData = useCallback(() => {
    loadDirectChannels({
      variables: {
        page: 0,
        limit,
        onlyWithHolders: displayedTab === TABS.HOLDERS,
        onlyWithFollowingUsers: displayedTab === TABS.FOLLOWING,
      },
    })
  }, [displayedTab, limit, loadDirectChannels])

  const handleLoadMore = useCallback(async () => {
    if (page < totalPages - 1) {
      await fetchMore({
        variables: {
          page: page + 1,
          limit,
          onlyWithHolders: displayedTab === TABS.HOLDERS,
          onlyWithFollowingUsers: displayedTab === TABS.FOLLOWING,
        },
        updateQuery: (prev, fetchMoreResult) =>
          updateDirectChannelsQueryUpdater(prev, fetchMoreResult),
      })

      setPage(page + 1)
    }
  }, [page, totalPages, fetchMore, limit, displayedTab])

  const handleDisplayedTab = useCallback(value => {
    setPage(0)
    setDisplayedTab(value)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  useEffect(() => {
    if (search) {
      setLimit(9999)
    } else {
      setLimit(10)
    }
  }, [search])

  useEffect(() => {
    if (!search) {
      fetchData()
    } else {
      const variables = {
        page: 0,
        limit,
        onlyWithHolders: displayedTab === TABS.HOLDERS,
        onlyWithFollowingUsers: displayedTab === TABS.FOLLOWING,
      }

      if (search) variables.search = search

      loadDirectChannels({ variables })
    }
  }, [displayedTab, fetchData, limit, loadDirectChannels, search])

  return (
    <>
      {!isCollapsed ? (
        <ResponsiveRow center mt={4} width={1}>
          <GoBackButton responsive onClick={onCollapsed}>
            <StyledGoBackIcon />
          </GoBackButton>
          <Button
            activeTab={displayedTab === TABS.ALL}
            dark
            height={[32, 40, 40, 40, 32]}
            mr={2}
            small
            width={['25%', '30%', '30%', '30%', 86]}
            onClick={() => handleDisplayedTab(TABS.ALL)}
          >
            {s('fields.all')}
          </Button>
          <Button
            activeTab={displayedTab === TABS.FOLLOWING}
            dark
            height={[32, 40, 40, 40, 32]}
            mr={2}
            small
            width={['25%', '30%', '30%', '30%', 86]}
            onClick={() => handleDisplayedTab(TABS.FOLLOWING)}
          >
            {s('fields.following')}
          </Button>
          <Button
            activeTab={displayedTab === TABS.HOLDERS}
            dark
            height={[32, 40, 40, 40, 32]}
            small
            width={['25%', '30%', '30%', '30%', 86]}
            onClick={() => handleDisplayedTab(TABS.HOLDERS)}
          >
            {s('fields.holders')}
          </Button>
        </ResponsiveRow>
      ) : (
        <Row justifyCenter mt={4} width={1}>
          {displayedTab === TABS.ALL && (
            <Button
              activeTab={displayedTab === TABS.ALL}
              dark
              height={32}
              small
              width={86}
              onClick={() => handleDisplayedTab(TABS.FOLLOWING)}
            >
              {s('fields.all')}
            </Button>
          )}
          {displayedTab === TABS.FOLLOWING && (
            <Button
              activeTab={displayedTab === TABS.FOLLOWING}
              dark
              height={32}
              small
              width={86}
              onClick={() => handleDisplayedTab(TABS.HOLDERS)}
            >
              {s('fields.following')}
            </Button>
          )}
          {displayedTab === TABS.HOLDERS && (
            <Button
              activeTab={displayedTab === TABS.HOLDERS}
              dark
              height={32}
              small
              width={86}
              onClick={() => handleDisplayedTab(TABS.ALL)}
            >
              {s('fields.holders')}
            </Button>
          )}
        </Row>
      )}
      {!isCollapsed ? (
        <Row center height={40} mt={4}>
          <Input
            icon={<RiSearchLine fill="#657786" />}
            name="search"
            placeholder={s('fields.search')}
            search
            width={1}
            onChange={handleChangeSearch}
          />
        </Row>
      ) : (
        <Row height={40} mt={4} width={1} />
      )}
      <Content mt={4}>
        {!loading ? (
          <>
            <Channels
              channelsData={channelsData}
              isCollapsed={isCollapsed}
              onLoadMore={handleLoadMore}
              onResponsiveCollapse={onResponsiveCollapse}
            />
            <BlockedListButton
              outline
              onClick={() => setIsBlockedUsersModalOpen(true)}
            >
              <BiBlock />
              Show my block list
            </BlockedListButton>
          </>
        ) : (
          <Loader alignSelfCenter mt={50} text="Loading..." />
        )}
      </Content>
      <BlockedUsersModal
        isOpen={isBlockedUsersModalOpen}
        onClose={() => setIsBlockedUsersModalOpen(false)}
      />
    </>
  )
}

Inbox.defaultProps = {
  isCollapsed: false,
  onCollapsed: noop,
  onResponsiveCollapse: noop,
}

Inbox.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapsed: PropTypes.func,
  onResponsiveCollapse: PropTypes.func,
}

export default Inbox
