import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'

import { IS_DEVELOPMENT } from 'Config'

import { setApolloClient } from 'Services/Shared'

import { updateSortOrder } from './TypePolicies/Mutation'
// import { favoriteRooms, rooms } from './TypePolicies/Query'
import { authLink, errorLink, requestLink } from './Links'

function createApolloClient({ history }) {
  const cache = new InMemoryCache({
    typePolicies: {
      Mutation: {
        fields: {
          updateSortOrder,
        },
      },
      UserProfile: {
        keyFields: ['userId'],
      },
      Query: {
        fields: {
          messages: {
            keyArgs: ['channelId', 'isPinned', 'sort'],
          },
        },
      },
      // Query: {
      //   fields: {
      //     rooms,
      //     favoriteRooms,
      //     feed: {
      //       keyArgs: false,
      //       merge(existing = [], incoming) {
      //         return [...existing, ...incoming]
      //       },
      //     },
      //   },
      // },
    },
  })

  const links = [errorLink(history), authLink(), requestLink()]

  const clientOptions = {
    link: ApolloLink.from(links),
    connectDevTools: IS_DEVELOPMENT,
    cache,
  }

  const client = new ApolloClient(clientOptions)

  setApolloClient(client)

  return client
}

function appendAdminContext(options) {
  return {
    ...options,
    context: { ...options?.context, admin: true },
  }
}

function useAdminQuery(query, options) {
  return useQuery(query, appendAdminContext(options))
}

function useAdminLazyQuery(query, options) {
  return useLazyQuery(query, appendAdminContext(options))
}

function useAdminMutation(query, options) {
  return useMutation(query, appendAdminContext(options))
}

function useAdminSubscription(query, options) {
  return useSubscription(query, appendAdminContext(options))
}

export {
  createApolloClient,
  useAdminLazyQuery,
  useAdminMutation,
  useAdminQuery,
  useAdminSubscription,
  useLazyQuery,
  useMutation,
  useQuery,
  useSubscription,
}
