import React, { useMemo } from 'react'
import TimezoneSelect from 'react-timezone-select'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import * as Components from '../../Select/Components'

const THEME = theme => {
  const { negative, primary } = theme?.colors || {}

  return {
    borderRadius: 14,
    colors: {
      danger: negative,
      dangerLight: `${negative}80`,
      neutral0: theme?.colors?.bg?.primary,
      neutral5: 'hsl(0, 0%, 95%)',
      neutral10: 'hsl(0, 0%, 90%)',
      neutral20: 'hsl(0, 0%, 80%)',
      neutral30: 'hsl(0, 0%, 70%)',
      neutral40: 'hsl(0, 0%, 60%)',
      neutral50: 'hsl(0, 0%, 50%)',
      neutral60: 'hsl(0, 0%, 40%)',
      neutral70: 'hsl(0, 0%, 30%)',
      neutral80: 'hsl(0, 0%, 20%)',
      neutral90: 'hsl(0, 0%, 10%)',
      primary,
      primary25: `${primary}40`,
      primary50: `${primary}80`,
      primary75: `${primary}C0`,
    },
    spacing: {
      baseUnit: 2,
      controlHeight: theme?.inputs?.height,
      menuGutter: 4,
    },
  }
}

function TimezoneSelectInput({ alternateStyle, ...rest }) {
  const theme = useTheme()

  const selectTheme = useMemo(() => THEME(theme), [theme])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timezonesSelectStyle = useMemo(
    () => ({
      control: provided => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '5px',
        padding: theme?.space[2],
        cursor: 'pointer',
        height: '40px',
        border: `1px solid ${theme?.colors?.secondary}`,
      }),
      indicatorSeparator: provided => ({
        ...provided,
        display: 'none',
      }),
      dropdownIndicator: provided => ({
        ...provided,
        svg: {
          stroke: theme?.text?.color?.default,
        },
      }),
      placeholder: provided => ({
        ...provided,
        fontFamily: theme?.font,
        fontSize: '14px',
      }),
      valueContainer: provided => ({
        ...provided,
        padding: '0 !important',
        marginLeft: theme?.space[2],
      }),
      singleValue: provided => ({
        ...provided,
        color: theme?.text?.color?.default,
        fontSize: '12px',
      }),
      menu: provided => ({
        ...provided,
        margin: 0,
        borderRadius: '5px',
        zIndex: 3,
      }),
      menuList: provided => ({
        ...provided,
        borderRadius: '5px',
        scrollbarWidth: 'thin',
        backgroundColor: theme?.colors?.bg?.default,
        scrollbarColor: `${theme?.colors?.border?.customDefault} ${theme?.colors?.bg?.secondary}`,
        '::-webkit-scrollbar': {
          width: '2px',
          height: '100%',
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: theme?.colors?.border?.customDefault,
        },
      }),
      option: (provided, state) => {
        const selected = state?.getValue()
        return {
          ...provided,
          color:
            selected[0]?.label === state?.data?.label
              ? theme?.text?.color?.custom
              : theme?.text?.color?.default,
          fontFamily: theme?.font,
          fontSize: '14px!important',
          backgroundColor: 'inherit',
          ':hover': {
            backgroundColor: theme?.colors?.bg?.customSecondary,
            color: theme?.text?.color?.custom,
            cursor: 'pointer',
          },
        }
      },
    }),
    [
      theme?.colors?.bg?.customSecondary,
      theme?.colors?.bg?.default,
      theme?.colors?.bg?.secondary,
      theme?.colors?.border?.customDefault,
      theme?.colors?.secondary,
      theme?.font,
      theme?.space,
      theme?.text?.color?.custom,
      theme?.text?.color?.default,
    ],
  )
  return (
    <TimezoneSelect
      {...rest}
      components={{ ...Components }}
      styles={alternateStyle && timezonesSelectStyle}
      theme={selectTheme}
    />
  )
}

TimezoneSelectInput.defaultProps = {
  alternateStyle: false,
}

TimezoneSelectInput.propTypes = {
  alternateStyle: PropTypes.bool,
}

export default TimezoneSelectInput
