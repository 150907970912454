import find from 'lodash/find'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

import {
  tiktokUrlRegex,
  userMentionRegex,
  vimeoEventRegex,
  vimeoUrlRegex,
  wistiaUrlRegex,
} from './Posts'

export function decode(str) {
  return str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec))
}

export function zeroPad(num, size) {
  return `000${num}`.slice(size * -1)
}

export function urlStringExtractor(content, replacers) {
  if (!content) {
    return null
  }

  const sortedReplacers = orderBy(replacers, 'priority', 'desc')

  const allMatches = uniqBy(
    reduce(
      sortedReplacers,
      (acc, replacer) => [
        ...acc,
        ...Array.from(content?.matchAll(replacer.regex)),
      ],
      [],
    ),
    'index',
  )

  if (allMatches.length === 0) {
    return null
  }

  if (
    allMatches[0][0].match(vimeoUrlRegex) ||
    allMatches[0][0].match(wistiaUrlRegex) ||
    allMatches[0][0].match(tiktokUrlRegex)
  ) {
    return {
      url: allMatches[0][0],
      videoId: allMatches[0][1],
    }
  }

  if (allMatches[0][0].match(vimeoEventRegex)) {
    return {
      url: allMatches[0][0],
      videoId: allMatches[0][1],
      event: true,
    }
  }

  return allMatches[0][0]
}

export function stringReplacer(content, replacers) {
  if (!content) {
    return null
  }
  // TODO: optimize logic here. shouldn't create duplicates in the first place
  const sortedReplacers = orderBy(replacers, 'priority', 'desc')

  const allMatches = uniqBy(
    reduce(
      sortedReplacers,
      (acc, replacer) => [
        ...acc,
        ...Array.from(content?.matchAll(replacer.regex)),
      ],
      [],
    ),
    'index',
  )

  // -- endTODO

  if (allMatches.length === 0) {
    return [content]
  }

  const sortedMatches = sortBy(allMatches, 'index')
  const components = []
  let currentCaret = 0

  forEach(sortedMatches, (replace, i) => {
    const { index } = replace
    const [matched] = replace

    if (index > currentCaret) {
      components.push(content.substring(currentCaret, index))
      currentCaret = index
    }

    const matchedReplacer = find(sortedReplacers, replacer =>
      matched.match(replacer.regex),
    )

    if (matchedReplacer) {
      components.push(matchedReplacer.render(replace))
    }

    const nextItem = sortedMatches[i + 1]
    const nextCaret = nextItem ? nextItem.index : content.length

    currentCaret += matched.length

    components.push(content.substring(currentCaret, nextCaret))

    currentCaret = nextCaret
  })

  return components
}

const MENTION_KIND = {
  user: 'user',
}

const MENTION_PREFIX = {
  [MENTION_KIND.user]: '@',
}

export function buildContentWithMentions(content = '', mentions = []) {
  let result = content

  forEach(mentions, mention => {
    const prefix = MENTION_PREFIX[mention.kind]

    if (!prefix) return

    const replaceRegexp = new RegExp(`${prefix}${mention.content}`, 'ig')

    result = result.replaceAll(
      replaceRegexp,
      `${prefix}[${mention.content}](${mention.entityId})`,
    )
  })

  return result
}

export function cleanupMentions(content = '') {
  let result = content

  if (result?.length > 0) {
    const matches = Array.from(result.matchAll(userMentionRegex))

    forEach(matches, match => {
      result = result.replace(match[0], `@${match[1]}`)
    })
  }

  return result
}

export function urlTransformer(url) {
  if (!url) {
    return null
  }
  try {
    const myUrl = includes(url, 'http')
      ? new URL(url)
      : new URL(`https://${url}`)
    return myUrl
  } catch (error) {
    return null
  }
}

export function encodeAuthTokens(tokens) {
  return btoa(JSON.stringify(tokens))
}

export function decodeAuthTokens(tokensStr) {
  try {
    return JSON.parse(atob(tokensStr))
  } catch (e) {
    return null
  }
}
