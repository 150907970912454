import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import compact from 'lodash/compact'
import join from 'lodash/join'
import map from 'lodash/map'
import split from 'lodash/split'

import bronzeUser from 'Assets/Png/bronzeUser.png'
import goldUser from 'Assets/Png/goldUser.png'
import silverUser from 'Assets/Png/silverUser.png'

import Text from 'Components/UI/Text'

import {
  BadgeWrapper,
  Empty,
  Image,
  OnlineIcon,
  UserRank,
  Wrapper,
} from './styles'

function Avatar({ size, src, username, topUser, online, ...rest }) {
  const makeAcronym = useMemo(() => {
    if (username) {
      return join(
        map(
          compact(split(username, ' ')),
          item => `${item[0]?.toUpperCase()}${item[1]?.toUpperCase() || ''}`,
        ),
      )
    }
    return null
  }, [username])

  // TODO: altPlacement - temporary property to fix badges placement in different avatar sizes (until comments area re-designed also)
  const renderTopUser = useMemo(() => {
    if (topUser) {
      return (
        <BadgeWrapper altPlacement={size === 30}>
          {topUser === 1 && <Image src={goldUser} />}
          {topUser === 2 && <Image src={silverUser} />}
          {topUser > 2 && (
            <>
              <Image src={bronzeUser} />
              <UserRank topTen={topUser === 10} white>
                {topUser}
              </UserRank>
            </>
          )}
        </BadgeWrapper>
      )
    }
    return null
  }, [size, topUser])

  return (
    <Wrapper size={size} {...pick(rest)}>
      {renderTopUser}
      {!!online && <OnlineIcon />}
      {src ? (
        <Image src={src} />
      ) : (
        <Empty>
          <Text bold primary>
            {makeAcronym}
          </Text>
        </Empty>
      )}
    </Wrapper>
  )
}

Avatar.defaultProps = {
  online: false,
  size: 32,
  src: null,
  topUser: null,
  username: null,
}

Avatar.propTypes = {
  online: PropTypes.bool,
  size: PropTypes.number,
  src: PropTypes.string,
  topUser: PropTypes.number,
  username: PropTypes.string,
}

export default Avatar
