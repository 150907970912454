import React from 'react'

import loadable from '@loadable/component'

import { Loader } from 'Components/UI'

const params = {
  fallback: <Loader flex={1} fullHeight fullWidth noShrink />,
}

const Auth = {
  ChangePassword: loadable(
    () =>
      import('./ChangePassword' /* webpackChunkName: "auth-change-password" */),
    params,
  ),
  RequestChangePassword: loadable(
    () =>
      import(
        './RequestChangePassword' /* webpackChunkName: "auth-request-change-password" */
      ),
    params,
  ),
  Root: loadable(() => import('./Root' /* webpackChunkName: "auth-root" */)),
  SignUp: loadable(
    () => import('./SignUp' /* webpackChunkName: "auth-sign-up" */),
    params,
  ),
  ConfirmEmail: loadable(
    () => import('./ConfirmEmail' /* webpackChunkName: "auth-confirm-email" */),
    params,
  ),
  SetUsernameCredentials: loadable(
    () =>
      import(
        './SetUsernameCredentials' /* webpackChunkName: "auth-set-username" */
      ),
    params,
  ),
  SignInByEmail: loadable(
    () =>
      import('./SignInByEmail' /* webpackChunkName: "auth-sign-in-admin" */),
    params,
  ),
}

export default Auth
