import React, { useCallback, useRef, useState } from 'react'
import { IMask, IMaskInput } from 'react-imask'
import PropTypes from 'prop-types'

import { FiXCircle } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { omit, pick } from '@styled-system/props'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { ErrorWrapper, Wrapper } from '../styles'

const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    color: ${themeGet('colors.font.disabled')};
  `

const Label = styled.div`
  position: absolute;
  color: ${themeGet('colors.font.default')};
  font-weight: 300;
  z-index: 2;
  padding-left: ${themeGet('space.3')}px;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  user-select: none;
  cursor: text;

  ${disabledCss}
`

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  right: 8px;
  line-height: 0;

  & svg {
    stroke: ${themeGet('colors.font.secondary')};
  }
`

const StyledInput = styled(IMaskInput)`
  padding: 0 ${themeGet('space.3')}px;
  border: none;
  outline: none;
  background: none;
  caret-color: ${themeGet('colors.font.secondary')};
  color: ${themeGet('colors.font.secondary')};
  font-weight: 300;
`

const labelTopCss = css`
  ${Label} {
    font-size: 12px;
    transform: translateY(-10px);
  }
`

const inputBottomCss = css`
  padding-top: 12px;
`

const withLabelCss = ({ withLabel }) =>
  withLabel &&
  css`
    :focus-within {
      ${labelTopCss}
    }

    > input {
      :focus {
        ${inputBottomCss}
      }
    }
  `

const hasValueCss = ({ hasValue }) =>
  hasValue &&
  css`
    ${StyledInput} {
      margin-right: 32px;
      color: ${themeGet('colors.font.secondary')};
      font-weight: 300;

      ::-webkit-datetime-edit-fields-wrapper {
        color: ${themeGet('colors.font.secondary')};
      }
    }
  `

const hasValueAndLabelCss = ({ hasValue, withLabel }) =>
  hasValue &&
  withLabel &&
  css`
    ${labelTopCss}

    > input {
      ${inputBottomCss}
    }
  `

const Container = styled.div`
  display: flex;
  height: 40px;
  position: relative;
  align-items: center;

  background-color: ${mapToTheme('inputs.background.input')};
  border: solid 1px ${mapToTheme('inputs.border.input')};
  border-radius: 18px;
  outline: none;
  appearance: none;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  overflow: hidden;

  :focus-within {
    border-color: ${mapToTheme('inputs.focus.border')};
  }

  ${withLabelCss}
  ${hasValueCss}
  ${hasValueAndLabelCss}

  ${space}
  ${layout}
`

function TimeInput({
  defaultValue,
  disabled,
  error,
  icon,
  label,
  value,
  search,
  noChange,
  onChange,
  onClear,
  ...rest
}) {
  const inputRef = useRef(null)
  const [innerValue, setInnerValue] = useState(defaultValue)
  const styledProps = pick(rest)
  const inputProps = omit(rest)

  const hasValue = !!value || !!innerValue

  const handleChange = onChange || (({ target }) => setInnerValue(target.value))

  const handleClear = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    setInnerValue('')

    if (typeof onClear === 'function') onClear()
  }, [onClear])

  const handleFocus = useCallback(() => inputRef.current?.focus(), [])

  return (
    <Wrapper {...styledProps}>
      <Container
        disabled={disabled}
        hasValue={hasValue}
        noChange={noChange}
        search={!!search}
        withError={!!error}
        withIcon={!!icon}
        withLabel={!!label}
      >
        <StyledInput
          blocks={{
            HH: {
              mask: IMask.MaskedRange,
              placeholderChar: 'HH',
              from: 0,
              to: 23,
              maxLength: 2,
            },
            MM: {
              mask: IMask.MaskedRange,
              placeholderChar: 'MM',
              from: 0,
              to: 59,
              maxLength: 2,
            },
          }}
          disabled={disabled}
          // eslint-disable-next-line no-return-assign
          inputRef={el => (inputRef.current = el)}
          mask="HH:MM"
          overwrite
          unmask
          {...inputProps}
          value={value || innerValue}
          onAccept={(val, mask) => handleChange(val)}
        />

        {label && (
          <Label disabled={disabled} onClick={handleFocus}>
            {label}
          </Label>
        )}

        {hasValue && !disabled && (
          <Close onClick={handleClear}>
            <FiXCircle size={16} />
          </Close>
        )}
      </Container>

      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Wrapper>
  )
}

TimeInput.defaultProps = {
  autoComplete: 'on',
  autoFocus: false,
  defaultValue: null,
  disabled: false,
  error: false,
  icon: null,
  label: null,
  noChange: false,
  placeholder: '',
  search: false,
  small: false,
  type: 'text',
  value: undefined,
  onChange: null,
  onClear: null,
}

TimeInput.propTypes = {
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  icon: PropTypes.node,
  label: PropTypes.string,
  noChange: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
}

export default TimeInput
