import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import delay from 'lodash/delay'
import forEach from 'lodash/forEach'

import { Row, Text } from 'Components/UI'

import { AUDIO_MESSAGE, UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile } from 'Services/Api/Queries/upload'
import toast from 'Services/Toast'

import { secondsToDuration } from 'Utils/Date'

import RecordButton from './RecordButton'
import { AnimationWrapper, Container, Wrapper } from './styles'

import AudioPlayer from '../../AudioPlayer'

function AudioInput({
  disabled,
  value,
  entityId,
  uploadType,
  onChange,
  ...rest
}) {
  const [isRecording, setIsRecording] = useState(false)
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null)
  const [currentTime, setCurrentTime] = useState(0)
  const [percent, setPercent] = useState(0)
  const audioRecorder = useRef()
  const audioChunks = useRef([])
  const audioStream = useRef()
  const recordButton = useRef()
  const animationRef = useRef()

  const handleProgress = useCallback(event => setPercent(event?.percent), [])

  const handleUploadFile = useCallback(
    async file =>
      uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: handleProgress,
      }),
    [entityId, uploadType, handleProgress],
  )

  const visualize = useCallback(stream => {
    const audioCtx = new AudioContext()
    const src = audioCtx.createMediaStreamSource(stream)
    const analyser = audioCtx.createAnalyser()
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)
    src.connect(analyser)

    draw()

    function draw() {
      animationRef.current = requestAnimationFrame(draw)

      const time = audioCtx.getOutputTimestamp()

      setCurrentTime(time?.contextTime)

      analyser.getByteFrequencyData(dataArray)

      forEach(dataArray, data => {
        recordButton?.current?.style?.setProperty(
          'width',
          `${75 + (data / 64) * 25}%`,
        )
        recordButton?.current?.style?.setProperty(
          'height',
          `${75 + (data / 64) * 25}%`,
        )
      })
    }
  }, [])

  const getAudioStream = useCallback(async () => {
    try {
      audioStream.current = await navigator.mediaDevices.getUserMedia({
        audio: true,
      })
    } catch (error) {
      toast.error({ title: 'Recording message', text: error?.message })
    }
  }, [])

  const onRecordingActive = useCallback(({ data }) => {
    audioChunks.current.push(data)
  }, [])

  const onRecordingStop = useCallback(async () => {
    const blob = new Blob(audioChunks.current)
    const url = URL.createObjectURL(blob)
    setMediaBlobUrl(url)
    const response = await handleUploadFile(blob)
    if (response?.ok) {
      setMediaBlobUrl(response.url)
      onChange(response?.url)
    }
  }, [handleUploadFile, onChange])

  const startRecording = useCallback(async () => {
    if (!audioStream.current) {
      await getAudioStream()
    }
    visualize(audioStream.current)
    setIsRecording(true)
    const isStreamEnded =
      audioStream?.current?.getTracks()[0]?.readyState === 'ended'
    if (isStreamEnded) {
      setMediaBlobUrl(null)
      cancelAnimationFrame(animationRef.current)
      await getAudioStream()
      visualize(audioStream.current)
    }
    audioRecorder.current = new MediaRecorder(audioStream.current)
    audioRecorder.current.ondataavailable = onRecordingActive
    audioRecorder.current.onstop = onRecordingStop
    audioRecorder.current.start()
    delay(() => {
      if (audioRecorder.current.state !== 'inactive') {
        audioRecorder.current.stop()
        cancelAnimationFrame(animationRef.current)
        audioStream.current && audioStream.current.getTracks()[0].stop()
        audioChunks.current = []
        setIsRecording(false)
      }
    }, AUDIO_MESSAGE.DURATION)
  }, [getAudioStream, onRecordingActive, onRecordingStop, visualize])

  const stopRecording = useCallback(() => {
    if (audioRecorder.current.state !== 'inactive') {
      audioRecorder.current.stop()
      cancelAnimationFrame(animationRef.current)
      audioStream.current && audioStream.current.getTracks()[0].stop()
      audioChunks.current = []
      setIsRecording(false)
    }
  }, [])

  return (
    <Container {...pick(rest)}>
      <Wrapper>
        <AnimationWrapper ref={recordButton}>
          <RecordButton
            onClick={!isRecording ? startRecording : stopRecording}
          />
        </AnimationWrapper>
      </Wrapper>
      <Row center mt={9}>
        {isRecording && <Text primary>{secondsToDuration(currentTime)}</Text>}
        {mediaBlobUrl && <AudioPlayer src={mediaBlobUrl} />}
      </Row>
    </Container>
  )
}

AudioInput.defaultProps = {
  disabled: false,
  entityId: null,
  height: 200,
  width: 200,
}

AudioInput.propTypes = {
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  uploadType: PropTypes.oneOf(Object.values(UPLOAD_TYPES)).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

export default AudioInput
