import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin, padding } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 480px;
  padding: 0 ${themeGet('space.6')}px ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.2')}) {
    width: calc(100vw - 30px);
  }
`

export const Image = styled.img`
  object-fit: contain;

  ${layout.height}
  ${layout.width}
`

export const Device = styled.div`
  width: 100%;
  height: 67px;
  background: ${themeGet('colors.bg.lightest')};
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
    height: 105px;
  }

  ${margin}
  ${padding}
`
