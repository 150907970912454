import React from 'react'
import PropTypes from 'prop-types'

import logo from 'Assets/Png/logo.png'

import { Container, LogoImage } from './styles'

function Logo({ height, width, customLogo }) {
  return (
    <Container height={height} width={width}>
      <LogoImage height={height} src={customLogo || logo} width={width} />
    </Container>
  )
}

Logo.defaultProps = {
  customLogo: null,
  width: 150,
  height: 170,
}

Logo.propTypes = {
  customLogo: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
}

export default Logo
