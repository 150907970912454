import { FiArrowDown, FiArrowUp } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { text } from 'Components/Styles'

const ARROW_SIZE = 12

export const StyledTable = styled.div`
  width: 100%;

  ${margin}
`

const cellRightCss = ({ right }) =>
  right &&
  css`
    justify-content: flex-end;
  `

const headerContentOffsetCss = ({ contentOffset }) =>
  contentOffset &&
  css`
    margin-bottom: ${contentOffset}px;
  `

export const Header = styled.div`
  display: flex;
  align-items: center;

  ${headerContentOffsetCss}
`

export const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  ${text()};
  font-weight: normal;
  user-select: none;
  color: ${themeGet('colors.primary')};

  @media (max-width: ${themeGet('breakpoints.1')}) {
    font-size: 10px;
  }

  ${cellRightCss}
`

export const TableRow = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid ${themeGet('colors.border.default')};
  padding: 0 ${themeGet('space.5')}px;
  color: ${themeGet('text.color.default')};
  font-size: 16px;
  font-weight: 600;

  :first-child {
    border: none;
  }

  @media (max-width: ${themeGet('breakpoints.2')}) {
    font-size: 14px;
  }

  @media (max-width: ${themeGet('breakpoints.1')}) {
    font-size: 12px;
  }

  ${layout.height}
`

export const RowCell = styled.div`
  display: flex;
  align-items: center;

  ${cellRightCss}
`

const bodyRowGapCss = ({ rowGap }) =>
  rowGap &&
  css`
    > ${TableRow}:not(:first-child) {
      margin-top: ${rowGap}px;
    }
  `

const expandableCss = ({ expandable }) =>
  expandable &&
  css`
    max-height: 270px;
    overflow-y: scroll;
    scrollbar-width: none;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    &::-webkit-scrollbar {
      display: none;
    }
  `

const expandedCss = ({ expanded }) =>
  expanded &&
  css`
    max-height: 540px;
  `

export const Body = styled.div`
  border: 1px solid ${themeGet('colors.border.default')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.primary')};
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${bodyRowGapCss}
  ${expandableCss}
  ${expandedCss}
`

export const ArrowAsc = styled(FiArrowUp).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.primary')};

  ${margin}
`

export const ArrowDesc = styled(FiArrowDown).attrs({
  size: ARROW_SIZE,
})`
  color: ${themeGet('colors.warning')};

  ${margin}
`

export const ControlRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${themeGet('colors.border.default')};
  border-top: none;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: ${themeGet('space.3')}px 0;
  background: ${themeGet('colors.bg.primary')};
`
