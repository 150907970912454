import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export const Wrapper = styled.div`
  width: 100%;
  .ql-toolbar.ql-snow {
    border: 1px solid ${mapToTheme('colors.border')};
    border-radius: 4px;
    margin-bottom: ${themeGet('space.1')}px;
  }
  .ql-container.ql-snow {
    border: none;
    background: ${themeGet('colors.bg.secondary')};
    border-radius: 4px;
  }
`
