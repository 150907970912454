import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: ${themeGet('colors.bg.black')};
  overflow-y: auto;
  justify-content: center;
  align-items: center;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
  }
`

export const Content = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 480px;
  padding: ${themeGet('space.4')}px ${themeGet('space.6')}px;
  background-color: ${themeGet('colors.bg.default')};
  border-radius: 24px;
  width: 480px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    background-color: ${themeGet('colors.bg.default')};
    padding-top: 0;
    min-height: 0;
    height: 160px;
    border-radius: 0;
  }
`
