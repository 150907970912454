import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import map from 'lodash/map'

import { Button, Column, Loader, Modal, Row, Text } from 'Components/UI'
import { RadioField } from 'Components/UI/Forms'

import { PALETTE } from 'Config'

import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELDS = {
  PALETTE: 'palette',
}

function PaletteModal({ currentPalette, ...rest }) {
  const s = useScopedI18n('user.modal.palette')
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [updateProfile] = useMutation(updateProfileMutation)

  const formConstraints = useMemo(() => ({}), [])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await updateProfile({ variables: { palette: values[FIELDS.PALETTE] } })
        toast.success({
          title: 'Custom palette',
          text: `The palette has successfully been set`,
        })

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error({
          title: 'Custom palette',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [updateProfile],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={{ palette: currentPalette }}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle ml={1}>
                <Text alignStart mb={16} mt={24} primary>
                  {s('subtitle')}
                </Text>
              </Subtitle>
              <Row flexWrap width={1}>
                {map(
                  PALETTE,
                  color =>
                    color !== PALETTE.denim && (
                      <RadioField
                        blue={color === PALETTE.blue}
                        denim={color === PALETTE.denim}
                        green={color === PALETTE.green}
                        key={color}
                        label={s(`palettes.${color}`)}
                        ml={1}
                        mt={1}
                        name={FIELDS.PALETTE}
                        orange={color === PALETTE.orange}
                        palette
                        pink={color === PALETTE.pink}
                        purple={color === PALETTE.purple}
                        value={PALETTE[color]}
                        yellow={color === PALETTE.yellow}
                      />
                    ),
                )}
              </Row>
            </Content>

            <Column center mt={2} pb={24}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    height={32}
                    mb={1}
                    outline
                    width={125}
                    onClick={handleSubmit}
                  >
                    {s('actions.apply')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

PaletteModal.defaultProps = {
  currentPalette: null,
}

PaletteModal.propTypes = {
  currentPalette: PropTypes.string,
}

export default PaletteModal
