import { AppLayout } from 'Components/Layout'

import App from 'Pages/App'
import Pages from 'Pages/App/Room/routes'

import * as ROUTES from './routes'

export const APP_ROUTES = {
  component: AppLayout,
  path: ROUTES.APP_ROOT,
  routes: [
    {
      component: App.Root,
      path: ROUTES.APP_ROOT,
      exact: true,
    },
    {
      path: ROUTES.APP_PROFILE(),
      component: App.Profile,
      exact: true,
    },
    {
      path: ROUTES.APP_ROOM(),
      component: App.Room,
      routes: [
        {
          component: App.Room,
          path: ROUTES.APP_ROOM(),
          exact: true,
        },
        {
          component: Pages.Content,
          path: ROUTES.APP_ROOM_POSTS(),
          exact: true,
        },
        {
          component: Pages.Events,
          path: ROUTES.APP_ROOM_EVENTS(),
          exact: true,
        },
        {
          component: Pages.Store,
          path: ROUTES.APP_ROOM_STORE(),
          exact: true,
        },
        {
          component: App.Channel,
          path: ROUTES.APP_ROOM_CHANNEL(),
          exact: true,
        },
        {
          component: App.Post,
          path: ROUTES.APP_ROOM_POST(),
          exact: true,
        },
      ],
    },
    {
      path: ROUTES.APP_MY_FEED,
      component: App.MyFeed,
      exact: true,
    },
    {
      path: ROUTES.APP_DIRECT_CHANNEL(),
      component: App.DirectChannel,
      exact: true,
    },
  ],
}
