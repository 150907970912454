import { HttpLink, split } from '@apollo/client'
// eslint-disable-next-line import/no-extraneous-dependencies
import { getMainDefinition } from '@apollo/client/utilities'

import { API } from 'Config'

import WebSocketLink from './WebSocketLink'

export default function requestLink() {
  return split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query)
      return kind === 'OperationDefinition' && operation === 'subscription'
    },
    new WebSocketLink('graphql'),
    split(
      operation => operation.getContext().admin === true,
      new HttpLink({
        uri: `${API.URL}/admin/graphql`,
      }),
      new HttpLink({
        uri: `${API.URL}/graphql`,
      }),
    ),
  )
}
