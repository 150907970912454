import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { flexbox } from '@styled-system/flexbox'
import { layout } from '@styled-system/layout'
import { margin, padding, space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export default styled.div`
  display: flex;

  ${flexbox}
  ${space}
  ${layout}
`

const alignEndCss = ({ alignEnd }) =>
  alignEnd &&
  css`
    align-items: flex-end;
  `

const alignSelfStartCss = ({ alignSelfStart }) =>
  alignSelfStart &&
  css`
    align-self: flex-start;
  `

const alignSelfCenterCss = ({ alignSelfCenter }) =>
  alignSelfCenter &&
  css`
    align-self: center;
  `

const alignSelfEndCss = ({ alignSelfEnd }) =>
  alignSelfEnd &&
  css`
    align-self: flex-end;
  `

const borderBottomCss = ({ borderBottom }) =>
  borderBottom &&
  css`
    border-bottom: solid 1px ${mapToTheme('colors.border')};
  `

const borderTopCss = ({ borderTop }) =>
  borderTop &&
  css`
    border-top: solid 1px ${mapToTheme('colors.border')};
  `

const borderLeftCss = ({ borderLeft }) =>
  borderLeft &&
  css`
    border-left: solid 1px ${mapToTheme('colors.border')};
  `

const centerCss = ({ center }) =>
  center &&
  css`
    align-items: center;
  `

const cursorCss = ({ cursor }) =>
  cursor &&
  css`
    cursor: pointer;
  `

const flexWrapCss = ({ flexWrap }) =>
  flexWrap &&
  css`
    flex-wrap: wrap;
  `

const fullHeightCss = ({ fullHeight }) =>
  fullHeight &&
  css`
    height: 100%;
  `

const fullScreenCss = ({ fullScreen }) =>
  fullScreen &&
  css`
    width: 100vw;
    height: 100vh;
  `

const fullWidthCss = ({ fullWidth }) =>
  fullWidth &&
  css`
    width: 100%;
  `

const justifyCenterCss = ({ justifyCenter }) =>
  justifyCenter &&
  css`
    justify-content: center;
  `

const justifyEndCss = ({ justifyEnd }) =>
  justifyEnd &&
  css`
    justify-content: flex-end;
  `

const noShrinkCss = ({ noShrink }) =>
  noShrink &&
  css`
    flex-shrink: 0;
  `

const smoothTransitionCss = ({ smoothTransition }) =>
  smoothTransition &&
  css`
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
  `

const spaceBetweenCss = ({ spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const spaceEvenlyCss = ({ spaceEvenly }) =>
  spaceEvenly &&
  css`
    justify-content: space-evenly;
  `

const squeezeCss = ({ squeeze }) =>
  squeeze &&
  css`
    width: fit-content;
  `

const relativeCss = ({ relative }) =>
  relative &&
  css`
    position: relative;
  `

const DEFAULT_PROPS = {
  alignEnd: undefined,
  alignSelfCenter: undefined,
  alignSelfEnd: undefined,
  alignSelfStart: undefined,
  borderBottom: undefined,
  borderLeft: undefined,
  borderTop: undefined,
  center: undefined,
  cursor: undefined,
  flexWrap: undefined,
  fullHeight: undefined,
  fullScreen: undefined,
  fullWidth: undefined,
  justifyCenter: undefined,
  justifyEnd: undefined,
  noShrink: undefined,
  relative: undefined,
  smoothTransition: undefined,
  spaceBetween: undefined,
  spaceEvenly: undefined,
  squeeze: undefined,
}

const PROP_TYPES = {
  alignEnd: PropTypes.bool,
  alignSelfCenter: PropTypes.bool,
  alignSelfEnd: PropTypes.bool,
  alignSelfStart: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderLeft: PropTypes.bool,
  borderTop: PropTypes.bool,
  center: PropTypes.bool,
  cursor: PropTypes.bool,
  flexWrap: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  noShrink: PropTypes.bool,
  relative: PropTypes.bool,
  smoothTransition: PropTypes.bool,
  spaceBetween: PropTypes.bool,
  spaceEvenly: PropTypes.bool,
  squeeze: PropTypes.bool,
}

const Element = styled.div`
  ${layout}
  ${margin}
  ${padding}
  ${flexbox}

  ${alignEndCss}
  ${alignSelfCenterCss}
  ${alignSelfEndCss}
  ${alignSelfStartCss}
  ${borderBottomCss}
  ${borderLeftCss}
  ${borderTopCss}
  ${centerCss}
  ${cursorCss}
  ${flexWrapCss}
  ${fullHeightCss}
  ${fullWidthCss}
  ${justifyCenterCss}
  ${justifyEndCss}
  ${noShrinkCss}
  ${relativeCss}
  ${smoothTransitionCss}
  ${spaceBetweenCss}
  ${spaceEvenlyCss}
  ${squeezeCss}
  ${fullScreenCss}
`

export const Column = styled(Element)`
  display: flex;
  flex-direction: column;
`

Column.defaultProps = DEFAULT_PROPS
Column.propTypes = PROP_TYPES

export const Row = styled(Element)`
  display: flex;
`

Row.defaultProps = DEFAULT_PROPS
Row.propTypes = PROP_TYPES
