import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import {
  CoinbaseIcon,
  CoinmetroIcon,
  HathorPayIcon,
  MetamaskIcon,
  PrivyIcon,
  UnlockIcon,
  WalletConnectIcon,
} from 'Components/UI/AuthMethodIcon/styles'

import { AUTH_METHODS } from 'Constants/ids'

function AuthMethodIcon({ kind, ...rest }) {
  switch (kind) {
    case AUTH_METHODS.METAMASK:
      return <MetamaskIcon {...pick(rest)} />
    case AUTH_METHODS.COINBASE:
      return <CoinbaseIcon {...pick(rest)} />
    case AUTH_METHODS.COINMETRO:
      return <CoinmetroIcon {...pick(rest)} />
    case AUTH_METHODS.HATHORPAY:
      return <HathorPayIcon {...pick(rest)} />
    case AUTH_METHODS.WALLET_CONNECT:
      return <WalletConnectIcon {...pick(rest)} />
    case AUTH_METHODS.UNLOCK_PROTOCOL:
      return <UnlockIcon {...pick(rest)} />
    case AUTH_METHODS.PRIVY:
      return <PrivyIcon {...pick(rest)} />
    default:
      return null
  }
}

AuthMethodIcon.defaultProps = {
  kind: '',
}

AuthMethodIcon.propTypes = {
  kind: PropTypes.string,
}

export default AuthMethodIcon
