const customPalettes = {
  blue: {
    primary: '#358ac4',
    secondary: '#d0e6f5',

    dark: '#72add6',
    medium: '#9ac4e1',
    light: '#c2dced',
  },
  orange: {
    primary: '#eba22f',
    secondary: '#fbecd5',

    dark: '#f0b963',
    medium: '#f5d197',
    light: '#fae8cb',
  },
  pink: {
    primary: '#ce24a2',
    secondary: '#f5d3ec',

    dark: '#da5bb9',
    medium: '#e792d0',
    light: '#f3c8e8',
  },
  purple: {
    primary: '#7d2ac5',
    secondary: '#e5d4f3',

    dark: '#9d5fd3',
    medium: '#be94e1',
    light: '#decaf0',
  },
  green: {
    primary: '#66de65',
    secondary: '#daf7da',

    dark: '#94e893',
    medium: '#b2eeb2',
    light: '#d1f5d1',
  },
  yellow: {
    primary: '#ffd400',
    secondary: '#fff5c4',

    dark: '#ffe14d',
    medium: '#ffe980',
    light: '#fff2b3',
  },
  denim: {
    primary: '#358ac4',
    secondary: '#d0e6f5',

    dark: '#72add6',
    medium: '#9ac4e1',
    light: '#c2dced',
  },
}

export default customPalettes
