import React from 'react'
import { components } from 'react-select'

import { MultiValueWrapper } from './styles'

const MultiValue = props => (
  <MultiValueWrapper as={components.MultiValue} {...props} />
)

MultiValue.propTypes = {
  ...components.MultiValue.propTypes,
}

export default MultiValue
