import React, { useCallback } from 'react'

import styled from 'styled-components'
import { margin } from '@styled-system/space'

import GoldBadgeGlyph from 'Assets/Svg/goldBadge.svg'

import { GOLD_BADGE, TIER } from 'Constants/ids'

import useAppContext from './useAppContext'

export const GoldBadgeIcon = styled(GoldBadgeGlyph)`
  ${margin}
`

function useGoldBadge() {
  const { me } = useAppContext()
  const tier = me?.membership?.tier

  const renderBadge = useCallback(
    (topRoomUsers, userId) => {
      if (
        (tier === TIER.apex || tier === TIER.prime) &&
        topRoomUsers[0]?.id === userId &&
        // TODO: remove ethereum credential
        topRoomUsers[0]?.ethereumCredential?.tokenBalances[1]?.balance >=
          GOLD_BADGE.REQUIRE
      ) {
        return <GoldBadgeIcon ml={9} />
      }
      return null
    },
    [tier],
  )

  return { renderBadge }
}

export default useGoldBadge
