import React from 'react'
import { components } from 'react-select'

import { PlaceholderWrapper } from './styles'

const Placeholder = props => (
  <PlaceholderWrapper as={components.Placeholder} {...props} />
)

Placeholder.propTypes = {
  ...components.Placeholder.propTypes,
}

export default Placeholder
