import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { FiRefreshCcw, FiTrash2, FiUpload } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { Dialog } from 'Components/Blocks/Modals'
import { Text } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile, uploadLogo } from 'Services/Api/Queries/upload'
import _, { useScopedI18n } from 'Services/I18n'

import { fileToDataURL } from 'Utils/Files'

import {
  Container,
  DeleteButton,
  Image,
  Progress,
  Replace,
  Wrapper,
} from './styles'

function ImageInput({
  disabled,
  value,
  entityId,
  uploadType,
  setLoadingRef,
  onChange,
  ...rest
}) {
  const s = useScopedI18n('dropzone')

  const [uploadingFile, setUploadingFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)
  const [deleteImageModal, setDeleteImageModal] = useState({
    isOpen: false,
    url: null,
  })

  const handleProgress = useCallback(event => setPercent(event?.percent), [])

  const handleUploadFile = useCallback(
    async file => {
      if (
        uploadType === UPLOAD_TYPES.tokenLogo ||
        uploadType === UPLOAD_TYPES.lockedRoomPhoto ||
        uploadType === UPLOAD_TYPES.roomLogo
      ) {
        return uploadLogo({
          file,
          type: uploadType,
          onProgress: handleProgress,
        })
      }
      return uploadFile({
        file,
        type: uploadType,
        entityId,
        onProgress: handleProgress,
      })
    },
    [entityId, uploadType, handleProgress],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]) => {
      if (!acceptedFile) return

      setLoading(true)
      setLoadingRef(true)

      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response?.ok) {
        onChange(response.url)
      }

      setLoading(false)
      setLoadingRef(false)
      setUploadingFile(null)
      setPercent(0)
    },
    [setUploadingFile, setLoadingRef, handleUploadFile, onChange],
  )

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteImageModal({ isOpen: false, url: null })
  }, [])

  const handleShowDeleteModal = useCallback(
    file => {
      if (disabled) return
      setDeleteImageModal({ isOpen: true, url: file.url })
    },
    [disabled],
  )

  const handleDeleteImage = useCallback(
    ok => {
      if (ok) onChange(null)
    },
    [onChange],
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDrop,
    accept: 'image/jpeg, image/png, image/jpg',
    disabled: loading || disabled,
  })

  const src = uploadingFile || value

  return (
    <Container {...pick(rest)}>
      <Wrapper {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />

        {src ? (
          <>
            <Image src={src} />
            {loading && <Progress value={percent} />}
          </>
        ) : (
          <>
            <FiUpload size={48} strokeWidth={1} />
            <Text light mt={3} small>
              {s('dragNDrop')} {_('general.orLower')}
            </Text>
            <Text primary small>
              {s('select')}
            </Text>
          </>
        )}
      </Wrapper>

      {value && !loading && (
        <>
          <DeleteButton onClick={handleShowDeleteModal}>
            <FiTrash2 />
          </DeleteButton>
          <Replace onClick={open}>
            <FiRefreshCcw size={44} strokeWidth={1} />
          </Replace>
        </>
      )}

      <Dialog
        content="Are you sure you want to delete image?"
        isOpen={deleteImageModal.isOpen}
        title="Remove image?"
        onClose={handleCloseDeleteModal}
        onFinish={handleDeleteImage}
      />
    </Container>
  )
}

ImageInput.defaultProps = {
  disabled: false,
  entityId: null,
  height: 200,
  setLoadingRef: undefined,
  width: 200,
}

ImageInput.propTypes = {
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setLoadingRef: PropTypes.func,
  uploadType: PropTypes.oneOf(Object.values(UPLOAD_TYPES)).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
}

export default ImageInput
