import { useCallback, useEffect, useMemo } from 'react'

import roomBySlugDataHookQuery from 'GraphQL/Queries/Rooms/roomBySlugDataHook.graphql'

import { useLazyQuery } from 'Services/Apollo'

export default function useRoomData(roomPointer) {
  const [loadRoom, { data, loading }] = useLazyQuery(roomBySlugDataHookQuery, {
    variables: { slug: roomPointer },
  })

  const fetchData = useCallback(() => {
    loadRoom({ variables: { slug: roomPointer } })
  }, [loadRoom, roomPointer])

  useEffect(() => {
    if (roomPointer) {
      fetchData()
    }
  }, [fetchData, roomPointer])

  const room = useMemo(() => data?.roomBySlug ?? {}, [data?.roomBySlug])

  return useMemo(
    () => ({
      roomId: room?.id,
      slug: room?.slug,
      label: room?.label,
      favorite: room?.favorite,
      owners: room?.owners,
      moderators: room?.moderators,
      bouncers: room?.bouncers,
      locked: room?.locked,
      logoUrl: room?.logoUrl,
      onlineCount: room?.onlineParticipantsCount,
      participantsCount: room?.participantsCount,
      totalValueUsd: room?.totalValueUsd,
      hideRoomPrice: room?.hideRoomPrice,
      settings: room?.settings,
      unreadEventsCount: room?.unreadEventsCount,
      unreadCount: room?.unreadCount,
      roomShopifySettings: room?.roomShopifySettings,
      loading,
    }),
    [
      loading,
      room?.bouncers,
      room?.favorite,
      room?.hideRoomPrice,
      room?.id,
      room?.label,
      room?.locked,
      room?.logoUrl,
      room?.moderators,
      room?.onlineParticipantsCount,
      room?.owners,
      room?.participantsCount,
      room?.roomShopifySettings,
      room?.settings,
      room?.slug,
      room?.totalValueUsd,
      room?.unreadCount,
      room?.unreadEventsCount,
    ],
  )
}
