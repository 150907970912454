import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'

import { useTheme } from 'styled-components'

import logo from 'Assets/Png/logo.png'

import { PRIVY_APP_ID } from 'Config'

import signInByPrivyMutation from 'GraphQL/Mutations/Auth/signInByPrivy.graphql'

import { REDIRECTOR } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import Auth from 'Services/Auth'
import toast from 'Services/Toast'

export default function usePrivyHandlers({
  roomGroupMeta,
  exclusiveMode,
  roomMeta,
  refetchMe,
  me,
}) {
  const theme = useTheme()
  const history = useHistory()

  const [signInByPrivy] = useMutation(signInByPrivyMutation)

  const privyConfig = useMemo(
    () => ({
      appearance: {
        accentColor:
          roomGroupMeta?.customHexColor || theme.colors.customDefault,
        theme: theme.colors.bg.primary,
        showWalletLoginFirst: true,
        logo: roomMeta?.logoUrl || logo,
      },
      loginMethods: ['email', 'wallet', 'google', 'apple', 'github', 'discord'],
      embeddedWallets: {
        createOnLogin: 'users-without-wallets',
        requireUserPasswordOnCreate: false,
      },
    }),
    [roomGroupMeta, roomMeta, theme],
  )

  const privyAppId = useMemo(
    () =>
      exclusiveMode && roomGroupMeta?.privyCredentials?.appId
        ? roomGroupMeta?.privyCredentials?.appId
        : PRIVY_APP_ID,
    [exclusiveMode, roomGroupMeta],
  )
  const handleLoginPrivy = useCallback(
    async user => {
      if (!me) {
        try {
          const result = await signInByPrivy({
            variables: {
              privyAppId,
              privyId: user?.id,
              withRefresh: true,
            },
          })

          await Auth.handleAuth(result?.data?.signInByPrivy)

          history.push(REDIRECTOR)

          await refetchMe?.()
        } catch (error) {
          toast.error({
            title: 'Privy',
            text: error?.message,
          })
        }
      }
    },
    [history, me, privyAppId, refetchMe, signInByPrivy],
  )
  return { privyAppId, privyConfig, handleLoginPrivy }
}
