import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import Radio from './Radio'
import RadioAsButton from './RadioAsButton'
import RadioBlockchain from './RadioBlockchain'
import RadioPalette from './RadioPalette'
import RadioWalletProvider from './RadioWalletProvider'

// TODO: move field error check to utils of some sort
// TODO: refactor component, too many kinds of radiobutton
function RadioField({
  name,
  value,
  palette,
  provider,
  blockchain,
  asButton,
  ...rest
}) {
  return (
    <Field name={name} type="radio" value={value}>
      {({ input, meta }) => {
        const { error } = Utils.Form.hasError(meta)

        return (
          <>
            {!palette && !provider && !blockchain && !asButton && (
              <Radio
                error={error}
                name={name}
                value={value}
                {...input}
                {...rest}
              />
            )}
            {asButton && (
              <RadioAsButton
                error={error}
                name={name}
                value={value}
                {...input}
                {...rest}
              />
            )}
            {palette && (
              <RadioPalette
                error={error}
                name={name}
                value={value}
                {...input}
                {...rest}
              />
            )}
            {provider && (
              <RadioWalletProvider
                error={error}
                name={name}
                value={value}
                {...input}
                {...rest}
              />
            )}
            {blockchain && (
              <RadioBlockchain
                error={error}
                name={name}
                value={value}
                {...input}
                {...rest}
              />
            )}
          </>
        )
      }}
    </Field>
  )
}

RadioField.defaultProps = {
  asButton: false,
  blockchain: false,
  palette: false,
  provider: false,
}

RadioField.propTypes = {
  asButton: PropTypes.bool,
  blockchain: PropTypes.bool,
  name: PropTypes.string.isRequired,
  palette: PropTypes.bool,
  provider: PropTypes.bool,
  value: PropTypes.string.isRequired,
}

export default RadioField
