import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import useDeepCompareEffectForMaps from 'use-deep-compare-effect'

import map from 'lodash/map'
import noop from 'lodash/noop'
import range from 'lodash/range'

import Marker from './Marker'
import { GoogleMap } from './styles'

const styles = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#edeef7' }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#dbdfed' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dbdfed' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#c8cde4' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#c8cde4' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#dbdfed' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#7d8abf' }],
  },
]

function Map({
  center,
  children,
  isInitialLoad,
  click,
  search,
  style,
  zoom,
  onAddressInputValue,
  onClick,
  onIdle,
  onInitialLoad,
  onMapSearchMarker,
  onSetAddressByClick,
  ...options
}) {
  const mapRef = useRef()
  const [googleMap, setGoogleMap] = useState(null)

  const latLng = useMemo(
    () =>
      click
        ? new window.google.maps.LatLng(
            parseFloat(click?.lat),
            parseFloat(click?.lng),
          )
        : null,
    [click],
  )

  const getAddressFromClick = useCallback(() => {
    const geocoder = new window.google.maps.Geocoder()

    if (latLng) {
      geocoder?.geocode({ latLng }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          onSetAddressByClick(results[0]?.formatted_address)
          onAddressInputValue(results[0]?.formatted_address)
        }
      })
    }
  }, [latLng, onAddressInputValue, onSetAddressByClick])

  useEffect(() => {
    if (mapRef?.current && !googleMap) {
      setGoogleMap(
        new window.google.maps.Map(mapRef?.current, {
          styles,
          disableDefaultUI: true,
        }),
      )
    }
  }, [googleMap])

  useEffect(() => {
    if (googleMap && search) {
      const request = {
        query: search,
        fields: ['name', 'geometry', 'formatted_address'],
      }

      const service = new window.google.maps.places.PlacesService(googleMap)

      service?.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          map(range(results?.length), i => onMapSearchMarker(results[i]))
          googleMap?.setCenter(results[0]?.geometry?.location)
          googleMap?.setZoom(16)
        }
      })
    }
  }, [googleMap, onMapSearchMarker, search])

  useDeepCompareEffectForMaps(() => {
    if (googleMap) {
      googleMap?.setOptions(options)
    }
  }, [googleMap, options])

  useEffect(() => {
    if (googleMap) {
      window.google.maps.event.clearListeners(googleMap, 'click')
      window.google.maps.event.clearListeners(googleMap, 'idle')

      if (onClick) {
        const clickListener = googleMap.addListener('click', onClick)

        return () => {
          window.google.maps.event.removeListener(clickListener)
        }
      }

      if (onIdle) {
        const idleListener = googleMap.addListener('idle', () =>
          onIdle(googleMap),
        )

        return () => {
          window.google.maps.event.removeListener(idleListener)
        }
      }
    }
    return () => {}
  }, [googleMap, onClick, onIdle])

  useEffect(() => {
    if (googleMap && zoom) {
      googleMap?.setZoom(zoom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMap])

  useEffect(() => {
    if (googleMap && center) {
      googleMap?.setCenter(center)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleMap])

  useEffect(() => {
    if (googleMap && latLng && isInitialLoad) {
      googleMap.setCenter(latLng)
      googleMap?.setZoom(14)
      getAddressFromClick()
      onInitialLoad(false)
    }
  }, [getAddressFromClick, googleMap, isInitialLoad, latLng, onInitialLoad])

  return (
    <GoogleMap mt={2} ref={mapRef} onClick={getAddressFromClick}>
      {click && <Marker map={googleMap} position={latLng} />}
    </GoogleMap>
  )
}

Map.defaultProps = {
  center: { lat: 0, lng: 0 },
  children: null,
  click: null,
  isInitialLoad: true,
  search: '',
  style: null,
  zoom: 3,
  onAddressInputValue: noop,
  onClick: noop,
  onIdle: noop,
  onInitialLoad: noop,
  onMapSearchMarker: noop,
  onSetAddressByClick: noop,
}

Map.propTypes = {
  center: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  click: PropTypes.object,
  isInitialLoad: PropTypes.bool,
  search: PropTypes.string,
  style: PropTypes.object,
  zoom: PropTypes.number,
  onAddressInputValue: PropTypes.func,
  onClick: PropTypes.func,
  onIdle: PropTypes.func,
  onInitialLoad: PropTypes.func,
  onMapSearchMarker: PropTypes.func,
  onSetAddressByClick: PropTypes.func,
}

export default Map
