import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Link, Loader, Modal, Row, Text } from 'Components/UI'

import { useAppContext } from 'Hooks'
import useFollow from 'Hooks/useFollow'

import { APP_PROFILE } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { Content, CustomFollowRow } from './styles'

function FollowersModal({ data, loading, followers, following, ...rest }) {
  const s = useScopedI18n('user.modal.followers')
  const { me } = useAppContext()

  const { handleFollow, handleUnfollow, myFollowing } = useFollow({
    trigger: rest?.isOpen,
  })

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const renderData = useMemo(
    () =>
      map(data, ({ id, profile, username, createdAt }) => (
        <CustomFollowRow center key={id} mt={24} spaceBetween width={1}>
          <Row>
            <Column>
              <Link clean to={APP_PROFILE(id)}>
                <Avatar size={41} src={profile?.photoUrl} username={username} />
              </Link>
            </Column>
            <Column ml={9} spaceBetween>
              <Text bold mb={2}>
                @{username}
              </Text>
              <Row>
                <Text primary small>
                  {s('member')}
                </Text>
                <Text ml={1} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATE_SHORT,
                  )}
                </Text>
              </Row>
            </Column>
            {/* <Row mt={1}>
              {me?.membership?.tier !== TIER.guest && (
                <MembershipWrap tier={membership?.tier} />
              )}
            </Row> */}
          </Row>
          {me?.id !== id && (
            <Row center>
              {myFollowing.has(id) ? (
                <Button
                  height={23}
                  mt={[2, 2, 0]}
                  small
                  width={90}
                  onClick={() => handleUnfollow(id)}
                >
                  {s('actions.unfollow')}
                </Button>
              ) : (
                <Button
                  height={23}
                  mt={[2, 2, 0]}
                  outline
                  small
                  width={90}
                  onClick={() => handleFollow(id)}
                >
                  {s('actions.follow')}
                </Button>
              )}
            </Row>
          )}
        </CustomFollowRow>
      )),
    [data, handleFollow, handleUnfollow, me, myFollowing, s],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={followers ? s('titleFollowers') : s('titleFollowing')}
      onMount={handleMount}
    >
      <Content>{loading ? <Loader alignSelfCenter /> : renderData}</Content>
    </Modal>
  )
}

FollowersModal.defaultProps = {
  data: null,
  followers: false,
  following: false,
  loading: false,
  myFollowing: null,
}

FollowersModal.propTypes = {
  data: PropTypes.array,
  followers: PropTypes.bool,
  following: PropTypes.bool,
  loading: PropTypes.bool,
  myFollowing: PropTypes.array,
}

export default FollowersModal
