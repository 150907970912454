import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'

import { AUTH_ACTIONS, AUTH_STATE, AUTH_TOKEN_DEFAULTS } from 'Constants/store'

import { getStore, getWebSocketLink } from 'Services/Shared'

export function getAuth() {
  return get(getStore().get(), AUTH_STATE)
}

export function getAccessToken() {
  return get(getAuth(), 'accessToken')
}

export function getRefreshToken() {
  return get(getAuth(), 'refreshToken')
}

export function setAuth(variables) {
  return getStore().dispatch(AUTH_ACTIONS.SET, variables)
}

export function clearAuth() {
  return getStore().dispatch(AUTH_ACTIONS.CLEAR)
}

function notifyLinks(token) {
  const webSocketLink = getWebSocketLink()

  if (webSocketLink) webSocketLink.onTokenChanged(token)
}

export default function authState(store) {
  store.on('@init', () => ({
    [AUTH_STATE]: AUTH_TOKEN_DEFAULTS,
  }))

  store.on(AUTH_ACTIONS.SET, (state, variables) => {
    const values = pick(variables, Object.keys(AUTH_TOKEN_DEFAULTS))

    const currentAuth = state[AUTH_STATE]
    const nextAuth = { ...currentAuth, ...values }

    if (isEqual(nextAuth, currentAuth)) return null

    if (nextAuth?.accessToken !== currentAuth?.accessToken) {
      notifyLinks(nextAuth?.accessToken)
    }

    return { [AUTH_STATE]: nextAuth }
  })

  store.on(AUTH_ACTIONS.CLEAR, () => ({ [AUTH_STATE]: AUTH_TOKEN_DEFAULTS }))
}
