import uniqBy from 'lodash/uniqBy'

import NotificationRuleFragment from 'GraphQL/Fragments/NotificationRule.graphql'
import notificationRulesQuery from 'GraphQL/Queries/Notifications/notificationRules.graphql'

export function updateNotificationRulesQuery(cache, result, rulesVariables) {
  const rulesOptions = {
    query: notificationRulesQuery,
    variables: {
      ...rulesVariables,
    },
  }

  const rulesData = cache.readQuery(rulesOptions)

  cache.writeQuery({
    ...rulesOptions,
    data: {
      ...rulesData,
      notificationRules: {
        ...rulesData?.notificationRules,
        rows: uniqBy(
          [result, ...(rulesData?.notificationRules?.rows || [])],
          'id',
        ),
      },
    },
  })
}

export function updateNotificationRuleUpdater(cache, result) {
  const fragment = {
    id: `NotificationRule:${result?.id}`,
    fragmentName: 'NotificationRuleFields',
    fragment: NotificationRuleFragment,
  }

  const data = cache.readFragment(fragment)

  if (data) {
    cache.writeFragment({ ...fragment, data: { ...data, ...result } })
  }
}

export function updateNotificationsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    notifications: {
      count:
        fetchMoreResult?.notifications?.count || prev?.notifications?.count,
      pages:
        fetchMoreResult?.notifications?.pages || prev?.notifications?.pages,
      rows: uniqBy(
        [
          ...(prev?.notifications?.rows || []),
          ...(fetchMoreResult?.notifications?.rows || []),
        ],
        'id',
      ),
    },
  }
}

export function updateNotificationRulesQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    notificationRules: {
      ...prev?.notificationRules,
      count:
        fetchMoreResult?.notificationRules?.count ||
        prev?.notificationRules?.count,
      pages:
        fetchMoreResult?.notificationRules?.pages ||
        prev?.notificationRules?.pages,
      rows: uniqBy(
        [
          ...(prev?.notificationRules?.rows || []),
          ...(fetchMoreResult?.notificationRules?.rows || []),
        ],
        'id',
      ),
    },
  }
}
