import numeral from 'numeral'

import isNumber from 'lodash/isNumber'

export function formatWithSpaces(value, format = '0,0') {
  return numeral(value).format(format).replace(/,/g, ' ')
}

export function iterativeAverage(value, count, add) {
  return (value * count + add) / (count + 1)
}

export function weightedIterativeAverage(value, weight, add, addWeight) {
  return (value * weight + add * addWeight) / (weight + addWeight)
}

export function formatedCurrencyValue(value) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formatter.format(value)
}

export function formatWithLetter(value) {
  return numeral(value).format('0.0a')
}

export function getNumber(value) {
  if (value === 'auto' || isNumber(value)) {
    return value
  }
  return value?.replace(/[^0-9]/g, '') || 0
}
