import get from 'lodash/get'

import RoomFragment from 'GraphQL/Fragments/Room/Room.graphql'
import roomLockedSubscription from 'GraphQL/Subscriptions/roomLocked.graphql'
import roomUnlockedSubscription from 'GraphQL/Subscriptions/roomUnlocked.graphql'

import { useSubscription } from 'Services/Apollo'

// TODO: remove 'locked' argument once subscription is fixed on back-end
// locked state shouldn't be defined by front
// currently roomUnlocked sends locked: true
function updateFragment({ client, room, locked }) {
  const fragment = {
    id: `Room:${room?.id}`,
    fragmentName: 'RoomFields',
    fragment: RoomFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    client.writeFragment({
      ...fragment,
      data: { ...data, locked },
    })
  }
}

function RoomLockedUnlocked() {
  useSubscription(roomLockedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const room = get(subscriptionData, ['data', 'roomLocked'])

      updateFragment({ client, room, locked: true })
    },
  })

  useSubscription(roomUnlockedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const room = get(subscriptionData, ['data', 'roomUnlocked'])

      updateFragment({ client, room, locked: false })
    },
  })

  return null
}

export default RoomLockedUnlocked
