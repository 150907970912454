import { useMemo } from 'react'

import reduce from 'lodash/reduce'

export default function useUserRank(topRoomUsers) {
  return useMemo(
    () =>
      reduce(
        topRoomUsers,
        (acc, user, rank) => {
          acc[user?.id] = rank + 1
          return acc
        },
        {},
      ),
    [topRoomUsers],
  )
}
