import { useCallback, useState } from 'react'

import noop from 'lodash/noop'

import toast from 'Services/Toast'

export default function useEntityModal({
  context,
  refetch,
  doOnClose = noop,
  doOnOpen = noop,
} = {}) {
  const [modal, setModal] = useState({
    isOpen: false,
    entity: undefined,
  })

  const refetchOnClose = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await refetch()
      } catch (error) {
        toast.error({
          title: context,
          text: error?.message,
        })
      }
    },
    [context, refetch],
  )

  const handleClose = useCallback(
    async success => {
      let overrides = {}

      if (refetch != null) {
        await refetchOnClose(success)
      } else {
        overrides = await doOnClose({ modal, success })
      }

      setModal({
        isOpen: false,
        entity: undefined,
        ...overrides,
      })
    },
    [doOnClose, modal, refetch, refetchOnClose],
  )

  const handleOpen = useCallback(
    async entityOrEvent => {
      const overrides = await doOnOpen({ modal, entityOrEvent })

      setModal({
        isOpen: true,
        entity: entityOrEvent?.target ? undefined : entityOrEvent,
        ...overrides,
      })
    },
    [doOnOpen, modal],
  )

  return [
    modal,
    {
      closeModal: handleClose,
      openModal: handleOpen,
    },
  ]
}
