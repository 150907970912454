import React, { useEffect } from 'react'
import { useHistory } from 'react-router'

import { Container } from 'Components/Layout/Auth/styles'
import { Flex, Loader } from 'Components/UI'

import { USER_ROLE } from 'Constants/ids'

import { useAppContext, useEntranceContext } from 'Hooks'

import Utils from 'Utils'

function Redirector() {
  const { me, isAdmin, isSuperAdmin } = useAppContext()
  const history = useHistory()
  const [{ enteredFrom }] = useEntranceContext()

  useEffect(() => {
    if (
      enteredFrom &&
      me?.role === USER_ROLE.USER &&
      enteredFrom !== '/oauth/unlock' &&
      enteredFrom !== '/teken/callback' &&
      enteredFrom !== '/oauth/teken/callback' &&
      enteredFrom !== '/oauth/twitter/callback' &&
      enteredFrom !== '/oauth/linkedin/callback'
    ) {
      history.replace(enteredFrom)
    } else {
      history.replace(Utils.Me.getRootRoute(me))
    }
  }, [history, me, isAdmin, isSuperAdmin, enteredFrom])

  return (
    <Container>
      <Flex
        alignItems="center"
        flex="1 0"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Loader />
        Redirect...
      </Flex>
    </Container>
  )
}

export default Redirector
