import { DOMAINS, IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from 'Config'

function getSubDomain() {
  const url = new URL(window?.location?.href)
  const hostname = url?.hostname?.replace('www.', '')
  return hostname?.split('.')[0]
}

function checkSubDomain() {
  const subdomain = getSubDomain()

  if (IS_DEVELOPMENT && subdomain === DOMAINS.DEVELOPMENT) return null
  if (IS_PRODUCTION && subdomain === DOMAINS.PRODUCTION) return null
  if (IS_STAGING && subdomain === DOMAINS.STAGING) return null

  return subdomain
}

export { checkSubDomain }
