import { useCallback, useState } from 'react'

import debounce from 'lodash/debounce'

function useDonationModal() {
  const [donationModal, setDonationModal] = useState({
    isOpen: false,
    entity: null,
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDonation = useCallback(
    debounce(entity => {
      setDonationModal({ isOpen: true, entity })
    }, 2000),
    [],
  )

  const handleDonationClick = useCallback(entity => {
    setDonationModal({ isOpen: true, entity })
  }, [])

  const handleDonation = useCallback(
    entity => {
      debouncedDonation(entity)
    },
    [debouncedDonation],
  )

  const handleDonationTimeout = useCallback(() => {
    debouncedDonation.cancel()
  }, [debouncedDonation])

  const handleCloseDonationModal = useCallback(() => {
    setDonationModal({ isOpen: false, entity: null })
  }, [])

  return {
    donationModal,
    handleDonation,
    handleDonationClick,
    handleDonationTimeout,
    handleCloseDonationModal,
  }
}

export default useDonationModal
