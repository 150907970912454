import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin, space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Wrapper = styled.div`
  ${space}
  ${layout}
`

const labelErrorCss = ({ error }) =>
  error &&
  css`
    color: ${themeGet('colors.warning')};
  `

export const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 15px;
  font-size: 12px;
  line-height: normal;
  color: ${themeGet('colors.font.secondary')};

  ${margin}
  ${labelErrorCss}
`

const thirdCss = ({ third }) =>
  third &&
  css`
    position: absolute;
    top: -22px;
    left: 28px;
    padding: 0;
  `

const secondaryCss = ({ secondary }) =>
  secondary &&
  css`
    position: absolute;
    bottom: 0;
    left: 57px;
    padding: 0;
  `

export const ErrorWrapper = styled.div`
  color: ${themeGet('colors.warning')};
  font-size: 12px;
  padding: 0 12px;

  ${secondaryCss}
  ${thirdCss}
`
