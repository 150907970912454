import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { FiCalendar } from 'react-icons/fi'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

// eslint-disable-next-line import/no-extraneous-dependencies
import Tippy from '@tippyjs/react/headless'

import noop from 'lodash/noop'

import DateRangePicker from 'Components/UI/DatePicker/RangePicker'

import { Container, Controls, Value } from './styles'

import Button from '../Button'
import { Column } from '../Flex'

function DateRangeInput({ placeholder, small, button, onChange, ...rest }) {
  const tippyInstance = useRef(null)
  const resetInstance = useRef(null)
  const [dates, setDates] = useState(null)
  const theme = useTheme()

  const handleReset = useCallback(
    instance => {
      setDates(null)
      onChange(null)
      resetInstance.current = instance
    },
    [onChange],
  )

  const handleChooseDates = useCallback(
    pickedDates => setDates(pickedDates),
    [],
  )

  const handleFinish = useCallback(() => {
    onChange(dates)

    if (tippyInstance.current) {
      tippyInstance.current.hide()
    }
  }, [dates, onChange])

  const value = useMemo(() => {
    if (!dates) {
      return placeholder
    }

    const { from, to } = dates
    return `${from.toLocaleString()} — ${to.toLocaleString()}`
  }, [dates, placeholder])

  return (
    <Tippy
      interactive
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: { padding: 16 },
          },
        ],
      }}
      render={() => (
        <Column>
          <DateRangePicker
            reset={resetInstance?.current}
            onFinish={handleChooseDates}
          />
          <Controls center pl={24} pr={24} spaceBetween width={1}>
            <Button
              height={32}
              mr={9}
              outline
              onClick={instance => handleReset(instance)}
            >
              Reset
            </Button>
            <Button height={32} outline onClick={handleFinish}>
              Apply
            </Button>
          </Controls>
        </Column>
      )}
      trigger="click"
      onMount={instance => {
        tippyInstance.current = instance
      }}
    >
      {button || (
        <Container {...pick(rest)} small={small}>
          <FiCalendar color={theme.colors.primary} size={12} />
          <Value ml={1}>{value}</Value>
        </Container>
      )}
    </Tippy>
  )
}

DateRangeInput.defaultProps = {
  button: null,
  placeholder: null,
  small: false,
  onChange: noop,
}

DateRangeInput.propTypes = {
  button: PropTypes.object,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  onChange: PropTypes.func,
}

export default DateRangeInput
