import React from 'react'
import PropTypes from 'prop-types'

import styled, { ThemeProvider as BaseThemeProvider } from 'styled-components'

import { useStoreon } from 'storeon/react'

import { UI_STATE } from 'Constants/store'

import theme from './defaultTheme'
import themes from './themes'

const Base = styled.div``

function ThemeProvider(props) {
  const { ui } = useStoreon(UI_STATE)
  const { light, dark } = themes
  const darkMode = ui?.darkMode
  const palette = ui?.palette

  return (
    <BaseThemeProvider
      theme={{
        ...theme,
        ...props.theme,
        ...(darkMode ? dark(palette) : light(palette)),
      }}
    >
      <Base {...props} />
    </BaseThemeProvider>
  )
}

ThemeProvider.defaultProps = {
  theme,
}

ThemeProvider.propTypes = {
  theme: PropTypes.shape({
    breakpoints: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    space: PropTypes.arrayOf(PropTypes.number),
    fontSizes: PropTypes.arrayOf(PropTypes.number),
    weights: PropTypes.arrayOf(PropTypes.number),
    colors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    font: PropTypes.string,
    monospace: PropTypes.string,
    radius: PropTypes.number,
  }),
}

export default ThemeProvider
