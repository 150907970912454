import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { FiUpload } from 'react-icons/fi'

import _, { useScopedI18n } from 'Services/I18n'

import { Container, SelectFile, Text } from './styles'

function Dropzone({ onDrop }) {
  const s = useScopedI18n('dropzone')

  const handleDrop = useCallback(
    files => {
      onDrop(files)
    },
    [onDrop],
  )

  const { getRootProps, getInputProps /* isDragActive */ } = useDropzone({
    onDrop: handleDrop,
  })

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <FiUpload size={48} strokeWidth={1} />
      <Text mt={3}>
        {s('dragNDrop')} {_('general.orLower')}
      </Text>
      <SelectFile mt={1}>{s('select')}</SelectFile>
    </Container>
  )
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
}
export default Dropzone
