import get from 'lodash/get'

import { ROOM_COUNTER_KIND } from 'Constants/ids'

import RoomFragment from 'GraphQL/Fragments/Room/Room.graphql'
import roomCounterUpdatedSubscription from 'GraphQL/Subscriptions/roomCounterUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function RoomCounters() {
  useSubscription(roomCounterUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const counter = get(subscriptionData, ['data', 'roomCounterUpdated'])
      const { kind, roomId, value } = counter
      const fragment = {
        id: `Room:${roomId}`,
        fragmentName: 'RoomFields',
        fragment: RoomFragment,
      }

      const data = client.readFragment(fragment)

      if (data) {
        switch (kind) {
          case ROOM_COUNTER_KIND.UNREAD_COUNT:
            client.writeFragment({
              ...fragment,
              data: { ...data, unreadCount: value },
            })
            break
          case ROOM_COUNTER_KIND.PARTICIPANTS_COUNT:
            client.writeFragment({
              ...fragment,
              data: { ...data, participantsCount: value },
            })
            break
          case ROOM_COUNTER_KIND.ONLINE_PARTICIPANTS_COUNT:
            client.writeFragment({
              ...fragment,
              data: { ...data, onlineParticipantsCount: value },
            })
            break
          case ROOM_COUNTER_KIND.UNREAD_EVENTS_COUNT:
            client.writeFragment({
              ...fragment,
              data: { ...data, unreadEventsCount: value },
            })
            break
          default:
            client.writeFragment({
              ...fragment,
              data: { ...data },
            })
        }
      }
    },
  })

  return null
}

export default RoomCounters
