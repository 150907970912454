import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  min-height: 200px;
  max-height: 360px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  width: 98%;
  padding: ${themeGet('space.5')}px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const NotificationRule = styled.div`
  height: 150px;
  width: 100%;
  background: ${themeGet('colors.bg.lightest')};
  border: 1px solid;
  border-color: ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  padding: ${themeGet('space.4')}px;

  ${margin}
`

export const FirefoxPaginationAnchor = styled.span``
