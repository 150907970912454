import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'

import desktopImage from 'Assets/Png/desktop.png'
import mobileImage from 'Assets/Png/mobile.png'

import { Button, Column, Loader, Modal, Row, Text } from 'Components/UI'

import disconnectDeviceMutation from 'GraphQL/Mutations/Devices/disconnectDevice.graphql'
import { devicesUpdater } from 'GraphQL/Updaters/devices'

import { useAdminMutation, useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Device, Image } from './styles'

function DevicesModal({ devices, userId, secondary, ...rest }) {
  const s = useScopedI18n('modal.devices')
  const [loading, setLoading] = useState(false)
  const [devicesList, setDevicesList] = useState([])

  const [disconnectDevice] = useMutation(disconnectDeviceMutation)
  const [adminDisconnectDevice] = useAdminMutation(disconnectDeviceMutation)
  const disconnect = !secondary ? disconnectDevice : adminDisconnectDevice

  const close = useRef(null)

  useEffect(() => {
    if (devices?.length > 0) {
      setDevicesList(devices)
    }
  }, [devices])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleDisconnectDevice = useCallback(
    async id => {
      setLoading(true)
      try {
        await disconnect({
          variables: { id },
          update: devicesUpdater(id),
        })

        setDevicesList([
          ...(filter(devicesList, device => device?.id !== id) || []),
        ])

        toast.success({
          title: 'Disconnect device',
          text: 'Device has been successfully disconnected',
        })
      } catch (error) {
        toast.error({
          title: 'Disconnect device',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [devicesList, disconnect],
  )

  const deviceTitle = useCallback(
    ({ isApplication, browser, name }) => {
      if (isApplication) {
        return <Text middle>{name}</Text>
      }

      return <Text middle>{s(`browsers.${browser}`)}</Text>
    },
    [s],
  )
  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={s('title')}
      onMount={handleMount}
    >
      <Content>
        {map(
          devicesList,
          ({ id, browser, name, lastConnectedAt, isApplication }) => (
            <Device key={id} mt={17} px={16} py={12}>
              <Row center width={1}>
                <Image
                  height={isApplication ? 51 : 31}
                  src={isApplication ? mobileImage : desktopImage}
                  width={54}
                />
                <Column ml={16}>
                  {deviceTitle({ isApplication, browser, name })}
                  <Row mt={2}>
                    <Text primary small>
                      {s('fields.connectedAt')}
                    </Text>
                    <Text ml={1} primary small>
                      {DateTime.fromISO(lastConnectedAt).toLocaleString(
                        DateTime.DATETIME_SHORT_WITH_SECONDS,
                      )}
                    </Text>
                  </Row>
                </Column>
              </Row>
              {loading ? (
                <Loader />
              ) : (
                <Button
                  plainTextButton
                  sixth
                  onClick={() => handleDisconnectDevice(id)}
                >
                  {s('actions.disconnect')}
                </Button>
              )}
            </Device>
          ),
        )}
      </Content>
    </Modal>
  )
}

DevicesModal.defaultProps = {
  devices: [],
  refetch: null,
  secondary: false,
  userId: null,
}

DevicesModal.propTypes = {
  devices: PropTypes.array,
  refetch: PropTypes.func,
  secondary: PropTypes.bool,
  userId: PropTypes.string,
}

export default DevicesModal
