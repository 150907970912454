import loadable from '@loadable/component'

const App = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "app-root" */)),
  Room: loadable(() => import('./Room' /* webpackChunkName: "app-room" */)),
  Profile: loadable(() =>
    import('./Profile' /* webpackChunkName: "app-profile" */),
  ),
  Post: loadable(() =>
    import('./Post' /* webpackChunkName: "app-room-post" */),
  ),
  MyFeed: loadable(() =>
    import('./MyFeed' /* webpackChunkName: "app-room-post" */),
  ),
  Channel: loadable(() =>
    import('./Channel' /* webpackChunkName: "app-room-channel" */),
  ),
  DirectChannel: loadable(() =>
    import('./DirectChannel' /* webpackChunkName: "app-inbox-channel" */),
  ),
}

export default App
