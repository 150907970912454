import React from 'react'

import { Area, AreaChart, ResponsiveContainer } from 'recharts'

import shuffle from 'lodash/shuffle'

const data = shuffle([
  {
    name: 'Page A',
    first: 7400,
    second: 2400,
    third: 2000,
  },
  {
    name: 'Page B',
    first: 5398,
    second: 2210,
    third: 1500,
  },
  {
    name: 'Page C',
    first: 10800,
    second: 3290,
    third: 2000,
  },
  {
    name: 'Page D',
    first: 5908,
    second: 4000,
    third: 2780,
  },
  {
    name: 'Page E',
    first: 5800,
    second: 5181,
    third: 1890,
  },
  {
    name: 'Page F',
    first: 4800,
    second: 4500,
    third: 2390,
  },
  {
    name: 'Page G',
    first: 5300,
    second: 3100,
    third: 2190,
  },
])

function Chart() {
  return (
    <ResponsiveContainer>
      <AreaChart
        data={data}
        height={280}
        margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
        width={280}
      >
        <Area
          dataKey="first"
          fill="#ffc658"
          fillOpacity={1}
          stroke="none"
          type="monotone"
        />
        <Area
          dataKey="second"
          fill="#a4de6c"
          fillOpacity={1}
          stroke="none"
          type="monotone"
        />
        <Area
          dataKey="third"
          fill="#83a6ed"
          fillOpacity={1}
          stroke="none"
          type="monotone"
        />
      </AreaChart>
    </ResponsiveContainer>
  )
}

export default Chart
