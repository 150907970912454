import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { matchPath, useHistory, useLocation } from 'react-router'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import { useApolloClient } from '@apollo/client'
import { useStoreon } from 'storeon/react'

import map from 'lodash/map'
import noop from 'lodash/noop'

import { Loader, PaginationAnchor, Row, Text } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import favoriteRoomMutation from 'GraphQL/Mutations/Favorites/favoriteRoom.graphql'
import unfavoriteRoomMutation from 'GraphQL/Mutations/Favorites/unfavoriteRoom.graphql'
import joinRoomMutation from 'GraphQL/Mutations/Rooms/joinRoom.graphql'
import postsFeedMeta from 'GraphQL/Queries/Posts/postsFeedMeta.graphql'
import canJoinRoomQuery from 'GraphQL/Queries/Rooms/canJoinRoom.graphql'
import { markFavoriteUpdater } from 'GraphQL/Updaters/rooms'

import {
  useAppContext,
  useEntranceContext,
  useRoomData,
  useRouteState,
} from 'Hooks'

import { APP_MY_FEED, APP_ROOM, APP_ROOT } from 'Router/routes'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import { checkSubDomain } from 'Services/Domain'
import toast from 'Services/Toast'

import Category from './Category'
import LayeredRoom from './LayeredRoom'
import SingleRoom from './SingleRoom'

import { ScrollingContainer, UnreadWrapper } from '../../styles'

function RoomsList({
  displayFavorites,
  loading,
  isCollapsed,
  roomsData,
  onDisplayFavorites,
  onLoadMore,
  onRefetchCategories,
}) {
  const [{ exclusiveMode }] = useEntranceContext()
  const { me } = useAppContext()
  const { isDirectLink, setIsDirectLink } = useRouteState()
  const history = useHistory()
  const location = useLocation()
  const client = useApolloClient()
  const subDomain = checkSubDomain()
  const { breakpoints } = useTheme()

  const pathname = location?.pathname ?? ''
  const { state } = location

  const { ui, dispatch } = useStoreon(UI_STATE)

  const { darkMode, channelsCollapsed } = ui

  const [joinViaLink, setJoinViaLink] = useState(false)

  const [
    loadFeedMeta,
    { data: postsFeedMetaData, loading: postsFeedMetaLoading },
  ] = useLazyQuery(postsFeedMeta)

  const [markFavorite] = useMutation(favoriteRoomMutation)
  const [unmarkFavorite] = useMutation(unfavoriteRoomMutation)

  const [joinRoom] = useMutation(joinRoomMutation)

  const isSubdomainMainRoom = useMemo(
    () => matchPath(pathname, { path: APP_ROOT }),
    [pathname],
  )

  const routeParams = useMemo(
    () =>
      matchPath(pathname, {
        path: APP_ROOM(),
      }),
    [pathname],
  )

  const roomPointer = useMemo(
    () =>
      subDomain && isSubdomainMainRoom?.isExact
        ? subDomain
        : routeParams?.params?.roomPointer ?? '',
    [subDomain, isSubdomainMainRoom, routeParams?.params?.roomPointer],
  )

  const roomBySlug = useRoomData(roomPointer)

  const myFeedPage = matchPath(pathname, {
    path: APP_MY_FEED,
    exact: true,
    strict: false,
  })

  const handleFavorite = useCallback(
    async (favorite, roomId, slug) => {
      try {
        const mark = favorite ? unmarkFavorite : markFavorite
        await mark({
          variables: { roomId },
          update: markFavoriteUpdater(roomId, !favorite),
        })
        if (state && state?.link) {
          if (subDomain && slug === subDomain) {
            history.replace(APP_ROOT)
          } else {
            history.replace(APP_ROOM(slug))
          }
        }
        if (onRefetchCategories) {
          await onRefetchCategories()
        }
      } catch (error) {
        toast.error({
          title: 'Add to Favorite',
          text: error?.message,
        })
      }
    },
    [
      subDomain,
      history,
      markFavorite,
      onRefetchCategories,
      state,
      unmarkFavorite,
    ],
  )

  const handleJoinRoom = useCallback(
    async (roomId, locked, slug) => {
      if (isMobile || window?.innerWidth < parseInt(breakpoints[3], 10)) {
        dispatch(UI_ACTIONS.SET, {
          leftIsCollapsed: true,
          channelsCollapsed: [...(channelsCollapsed || []), roomId],
        })
      }

      if (locked) {
        const { data: joinData } = await client.query({
          query: canJoinRoomQuery,
          variables: { roomId },
          fetchPolicy: 'network-only',
        })

        if (joinData?.canJoinRoom?.success) {
          try {
            await joinRoom({ variables: { roomId } })
          } catch (error) {
            toast.error({ title: 'Cannot join the Room', text: error?.message })
          }
        }
      }

      if (!roomId) {
        history.push(APP_MY_FEED)
      } else {
        history.replace(APP_ROOM(slug))
      }
    },
    [channelsCollapsed, dispatch, breakpoints, client, joinRoom, history],
  )

  useEffect(() => {
    if (!me || exclusiveMode) return

    loadFeedMeta().then()
  }, [loadFeedMeta, exclusiveMode, me])

  useEffect(() => {
    async function checkJoinLink() {
      const { data: joinData } = await client.query({
        query: canJoinRoomQuery,
        variables: { roomId: roomBySlug?.roomId },
      })

      if (joinData?.canJoinRoom?.success) {
        setJoinViaLink(true)
      }

      setIsDirectLink(false)
    }

    if (isDirectLink && !loading && roomBySlug?.roomId) {
      checkJoinLink()
    }
  }, [client, isDirectLink, loading, roomBySlug, setIsDirectLink])

  useEffect(() => {
    async function joinLink() {
      try {
        const response = await joinRoom({
          variables: { roomId: roomBySlug?.roomId },
        })

        if (response?.data?.joinRoom?.success) {
          setJoinViaLink(false)

          if (!roomBySlug?.favorite) {
            await handleFavorite(
              roomBySlug?.favorite,
              roomBySlug?.roomId,
              roomBySlug?.slug,
            )
          }
        }
      } catch (error) {
        toast?.error({
          title: 'Join Room',
          text: error?.message,
        })
      } finally {
        onDisplayFavorites(true)
      }
    }

    if (joinViaLink) {
      joinLink()
    }
  }, [handleFavorite, joinRoom, joinViaLink, onDisplayFavorites, roomBySlug])

  const unread = useCallback((unreadCount, absolute = false) => {
    if (unreadCount > 25) {
      return (
        <UnreadWrapper absolutePosition={absolute}>
          <Text small white>
            25+
          </Text>
        </UnreadWrapper>
      )
    }
    if (unreadCount > 0 && unreadCount <= 25) {
      return (
        <UnreadWrapper absolutePosition={absolute}>
          <Text small white>
            {unreadCount}
          </Text>
        </UnreadWrapper>
      )
    }
    return <Row height={18} />
  }, [])

  return (
    <ScrollingContainer>
      {exclusiveMode || (
        <SingleRoom
          darkMode={darkMode}
          isCollapsed={isCollapsed}
          isMyFeed
          isSelected={myFeedPage?.isExact}
          myFeedUnreadCount={postsFeedMetaData?.postsFeedMeta?.unreadCount}
          onJoin={handleJoinRoom}
          onUnread={unread}
        />
      )}

      {map(roomsData, roomData => {
        const roomRegex = new RegExp(`(?:\\b${roomData?.slug}\\b)`, 'g')

        if (roomData?.rooms?.length > 0) {
          return (
            <Category
              category={roomData}
              darkMode={darkMode}
              domain={subDomain}
              isCollapsed={isCollapsed}
              isSubdomainMainRoom={isSubdomainMainRoom?.isExact}
              key={roomData?.id}
              pathname={pathname}
              onFavorite={handleFavorite}
              onJoin={handleJoinRoom}
              onUnread={unread}
            />
          )
        }
        if (
          !roomData?.rooms &&
          roomData?.groupRooms?.length > 0 &&
          roomData?.isDefaultLayered
        ) {
          return (
            <LayeredRoom
              darkMode={darkMode}
              domain={subDomain}
              isCollapsed={isCollapsed}
              isSubdomainMainRoom={isSubdomainMainRoom?.isExact}
              key={roomData?.id}
              room={roomData}
              onFavorite={handleFavorite}
              onJoin={handleJoinRoom}
              onUnread={unread}
            />
          )
        }
        if (!roomData?.rooms) {
          return (
            <SingleRoom
              darkMode={darkMode}
              isCollapsed={isCollapsed}
              isSelected={
                pathname.includes(roomData?.id) ||
                pathname.match(roomRegex) ||
                (roomData?.slug === subDomain &&
                  isSubdomainMainRoom?.isExact) ||
                (subDomain === roomData?.slug &&
                  pathname.includes('post') &&
                  !pathname.includes('room'))
              }
              key={roomData?.id}
              room={roomData}
              onFavorite={handleFavorite}
              onJoin={handleJoinRoom}
              onUnread={unread}
            />
          )
        }
        return null
      })}

      {(loading || postsFeedMetaLoading) && (
        <Row justifyCenter>
          <Loader alignSelfCenter mt={50} text="Loading..." />
        </Row>
      )}

      {displayFavorites && (
        <Waypoint onEnter={onLoadMore}>
          <PaginationAnchor />
        </Waypoint>
      )}
    </ScrollingContainer>
  )
}

RoomsList.defaultProps = {
  displayFavorites: false,
  isCollapsed: false,
  loading: false,
  roomsData: {},
  onDisplayFavorites: noop,
  onLoadMore: noop,
  onRefetchCategories: noop,
}

RoomsList.propTypes = {
  displayFavorites: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  loading: PropTypes.bool,
  roomsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onDisplayFavorites: PropTypes.func,
  onLoadMore: PropTypes.func,
  onRefetchCategories: PropTypes.func,
}

export default RoomsList
