import forEach from 'lodash/forEach'

import { SORT_ORDER_TYPES } from 'Constants/ids'

import ChannelFragment from 'GraphQL/Fragments/Channel.graphql'

export const updateSortOrder = {
  merge(_, incoming, { cache, variables }) {
    if (!incoming?.ok) return
    const { type, data } = variables
    switch (type) {
      case SORT_ORDER_TYPES.CHANNEL:
        forEach(data, entry => {
          cache.updateFragment(
            {
              id: `Channel:${entry?.id}`,
              fragment: ChannelFragment,
              fragmentName: 'ChannelFields',
            },
            fragmentData => ({
              ...(fragmentData || {}),
              sortOrder: entry?.sortOrder,
            }),
          )
        })
        break
      default:
        break
    }
  },
}
