import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import SmileGlyph from 'Assets/Svg/smile.svg'

const alternateCss = ({ alternate }) =>
  alternate &&
  css`
    position: relative;
    padding-bottom: 0;
  `

export const Container = styled.div`
  padding-bottom: ${themeGet('space.4')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  max-width: 100%;

  ${alternateCss}

  ${layout}
  ${margin}
`

export const SmileIcon = styled(SmileGlyph)``
