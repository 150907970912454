import styled from 'styled-components'

import backgroundImage from 'Assets/Png/maintenanceBackground.png'

export const BackgroundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${backgroundImage});
  object-fit: fill;
  width: 100vw;
  height: 100vh;
`
