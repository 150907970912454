import React from 'react'

import { Link } from 'Components/UI'

import { ROOT } from 'Router/routes'

import { Code, Container } from './styles'

export default function NotFound() {
  return (
    <Container>
      <Code>404</Code>
      Page not found
      <Link to={ROOT}>Go to the root</Link>
    </Container>
  )
}
