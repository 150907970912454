import styled from 'styled-components'
import { margin } from '@styled-system/space'

import { flexCenter } from 'Components/Styles'

export const ExpanderContainer = styled.div`
  ${flexCenter};
  height: 100%;
  cursor: pointer;

  ${margin}
`
