import React from 'react'
import { components } from 'react-select'

import { Value } from './styles'

const SingleValue = props => <Value as={components.SingleValue} {...props} />

SingleValue.propTypes = {
  ...components.SingleValue.propTypes,
}

export default SingleValue
