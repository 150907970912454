import { AdminLayout } from 'Components/Layout'

import Admin from 'Pages/Admin'

import * as ROUTES from './routes'

export const ADMIN_ROUTES = {
  component: AdminLayout,
  path: ROUTES.ADMIN_ROOT,
  routes: [
    {
      component: Admin.Root,
      path: ROUTES.ADMIN_ROOT,
      exact: true,
    },
    {
      component: Admin.Admins,
      path: ROUTES.ADMIN_ADMINS,
      exact: true,
    },
    {
      component: Admin.Members,
      path: ROUTES.ADMIN_MEMBERS,
      exact: true,
    },
    {
      component: Admin.Rooms,
      path: ROUTES.ADMIN_ROOMS,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_ROOMS_CREATE(),
      component: Admin.Room,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_ROOMS_EDIT(),
      component: Admin.Room,
      exact: true,
    },
    {
      component: Admin.Settings,
      path: ROUTES.ADMIN_SETTINGS,
      exact: true,
    },
    {
      component: Admin.Tokens,
      path: ROUTES.ADMIN_TOKENS,
      exact: true,
    },
    {
      component: Admin.Categories,
      path: ROUTES.ADMIN_CATEGORIES,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_CATEGORY(),
      component: Admin.Category,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_ROOM_GROUP(),
      component: Admin.RoomGroup,
      exact: true,
    },
    {
      component: Admin.SlugBlacklist,
      path: ROUTES.ADMIN_SLUG_BLACKLIST,
      exact: true,
    },
  ],
}
