const APOLLO_CLIENT = 'apolloClient'
const WEB_SOCKET_LINK = 'webSocketLink'
const STORE = 'store'
const WALLET_CONNECT_CLIENT = 'walletConnectClient'

const services = {}

function checkHasValue(serviceName) {
  if (!services[serviceName])
    throw new Error(`${serviceName} not found in Shared`)

  return services[serviceName]
}

function setApolloClient(apolloClient) {
  services[APOLLO_CLIENT] = apolloClient
}

function setStore(store) {
  services[STORE] = store
}

function setWebSocketLink(webSocketLink) {
  services[WEB_SOCKET_LINK] = webSocketLink
}

function setWalletConnectClient(walletConnectClient) {
  services[WALLET_CONNECT_CLIENT] = walletConnectClient
}

function getApolloClient() {
  return checkHasValue(APOLLO_CLIENT)
}

function getStore() {
  return checkHasValue(STORE)
}

function getWebSocketLink() {
  return checkHasValue(WEB_SOCKET_LINK)
}

function getWalletConnectClient() {
  return checkHasValue(WALLET_CONNECT_CLIENT)
}

export {
  getApolloClient,
  getStore,
  getWalletConnectClient,
  getWebSocketLink,
  setApolloClient,
  setStore,
  setWalletConnectClient,
  setWebSocketLink,
}
