import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Flex } from 'Components/UI'

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${themeGet('space.3')}px;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  border: 1px dashed ${themeGet('colors.border.default')};

  > svg {
    color: ${themeGet('colors.font.secondary')};
  }

  ${props =>
    props.isDragActive &&
    css`
      border-color: ${themeGet('colors.green')};
    `};

  ${layout.width}
  ${margin}
`
const flexBlockCss = css`
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
  z-index: 3;
  border-radius: 4px;

  :hover {
    color: ${themeGet('colors.primary')};
  }
`

export const DeleteButton = styled.div`
  ${flexBlockCss};
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
`

export const Container = styled(Flex)`
  flex-wrap: wrap;
  position: relative;

  :hover {
    ${DeleteButton} {
      display: flex;
    }
  }

  ${layout.width};
  ${layout.height};
`

const valueCss = ({ value }) => css`
  width: ${Math.max(Math.min(value || 0, 100), 0)}%;
`

export const Progress = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 16px;

  width: 80px;
  height: 8px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;

  :after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background: ${themeGet('colors.primary')};
    border-radius: 3px;
    width: 100%;
    transition: all 1s ease;
    max-width: 136px;

    ${valueCss};
  }
`
