import { captureException } from '@sentry/browser'
import qs from 'qs'
import Request from 'superagent'

import forOwn from 'lodash/forOwn'
import noop from 'lodash/noop'

import { API, IS_PRODUCTION } from 'Config'

import { getAccessToken } from 'Services/Store/auth'

const sendMethod = HTTPMethod =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? 'send'
    : 'query'

const sendArguments = (HTTPMethod, query) =>
  HTTPMethod === 'post' ||
  HTTPMethod === 'put' ||
  HTTPMethod === 'patch' ||
  HTTPMethod === 'delete'
    ? query
    : qs.stringify(query, { arrayFormat: 'brackets' })

const defaultOptions = {
  url: `${API.URL}/v1`,
  endpoint: '',
  method: 'GET',
  query: {},
  headers: {},
  file: null,
  withoutAuthorization: false,
  onProgress: noop,
}

const absoluteUrl = new RegExp('^(?:[a-z]+:)?//', 'i')

export default function apiCall(options) {
  const agent = Request.agent()

  const {
    url,
    endpoint,
    method,
    file,
    query,
    headers,
    payload,
    withoutAuthorization,
    onProgress,
  } = { ...defaultOptions, ...options }

  const allHeaders = {
    ...headers,
  }

  if (!withoutAuthorization) {
    const accessToken = getAccessToken()

    if (accessToken) {
      allHeaders.Authorization = `Bearer ${accessToken}`
    }
  }

  const HTTPMethod = method.toLowerCase()

  const fullUrl = absoluteUrl.test(endpoint) ? endpoint : url + endpoint

  const request = agent[HTTPMethod](fullUrl)

  if (file) {
    request.attach('file', file)

    forOwn(query, (value, key) => {
      request.field(key, value)
    })
  } else {
    request[sendMethod(HTTPMethod)](sendArguments(HTTPMethod, query))
  }

  return new Promise((resolve, reject) => {
    request
      .set(allHeaders)
      .on('progress', onProgress)
      .end((error, req) => {
        if (IS_PRODUCTION) {
          captureException(error) // Sentry exception capture
        }

        let body = req?.body

        if (payload) {
          body = { ...body, payload }
        }

        if (error) {
          reject(error)
        } else {
          resolve(body)
        }
      })
  })
}
