import { useCallback, useMemo } from 'react'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'

import map from 'lodash/map'

import channelsByRoomIdQuery from 'GraphQL/Queries/Admin/Channels/channelsByRoomId.graphql'

export default function useChannelOptions({ roomId }) {
  const client = useApolloClient()

  const fetchChannels = useCallback(
    async (inputValue, callback) => {
      const variables = { roomId, limit: 20 }

      if (inputValue) {
        variables.search = inputValue
      }

      const response = await client.query({
        query: channelsByRoomIdQuery,
        context: { admin: true },
        fetchPolicy: 'network-only',
        variables,
        skip: !roomId,
      })

      const channels = map(response?.data?.channelsByRoomId?.rows, row => ({
        label: row?.name,
        value: row?.id,
      }))

      callback(channels)
    },
    [client, roomId],
  )

  return useMemo(
    () => ({ fetchChannelsData: debounce(fetchChannels, 700) }),
    [fetchChannels],
  )
}
