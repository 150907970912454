import React from 'react'
import PropTypes from 'prop-types'

import { Row } from 'Components/UI/Flex'
import Text from 'Components/UI/Text'

import { TIER_LABEL } from 'Constants/ids'

import { MembershipWrapper } from './styles'

function MembershipWrap({ tier, short }) {
  return (
    <MembershipWrapper color={tier}>
      {!short ? (
        <Row>
          <Text lineHeight="1.05em" medium small uppercase>
            {TIER_LABEL[tier]}
          </Text>
          <Text lineHeight="1.05em" medium ml={1} small uppercase>
            member
          </Text>
        </Row>
      ) : (
        <Text lineHeight="1.05em" medium small uppercase>
          {TIER_LABEL[tier]}
        </Text>
      )}
    </MembershipWrapper>
  )
}

MembershipWrap.defaultProps = {
  short: false,
  tier: '',
}

MembershipWrap.propTypes = {
  short: PropTypes.bool,
  tier: PropTypes.string,
}

export default MembershipWrap
