import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'
import { useStoreon } from 'storeon/react'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Link, Row, Text } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import { APP_PROFILE } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { Content } from '../styles'

function General({ userId, username, profile, isCollapsed, createdAt }) {
  const { dispatch } = useStoreon(UI_STATE)

  const s = useScopedI18n('user.profile')
  const memberSince = DateTime.fromISO(createdAt).toLocaleString(
    DateTime.DATE_SHORT,
  )

  const handleCollapsed = useCallback(() => {
    if (isMobile) {
      dispatch(UI_ACTIONS.SET, {
        rightIsCollapsed: !isCollapsed,
      })
    }
  }, [dispatch, isCollapsed])

  return (
    <Content>
      {!isCollapsed ? (
        <Row>
          <Column>
            <Link clean to={APP_PROFILE(userId)} onClick={handleCollapsed}>
              <Avatar size={40} src={profile?.photoUrl} username={username} />
            </Link>
          </Column>
          <Column ml={12}>
            <Row>
              <Text bold overflowWrap>
                {username ? `@${username}` : '—'}
              </Text>
            </Row>
            {/* <Row mt={1}>
              {membership !== TIER.guest && (
                <MembershipWrap tier={membership} />
              )}
            </Row> */}
            <Row mt={2}>
              <Text primary small>
                {s('fields.member')}
              </Text>
              <Text ml={1} primary small>
                {memberSince}
              </Text>
            </Row>
          </Column>
        </Row>
      ) : (
        <Column center width={1}>
          <Link clean to={APP_PROFILE(userId)}>
            <Avatar size={40} src={profile?.photoUrl} username={username} />
          </Link>
          {/* <Row mt={2}>
            {membership !== TIER.guest && (
              <MembershipWrap short tier={membership} />
            )}
          </Row> */}
        </Column>
      )}
    </Content>
  )
}

General.defaultProps = {
  createdAt: null,
  isCollapsed: false,
}

General.propTypes = {
  createdAt: PropTypes.string,
  isCollapsed: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}

export default General
