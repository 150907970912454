import loadable from '@loadable/component'

const Telegram = {
  LoginCallback: loadable(() =>
    import('./LoginCallback' /* webpackChunkName: "telegram-login-callback" */),
  ),
  LoginRedirect: loadable(() =>
    import('./LoginRedirect' /* webpackChunkName: "telegram-login-redirect" */),
  ),
  Auth: loadable(() =>
    import('./Auth' /* webpackChunkName: "telegram-auth" */),
  ),
}

export default Telegram
