export const ROOM_META_LINKS = [
  {
    label: 'Coinmarketcap',
    value: 'coinmakertcap',
  },
  {
    label: 'Coingecko',
    value: 'coingecko',
  },
  {
    label: 'Etherscan',
    value: 'etherscan',
  },
  {
    label: 'Coinmetro',
    value: 'coinmetro',
  },
  {
    label: 'Twitter',
    value: 'twitter',
  },
  {
    label: 'Telegram',
    value: 'telegram',
  },
  {
    label: 'Discord',
    value: 'discord',
  },
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Dextools',
    value: 'dextools',
  },
  {
    label: 'Uniswap',
    value: 'uniswap',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
  {
    label: 'LinkedIn',
    value: 'linkedin',
  },
  {
    label: 'Shopify',
    value: 'shopify',
  },
  {
    label: 'Patreon',
    value: 'patreon',
  },
  {
    label: 'Substack',
    value: 'substack',
  },
  {
    label: 'Sushiswap',
    value: 'sushiswap',
  },
  {
    label: 'Santiment',
    value: 'santiment',
  },
  {
    label: 'Unizen',
    value: 'unizen',
  },
  {
    label: 'LCX',
    value: 'lcx',
  },
  {
    label: 'Nileswap',
    value: 'nileswap',
  },
  {
    label: 'OpenSea',
    value: 'opensea',
  },
  {
    label: 'Information',
    value: 'information',
  },
]

export const ROOM_MANAGER_TYPES = {
  OWNER: 'owner',
  MODERATOR: 'moderator',
  BOUNCER: 'bouncer',
}

export const ROOM_EVENT_ACCESS_TYPE = {
  QR: 'qr',
  QR_CODEWORD: 'qrCodeword',
}
