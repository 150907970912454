import React, { useCallback, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import { BsGear } from 'react-icons/bs'
import { FiLogOut, FiMoon, FiSun, FiTrash } from 'react-icons/fi'

import { useStoreon } from 'storeon/react'

import { Dialog } from 'Components/Blocks/Modals'
import { Button, Dropdown, Row, Text } from 'Components/UI'

import { THEMES } from 'Config'

import { UI_STATE } from 'Constants/store'

import deleteHimselfMutation from 'GraphQL/Mutations/User/deleteHimself.graphql'
import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'
import { userAccountDeleteUpdater } from 'GraphQL/Updaters/user'

import { SIGN_OUT } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Container, ControlButtonWrapper, ModesContainer } from './styles'

function Controls({ displayBalance, isCollapsed, userId }) {
  const s = useScopedI18n('user.profile')
  const history = useHistory()
  const { ui } = useStoreon(UI_STATE)
  const { darkMode } = ui

  const [requestDelete, setRequestDelete] = useState({
    isOpen: false,
  })
  const [confirmDelete, setConfirmDelete] = useState({
    isOpen: false,
  })

  const [updateProfile] = useMutation(updateProfileMutation)
  const [deleteHimself] = useMutation(deleteHimselfMutation)

  const handleRequestDeleteModal = useCallback(() => {
    setRequestDelete({ isOpen: true })
  }, [])

  const handleCloseRequestDelete = useCallback(() => {
    setRequestDelete({ isOpen: false })
  }, [])

  const handleConfirmDeleteModal = useCallback(() => {
    setConfirmDelete({ isOpen: true })
  }, [])

  const handleCloseConfirmDelete = useCallback(() => {
    setConfirmDelete({ isOpen: false })
  }, [])

  const handleRequestDelete = useCallback(
    success => {
      if (!success) {
        return
      }

      handleConfirmDeleteModal()
    },
    [handleConfirmDeleteModal],
  )

  const handleConfirmDelete = useCallback(
    async success => {
      if (!success) {
        return
      }
      try {
        await deleteHimself({
          update: userAccountDeleteUpdater(userId),
        })
        toast.success({
          title: 'Request delete account',
          text: s('messages.successDelete'),
        })

        history.push(SIGN_OUT)
      } catch (error) {
        toast.error({ title: 'Request delete account', text: error?.message })
      }
    },
    [deleteHimself, history, s, userId],
  )

  const handleUpdateProfileTheme = useCallback(
    async value => {
      try {
        await updateProfile({
          variables: { theme: value ? THEMES.DARK : THEMES.LIGHT },
        })
      } catch (error) {
        toast?.error({
          title: 'Update theme',
          text: error?.message,
        })
      }
    },
    [updateProfile],
  )

  const handleSignOut = useCallback(() => history.push(SIGN_OUT), [history])

  const settingsOptions = useMemo(() => {
    const items = []

    items.push({
      type: Dropdown.ItemType.Button,
      Icon: FiTrash,
      content: s('actions.deleteAccount'),
      warning: true,
      action: () => handleRequestDeleteModal(),
    })

    return items
  }, [handleRequestDeleteModal, s])

  const requestDeleteMessage = (
    <Text
      dangerouslySetInnerHTML={{
        __html: s('messages.requestDelete'),
      }}
    />
  )

  const confirmDeleteMessage = (
    <Text
      dangerouslySetInnerHTML={{
        __html: s('messages.confirmDelete'),
      }}
    />
  )

  return (
    <Container>
      {!isCollapsed ? (
        <Row mt={2} width={1}>
          {!displayBalance && (
            <ControlButtonWrapper mr={2}>
              <Dropdown items={settingsOptions}>
                <Button
                  height={46}
                  middle
                  neutral
                  noEffectsIcon
                  stroke
                  width={46}
                >
                  <BsGear />
                </Button>
              </Dropdown>
            </ControlButtonWrapper>
          )}
          <ModesContainer mr={2} px={4}>
            <Button
              middle
              neutral
              noEffectsIcon
              stroke
              style={{ justifyContent: 'space-between' }}
              width="100%"
              onClick={handleSignOut}
            >
              <Text mr={2} small>
                {s('actions.logout')}
              </Text>
              <FiLogOut strokeWidth={1} />
            </Button>
          </ModesContainer>
          <ControlButtonWrapper>
            {!darkMode ? (
              <Button
                fill={!darkMode}
                neutral
                noEffectsIcon
                width={46}
                onClick={() => handleUpdateProfileTheme(true)}
              >
                <FiSun />
              </Button>
            ) : (
              <Button
                fill={!!darkMode}
                modeDark
                noEffectsIcon
                width={46}
                onClick={() => handleUpdateProfileTheme(false)}
              >
                <FiMoon />
              </Button>
            )}
          </ControlButtonWrapper>
        </Row>
      ) : (
        <Row width={1}>
          <ModesContainer collapsed>
            {!darkMode ? (
              <Button
                fill
                neutral
                noEffectsIcon
                width={22}
                onClick={() => handleUpdateProfileTheme(true)}
              >
                <FiSun />
              </Button>
            ) : (
              <Button
                fill
                modeDark
                noEffectsIcon
                width={22}
                onClick={() => handleUpdateProfileTheme(false)}
              >
                <FiMoon />
              </Button>
            )}
          </ModesContainer>
        </Row>
      )}

      <Dialog
        content={requestDeleteMessage}
        isOpen={requestDelete?.isOpen}
        title="Delete account"
        onClose={handleCloseRequestDelete}
        onFinish={handleRequestDelete}
      />

      <Dialog
        content={confirmDeleteMessage}
        isOpen={confirmDelete?.isOpen}
        title="Delete account"
        onClose={handleCloseConfirmDelete}
        onFinish={handleConfirmDelete}
      />
    </Container>
  )
}

Controls.defaultProps = {
  displayBalance: false,
  isCollapsed: false,
  userId: null,
}

Controls.propTypes = {
  displayBalance: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  userId: PropTypes.string,
}

export default Controls
