import { DateTime } from 'luxon'
import Cookies from 'universal-cookie'

import { COOKIE, IS_DEVELOPMENT } from 'Config'

const defaultOptions = {
  path: '/',
  expires: DateTime.local().plus({ year: 1 }).toJSDate(),
}

if (!IS_DEVELOPMENT) {
  defaultOptions.domain = COOKIE.SHARED_DOMAIN
  defaultOptions.secure = true
}

const keyWithPrefix = key =>
  !IS_DEVELOPMENT ? `${COOKIE.PREFIX}${key}` : `${key}`

let cookie = new Cookies()

function reinitialize(cookies) {
  cookie = new Cookies(cookies)
}

function setItem(key, value) {
  cookie.set(keyWithPrefix(key), value, defaultOptions)
}

function getItem(key) {
  return cookie.get(keyWithPrefix(key), { doNotParse: true })
}

export const CookieStorage = { setItem, getItem, reinitialize }
