import React from 'react'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import keyBy from 'lodash/keyBy'

import { SUBDOMAIN_ROOT } from 'Router/routes'

const StyledLink = styled.a`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  color: ${themeGet('text.color.default')};

  &:hover {
    color: ${themeGet('colors.customDark')};
  }

  &:active {
    color: ${themeGet('colors.customDefault')};
  }
`

export function subdomainLink(label, data) {
  const dataByLabel = keyBy(data, 'label')
  const room = dataByLabel[label]

  return (
    <StyledLink href={SUBDOMAIN_ROOT(room?.slug)}>{room?.label}</StyledLink>
  )
}
