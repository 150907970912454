import { OAuthLayout, RootLayout } from 'Components/Layout'

import { USER_ROLE } from 'Constants/ids'

import SignOut from 'Pages/Auth/SignOut'
import NotFound from 'Pages/NotFound'
import Public from 'Pages/Public'
import Telegram from 'Pages/Telegram'
import UIKit from 'Pages/UIKit'

import { ADMIN_ROUTES } from './admin'
import { APP_ROUTES } from './app'
import { AUTH_ROUTES } from './auth'
import { HOST_ROUTES } from './host'
import Redirector from './Redirector'
import * as ROUTES from './routes'

// TODO: component Public.TekenAuthCallback should be removed after sucessfully implementation url with domain&slug
export default function router(user) {
  const allRoutes = [
    {
      component: Public.Root,
      path: ROUTES.ROOT,
      exact: true,
    },
    {
      component: Telegram.Auth,
      path: ROUTES.TELEGRAM_AUTH,
      exact: true,
    },
    {
      component: Telegram.LoginCallback,
      path: ROUTES.TELEGRAM_LOGIN_CALLBACK,
      exact: true,
    },
    {
      component: Telegram.LoginRedirect,
      path: ROUTES.TELEGRAM_LOGIN_REDIRECT,
      exact: true,
    },
    {
      component: Redirector,
      path: ROUTES.REDIRECTOR,
      exact: true,
    },
    {
      component: Public.ConnectSocialAccounts,
      path: ROUTES.SOCIAL,
      exact: true,
    },
    {
      component: OAuthLayout,
      path: ROUTES.OAUTH_CALLBACK(),
      routes: [
        /* {
          component: Public.TekenAuthCallback,
          path: ROUTES.OAUTH_CALLBACK('teken/callback'),
          exact: true,
        }, */
        {
          component: Public.UnlockProtocolAuthCallback,
          path: ROUTES.OAUTH_CALLBACK('unlock'),
          exact: true,
        },
        {
          component: Public.TwitterCallback,
          path: ROUTES.OAUTH_CALLBACK('twitter/callback'),
          exact: true,
        },
        {
          component: Public.LinkedInCallback,
          path: ROUTES.OAUTH_CALLBACK('linkedin/callback'),
          exact: true,
        },
        {
          component: Public.OAuthCallback,
          path: ROUTES.OAUTH_CALLBACK(),
          exact: true,
        },
      ],
    },
    {
      component: Public.TekenCallback,
      path: ROUTES.TEKEN_CALLBACK,
      exact: true,
    },
    {
      component: Public.MagicLink,
      path: ROUTES.AUTH_MAGIC_LINK,
      exact: true,
    },
    {
      component: Public.SignInFromMobile,
      path: ROUTES.AUTH_FROM_MOBILE,
      exact: true,
    },
  ]

  allRoutes.push({
    component: SignOut,
    path: ROUTES.SIGN_OUT,
    exact: true,
  })

  allRoutes.push({
    component: UIKit,
    path: ROUTES.UI_KIT,
    exact: true,
  })

  if (user) {
    switch (user.role) {
      case USER_ROLE.ADMIN:
      case USER_ROLE.SUPER_ADMIN:
        allRoutes.push(ADMIN_ROUTES)
        break
      case USER_ROLE.HOST:
        allRoutes.push(HOST_ROUTES)
        break
      case USER_ROLE.USER:
        allRoutes.push(APP_ROUTES)
        break

      default:
        break
    }
  } else {
    allRoutes.push(AUTH_ROUTES)
    allRoutes.push({
      component: Public.Enter,
      path: ROUTES.APP_ENTER,
      exact: true,
    })
    allRoutes.push({
      component: Public.Enter,
      path: ROUTES.APP_ROOM(),
      exact: false,
    })
  }

  allRoutes.push({
    component: NotFound,
  })

  return [
    {
      component: RootLayout,
      routes: allRoutes,
    },
  ]
}
