import React, { useCallback, useMemo } from 'react'
import { isAndroid, isIOS, isMobile } from 'react-device-detect'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import noop from 'lodash/noop'

import logo from 'Assets/Png/logo.png'

import { Button, Column, Text } from 'Components/UI'
import InputField from 'Components/UI/Forms/Input/InputField'

import { FRONTEND_URL } from 'Config'

import signInByUsernameMutation from 'GraphQL/Mutations/Auth/signInByUsername.graphql'

import { useEntranceContext } from 'Hooks'

import { REDIRECTOR } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import Auth from 'Services/Auth'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { encodeAuthTokens } from 'Utils/Strings'

import { Content } from './styles'

import { ConnectWrapper, Image } from '../styles'

const FIELD = {
  USERNAME: 'username',
  PASSWORD: 'password',
}

function SignInByPassword({ onStep, loading, setLoading }) {
  const s = useScopedI18n('public.modal.inviteToRoom')
  const [{ exclusiveMode, roomMeta }] = useEntranceContext()

  const history = useHistory()

  const [signIn] = useMutation(signInByUsernameMutation)
  const formConstraints = useMemo(
    () => ({
      [FIELD.USERNAME]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.usernameRequired')}`,
        },
      },
      [FIELD.PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.passwordRequired')}`,
        },
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        const result = await signIn({
          variables: {
            username: values[FIELD.USERNAME],
            password: values[FIELD.PASSWORD],
            withRefresh: true,
          },
        })

        if (isMobile && (isIOS || isAndroid) && !exclusiveMode) {
          const auth = encodeAuthTokens(result?.data?.signInByUsername)
          if (roomMeta?.slug) {
            window.location.replace(
              `${FRONTEND_URL}/auth/username?auth=${auth}&slug=${roomMeta?.slug}`,
            )
          } else {
            window.location.replace(
              `${FRONTEND_URL}/auth/username?auth=${auth}`,
            )
          }
        } else {
          await Auth.handleAuth(result?.data?.signInByUsername)
          history.push(REDIRECTOR)
        }
      } catch (error) {
        toast.error({
          title: 'Sign In',
          text: _(`error.${error?.message || 'generic'}`),
        })
        setLoading(false)
      }
    },
    [history, roomMeta, setLoading, signIn],
  )

  return (
    <Column center fullWidth>
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputField
                label={_('auth.shared.username')}
                mt={4}
                name={FIELD.USERNAME}
                noChange
                width={1}
              />
              <InputField
                label={_('auth.shared.password')}
                mt={3}
                name={FIELD.PASSWORD}
                noChange
                type="password"
                width={1}
              />
              <Button
                disabled={loading}
                height={48}
                mt={24}
                type="submit"
                width={230}
                onClick={handleSubmit}
              >
                {_('auth.signIn.action')}
              </Button>
            </Content>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
      <Text blueDark mb={3}>
        {s('actions.or')}
      </Text>
      <ConnectWrapper disabled={loading} mb={2} onClick={() => onStep(1)}>
        <Image src={logo} />
        <Text bold ml={3}>
          {s('actions.signInEmail')}
        </Text>
      </ConnectWrapper>
    </Column>
  )
}

SignInByPassword.defaultProps = {
  loading: false,
  setLoading: noop,
  onStep: noop,
}

SignInByPassword.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onStep: PropTypes.func,
}

export default SignInByPassword
