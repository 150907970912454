import React from 'react'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { EMOJIS } from 'Constants/ids'

import { Container, Emoji } from './styles'

import Popover from '../Popover'

function ReactionsPicker({
  children,
  trigger,
  offset,
  onReaction,
  myReactions,
  ...rest
}) {
  return (
    <Popover
      clear
      content={
        <Container>
          {map(
            filter(EMOJIS, emo => !includes(myReactions, emo?.type)),
            (emoji, idx) => (
              <Emoji
                dangerouslySetInnerHTML={{ __html: emoji?.value }}
                key={emoji?.type}
                mr={idx === EMOJIS.length - 1 ? 0 : 2}
                red={emoji?.type === 'heart'}
                onClick={() => onReaction(emoji?.type)}
              />
            ),
          )}
        </Container>
      }
      hideOnClick
      interactive
      offset={offset}
      trigger={trigger}
      {...rest}
    >
      {children}
    </Popover>
  )
}

ReactionsPicker.defaultProps = {
  children: null,
  myReactions: [],
  offset: [0, 0],
  trigger: 'click',
  onReaction: noop,
}

ReactionsPicker.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  myReactions: PropTypes.array,
  offset: PropTypes.arrayOf(PropTypes.number),
  trigger: PropTypes.oneOf(['click', 'mouseenter focus']),
  onReaction: PropTypes.func,
}

export default ReactionsPicker
