import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import PropTypes from 'prop-types'

import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi'

import arrayMutators from 'final-form-arrays'
import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Column, Loader, Row, Text } from 'Components/UI'
import { InputField, SwitchField } from 'Components/UI/Forms'

import createPollMutation from 'GraphQL/Mutations/Poll/createPoll.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { ModalContent } from './styles'

const FIELDS = {
  ANONYMOUS: 'anonymous',
  OPTIONS: 'options',
  QUESTION: 'question',
}

function PollForm({ closeRef, roomId, setForm }) {
  const s = useScopedI18n('room.posts.poll')
  const [loading, setLoading] = useState(false)

  const [createPoll] = useMutation(createPollMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.ANONYMOUS]: {
        presence: true,
      },
      [FIELDS.OPTIONS]: {
        presence: true,
      },
      [FIELDS.QUESTION]: {
        presence: true,
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await createPoll({
          variables: {
            roomId,
            anonymous: get(values, FIELDS.ANONYMOUS, false),
            question: get(values, FIELDS.QUESTION),
            options: get(values, FIELDS.OPTIONS),
          },
        })

        toast.success({
          title: 'Poll',
          text: `You've successfully posted and started new poll`,
        })

        setLoading(false)

        closeRef.current()
        if (setForm) {
          setForm({ isOpen: false, isEdit: false, entity: null })
        }
      } catch (error) {
        toast.error({
          title: 'Poll',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [closeRef, createPoll, roomId, setForm],
  )

  return (
    <Form
      initialValues={{
        [FIELDS.ANONYMOUS]: false,
        [FIELDS.OPTIONS]: [null, null],
      }}
      mutators={{ ...arrayMutators }}
      render={({ handleSubmit }) => (
        <>
          <ModalContent>
            <InputField
              label={s('fields.question')}
              mt={24}
              name={FIELDS.QUESTION}
              required
              width={1}
            />
            <Row alignSelfStart mb={9} mt={24}>
              <Text bold primary small>
                {s('fields.options')}
              </Text>
            </Row>
            <FieldArray name={FIELDS.OPTIONS}>
              {({ fields }) => (
                <Column width={1}>
                  {fields?.map((name, index) => (
                    <Row center key={name} mb={16}>
                      <InputField
                        label={s('fields.option', { option: index + 1 })}
                        name={name}
                        required
                        width={1}
                      />
                      {index >= 2 && (
                        <Button
                          disabled={loading}
                          iconButton
                          ml={9}
                          noEffectsIcon
                          stroke
                          onClick={() => fields.remove(index)}
                        >
                          <FiMinusCircle strokeWidth={1} />
                        </Button>
                      )}
                    </Row>
                  ))}
                  <Row center justifyCenter mb={17} mt={9}>
                    <Button
                      disabled={loading || fields?.length === 6}
                      iconButton
                      iconLabel
                      noEffectsIcon
                      stroke
                      width={90}
                      onClick={() => fields.push()}
                    >
                      <FiPlusCircle mr={2} strokeWidth={1} />
                      {s('actions.addOption')}
                    </Button>
                  </Row>
                </Column>
              )}
            </FieldArray>
            <Row borderTop center pb={16} pt={24} spaceBetween width={1}>
              <Text bold>{s('fields.anonymous')}</Text>
              <SwitchField name={FIELDS.ANONYMOUS} />
            </Row>
          </ModalContent>

          <Row center justifyCenter mt={24} pb={24}>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <Button
                  height={32}
                  mr={16}
                  outline
                  width={130}
                  onClick={() =>
                    closeRef.current ? closeRef.current() : noop()
                  }
                >
                  {s('actions.cancel')}
                </Button>
                <Button height={32} width={130} onClick={handleSubmit}>
                  {s('actions.post')}
                </Button>
              </>
            )}
          </Row>
        </>
      )}
      validate={values => validate(values, formConstraints)}
      onSubmit={submit}
    />
  )
}

PollForm.defaultProps = {
  closeRef: null,
  roomId: '',
  setForm: null,
}

PollForm.propTypes = {
  closeRef: PropTypes.object,
  roomId: PropTypes.string,
  setForm: PropTypes.func,
}

export default PollForm
