import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { text } from 'Components/Styles'
import { Link } from 'Components/UI'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  background: ${themeGet('colors.white')};
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100px;
  background: ${themeGet('colors.bg.extra')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const PageContent = styled.div`
  background: ${themeGet('colors.white')};
  overflow: auto;
  padding-left: ${themeGet('space.4')}px;
  height: calc(100vh - 100px);
`

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  text-decoration: none;

  ${text(16)}

  color: ${themeGet('colors.white')}!important;

  &.active {
    font-weight: bold;
  }

  > svg {
    margin-right: 4px;
  }
`
