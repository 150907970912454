import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Modal } from 'Components/UI'

import { Content, Image } from './styles'

function ImageModal({ src, ...rest }) {
  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      onMount={handleMount}
    >
      <Content>
        <Image src={src} />
      </Content>
    </Modal>
  )
}

ImageModal.defaultProps = {
  src: null,
}

ImageModal.propTypes = {
  src: PropTypes.string,
}

export default ImageModal
