import React from 'react'
import { createRoot } from 'react-dom/client'

import { loadableReady } from '@loadable/component'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { IS_DEVELOPMENT, SENTRY } from './Config'
import Container from './Container'

if (!IS_DEVELOPMENT) {
  Sentry.init({
    dsn: SENTRY.DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

const container = document.getElementById('root')
const root = createRoot(container)

loadableReady(() => root.render(<Container />))
