import React from 'react'

import { FiLogOut } from 'react-icons/fi'

import Logo from 'Assets/Png/logo.png'

import { Dropdown, Text } from 'Components/UI'

import { ROLE_NAMES } from 'Constants/strings'

import { useAppContext } from 'Hooks'

import { SIGN_OUT } from 'Router/routes'

import { Container, Image, MenuLink } from './styles'

function Aside() {
  const { me } = useAppContext()

  return (
    <Container center noShrink>
      <Dropdown
        items={[
          {
            type: Dropdown.ItemType.Link,
            Icon: FiLogOut,
            content: 'Sign Out',
            to: SIGN_OUT,
          },
        ]}
        offset={[0, 5]}
        placement="bottom"
      >
        <Image src={Logo} />
      </Dropdown>
      <Text mt={9} small white>
        {me?.primaryEmailCredential?.email}
      </Text>
      <Text small white>
        {ROLE_NAMES[me?.role] || ''}
      </Text>
      <MenuLink mt={9} to={SIGN_OUT}>
        <FiLogOut /> Sign Out
      </MenuLink>
    </Container>
  )
}

export default Aside
