import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const colorCss = ({ color }) =>
  color &&
  css`
    border: 1px solid ${themeGet(`memberships.color.${color}`)};

    & p {
      color: ${themeGet(`memberships.textColor.${color}`)};
    }
  `

export const MembershipWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  height: fit-content;
  padding: 0 ${themeGet('space.1')}px;

  ${colorCss}
`
