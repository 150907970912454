import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: ${themeGet('colors.violet')};
  cursor: pointer;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  &:hover {
    background: ${themeGet('colors.violetDark')};
  }

  &:active {
    background: ${themeGet('colors.violetDarkest')};
  }
`
