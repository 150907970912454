import filter from 'lodash/filter'

import userFragment from 'GraphQL/Fragments/User.graphql'
import userFollowersQuery from 'GraphQL/Queries/User/userFollowers.graphql'
import userFollowingQuery from 'GraphQL/Queries/User/userFollowing.graphql'

export function followUpdater({ fromUserId, toUserId, shouldRemove }) {
  return function updateCache(cache) {
    const fromUserOptions = {
      id: `User:${fromUserId}`,
      fragment: userFragment,
      fragmentName: 'UserFields',
    }
    const fromUser = cache.readFragment(fromUserOptions)

    const toUserOptions = {
      id: `User:${toUserId}`,
      fragment: userFragment,
      fragmentName: 'UserFields',
    }
    const toUser = cache.readFragment(toUserOptions)

    // Following
    const followingQueryOptions = {
      query: userFollowingQuery,
      variables: { userId: fromUserId },
    }

    const followingData = cache.readQuery(followingQueryOptions)

    cache.writeQuery({
      ...followingQueryOptions,
      data: {
        ...followingData,
        userFollowing: shouldRemove
          ? filter(
              followingData?.userFollowing,
              follower => follower.id !== toUserId,
            )
          : [toUser, ...(followingData?.userFollowing || [])],
      },
    })

    cache.writeFragment({
      ...fromUserOptions,
      data: {
        followingCount:
          (fromUser?.followingCount || 0) + (shouldRemove ? -1 : 1),
      },
    })

    // Followers
    const followersQueryOptions = {
      query: userFollowersQuery,
      variables: { userId: toUserId },
    }

    const followersData = cache.readQuery(followersQueryOptions)

    cache.writeQuery({
      ...followersQueryOptions,
      data: {
        ...followersData,
        userFollowers: shouldRemove
          ? filter(
              followersData?.userFollowers,
              follower => follower.id !== fromUserId,
            )
          : [fromUser, ...(followersData?.userFollowers || [])],
      },
    })

    cache.writeFragment({
      ...toUserOptions,
      data: {
        followersCount: (toUser?.followersCount || 0) + (shouldRemove ? -1 : 1),
        following: shouldRemove,
      },
    })
  }
}
