import { FiChevronRight } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: ${themeGet('space.4')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${margin}
`

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  height: calc(100svh - 85px);
  scrollbar-width: none;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: thin;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`

export const ButtonWrapper = styled.div`
  position: absolute;
  left: -12px;
  top: 245px;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    display: none;
  }
`

const collapsedWrapperCss = ({ collapsedWrapper }) =>
  collapsedWrapper &&
  css`
    right: 3px;

    & svg {
      height: 10px;
      width: 10px;
    }
  `

export const NotificationWrapper = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${collapsedWrapperCss}
`

export const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 10px;
    height: 10px;
  }

  ${margin}
`

const collapsedUnreadCss = ({ collapsedUnread }) =>
  collapsedUnread &&
  css`
    right: 1px;
    height: 13px;
    min-width: 13px;

    & p {
      font-size: 7px;
    }
  `

export const UnreadWrapper = styled.div`
  position: absolute;
  top: -16px;
  right: -16px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  background: ${themeGet('colors.bg.customDefault')};
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 50px;
  padding: ${themeGet('space.1')}px;

  ${collapsedUnreadCss}
`

const iconCollapsedCss = ({ iconCollapsed }) =>
  iconCollapsed &&
  css`
    transform: rotate(180deg);
    transition: all ${themeGet('transitionTime.long')} ease-in-out;
  `

export const CollapseArrow = styled(FiChevronRight)`
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${iconCollapsedCss}
`
