import React from 'react'
import { components } from 'react-select'

import { MenuListWrapper } from './styles'

const MenuList = props => (
  <MenuListWrapper as={components.MenuList} {...props} />
)

MenuList.propTypes = {
  ...components.MenuList.propTypes,
}

export default MenuList
