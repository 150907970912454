import palette from './palette'

const switches = {
  border: {
    default: palette.warning,
    disabled: palette.secondary,
    checked: palette.green,
  },
  bg: {
    default: palette.warning,
    disabled: palette.secondary,
    checked: palette.green,
  },
  icon: {
    bg: {
      default: palette.white,
      disabled: palette.negative,
    },
  },
}

export default switches
