import { useMemo } from 'react'

import map from 'lodash/map'

import tokensOptionsQuery from 'GraphQL/Queries/Tokens/tokensOptions.graphql'

import { useAdminQuery } from 'Services/Apollo'

export default function useTokenOptions() {
  const { data } = useAdminQuery(tokensOptionsQuery)

  const tokens = useMemo(() => data?.tokens ?? {}, [data])

  return useMemo(
    () =>
      map(tokens, token => ({
        label: token?.name,
        value: token?.id,
        standard: token?.standard,
        symbol: token?.symbol,
      })),
    [tokens],
  )
}
