import uniqBy from 'lodash/uniqBy'

export function updateEventsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    roomEventsByRoomId: {
      ...prev?.roomEventsByRoomId,
      rows: uniqBy(
        [
          ...(prev?.roomEventsByRoomId?.rows || []),
          ...(fetchMoreResult?.roomEventsByRoomId?.rows || []),
        ],
        'id',
      ),
    },
  }
}

export function updateEventStatsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    roomEventVisitorStats: {
      ...prev?.roomEventVisitorStats,
      rows: uniqBy(
        [
          ...(prev?.roomEventVisitorStats?.rows || []),
          ...(fetchMoreResult?.roomEventVisitorStats?.rows || []),
        ],
        'user',
      ),
    },
  }
}

export function updateEventStatsLogsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    roomEventStatsLogs: {
      ...prev?.roomEventStatsLogs,
      rows: uniqBy(
        [
          ...(prev?.roomEventStatsLogs?.rows || []),
          ...(fetchMoreResult?.roomEventStatsLogs?.rows || []),
        ],
        'id',
      ),
    },
  }
}
