import { FiChevronLeft } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import lockGlyph from 'Assets/Svg/lock.svg'
import { metaIcons } from 'Assets/Svg/metaIcons'
import verifiedRoomGlyph from 'Assets/Svg/verifiedRoom.svg'

import { Column, Text } from 'Components/UI'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `

const iconCollapsedCss = ({ iconCollapsed }) =>
  iconCollapsed &&
  css`
    transform: rotate(-180deg);
    transition: all ${themeGet('transitionTime.long')} ease-in-out;
  `

const lockedTextCss = ({ lockedText }) =>
  lockedText &&
  css`
    color: ${themeGet('text.color.primary')};
  `

const selectedRoomCss = ({ selectedRoom }) =>
  selectedRoom &&
  css`
    border-color: ${themeGet('colors.border.customDefault')};
    min-height: 116px;
  `

const selectedCollapsedRoomCss = ({ selectedRoom, collapsed }) =>
  selectedRoom &&
  collapsed &&
  css`
    border-color: ${themeGet('colors.border.customDefault')};
    min-height: 83px;
  `

const mainLayerRoomCss = ({ mainLayerRoom }) =>
  mainLayerRoom &&
  css`
    max-height: 230px;
  `

const selectedLayeredRoomCss = ({ selectedLayeredRoom }) =>
  selectedLayeredRoom &&
  css`
    border-color: ${themeGet('colors.border.customDefault')};
    min-height: 145px;
  `

const myFeedCss = ({ isMyFeed }) =>
  isMyFeed &&
  css`
    min-height: 54px;
    max-height: 54px;
  `

const myFeedSelectedCss = ({ isMyFeed, selectedRoom }) =>
  isMyFeed &&
  selectedRoom &&
  css`
    min-height: 54px;
    max-height: 54px;
    background: ${themeGet('colors.bg.customSecondary')};
    border-color: ${themeGet('colors.border.customSecondary')};
  `

const myFeedCollapsedCss = ({ isMyFeed, collapsed }) =>
  isMyFeed &&
  collapsed &&
  css`
    min-height: 54px;
    max-height: 54px;
  `

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
`

export const Room = styled.div`
  min-height: 83px;
  max-height: 115px;
  width: 100%;
  background: ${themeGet('colors.bg.secondary')};
  border: 3px solid;
  border-color: ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  padding: ${themeGet('space.4')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${selectedRoomCss}
  ${collapsedCss}
  ${selectedCollapsedRoomCss}
  ${mainLayerRoomCss}
  ${selectedLayeredRoomCss}
  ${myFeedCss}
  ${myFeedSelectedCss}
  ${myFeedCollapsedCss}

  ${margin}
`

export const RoomTooltip = styled.div`
  width: 100%;
  background: ${themeGet('colors.bg.secondary')};
  border-radius: 8px;
  padding: ${themeGet('space.2')}px ${themeGet('space.5')}px;
  color: ${themeGet('text.color.default')};
  font-weight: 600;
  position: relative;

  :after,
  :before {
    right: 100%;
    top: 30%;
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :before {
    border-right-color: ${themeGet('colors.bg.secondary')};
    border-width: 6px;
  }
`

const activeSubCss = ({ activeSub }) =>
  activeSub &&
  css`
    color: ${themeGet('colors.white')};
    background: ${themeGet('colors.customDefault')};
    border: 1px solid ${themeGet('colors.border.customDefault')};
  `

export const SubRoom = styled.div`
  height: 20px;
  width: fit-content;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  background: inherit;
  color: ${themeGet('colors.customDefault')};
  font-weight: 400;
  font-size: 10px;
  text-decoration: none;
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 20px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  cursor: pointer;
  max-width: 70px;

  &:hover {
    background: ${themeGet('colors.customDark')};
    border: 1px solid ${themeGet('colors.border.customDark')};
    color: ${themeGet('colors.white')};
  }

  &:active {
    background: ${themeGet('colors.customDefault')};
    border: 1px solid ${themeGet('colors.border.customDefault')};
    color: ${themeGet('colors.white')};
  }

  span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
  }

  ${margin}
  ${activeSubCss}
  ${collapsedCss}
`

const absolutePositionCss = ({ absolutePosition }) =>
  absolutePosition &&
  css`
    position: absolute;
    top: -9px;
    right: -9px;
  `

export const SelectableText = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-clamp: 4;
  -webkit-line-clamp: 4;

  ${lockedTextCss}
`

export const RoomButton = styled(Column)`
  cursor: pointer;
`

const collapsedLogoCss = ({ collapsedLogo }) =>
  collapsedLogo &&
  css`
    width: 50px;
    height: 50px;
  `

export const RoomLogo = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: fill;

  ${collapsedLogoCss}
`

export const VerifiedRoomIcon = styled(verifiedRoomGlyph)`
  width: 18px;
  height: 18px;

  ${margin}
`

export const LockIcon = styled(lockGlyph)`
  ${absolutePositionCss}
`

export const CollapseArrow = styled(FiChevronLeft)`
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${iconCollapsedCss}
`

export const CoinMarketCapIcon = styled(metaIcons.coinmakertcap)``

export const CoinGeckoIcon = styled(metaIcons.coingecko)``

export const CoinMetroIcon = styled(metaIcons.coinmetro)``

export const DexToolsIcon = styled(metaIcons.dextools)``

export const DiscordIcon = styled(metaIcons.discord)``

export const EtherscanIcon = styled(metaIcons.etherscan)``

export const FacebookIcon = styled(metaIcons.facebook)``

export const InformationIcon = styled(metaIcons.information)``

export const InstagramIcon = styled(metaIcons.instagram)``

export const LcxIcon = styled(metaIcons.lcx)``

export const LinkedInIcon = styled(metaIcons.linkedin)``

export const NileswapIcon = styled(metaIcons.nileswap)``

export const OpenSeaIcon = styled(metaIcons.opensea)``

export const PatreonIcon = styled(metaIcons.patreon)``

export const SantimentIcon = styled(metaIcons.santiment)``

export const ShopifyIcon = styled(metaIcons.shopify)``

export const SubstackIcon = styled(metaIcons.substack)``

export const SushiswapIcon = styled(metaIcons.sushiswap)``

export const TelegramIcon = styled(metaIcons.telegram)``

export const TwitterIcon = styled(metaIcons.twitter)``

export const UniswapIcon = styled(metaIcons.uniswap)``

export const UnizenIcon = styled(metaIcons.unizen)``
