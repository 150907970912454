import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: ${themeGet('space.3')}px;
    padding-left: ${themeGet('space.2')}px;
  `

const selectedChannelCss = ({ selectedChannel }) =>
  selectedChannel &&
  css`
    border-color: ${themeGet('colors.border.customDefault')};
  `

const selectedCollapsedChannelCss = ({ selectedChannel, collapsed }) =>
  selectedChannel &&
  collapsed &&
  css`
    border-color: ${themeGet('colors.border.customDefault')};
  `

export const SingleChannel = styled.div`
  min-height: 62px;
  max-height: 84px;
  width: 100%;
  border: 3px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  padding: ${themeGet('space.4')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  cursor: pointer;

  ${selectedChannelCss}
  ${collapsedCss}
  ${selectedCollapsedChannelCss}

  ${margin}
`
