import React, { useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'

import { usePrivy } from '@privy-io/react-auth'
import { useStoreon } from 'storeon/react'

import { Row } from 'Components/UI'

import { PALETTE } from 'Config'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import { useEntranceContext } from 'Hooks'

import Auth from 'Services/Auth'

function SignOut() {
  const history = useHistory()
  const { dispatch } = useStoreon(UI_STATE)
  const [{ exclusiveMode }] = useEntranceContext()
  const { logout, user } = usePrivy()

  useEffect(() => {
    async function asyncSignOut() {
      if (user) {
        await logout()
      }
      await Auth.logOut(history)
    }
    if (exclusiveMode) {
      dispatch(UI_ACTIONS.SET, {
        darkMode: true,
        rightIsCollapsed: isMobile,
        leftIsCollapsed: isMobile,
      })
    } else {
      dispatch(UI_ACTIONS.SET, {
        darkMode: true,
        palette: PALETTE.blue,
        rightIsCollapsed: isMobile,
        leftIsCollapsed: isMobile,
      })
    }

    asyncSignOut()
  }, [dispatch, exclusiveMode, history, logout, user])

  return <Row />
}

export default SignOut
