import { useCallback, useEffect, useMemo } from 'react'

import map from 'lodash/map'

import categorizedChannelsQuery from 'GraphQL/Queries/Channels/categorizedChannels.graphql'

import { useLazyQuery } from 'Services/Apollo'

export default function useChannelCategoriesOptions(roomId) {
  const [loadCategories, { data }] = useLazyQuery(categorizedChannelsQuery)

  const fetchData = useCallback(() => {
    loadCategories({ variables: { roomId } })
  }, [loadCategories, roomId])

  useEffect(() => {
    if (roomId) {
      fetchData()
    }
  }, [fetchData, roomId])

  const rows = useMemo(
    () => data?.categorizedChannels ?? {},
    [data?.categorizedChannels],
  )

  return useMemo(
    () =>
      map(rows, category => ({
        label: category?.name,
        value: category?.id,
      })),
    [rows],
  )
}
