import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 350px;
  height: 150px;
  padding: 0 ${themeGet('space.5')}px;
`

export const Subtitle = styled.div`
  align-self: flex-start;

  ${margin}
`
