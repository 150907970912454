import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'

import SortArrowsGlyph from 'Assets/Svg/sortArrows.svg'

import Button from '../Button'

const sortAscCss = ({ sortAsc }) =>
  sortAsc &&
  css`
    g {
      & g:nth-child(1) {
        stroke: ${mapToTheme('buttons.color')};
      }
    }
  `

const sortDescCss = ({ sortDesc }) =>
  sortDesc &&
  css`
    g {
      & g:nth-child(2) {
        stroke: ${mapToTheme('buttons.color')};
      }
    }
  `

export const SortArrowsIcon = styled(SortArrowsGlyph)`
  width: 17px !important;
  height: 13px !important;
`

export const StyledButton = styled(Button)`
  border: none;
  border-radius: 50px;
  background: inherit;

  &:active,
  &:hover {
    background: ${mapToTheme('buttons.hover.backgroundColor')};
    g {
      stroke: ${mapToTheme('buttons.hover.color')};
    }
  }

  ${sortAscCss}
  ${sortDescCss}
`
