import React from 'react'
import { components } from 'react-select'

import { Item } from './styles'

const NoOptionsMessage = props => (
  <Item as={components.NoOptionsMessage} {...props} />
)

NoOptionsMessage.propTypes = {
  ...components.NoOptionsMessage.propTypes,
}

export default NoOptionsMessage
