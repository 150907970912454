import meQuery from 'GraphQL/Queries/User/me.graphql'

import { ROOT } from 'Router/routes'

import { getApolloClient, getWebSocketLink } from 'Services/Shared'
import { clearAuth, setAuth } from 'Services/Store/auth'

function notifyLinks(token) {
  const subscriptionLink = getWebSocketLink()
  if (subscriptionLink) subscriptionLink.onTokenChanged(token)
}

async function logOut(history) {
  clearAuth()

  history.replace(ROOT)

  await getApolloClient().resetStore()

  notifyLinks(null)
}

async function handleAuth({ accessToken, refreshToken }) {
  setAuth({ accessToken, refreshToken })

  const client = getApolloClient()
  await client.query({ query: meQuery })

  notifyLinks(accessToken)
}

const Auth = { handleAuth, logOut }

export default Auth
