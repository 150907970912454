import { useEffect } from 'react'

import EventBus from 'Services/EventBus'

export default function useEventBusSubscribe(actionName, actionHandler) {
  return useEffect(() => {
    const subscription = EventBus.on(actionName, actionHandler)

    return subscription.clear
  }, [actionName, actionHandler])
}
