import React from 'react'

import { useAppContext } from 'Hooks'

import BalanceUpdated from './BalanceUpdated'
import PostsLikesSaves from './PostsLikesSaves'
import RoomCounters from './RoomCounters'
import RoomLockedUnlocked from './RoomLockedUnlocked'
import RoomUpdated from './RoomUpdated'
import SystemSettings from './SystemSettings'
import UserUpdates from './UserUpdates'

export function SubscriptionManager() {
  const { me } = useAppContext()

  if (me?.role !== 'user') return null

  return (
    <>
      <PostsLikesSaves />
      <RoomCounters />
      <RoomLockedUnlocked />
      <RoomUpdated />
      <SystemSettings />
      <UserUpdates />
      <BalanceUpdated />
    </>
  )
}
