export const WEB = 'www'

export const USER_ROLE = {
  SUPER_ADMIN: 'superAdmin',
  ADMIN: 'admin',
  USER: 'user',
  HOST: 'host',
}

export const BREAKPOINTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  LARGE: 'large',
}

export const LANGUAGE = {
  EN: 'en',
  RU: 'ru',
}

export const UPLOAD_TYPES = {
  profilePhoto: 'profilePhoto',
  lockedRoomPhoto: 'lockedRoomPhoto',
  imageAttachment: 'imageAttachment',
  voiceAttachment: 'voiceAttachment',
  tokenLogo: 'tokenLogo',
  roomLogo: 'roomLogo',
  uidTokenJson: 'uidTokenJSON',
  fileAttachment: 'fileAttachment',
  fontFile: 'fontFile',
  roomGroupBanner: 'roomGroupBanner',
}

export const OAUTH_PROVIDER = {
  coinmetro: 'coinmetro',
  unlockProtocol: 'unlockProtocol',
}

export const TIER = {
  guest: 'guest',
  // TODO: rename to 'mod'
  zen: 'zen',
  // TODO: rename to 'host'
  solo: 'solo',
  pro: 'pro',
  prime: 'prime',
  apex: 'apex',
}

export const TIER_LABEL = {
  [TIER.guest]: 'Guest',
  [TIER.zen]: 'Mod',
  [TIER.solo]: 'Host',
  [TIER.pro]: 'Pro',
  [TIER.prime]: 'Prime',
  [TIER.apex]: 'Apex',
}

export const MEMBERSHIP_TIER = [
  {
    value: TIER.guest,
    label: TIER_LABEL[TIER.guest],
  },
  {
    value: TIER.zen,
    label: TIER_LABEL[TIER.zen],
  },
  {
    value: TIER.solo,
    label: TIER_LABEL[TIER.solo],
  },
  {
    value: TIER.pro,
    label: TIER_LABEL[TIER.pro],
  },
  {
    value: TIER.prime,
    label: TIER_LABEL[TIER.prime],
  },
  {
    value: TIER.apex,
    label: TIER_LABEL[TIER.apex],
  },
]

export const GOLD_BADGE = {
  REQUIRE: '10000000',
}

export const AUDIO_MESSAGE = {
  DURATION: '69000',
}

export const ATTACH_TYPES = {
  IMAGE: 'image',
  LINK: 'link',
  YOUTUBE_LINK: 'youtubeLink',
  VIDEO_LINK: 'videoLink',
  AUDIO: 'audio',
  POLL: 'poll',
  FILE: 'file',
  VIMEO_EVENT: 'vimeoEvent',
}

export const ROOM_COUNTER_KIND = {
  ONLINE_PARTICIPANTS_COUNT: 'OnlineParticipantsCount',
  PARTICIPANTS_COUNT: 'ParticipantsCount',
  UNREAD_COUNT: 'UnreadCount',
  UNREAD_EVENTS_COUNT: 'UnreadEventsCount',
}

export const CHANNEL_COUNTER_KIND = {
  PINNED_MESSAGES_COUNT: 'PinnedMessagesCount',
  UNREAD_COUNT: 'UnreadCount',
}

export const ETHEREUM_CHAIN_ID = {
  PRODUCTION: 1,
  DEVELOPMENT: 11155111,
  STAGING: 11155111,
}

export const BINANCE_CHAIN_ID = {
  PRODUCTION: 56,
  DEVELOPMENT: 97,
  STAGING: 97,
}

export const POLYGON_CHAIN_ID = {
  PRODUCTION: 137,
  DEVELOPMENT: 80002,
  STAGING: 80002,
}

export const WALLET_NETWORKS = {
  ETH: 'eth',
  BSC: 'bsc',
}

export const ALLOWED_PROFILE_VIEWERS = [TIER.pro, TIER.prime, TIER.apex]

export const WHL_MEMBERS = [
  TIER.zen,
  TIER.solo,
  TIER.pro,
  TIER.prime,
  TIER.apex,
]

export const WHL = 'whl'

export const CONTENT_TYPES = {
  POSTS: 'posts',
  PINNED_POSTS: 'pinnedPosts',
  STORE: 'store',
  STORE_FULLSCREEN: 'storeFullscreen',
  ACCESS_PASSES: 'accessPasses',
}

export const COMMENTABLE_TYPES = {
  POST: 'post',
  COMMENT: 'comment',
}

export const LIKEABLE_TYPES = {
  POST: 'post',
  MESSAGE: 'message',
}

export const EMOJIS = [
  {
    type: 'thinking',
    value: '&#129300;',
  },
  {
    type: 'whale',
    value: '&#128011;',
  },
  {
    type: 'heart',
    value: '&#10084',
  },
  {
    type: 'raisingHands',
    value: '&#128588',
  },
  {
    type: 'award',
    value: '&#127942;',
  },
  {
    type: 'idea',
    value: '&#128161;',
  },
  {
    type: 'checked',
    value: '&#9989;',
  },
  {
    type: 'earnings',
    value: '&#128184;',
  },
  {
    type: 'foldedHands',
    value: '&#128591',
  },
]

export const REACTION_TARGET_TYPES = {
  COMMENT: 'comment',
}

export const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
  UNKNOWN: 'unknown',
}

export const TRANSACTION_KINDS = {
  ROOM_FEE: 'roomFee',
  SIGN_UP: 'signUp',
}

export const TIME_UNITS = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
]

export const SORT_ORDER_TYPES = {
  ROOM: 'room',
  ROOM_GROUP: 'roomGroup',
  CATEGORY: 'category',
  CHANNEL: 'channel',
  CHANNEL_CATEGORY: 'channelCategory',
}

export const TOKEN_STANDARD = {
  ERC20: 'erc20',
  ERC721: 'erc721',
  BEP20: 'bep20',
  BEP721: 'bep721',
  POLYGON20: 'polygon20',
  POLYGON721: 'polygon721',
  HTR: 'htr',
  HTR_UID: 'htrUid',
  HTR_UID_COLLECTION: 'htrUidCollection',
  NFT_UID_COLLECTION: 'nftUidCollection',
}

export const WALLET_PROVIDERS = {
  ETHEREUM: 'ethereum',
  BSC: 'bsc',
  POLYGON: 'polygon',
  HATHOR: 'htr',
  TREZOR: 'trezor',
  XCM: 'xcm',
  UNLOCK_PROTOCOL: 'unlockProtocol',
  WALLET_CONNECT: 'walletConnect',
}

export const TOKEN_BLOCKCHAINS = {
  ETHEREUM: 'ethereum',
  BSC: 'bsc',
  HATHOR: 'htr',
  POLYGON: 'polygon',
}

export const TOKEN_CHAINS = {
  ETHEREUM: 'Ethereum',
  SMART_CHAIN: 'SmartChain',
  POLYGON: 'Polygon',
}

export const TOKEN_KIND = {
  NFT: 'NFT',
  NATIVE: 'Native',
  TOKEN: 'Token',
}

export const TREZOR_ETH_PATH = "m/44'/60'/0'/0/0"

export const ASIDE_MENU_STATES = {
  ROOMS: 'rooms',
  INBOX: 'inbox',
}

export const TREZOR_ETH_BUNDLE = [
  { path: "m/44'/60'/0'/0/0", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/1", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/2", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/3", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/4", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/5", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/6", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/7", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/8", showOnTrezor: false },
  { path: "m/44'/60'/0'/0/9", showOnTrezor: false },
]

export const NFT_REQUIREMENT_KINDS = {
  AMOUNT: 'Amount',
  ATTRIBUTE: 'Attribute',
  CUSTOM_ID: 'CustomID',
}
export const NFT_REQUIREMENT_OPTIONS = [
  {
    value: NFT_REQUIREMENT_KINDS.AMOUNT,
    label: 'Amount',
  },
  {
    value: NFT_REQUIREMENT_KINDS.ATTRIBUTE,
    label: 'Attribute',
  },
  {
    value: NFT_REQUIREMENT_KINDS.CUSTOM_ID,
    label: 'Custom ID',
  },
]

export const ROOM_COMMUNITY_BADGES = [
  {
    label: 'Official community',
    value: 'officialCommunity',
  },
  {
    label: 'Unofficial community',
    value: 'unOfficialCommunity',
  },
  {
    label: 'None',
    value: 'none',
  },
]

export const ROOM_MINIMUM_TYPE = {
  TOKENS: 'tokens',
  USD: 'usd',
}

export const LOCKED_MESSAGE_ATTACHMENT_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
}

export const ACCEPTED_IMAGES = ['image/jpeg', 'image/jpg', 'image/png']

export const ACCEPTED_FILES = [
  'audio/mpeg',
  'audio/mp3',
  'audio/mp4',
  'audio/wav',
  'video/mp4',
  'video/mpeg',
  'application/pdf',
]

export const AUTH_METHODS = {
  METAMASK: 'metamask',
  COINBASE: 'coinbase',
  COINMETRO: 'coinmetro',
  HATHORPAY: 'hathorpay',
  WALLET_CONNECT: 'walletConnect',
  UNLOCK_PROTOCOL: 'unlockProtocol',
  PRIVY: 'privy',
}

export const VERIFICATION_METHODS = {
  TELEGRAM: 'telegram',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
}

export const TIKTOK_OEMBED_BASE_URL = `https://www.tiktok.com/oembed?url=`
export const TIKTOK_EMBED_BASE_URL = `https://www.tiktok.com/embed/v2/`
