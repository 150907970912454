import React, { useCallback } from 'react'
import { Field, useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import TimeInput from './TimeInput'

function TimeInputField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  validate,
  ...rest
}) {
  const formApi = useForm()

  const handleClear = useCallback(() => {
    formApi.change(name, '')
  }, [formApi, name])

  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <TimeInput
              error={displayError && hasError && error}
              value={value}
              {...input}
              {...rest}
              onChange={handleChange}
              onClear={handleClear}
            />
          </>
        )
      }}
    </Field>
  )
}

TimeInputField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  input: null,
  validate: null,
}

TimeInputField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.shape(TimeInput.propTypes),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
}

export default TimeInputField
