import React from 'react'
import { components } from 'react-select'

import { MultiValueContainerWrapper } from './styles'

const MultiValueContainer = props => (
  <MultiValueContainerWrapper as={components.MultiValueContainer} {...props} />
)

MultiValueContainer.propTypes = {
  ...components.MultiValueContainer.propTypes,
}

export default MultiValueContainer
