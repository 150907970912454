import UserFragment from 'GraphQL/Fragments/User.graphql'

// TODO: updater will be used once back-end implemented
export function userAccountDeleteUpdater(userId) {
  return function updateCache(cache) {
    const fragment = {
      id: `User:${userId}`,
      fragmentName: 'UserFields',
      fragment: UserFragment,
    }

    const data = cache.readFragment(fragment)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: { ...data, isDeleted: true },
      })
    }
  }
}
