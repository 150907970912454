const palette = {
  black: '#000000',
  blackExtra: '#14171a',

  white: '#ffffff',

  primaryDark: '#1f1f1f',
  primary: '#657786',
  primaryExtra: '#4c5963',
  primaryLight: '#322830',

  negative: '#637583',

  secondaryDark: '#191919',
  secondary: '#2e2e2e',
  secondaryLight: '#221c21',
  secondaryLightest: '#242424',
  secondaryExtra: '#eee6f0',
  secondaryExtraTwo: '#e6ecf0',

  violetDarkest: '#b04293',
  violetDark: '#b7549d',
  violet: '#c068a9',
  violetLight: '#c77ab3',
  violetLightest: '#dfb3d4',
  violetExtra: '#bd66a8',

  blueDarkest: '#0591e6',
  blueDark: '#1e9ce8',
  blue: '#37a7e8',
  blueLight: '#50b2ed',
  blueLightest: '#9bd3f5',

  greenDark: '#009345',
  green: '#05e67d',
  greenLight: '#77c863',

  gold: '#e6bd05',

  warning: '#e0245e',
  warningDark: '#b31b4a',
  warningSecondary: '#9c2b06',

  grayLight: '#a5afb7',

  guest: '#e6ecf0',
  zen: '#6b7c8a',
  solo: '#ae5433',
  pro: '#866e5e',
  apex: '#647867',

  brightBlue: '#00f8f8',

  palettes: {
    pink: '#ce24a2',
    blue: '#358ac4',
    orange: '#eba22f',
    purple: '#7d2ac5',
    green: '#66de65',
    denim: '#358ac4',
    yellow: '#ffd400',
  },
}

export default palette
