import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'

import CategorizedRoomsFragment from 'GraphQL/Fragments/Room/CategorizedRooms.graphql'
import RoomFragment from 'GraphQL/Fragments/Room/Room.graphql'

export function markFavoriteUpdater(roomId, favorite) {
  return function updateCache(cache) {
    const id = `Room:${roomId}`

    cache.writeFragment({
      id,
      fragment: RoomFragment,
      fragmentName: 'RoomFields',
      data: { favorite },
    })

    cache.modify({
      fields: {
        favoriteRoomsList: existing => ({
          ...existing,
          rows: favorite
            ? [{ __ref: id }, ...existing.rows]
            : filter(existing.rows, ({ __ref }) => __ref !== id),
        }),
        categorizedRooms: existing => ({
          ...existing,
          rooms: uniqBy(
            [{ __ref: id, favorite }, ...(existing.rooms || [])],
            '__ref',
          ),
        }),
      },
    })
  }
}

export function joinRoomLockedUpdater(roomId, locked) {
  return function updateCache(cache) {
    const id = `Room:${roomId}`

    cache.writeFragment({
      id,
      fragment: RoomFragment,
      fragmentName: 'RoomFields',
      data: { locked },
    })

    cache.modify({
      fields: {
        rooms: existing => ({
          ...existing,
          rows: locked
            ? [{ __ref: id }, ...existing.rows]
            : filter(existing.rows, ({ __ref }) => __ref !== id),
        }),
      },
    })
  }
}

export function leaveRoomUpdater({ roomId, userId, categoryId }) {
  return function updateCache(cache) {
    const id = `Room:${roomId}`
    const catId = `CategorizedRooms:${categoryId}`

    const fragment = {
      id,
      fragment: RoomFragment,
      fragmentName: 'RoomFields',
    }

    const categoryFragment = {
      id: catId,
      fragment: CategorizedRoomsFragment,
      fragmentName: 'CategorizedRoomsFields',
    }

    const data = cache.readFragment(fragment)

    const categoryData = cache.readFragment(categoryFragment)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: {
          ...data,
          locked: true,
          participants: filter(
            data?.participants,
            participant => participant?.user?.id !== userId,
          ),
        },
      })
    }

    if (categoryData) {
      cache.writeFragment({
        ...categoryFragment,
        data: {
          ...categoryData,
          rooms: filter(categoryData?.rooms, room => room?.id !== roomId),
        },
      })
    }
  }
}

export function updateRoomsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    rooms: {
      ...prev?.rooms,
      rows: [
        ...(prev?.rooms?.rows || []),
        ...(fetchMoreResult?.rooms?.rows || []),
      ],
    },
  }
}

export function updateFavoriteRoomsListQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    favoriteRoomsList: {
      ...prev?.favoriteRoomsList,
      rows: [
        ...(prev?.favoriteRoomsList?.rows || []),
        ...(fetchMoreResult?.favoriteRoomsList?.rows || []),
      ],
    },
  }
}

export function updateParticipantsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    participantsByRoomId: {
      ...prev?.participantsByRoomId,
      rows: [
        ...(prev?.participantsByRoomId?.rows || []),
        ...(fetchMoreResult?.participantsByRoomId?.rows || []),
      ],
    },
  }
}
