import UserFragment from 'GraphQL/Fragments/User.graphql'
import balanceUpdatedSubscription from 'GraphQL/Subscriptions/balanceUpdated.graphql'

import { useSubscription } from 'Services/Apollo'
import EventBus from 'Services/EventBus'

function updateFragment(
  cache,
  { userId, balanceAutoUpdatedAt, balancesManualUpdatedAt, wallets },
) {
  const userFragment = {
    id: `User:${userId}`,
    fragmentName: 'UserFields',
    fragment: UserFragment,
  }

  cache.updateFragment(userFragment, data =>
    data
      ? { ...data, balanceAutoUpdatedAt, balancesManualUpdatedAt, wallets }
      : null,
  )

  EventBus.trigger(EventBus.actions.balance.update)
}

export default function BalanceUpdated() {
  useSubscription(balanceUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      updateFragment(client.cache, subscriptionData?.data?.balanceUpdated || {})
    },
  })

  return null
}
