import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Loader, Modal, Row, Search, Text } from 'Components/UI'

import BlockedUserItem from './BlockedUserItem'
import { Content, LoaderWrapper, ScrollingContainer } from './styles'
import useBlockedUsers from './useBlockedUsers'

function BlockedUsersModal({ isOpen, onClose }) {
  const [searchQuery, setSearchQuery] = useState('')
  const { blockedList, loading } = useBlockedUsers({
    searchQuery,
  })

  return (
    <Modal isOpen={isOpen} title="Blocked users" onClose={onClose}>
      <Content>
        <Row pb={3}>
          <Search onChange={v => setSearchQuery(v)} />
        </Row>
        <ScrollingContainer>
          {loading && (
            <LoaderWrapper>
              <Loader />
            </LoaderWrapper>
          )}
          {!loading && blockedList.length === 0 && (
            <Text alignTextCenter color="font.default" py={6}>
              You don&apos;t have any blocked users
            </Text>
          )}
          {blockedList.map(item => (
            <BlockedUserItem
              avatar={item?.blockedUser?.profile?.avatar?.url}
              blockDate={item.createdAt}
              channelId={item.channelId}
              key={item.blockedUserId}
              userId={item.blockedUser.id}
              username={item.blockedUser.username}
            />
          ))}
        </ScrollingContainer>
      </Content>
    </Modal>
  )
}

BlockedUsersModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
}

BlockedUsersModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
}

export default BlockedUsersModal
