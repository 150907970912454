import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div.attrs({
  as: 'section',
})`
  background-color: ${themeGet('colors.bg.primary')};
  height: 100vh;
  overflow-y: auto;
  padding: 32px;
`
