import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { Button, Column, Loader, Modal, Row, Text } from 'Components/UI'
import {
  InputField,
  RadioField,
  SelectField,
  TimeInputField,
  TimezoneSelectField,
} from 'Components/UI/Forms'

import { LOGICAL_OPERATORS, SCHEDULES, TRIGGERS } from 'Constants/notifications'

import createNotificationRuleMutation from 'GraphQL/Mutations/Notifications/createNotificationRule.graphql'
import updateNotificationRuleMutation from 'GraphQL/Mutations/Notifications/updateNotificationRule.graphql'
import participantsByRoomIdQuery from 'GraphQL/Queries/Rooms/participantsByRoomId.graphql'
import {
  updateNotificationRulesQuery,
  updateNotificationRuleUpdater,
} from 'GraphQL/Updaters/notifications'

import { useRoomOptions, useTimezones } from 'Hooks'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import {
  Content,
  CustomScheduleRow,
  DailyWrapper,
  RadioButtonsWrapper,
} from './styles'

const FIELDS = {
  KEYWORD: 'keyword',
  LOGICAL_OPERATOR: 'logicalOperator',
  NAME: 'name',
  ROOM_IDS: 'roomIds',
  TIME: 'time',
  TIMEZONE: 'timezone',
  SCHEDULE_TYPE: 'scheduleType',
  TRIGGER_TYPE: 'triggerType',
  USER_IDS: 'userIds',
}

const LOAD_COUNT = 999999

const SCHEDULE_TYPE = {
  DAILY: 'daily',
  LIVE: 'live',
}

function CreateNotificationRule({ data, rulesVariables, ...rest }) {
  const s = useScopedI18n('user.notificationRules.create')
  const [loading, setLoading] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.KEYWORD]: null,
    [FIELDS.LOGICAL_OPERATOR]: LOGICAL_OPERATORS.AND,
    [FIELDS.NAME]: null,
    [FIELDS.ROOM_IDS]: [],
    [FIELDS.TIME]: '',
    [FIELDS.TIMEZONE]: {},
    [FIELDS.SCHEDULE_TYPE]: SCHEDULE_TYPE.LIVE,
    [FIELDS.TRIGGER_TYPE]: TRIGGERS.KEYWORD,
    [FIELDS.USER_IDS]: [],
  })
  const [roomId, setRoomId] = useState(null)

  const close = useRef(null)

  const { fetchRoomsOptions } = useRoomOptions()
  const timezoneOptions = useTimezones()
  const timezonesByValue = keyBy(timezoneOptions, 'value')

  useEffect(() => {
    switch (data?.scheduleType) {
      case SCHEDULE_TYPE.DAILY:
        setRoomId(data?.rooms[0]?.id)

        setFormInitialValues({
          [FIELDS.NAME]: data?.name,
          [FIELDS.KEYWORD]: data?.keyword,
          [FIELDS.LOGICAL_OPERATOR]: data?.logicalOperator,
          [FIELDS.SCHEDULE_TYPE]: data?.scheduleType,
          [FIELDS.TRIGGER_TYPE]: data?.triggerType,
          [FIELDS.ROOM_IDS]: [
            {
              label: data?.rooms[0]?.label,
              value: data?.rooms[0]?.id,
            },
          ],
          [FIELDS.USER_IDS]: map(data?.users, ({ id, username }) => ({
            label: username,
            value: id,
          })),
          [FIELDS.TIME]: data?.scheduleDailyConfig?.time
            ? data?.scheduleDailyConfig?.time?.slice(0, 5)
            : '',
          [FIELDS.TIMEZONE]: data?.scheduleDailyConfig?.timezone
            ? {
                label:
                  timezonesByValue[data?.scheduleDailyConfig?.timezone]?.label,
                value: data?.scheduleDailyConfig?.timezone,
              }
            : {},
        })
        break
      case SCHEDULE_TYPE.LIVE:
        setRoomId(data?.rooms[0]?.id)

        setFormInitialValues({
          [FIELDS.NAME]: data?.name,
          [FIELDS.KEYWORD]: data?.keyword,
          [FIELDS.LOGICAL_OPERATOR]: data?.logicalOperator,
          [FIELDS.SCHEDULE_TYPE]: data?.scheduleType,
          [FIELDS.TIMEZONE]: {},
          [FIELDS.TRIGGER_TYPE]: data?.triggerType,
          [FIELDS.ROOM_IDS]: [
            {
              label: data?.rooms[0]?.label,
              value: data?.rooms[0]?.id,
            },
          ],
          [FIELDS.USER_IDS]: map(data?.users, ({ id, username }) => ({
            label: username,
            value: id,
          })),
        })
        break
      default:
        setFormInitialValues({
          [FIELDS.KEYWORD]: null,
          [FIELDS.LOGICAL_OPERATOR]: LOGICAL_OPERATORS.AND,
          [FIELDS.NAME]: null,
          [FIELDS.ROOM_IDS]: [],
          [FIELDS.TIME]: '',
          [FIELDS.TIMEZONE]: {},
          [FIELDS.SCHEDULE_TYPE]: SCHEDULE_TYPE.LIVE,
          [FIELDS.TRIGGER_TYPE]: TRIGGERS.KEYWORD,
          [FIELDS.USER_IDS]: [],
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const [loadParticipants, { data: participantsData }] = useLazyQuery(
    participantsByRoomIdQuery,
  )

  const participants = useMemo(
    () => participantsData?.participantsByRoomId ?? {},
    [participantsData?.participantsByRoomId],
  )

  const { rows } = participants

  const participantsOptions = useMemo(
    () =>
      map(rows, row => ({
        value: row?.user?.id,
        label: row?.user?.username,
      })),
    [rows],
  )

  const [createNotificationRule] = useMutation(createNotificationRuleMutation)
  const [updateNotificationRule] = useMutation(updateNotificationRuleMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.LOGICAL_OPERATOR]: {
        presence: {
          presence: true,
          message: '^You must choose one logical operator',
        },
      },
      [FIELDS.NAME]: {
        presence: true,
      },
      [FIELDS.SCHEDULE_TYPE]: {
        presence: {
          presence: true,
          message: '^You must select schedule type',
        },
      },
      [FIELDS.TRIGGER_TYPE]: {
        presence: true,
      },
      [FIELDS.KEYWORD]: {
        presence: {
          presence: true,
          message: '^You must specify one or two keywords',
        },
      },
      [FIELDS.ROOM_IDS]: {
        length: {
          maximum: 1,
          message: '^You can specify only one room filter',
        },
        presence: (value, attributes) => {
          if (attributes.logicalOperator === LOGICAL_OPERATORS.AND) {
            return {
              allowEmpty: false,
              is: true,
              message:
                attributes.userIds.length > 0
                  ? '^Please add room filter'
                  : '^You don`t add any filters, please add room and member filters',
            }
          }
          if (
            attributes.logicalOperator === LOGICAL_OPERATORS.OR &&
            attributes.userIds.length === 0
          ) {
            return {
              allowEmpty: false,
              is: true,
              message: '^You must add at least one filter',
            }
          }
          return {
            allowEmpty: false,
            is: true,
            message: '^Please add room filter',
          }
        },
      },
      [FIELDS.USER_IDS]: {
        length: {
          maximum: 3,
          message: '^You can specify maximum 3 members filter',
        },
        presence: (value, attributes) => {
          if (attributes.logicalOperator === LOGICAL_OPERATORS.AND) {
            return {
              allowEmpty: false,
              is: true,
              message:
                attributes.roomIds.length > 0
                  ? '^Please add member filter'
                  : '^You don`t add any filters, please add room and member filters',
            }
          }
          if (
            attributes.logicalOperator === LOGICAL_OPERATORS.OR &&
            attributes.roomIds.length === 0
          ) {
            return {
              allowEmpty: false,
              is: true,
              message: '^You must add at least one filter',
            }
          }
          return false
        },
      },
    }),
    [],
  )

  const handleRoomId = useCallback(id => {
    if (id) {
      setRoomId(id)
    }
  }, [])

  const fetchParticipants = useCallback(() => {
    loadParticipants({ variables: { first: LOAD_COUNT, roomId, search: '' } })
  }, [loadParticipants, roomId])

  useEffect(() => {
    if (roomId) {
      fetchParticipants()
    }
  }, [fetchParticipants, roomId])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        const variables = {
          keyword: get(values, FIELDS.KEYWORD) || null,
          logicalOperator: get(values, FIELDS.LOGICAL_OPERATOR) || null,
          name: get(values, FIELDS.NAME) || null,
          roomIds: [values[FIELDS.ROOM_IDS][0]?.value] || null,
          scheduleDailyConfig:
            values?.scheduleType === 'daily'
              ? {
                  time:
                    `${get(values, FIELDS.TIME).slice(0, 2)}:${get(
                      values,
                      FIELDS.TIME,
                    ).slice(2, 4)}:00` || null,
                  timezone: values[FIELDS.TIMEZONE].value || null,
                }
              : null,
          scheduleType: get(values, FIELDS.SCHEDULE_TYPE) || null,
          triggerType: get(values, FIELDS.TRIGGER_TYPE) || null,
          userIds: map(values[FIELDS.USER_IDS], user => user?.value) || null,
        }
        if (!data) {
          await createNotificationRule({
            variables: { ...variables },
            update: (cache, result) => {
              const resultData = result?.data?.createNotificationRule
              updateNotificationRulesQuery(cache, resultData, rulesVariables)
            },
          })

          toast.success({
            title: s('messages.title'),
            text: s('messages.createSuccess'),
          })
        } else {
          await updateNotificationRule({
            variables: { ...variables, id: data?.id },
            update: (cache, result) => {
              const resultData = result?.data?.updateNotificationRule
              updateNotificationRuleUpdater(cache, resultData)
            },
          })
          toast.success({
            title: s('messages.title'),
            text: s('messages.editSuccess'),
          })
        }

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error({
          title: s('messages.title'),
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [createNotificationRule, data, rulesVariables, s, updateNotificationRule],
  )

  const modalTitle = data ? s('titleEdit') : s('title')

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={modalTitle}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit, values }) => (
          <>
            <Content>
              <Row borderBottom p={24} width={1}>
                <InputField
                  label={s('fields.name')}
                  name={FIELDS.NAME}
                  width={1}
                />
              </Row>
              <Column borderBottom p={24} width={1}>
                <Text primary small>
                  {s('fields.trigger.title')}
                </Text>
                <Row center mb={14} mt={9}>
                  <RadioField
                    circle
                    label={s('fields.trigger.keyword')}
                    name={FIELDS.TRIGGER_TYPE}
                    value={TRIGGERS.KEYWORD}
                  />
                </Row>
                <InputField
                  label={s('fields.keyword')}
                  name={FIELDS.KEYWORD}
                  width={1}
                />
              </Column>
              <Column borderBottom p={24} width={1}>
                <SelectField
                  async
                  defaultOptions
                  isMulti
                  loadOptions={fetchRoomsOptions}
                  name={FIELDS.ROOM_IDS}
                  placeholder={s('fields.room')}
                  width={1}
                />
                {!!values?.roomIds && handleRoomId(values?.roomIds[0]?.value)}
                <Row center mt={24} spaceBetween width={1}>
                  <Row borderBottom width={0.45} />
                  <Row center ml={2} spaceBetween>
                    <RadioField
                      label={s('fields.and')}
                      name={FIELDS.LOGICAL_OPERATOR}
                      textRadioButton
                      value={LOGICAL_OPERATORS.AND}
                    />
                    <Text light mb={1} ml={1} mr={1} primary>
                      &bull;
                    </Text>
                    <RadioField
                      label={s('fields.or')}
                      name={FIELDS.LOGICAL_OPERATOR}
                      textRadioButton
                      value={LOGICAL_OPERATORS.OR}
                    />
                  </Row>
                  <Row borderBottom width={0.45} />
                </Row>
                <SelectField
                  isMulti
                  mt={24}
                  name={FIELDS.USER_IDS}
                  options={participantsOptions}
                  placeholder={s('fields.users')}
                  width={1}
                />
              </Column>
              <Column p={24} width={1}>
                <Text primary small>
                  {s('fields.schedule.title')}
                </Text>
                <CustomScheduleRow center height={40} mt={9}>
                  <RadioButtonsWrapper>
                    <RadioField
                      circle
                      label={s('fields.schedule.live')}
                      name={FIELDS.SCHEDULE_TYPE}
                      value={SCHEDULES.LIVE}
                    />
                    <RadioField
                      circle
                      label={s('fields.schedule.daily')}
                      ml={28}
                      name={FIELDS.SCHEDULE_TYPE}
                      value={SCHEDULES.DAILY}
                    />
                  </RadioButtonsWrapper>
                  {values?.scheduleType === 'daily' && (
                    <DailyWrapper>
                      <Column ml={[0, 0, 14]}>
                        <TimeInputField
                          height={40}
                          label={s('fields.schedule.time')}
                          name={FIELDS.TIME}
                          width={90}
                        />
                      </Column>
                      <Column ml={14} width={1}>
                        <TimezoneSelectField
                          name={FIELDS.TIMEZONE}
                          placeholder={s('fields.schedule.timezone')}
                        />
                      </Column>
                    </DailyWrapper>
                  )}
                </CustomScheduleRow>
              </Column>
            </Content>

            <Row center justifyCenter mt={24} pb={24}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    height={32}
                    mr={3}
                    outline
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                  <Button height={32} mr={3} width={130} onClick={handleSubmit}>
                    {data ? s('actions.save') : s('actions.add')}
                  </Button>
                </>
              )}
            </Row>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

CreateNotificationRule.defaultProps = {
  data: null,
  rulesVariables: null,
}

CreateNotificationRule.propTypes = {
  data: PropTypes.object,
  rulesVariables: PropTypes.object,
}

export default CreateNotificationRule
