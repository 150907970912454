import React from 'react'

import { Funnel, FunnelChart, LabelList, Tooltip } from 'recharts'

const data = [
  {
    value: 100,
    name: 'Visited',
    fill: '#8884d8',
  },
  {
    value: 80,
    name: 'Logged in',
    fill: '#83a6ed',
  },
  {
    value: 50,
    name: 'Subscribed',
    fill: '#8dd1e1',
  },
  {
    value: 40,
    name: 'Renewed',
    fill: '#82ca9d',
  },
  {
    value: 26,
    name: 'Support',
    fill: '#a4de6c',
  },
]

function Chart() {
  return (
    <FunnelChart
      cx={150}
      cy={150}
      height={300}
      margin={{ top: 10, left: 10, right: 10, bottom: 10 }}
      width={300}
    >
      <Tooltip />

      <Funnel data={data} dataKey="value" isAnimationActive>
        <LabelList dataKey="name" fill="#000" position="right" stroke="none" />
      </Funnel>
    </FunnelChart>
  )
}

export default Chart
