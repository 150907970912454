import React from 'react'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import { Button, Row } from 'Components/UI'

import { GoBackButton, StyledGoBackIcon } from 'Pages/UIKit/styles'

import { useScopedI18n } from 'Services/I18n'

import { ResponsiveRow } from '../styles'

export default function Tabs({
  isCollapsed,
  onCollapsed,
  displayFavorites,
  onDisplayFavorites,
}) {
  const s = useScopedI18n('user.rooms')

  return (
    <>
      {!isCollapsed ? (
        <ResponsiveRow center mt={4}>
          <GoBackButton responsive onClick={onCollapsed}>
            <StyledGoBackIcon />
          </GoBackButton>
          <Button
            activeTab={displayFavorites}
            dark
            height={[32, 40, 40, 40, 32]}
            mr={9}
            small
            width={['30%', '35%', '40%', '40%', 130]}
            onClick={() => onDisplayFavorites(true)}
          >
            {s('fields.favorites')}
          </Button>
          <Button
            activeTab={!displayFavorites}
            dark
            height={[32, 40, 40, 40, 32]}
            small
            width={['30%', '35%', '40%', '40%', 130]}
            onClick={() => onDisplayFavorites(false)}
          >
            {s('fields.all')}
          </Button>
        </ResponsiveRow>
      ) : (
        <Row justifyCenter mt={4} width={1}>
          {displayFavorites ? (
            <Button
              dark
              height={32}
              small
              width={40}
              onClick={() => onDisplayFavorites(false)}
            >
              {s('fields.all')}
            </Button>
          ) : (
            <Button
              dark
              height={32}
              small
              width={80}
              onClick={() => onDisplayFavorites(true)}
            >
              {s('fields.favorites')}
            </Button>
          )}
        </Row>
      )}
    </>
  )
}

Tabs.defaultProps = {
  displayFavorites: false,
  isCollapsed: false,
  onCollapsed: noop,
  onDisplayFavorites: noop,
}

Tabs.propTypes = {
  displayFavorites: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  onCollapsed: PropTypes.func,
  onDisplayFavorites: PropTypes.func,
}
