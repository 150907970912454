import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import notificationsQuery from 'GraphQL/Queries/Notifications/notifications.graphql'
import notificationCreatedSubscription from 'GraphQL/Subscriptions/notificationCreated.graphql'
import notificationDeletedSubscription from 'GraphQL/Subscriptions/notificationDeleted.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery(client, notification, notificationsVariables, remove) {
  const notificationsOptions = {
    query: notificationsQuery,
    variables: {
      ...notificationsVariables,
    },
  }

  const id = `Notification:${notification?.id}`

  const notificationsData = client.readQuery(notificationsOptions)

  if (remove) {
    client.cache.evict({ id })
    client.cache.gc()
  } else {
    client.writeQuery({
      ...notificationsOptions,
      data: {
        ...notificationsData,
        notifications: {
          ...notificationsData?.notifications,
          rows: uniqBy(
            [notification, ...(notificationsData?.notifications?.rows || [])],
            'id',
          ),
        },
      },
    })
  }
}

function NotificationsSubscription({ notificationsVariables }) {
  useSubscription(notificationCreatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const notification = get(subscriptionData, [
        'data',
        'notificationCreated',
      ])

      updateQuery(client, notification, notificationsVariables)
    },
  })

  useSubscription(notificationDeletedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const notification = get(subscriptionData, [
        'data',
        'notificationDeleted',
      ])

      updateQuery(client, notification, notificationsVariables, {
        remove: true,
      })
    },
  })

  return <></>
}

NotificationsSubscription.defaultProps = {
  notificationsVariables: null,
}

NotificationsSubscription.propTypes = {
  notificationsVariables: PropTypes.object,
}

export default NotificationsSubscription
