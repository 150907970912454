import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import { FiArrowLeft, FiPlus, FiX } from 'react-icons/fi'

import { useTheme } from 'styled-components'

import noop from 'lodash/noop'
import pick from 'lodash/pick'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'

import { Column } from 'Components/UI/Flex'
import Logo from 'Components/UI/Logo'
import Text from 'Components/UI/Text'

import { AddButton, CloseButton, StyledModal } from './styles'

ReactModal.setAppElement('body')

function Modal({
  children,
  isOpen,
  shouldCloseOnOverlayClick,
  noCloseButton,
  title,
  withLogo,
  logo,
  renderTitleAppendix,
  onCreate,
  onClose,
  onMount,
  withBackButton,
  handleBack,
  hidden,
}) {
  const theme = useTheme()
  const [internalOpen, setInternalOpen] = useState(false)
  const hasLogo = useMemo(() => !!logo || withLogo, [logo, withLogo])

  const TIMEOUT = toNumber(replace(theme.transitionTime.modal, 'ms', ''))

  useEffect(() => {
    if (isOpen) {
      setInternalOpen(true)
    }
  }, [isOpen])

  const handleClose = useCallback(() => {
    setInternalOpen(false)
  }, [])

  useEffect(() => {
    onMount({ handleClose })
  }, [handleClose, onMount])

  return (
    <StyledModal
      closeTimeoutMS={TIMEOUT}
      hidden={hidden}
      isOpen={internalOpen}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      onAfterClose={onClose}
      onRequestClose={handleClose}
    >
      <Column center pt={24} px={24}>
        {!withBackButton && !noCloseButton && (
          <CloseButton onClick={handleClose}>
            <FiX />
          </CloseButton>
        )}
        {withBackButton && (
          <CloseButton onClick={handleBack}>
            <FiArrowLeft />
          </CloseButton>
        )}

        {/* TODO: remove it from here */}
        <Text bold mb={hasLogo ? 2 : 0} middle>
          {title}
        </Text>

        {/* TODO: remove it from here */}
        {hasLogo && <Logo customLogo={logo} height={60} width={60} />}

        {/* TODO: remove it from here */}
        {renderTitleAppendix && (
          <AddButton
            height={24}
            iconButton
            noEffectsIcon
            stroke
            width={60}
            onClick={() => onCreate()}
          >
            <FiPlus mr={2} size={0.5} strokeWidth={1} />
            Add
          </AddButton>
        )}
      </Column>

      {children}
    </StyledModal>
  )
}

Modal.defaultProps = {
  children: undefined,
  isOpen: false,
  handleBack: noop,
  hidden: false,
  noCloseButton: false,
  renderTitleAppendix: false,
  shouldCloseOnOverlayClick: true,
  title: null,
  withBackButton: false,
  withLogo: false,
  logo: undefined,
  onClose: noop,
  onCreate: noop,
  onMount: noop,
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.objectOf(PropTypes.node),
  ]),
  handleBack: PropTypes.func,
  hidden: PropTypes.bool,
  isOpen: PropTypes.bool,
  logo: PropTypes.string,
  noCloseButton: PropTypes.bool,
  renderTitleAppendix: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  withBackButton: PropTypes.bool,
  withLogo: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func,
  onMount: PropTypes.func,
}

const PROPS = [
  'isOpen',
  'renderTitleAppendix',
  'shouldCloseOnOverlayClick',
  'title',
  'onClose',
  'onMount',
]

Modal.pickProps = props => pick(props, PROPS)

export default Modal
