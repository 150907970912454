import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import LinkedInBlueGlyph from 'Assets/Svg/linkedinBlue.svg'
import TelegramBlueGlyph from 'Assets/Svg/telegramBlue.svg'
import TelegramBlueBigGlyph from 'Assets/Svg/telegramBlueBig.svg'
import TwitterBlueGlyph from 'Assets/Svg/twitterBlue.svg'
import TwitterBlueBigGlyph from 'Assets/Svg/twitterBlueBig.svg'

import { Link } from 'Components/UI'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  `

const collapsedContentCss = ({ collapsedContent }) =>
  collapsedContent &&
  css`
    align-items: center;
  `

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.secondary')};
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${collapsedCss}
`

export const Content = styled.div`
  display: flex;
  width: 100%;
  padding: ${themeGet('space.4')}px ${themeGet('space.4')}px
    ${themeGet('space.2')}px;

  ${collapsedContentCss}
`

export const ExternalLink = styled.a`
  color: ${themeGet('colors.customDefault')};
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;

  &:hover {
    color: ${themeGet('colors.customMedium')};
  }

  :active {
    color: ${themeGet('colors.customDefault')};
  }
`

export const StyledLink = styled(Link)`
  color: ${themeGet('colors.customDefault')} !important;

  &:hover {
    color: ${themeGet('colors.customMedium')} !important;
  }

  &visited,
  &:focus,
  &:active {
    color: ${themeGet('colors.customDefault')} !important;
  }
`

export const TelegramIcon = styled(TelegramBlueGlyph)`
  ${margin}
`

export const TelegramBigIcon = styled(TelegramBlueBigGlyph)`
  ${margin}
`

export const LinkedInBigIcon = styled(LinkedInBlueGlyph)`
  height: 16px !important;

  ${margin}
`
export const TwitterBigIcon = styled(TwitterBlueBigGlyph)`
  ${margin}
`

export const TwitterIcon = styled(TwitterBlueGlyph)`
  ${margin}
`
