import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: ${themeGet('colors.bg.secondary')};
  color: ${themeGet('colors.white')};

  a {
    margin-top: 8px;
    color: ${themeGet('colors.white')};
    text-decoration: underline;
  }
`

export const Code = styled.div`
  font-size: 64px;
  line-height: 1.3em;
`
