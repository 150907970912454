import { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'
import { themeGet } from '@styled-system/theme-get'

import { toasts } from './styles'

import 'react-toastify/dist/ReactToastify.min.css'

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    width: 100%;
   }

  body {
    color: ${themeGet('colors.font.primary')};
    background-color: ${themeGet('colors.bg.default')};
    font-size: 14px;
    font-family: ${themeGet('font')};
    line-height: 20px;
    min-width: 100vw;
    min-height: 100vh;
    height: 100%;
    padding: 0;
    overflow: auto;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;

    scrollbar-width: thin;
    scrollbar-color: ${themeGet('colors.border.customDefault')}
    ${themeGet('colors.bg.secondary')};

    &::-webkit-scrollbar {
      width: 2px;
      height: 100%;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${themeGet('colors.border.customDefault')};
    }
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  ${toasts}


`
