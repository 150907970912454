import React, { useCallback } from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Column, Link, Logo, Row, Text } from 'Components/UI'

import { ROOT } from 'Router/routes'

import _ from 'Services/I18n'
import { clearAuth } from 'Services/Store/auth'

import {
  BackgroundContainer,
  Content,
  ExtrernalLink,
  GoBackIcon,
  LinkedInIcon,
  Links,
  PrimaryIntroText,
  ResponsiveWrapper,
  SecondaryIntroText,
  TwitterIcon,
} from './styles'

function Auth({ route }) {
  const handleGoBack = useCallback(() => {
    clearAuth()
  }, [])

  return (
    <>
      <BackgroundContainer black>
        <Column ml={[4, 24, 48, 64]}>
          <Row mt={[24, 36, 48]}>
            <Logo height={[91, 120, 150, 170]} width={[80, 105, 130, 150]} />
          </Row>
          <PrimaryIntroText maxWidth={515} mt={[32, 36, 41, 46]}>
            <Text bigger bolder white>
              {_('general.introPartOne')}
            </Text>
            <Text bigger bolder white>
              {_('general.introPartTwo')}
            </Text>
          </PrimaryIntroText>
          <SecondaryIntroText maxWidth={480} mt={26}>
            <Text middle primary>
              {_('general.introSecondary')}
            </Text>
          </SecondaryIntroText>
        </Column>
        <Links mb={40} ml={[4, 24, 48, 64]} mt={40}>
          <ExtrernalLink
            href="https://www.linkedin.com/company/sentr3/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <LinkedInIcon />
          </ExtrernalLink>
          <ExtrernalLink
            href="https://twitter.com/SENTR_3"
            rel="noopener noreferrer"
            target="_blank"
          >
            <TwitterIcon ml={24} />
          </ExtrernalLink>
        </Links>
      </BackgroundContainer>
      <BackgroundContainer white>
        <Column>
          <ResponsiveWrapper>
            <Row ml={24} mr={24} mt={32} spaceBetween>
              <Link clean to={ROOT} onClick={handleGoBack}>
                <Row spaceBetween width={64}>
                  <GoBackIcon mt={1} />
                  <Text middle primary>
                    {_('general.back')}
                  </Text>
                </Row>
              </Link>
              <Row height={32}>
                {/* <Button
                height={32}
                noChange
                width={218}
                onClick={handlePublicStats}
              >
                {_('general.check')}
              </Button> */}
              </Row>
            </Row>
          </ResponsiveWrapper>
          <Content>{renderRoutes(route.routes)}</Content>
        </Column>
      </BackgroundContainer>
    </>
  )
}

Auth.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Auth
