import React from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import noop from 'lodash/noop'

import Channel from './Channel'
import { ChannelsContainer } from './styles'

function Channels({
  categoryId,
  categoryNum,
  canChangeChannels,
  channels,
  isOpened,
  roomSlug,
  onResponsiveCollapse,
}) {
  return (
    <Droppable droppableId={categoryId} type={categoryNum?.toString()}>
      {provided => (
        <ChannelsContainer
          channelsOpened={isOpened}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {map(channels, (channel, index) => (
            <Draggable
              draggableId={`${categoryNum}${index}`}
              index={index}
              isDragDisabled={!canChangeChannels}
              key={`${categoryNum}${index}`}
            >
              {providedDraggable => (
                <Channel
                  channel={channel}
                  index={index}
                  key={channel?.id}
                  provided={providedDraggable}
                  roomSlug={roomSlug}
                  onResponsiveCollapse={onResponsiveCollapse}
                />
              )}
            </Draggable>
          ))}

          {provided.placeholder}
        </ChannelsContainer>
      )}
    </Droppable>
  )
}

Channels.defaultProps = {
  canChangeChannels: false,
  categoryId: null,
  categoryNum: 0,
  channels: null,
  isOpened: false,
  roomSlug: null,
  onResponsiveCollapse: noop,
}

Channels.propTypes = {
  canChangeChannels: PropTypes.bool,
  categoryId: PropTypes.string,
  categoryNum: PropTypes.number,
  channels: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  isOpened: PropTypes.bool,
  roomSlug: PropTypes.bool,
  onResponsiveCollapse: PropTypes.func,
}

export default Channels
