import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { FiCalendar } from 'react-icons/fi'

import { useTheme } from 'styled-components'
import { pick } from '@styled-system/props'

// eslint-disable-next-line import/no-extraneous-dependencies
import Tippy from '@tippyjs/react/headless'
import { DateTime } from 'luxon'

import noop from 'lodash/noop'

import DatePicker from 'Components/UI/DatePicker/DatePicker'

import { Container, Controls, Value } from './styles'

import Button from '../Button'
import { Column } from '../Flex'

function DateInput({
  placeholder,
  small,
  button,
  onChange,
  value,
  offset,
  ...rest
}) {
  const tippyInstance = useRef(null)
  const resetInstance = useRef(null)
  const [date, setDate] = useState(null)
  const theme = useTheme()

  const handleReset = useCallback(
    instance => {
      setDate(null)
      onChange(null)
      resetInstance.current = instance
    },
    [onChange],
  )

  const handleChooseDate = useCallback(
    pickedDate => {
      setDate(pickedDate)
      onChange(pickedDate)
    },
    [onChange],
  )

  const handleFinish = useCallback(() => {
    onChange(date)

    if (tippyInstance.current) {
      tippyInstance.current.hide()
    }
  }, [date, onChange])

  const innerValue = useMemo(() => {
    if (!date) {
      return placeholder
    }

    return `${date.toLocaleString()}`
  }, [date, placeholder])

  const formateddValue = useMemo(
    () => (value ? DateTime.fromFormat(value, 'yyyy-MM-dd').toJSDate() : null),
    [value],
  )

  return (
    <Tippy
      interactive
      offset={offset}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: { padding: 16 },
          },
        ],
      }}
      render={() => (
        <Column>
          <DatePicker
            initialValue={formateddValue}
            reset={resetInstance?.current}
            onFinish={handleChooseDate}
          />
          <Controls center pl={24} pr={24} spaceBetween width={1}>
            <Button
              height={32}
              mr={9}
              outline
              onClick={instance => handleReset(instance)}
            >
              Reset
            </Button>
            <Button height={32} outline onClick={handleFinish}>
              Apply
            </Button>
          </Controls>
        </Column>
      )}
      trigger="click"
      onMount={instance => {
        tippyInstance.current = instance
      }}
    >
      {button || (
        <Container {...pick(rest)} small={small}>
          <FiCalendar color={theme.colors.primary} size={14} />
          <Value ml={1}>{value || innerValue}</Value>
        </Container>
      )}
    </Tippy>
  )
}

DateInput.defaultProps = {
  button: null,
  offset: [0, 0],
  placeholder: null,
  small: false,
  value: undefined,
  onChange: noop,
}

DateInput.propTypes = {
  button: PropTypes.object,
  offset: PropTypes.arrayOf(PropTypes.number),
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
}

export default DateInput
