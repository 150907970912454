import React from 'react'
import PropTypes from 'prop-types'

import ChannelCategoriesSubscription from './ChannelCategoriesSubscription'
import ChannelParticipantSubscription from './ChannelParticipantSubscription'
import ChannelsSubscription from './ChannelsSubscription'

export function Subscriptions({ channelCategoriesVariables }) {
  return (
    <>
      <ChannelCategoriesSubscription
        channelCategoriesVariables={channelCategoriesVariables}
      />
      <ChannelsSubscription
        channelCategoriesVariables={channelCategoriesVariables}
      />
      <ChannelParticipantSubscription />
    </>
  )
}

Subscriptions.defaultProps = {
  channelCategoriesVariables: null,
}

Subscriptions.propTypes = {
  channelCategoriesVariables: PropTypes.object,
}
