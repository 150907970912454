import { BsCircleFill } from 'react-icons/bs'
import { FiCheck } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

import Text from 'Components/UI/Text'

// TODO: map to theme
export const IconCheck = styled(FiCheck)`
  display: none;
  position: absolute;
  color: ${themeGet('colors.white')};
  left: 3px;
  top: 4px;
  pointer-events: none;
`

export const IconCircle = styled(BsCircleFill)`
  display: none;
  position: absolute;
  top: 3px;
  left: 3px;
  color: ${themeGet('colors.customDefault')};
  pointer-events: none;
`

export const Background = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  background-color: ${themeGet('colors.bg.primary')};
  border: 1px solid ${themeGet('colors.customDefault')};
  border-radius: 50px;
  cursor: pointer;
  user-select: none;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;

  ${margin}
`

const circleCss = ({ circle }) =>
  circle &&
  css`
    > input {
      &:checked ~ ${IconCircle} {
        display: block;
      }

      &:checked ~ ${Background} {
        background-color: ${themeGet('colors.bg.primary')};
        border: 1px solid ${themeGet('colors.customDefault')};
      }

      &:disabled ~ ${Background} {
        border-color: ${themeGet('colors.border.customSecondary')};
        cursor: not-allowed;
      }
      &:disabled ~ ${IconCircle} {
        color: ${themeGet('colors.bg.customSecondary')};
        cursor: not-allowed;
      }
      &:disabled ~ ${TextContent} p:first-child {
        color: ${themeGet('colors.font.disabledBox')};
        cursor: not-allowed;
      }
    }
  `

const alternateCircleCss = ({ alternateCircle }) =>
  alternateCircle &&
  css`
    --size: 16px;

    ${Background} {
      height: 16px;
      width: 16px;
    }

    > input {
      &:checked ~ ${IconCircle} {
        display: block;
        height: 8px;
        width: 8px;
        top: 4px;
        left: 4px;
      }

      &:checked ~ ${Background} {
        background-color: ${themeGet('colors.bg.primary')};
        border: 1px solid ${themeGet('colors.customDefault')};
      }
    }
  `

export const Container = styled.label`
  --size: 20px;

  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
  min-height: var(--size);
  user-select: none;

  > input {
    width: var(--size);
    height: var(--size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${IconCheck} {
      display: block;
    }

    &:checked ~ ${Background} {
      background-color: ${themeGet('colors.customDefault')};
      border: 1px solid ${themeGet('colors.customDefault')};
    }

    &:disabled ~ ${Background} {
      border-color: ${themeGet('colors.border.customSecondary')};
      background: ${themeGet('colors.bg.customSecondary')};
      cursor: not-allowed;
    }

    &:disabled ~ ${TextContent} p:first-child {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  ${typography}
  ${margin}

  ${circleCss}
  ${alternateCircleCss}
`

export const TextOption = styled(Text)`
  position: absolute;
  color: ${themeGet('colors.primary')};
  cursor: pointer;
  font-weight: 300;

  &:hover {
    color: ${themeGet('colors.customDark')};
  }
`

const alternateCss = ({ alternate }) =>
  alternate &&
  css`
    > input {
      &:checked ~ ${TextOption} {
        color: ${themeGet('colors.violet')};
      }

      &:disabled ~ ${TextOption} {
        color: ${themeGet('colors.violetLightest')};
      }
    }

    ${layout.width}
  `

export const TextContainer = styled.label`
  --height: 20px;
  --width: 26px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  min-height: var(--height);
  user-select: none;

  > input {
    width: var(--width);
    height: var(--height);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${TextOption} {
      color: ${themeGet('colors.customDefault')};
    }

    &:disabled ~ ${TextOption} {
      color: ${themeGet('colors.customLight')};
      cursor: not-allowed;
    }
  }

  ${typography}
  ${margin}
  ${alternateCss}
`

export const ButtonContainer = styled.label`
  --height: 20px;
  --width: 26px;
  position: relative;
  display: flex;

  > input {
    margin: 0;
    opacity: 0;

    ~ ${TextOption} {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-style: solid;
      border-radius: ${mapToTheme('buttons.border.radius')}px;
      border-width: ${mapToTheme('buttons.border.width')}px;
      box-shadow: ${mapToTheme('buttons.active.shadow')};
      color: ${mapToTheme('buttons.outline.color')};
      border-color: ${mapToTheme('buttons.outline.border')};
      background-color: ${mapToTheme('buttons.outline.backgroundColor')};
    }

    &:checked ~ ${TextOption} {
      color: ${mapToTheme('buttons.color')};
      background-color: ${mapToTheme('buttons.backgroundColor')};
    }
  }

  ${layout}
  ${typography}
  ${margin}
`

export const PaletteBackground = styled.div`
  position: absolute;
  top: 0;
  width: 106px;
  height: 120px;
  background: ${themeGet('colors.bg.audio')};
  border: 1px solid ${themeGet('colors.bg.audio')};
  padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: 50px;
    height: 50px;
    padding: 0 ${themeGet('space.1')}px;
  }
`

export const PaletteIcon = styled(BsCircleFill)`
  position: relative;
  height: 56px;
  width: 56px;
  fill: ${mapToTheme('colors.palettes')};

  @media (max-width: ${themeGet('breakpoints.1')}) {
    height: 49px;
    width: 40px;
  }
`

export const PaletteIconChecked = styled(BsCircleFill)`
  position: absolute;
  top: 37px;
  left: 45px;
  display: none;
  height: 16px;
  width: 16px;
  fill: ${themeGet('colors.white')};

  @media (max-width: ${themeGet('breakpoints.1')}) {
    top: 17px;
    left: 20px;
  }
`

export const PaletteText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 16px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    bottom: -20px;
  }
`

export const PaletteContainer = styled.label`
  --height: 120px;
  --width: 106px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: var(--width);
  min-height: var(--height);
  user-select: none;

  > input {
    width: var(--width);
    height: var(--height);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${PaletteBackground} {
      border: 1px solid ${mapToTheme('colors.palettes')};
    }

    &:checked ~ ${PaletteIconChecked} {
      display: block;
    }
  }

  @media (max-width: ${themeGet('breakpoints.1')}) {
    --height: 50px;
    --width: 56px;
  }

  ${typography}
  ${margin}
`

export const ProviderBackground = styled.div`
  position: absolute;
  top: 0;
  width: 253px;
  height: 64px;
  background: ${themeGet('colors.bg.audio')};
  border: 1px solid ${themeGet('colors.bg.audio')};
  padding: ${themeGet('space.2')}px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
`

export const ProviderLogo = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  width: 48px;
  height: 48px;
`

export const ProviderIcon = styled(BsCircleFill)`
  position: absolute;
  bottom: 8px;
  right: 8px;
  height: 22px;
  width: 22px;
  color: ${themeGet('colors.bg.primary')};
  border: 1px solid ${themeGet('colors.customDefault')};
  border-radius: 50px;
`

export const ProviderIconChecked = styled(BsCircleFill)`
  position: absolute;
  bottom: 12px;
  right: 12px;
  display: none;
  height: 16px;
  width: 16px;
  fill: ${themeGet('colors.customDefault')};
`

export const ProviderText = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 23px;
  left: 64px;
`

export const ProviderContainer = styled.label`
  --height: 64px;
  --width: 253px;

  position: relative;
  display: flex;
  align-items: center;
  min-width: var(--width);
  min-height: var(--height);
  user-select: none;

  > input {
    width: var(--width);
    height: var(--height);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${ProviderIconChecked} {
      display: block;
    }
  }

  ${typography}
  ${margin}
`

export const BlockchainIconChecked = styled(FiCheck)`
  position: absolute;
  bottom: -4px;
  right: -4px;
  display: none;
  height: 22px;
  width: 22px;
  stroke: ${themeGet('colors.customDefault')};
  stroke-width: 2px;
`

export const BlockchainLogo = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  svg {
    width: 48px;
    height: 48px;
  }
`

export const BlockchainContainer = styled.label`
  --height: 48px;
  --width: 48px;

  position: relative;
  display: flex;
  align-items: center;
  min-width: var(--width);
  min-height: var(--height);
  user-select: none;
  cursor: pointer;

  > input {
    width: var(--width);
    height: var(--height);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${BlockchainIconChecked} {
      display: block;
    }
  }

  &:disabled {
    cursor: not-allowed !important;
  }

  ${typography}
  ${margin}
`
