import isEqual from 'lodash/isEqual'

import { ROUTE_ACTIONS, ROUTE_DEFAULTS, ROUTE_STATE } from 'Constants/store'

export default function routeState(store) {
  store.on('@init', () => ({
    [ROUTE_STATE]: ROUTE_DEFAULTS,
  }))

  store.on(ROUTE_ACTIONS.SET, (state, variables) => {
    const currentState = state[ROUTE_STATE]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [ROUTE_STATE]: nextState }
  })
}
