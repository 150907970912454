import React from 'react'
import { components } from 'react-select'

import { MultiValueLabelWrapper } from './styles'

const MultiValueLabel = props => (
  <MultiValueLabelWrapper as={components.MultiValueLabel} {...props} />
)

MultiValueLabel.propTypes = {
  ...components.MultiValueLabel.propTypes,
}

export default MultiValueLabel
