import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import CategorizedChannelsFragment from 'GraphQL/Fragments/CategorizedChannels.graphql'
import categorizedChannelsQuery from 'GraphQL/Queries/Channels/categorizedChannels.graphql'
import channelCategoryCreatedSubscription from 'GraphQL/Subscriptions/Channels/channelCategoryCreated.graphql'
import channelCategoryDeletedSubscription from 'GraphQL/Subscriptions/Channels/channelCategoryDeleted.graphql'
import channelCategoryUpdatedSubscription from 'GraphQL/Subscriptions/Channels/channelCategoryUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateFragment(client, channelCategory) {
  const fragment = {
    id: `CategorizedChannels:${channelCategory?.id}`,
    fragmentName: 'CategorizedChannelsFields',
    fragment: CategorizedChannelsFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    client.writeFragment({
      ...fragment,
      data: {
        ...data,
        ...channelCategory,
        channels: data?.channels,
        __typename: 'CategorizedChannels',
      },
    })
  }
}

function updateQuery({
  client,
  channelCategory,
  remove,
  channelCategoriesVariables,
}) {
  const channelCategoriesOptions = {
    query: categorizedChannelsQuery,
    variables: {
      ...channelCategoriesVariables,
    },
  }

  const id = `CategorizedChannels:${channelCategory?.id}`

  const channelCategoriesData = client.readQuery(channelCategoriesOptions)

  if (channelCategoriesData) {
    if (remove) {
      client.cache.evict({ id })
      client.cache.gc()
    } else if (channelCategoriesVariables?.roomId === channelCategory?.roomId) {
      client.writeQuery({
        ...channelCategoriesOptions,
        data: {
          ...channelCategoriesData,
          categorizedChannels: uniqBy(
            [
              ...(channelCategoriesData?.categorizedChannels || []),
              { ...channelCategory, __typename: 'CategorizedChannels' },
            ],
            'id',
          ),
        },
      })
    }
  }
}

function ChannelCategoriesSubscription({ channelCategoriesVariables }) {
  useSubscription(channelCategoryCreatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channelCategory = get(subscriptionData, [
        'data',
        'channelCategoryCreated',
      ])
      const remove = false

      updateQuery({
        client,
        channelCategory,
        remove,
        channelCategoriesVariables,
      })
    },
  })

  useSubscription(channelCategoryDeletedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channelCategory = get(subscriptionData, [
        'data',
        'channelCategoryDeleted',
      ])
      const remove = true

      updateQuery({
        client,
        channelCategory,
        remove,
        channelCategoriesVariables,
      })
    },
  })

  useSubscription(channelCategoryUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channelCategory = get(subscriptionData, [
        'data',
        'channelCategoryUpdated',
      ])

      updateFragment(client, channelCategory)
    },
  })

  return <></>
}

ChannelCategoriesSubscription.defaultProps = {
  channelCategoriesVariables: null,
}

ChannelCategoriesSubscription.propTypes = {
  channelCategoriesVariables: PropTypes.object,
}

export default ChannelCategoriesSubscription
