import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Column } from 'Components/UI'

export const Content = styled(Column)`
  width: 490px;
  min-height: 350px;
  padding: 0 ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const UsersFrame = styled.div`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  width: 100%;
  padding: ${themeGet('space.3')}px 0;
`
