import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import PropTypes from 'prop-types'

import { FiChevronLeft, FiPlus } from 'react-icons/fi'
import { VscMenu } from 'react-icons/vsc'

import { useTheme } from 'styled-components'

import { useStoreon } from 'storeon/react'

import noop from 'lodash/noop'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Row, Text } from 'Components/UI'

import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import { useAppContext } from 'Hooks'

import { IconWrapper, RoomListWrapper } from './styles'

function Header({ canChangeChannels, room, onChannelCategory }) {
  const { me } = useAppContext()
  const { dispatch, ui } = useStoreon(UI_STATE)

  const theme = useTheme()
  const { darkMode } = ui

  const { rightIsCollapsed, leftIsCollapsed, channelsCollapsed } = ui

  const handleCollapsedLeft = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      leftIsCollapsed: !leftIsCollapsed,
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, leftIsCollapsed, room])

  const handleCollapsedRight = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      rightIsCollapsed: !rightIsCollapsed,
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, rightIsCollapsed, room])

  const handleCollapseChannels = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, room])

  return (
    <Column>
      <Row spaceBetween>
        <RoomListWrapper mb={4} onClick={handleCollapsedLeft}>
          <VscMenu
            color={darkMode ? theme.colors.white : theme.colors.blackSecondary}
            grayLight
            size={24}
          />
        </RoomListWrapper>
        <RoomListWrapper mb={4}>
          <div aria-hidden="true" role="button" onClick={handleCollapsedRight}>
            <Avatar
              size={40}
              src={me.profile?.photoUrl}
              username={me.username}
            />
          </div>
        </RoomListWrapper>
      </Row>
      <Row center spaceBetween width={1}>
        <Row center>
          {isMobile && (
            <Button noEffectsIcon onClick={handleCollapseChannels}>
              <FiChevronLeft color={theme.colors.customDefault} />
            </Button>
          )}
          <Text bolder> Channels</Text>
        </Row>
        <Column>
          {canChangeChannels && (
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={() => onChannelCategory(room?.id)}
            >
              <IconWrapper>
                <FiPlus />
              </IconWrapper>
            </Button>
          )}
        </Column>
      </Row>
    </Column>
  )
}

Header.defaultProps = {
  canChangeChannels: false,
  room: null,
  onChannelCategory: noop,
}

Header.propTypes = {
  canChangeChannels: PropTypes.bool,
  room: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onChannelCategory: PropTypes.func,
}

export default Header
