import { getEnv } from 'Services/Envs'

export const APP_ENV = getEnv('ENV')

export const IS_PRODUCTION = APP_ENV === 'production'
export const IS_DEVELOPMENT = APP_ENV === 'development'
export const IS_STAGING = APP_ENV === 'staging'

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
}

export const PALETTE = {
  denim: 'denim',
  blue: 'blue',
  pink: 'pink',
  orange: 'orange',
  purple: 'purple',
  green: 'green',
  yellow: 'yellow',
}

const apiProtocol = getEnv('API_SSL') === 'true' ? 'https' : 'http'
const socketProtocol = getEnv('API_SSL') === 'true' ? 'wss' : 'ws'

export const APP_NAME = getEnv('NAME')

const API_URL = `${apiProtocol}://${getEnv('API_URL')}`

export const FRONTEND_URL = getEnv('FRONTEND_URL')

export const APP_URL = `${apiProtocol}://${FRONTEND_URL}`

export const API = {
  URL: API_URL,
  WS: `${socketProtocol}://${getEnv('API_URL')}`,
  PROXY: `${API_URL}/proxy/`,
}

const url = new URL(window.location)
const cookieDomain = getEnv('COOKIE_DOMAIN')

export const COOKIE = {
  SHARED_DOMAIN: `.${
    url.hostname.includes(cookieDomain) ? cookieDomain : url.hostname
  }`,
  PREFIX: '_secure',
}

export const SENTRY = {
  DSN: getEnv('SENTRY_DSN'),
}

export const MOBILE_SCHEME = getEnv('MOBILE_SCHEME')
export const TELEGRAM_BOT = getEnv('TELEGRAM_BOT')

export const WEB3 = {
  REGISTER_MANAGER_CONTRACT_ADDRESS: getEnv(
    'REGISTER_MANAGER_CONTRACT_ADDRESS',
  ),
  UNISWAP_V2_CONTRACT_ADDRESS: getEnv('UNISWAP_V2_CONTRACT_ADDRESS'),
  WHL_CONTRACT_ADDRESS: getEnv('WHL_CONTRACT_ADDRESS'),
  WETH_ADDRESS: getEnv('WETH_ADDRESS'),
}

export const DOMAINS = {
  DEVELOPMENT: 'sentr3',
  STAGING: 'staging',
  PRODUCTION: 'app',
}

export const GOOGLE_API_KEY = getEnv('GOOGLE_API_KEY')

export const WALLET_CONNECT_PROJECT_ID = getEnv('WALLET_CONNECT_PROJECT_ID')

export const PRIVY_APP_ID = getEnv('PRIVY_APP_ID')

export const TWITTER_CLIENT_ID = getEnv('TWITTER_CLIENT_ID')

export const LINKEDIN_CLIENT_ID = getEnv('LINKEDIN_CLIENT_ID')
