import { useCallback, useContext } from 'react'

import _ from 'Services/I18n'
import toast from 'Services/Toast'
import Web3Context from 'Services/Web3Context'

export default function useWeb3() {
  const {
    account,
    setAccount,
    setIsMetaMaskConnecting,
    setIsMetaMaskConnected,
    ...rest
  } = useContext(Web3Context)

  const connect = useCallback(async () => {
    if (account) return

    if (typeof window.ethereum !== 'undefined') {
      setIsMetaMaskConnecting(true)

      try {
        const accounts = await window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(([address]) => {
            setAccount(address)
            return address
          })

        setIsMetaMaskConnected(accounts?.length > 0)
      } catch (error) {
        toast.error({
          title: 'Auth',
          text: error?.message || _('error.generic'),
        })
      } finally {
        setIsMetaMaskConnecting(false)
      }
    }
  }, [account, setAccount, setIsMetaMaskConnected, setIsMetaMaskConnecting])

  return { ...rest, account, setAccount, connect }
}
