import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Container, Content } from './styles'

function OAuth({ route }) {
  return (
    <Container>
      <Content>{renderRoutes(route.routes)}</Content>
    </Container>
  )
}

OAuth.propTypes = {
  route: PropTypes.object.isRequired,
}

export default OAuth
