import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Column, Loader, Modal, Text } from 'Components/UI'
import { TextareaField } from 'Components/UI/Forms'

import requestUserBanMutation from 'GraphQL/Mutations/Ban/requestUserBan.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const FIELDS = {
  DESCRIPTION: 'description',
}

function RequestBanModal({ userId, roomId, ...rest }) {
  const s = useScopedI18n('user.modal.requestBan')
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [requestUserBan] = useMutation(requestUserBanMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.DESCRIPTION]: {
        presence: true,
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await requestUserBan({
          variables: {
            description: values[FIELDS.DESCRIPTION],
            roomId,
            userId,
          },
        })

        toast.success({
          title: 'Request user ban',
          text: 'User ban successfully requested',
        })

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error({
          title: 'Request user ban',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [requestUserBan, roomId, userId],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Text mb={16} mt={24} primary>
                {s('subtitle')}
              </Text>
              <TextareaField
                input={{
                  disabled: loading,
                }}
                name={FIELDS.DESCRIPTION}
                noResize
                placeholder={s('description')}
                requestBan
                width={1}
              />
            </Content>

            <Column center mt={24} pb={24}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button height={40} width={130} onClick={handleSubmit}>
                    {s('actions.save')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

RequestBanModal.defaultProps = {
  roomId: null,
  userId: null,
}

RequestBanModal.propTypes = {
  roomId: PropTypes.string,
  userId: PropTypes.string,
}

export default RequestBanModal
