import React, { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'

import find from 'lodash/find'

import {
  OnlineIcon,
  ResponsiveButtonRow,
} from 'Components/Blocks/Modals/styles'
import { Loader, Row, Text } from 'Components/UI'

import { useEntranceContext } from 'Hooks'

import { APP_ROOM } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { InviteMessageWrapper } from './styles'

function JoinRoomHeader() {
  const s = useScopedI18n('public.modal.inviteToRoom')
  const [{ roomMeta, loading, roomGroupMeta, exclusiveMode }] =
    useEntranceContext()

  const { pathname } = useLocation()

  const fromRoomSlug = matchPath(pathname, {
    path: APP_ROOM(),
  })?.params?.roomPointer

  const inviteMessage = useMemo(() => {
    if (exclusiveMode && fromRoomSlug) {
      const selectedRoom = find(
        roomGroupMeta?.rooms,
        room => room?.slug === fromRoomSlug,
      )
      return selectedRoom?.authSettings?.inviteMessage
    }
    return roomMeta?.authSettings?.inviteMessage
  }, [exclusiveMode, fromRoomSlug, roomGroupMeta])

  return (
    <>
      {loading ? (
        <Loader mt={5} />
      ) : (
        <>
          <Text alignTextCenter mb="15px" mt={5}>
            {inviteMessage ? (
              <InviteMessageWrapper
                dangerouslySetInnerHTML={{
                  __html: inviteMessage,
                }}
              />
            ) : (
              <>
                <Text as="span" bold>
                  {roomMeta?.label || s('appName')}
                </Text>
                <Text as="span" ml={1}>
                  {s('inviteText')}
                </Text>
              </>
            )}
          </Text>
          {!roomMeta?.authSettings?.hideMembers && (
            <ResponsiveButtonRow mb={3} mt={5} spaceBetween width={285}>
              <Row center>
                <OnlineIcon />
                <Text ml={2}>
                  <Text as="span" bold>
                    {roomMeta?.onlineParticipantsCount || 0}
                  </Text>
                  <Text as="span" ml={1}>
                    {s('online')}
                  </Text>
                </Text>
              </Row>
              <Row center mt={[3, 0, 0, 0, 0]}>
                <OnlineIcon secondary />
                <Text ml={2}>
                  <Text as="span" bold>
                    {roomMeta?.participantsCount || 0}
                  </Text>
                  <Text as="span" ml={1}>
                    {s('members')}
                  </Text>
                </Text>
              </Row>
            </ResponsiveButtonRow>
          )}
        </>
      )}
    </>
  )
}

export default JoinRoomHeader
