import { DateTime } from 'luxon'

import { TIME_UNITS } from 'Constants/ids'

import * as Strings from './Strings'

export function getIsPm(date) {
  return !!date.toLocaleString(DateTime.TIME_SIMPLE).match(/pm/i)
}

export function localeWithMeridiem() {
  return !!DateTime.local().toLocaleString(DateTime.TIME_SIMPLE).match(/am|pm/i)
}

export function secondsToHms(timeInSeconds) {
  const time = parseFloat(timeInSeconds).toFixed(3)
  const hours = Math.floor(time / 60 / 60)
  const minutes = Math.floor(time / 60) % 60

  return `${Strings.zeroPad(hours, 2)}:${Strings.zeroPad(minutes, 2)}`
}

export function hmsToSeconds(str) {
  const [hh = '0', mm = '0', ss = '0'] = (str || '0:0:0').split(':')
  const hour = parseInt(hh, 10) || 0
  const minute = parseInt(mm, 10) || 0
  const second = parseInt(ss, 10) || 0
  return hour * 3600 + minute * 60 + second
}

export function secondsToDuration(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60)
  const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`
  const seconds = Math.floor(timeInSeconds % 60)
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`
  return `${returnedMinutes}:${returnedSeconds}`
}

export function timeAgo(date) {
  const dateTime = DateTime.fromISO(date)
  const diff = dateTime.diffNow().shiftTo(...TIME_UNITS)
  const timeUnit = TIME_UNITS.find(unit => diff.get(unit) !== 0) || 'second'

  const relativeFormatter = new Intl.RelativeTimeFormat('en', {
    numeric: 'auto',
  })

  return relativeFormatter.format(Math.trunc(diff.as(timeUnit)), timeUnit)
}
