import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import {
  BlockchainContainer,
  BlockchainIconChecked,
  BlockchainLogo,
} from './styles'

function RadioBlockchain({
  value,
  name,
  disabled,
  error,
  logo,
  label,
  meta,
  ...rest
}) {
  return (
    <BlockchainContainer {...pick(rest)}>
      <input
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        {...omit(rest)}
      />
      <BlockchainLogo>{logo}</BlockchainLogo>
      <BlockchainIconChecked />
    </BlockchainContainer>
  )
}

RadioBlockchain.defaultProps = {
  circle: false,
  disabled: false,
  error: null,
  label: null,
  logo: null,
  meta: {},
  name: null,
  textRadioButton: false,
  value: null,
}

RadioBlockchain.propTypes = {
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  logo: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  meta: PropTypes.object,
  name: PropTypes.string,
  textRadioButton: PropTypes.bool,
  value: PropTypes.string,
}

export default RadioBlockchain
