import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'
import round from 'lodash/round'

import { Button, Column, Loader, Modal, Row, Text, Tip } from 'Components/UI'

import { WALLET_PROVIDERS } from 'Constants/ids'

import disconnectWalletMutation from 'GraphQL/Mutations/Wallets/disconnectWallet.graphql'
import { disconnectWalletUpdater } from 'GraphQL/Updaters/wallets'

import { useAppContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import {
  Badge,
  CoinmetroIcon,
  Content,
  Entity,
  EntityWalletAdress,
  HathorNetworkIcon,
  MetamaskIcon,
  SelectArrow,
  Subtitle,
} from './styles'

function WalletsModal({
  wallets,
  walletsLoading,
  walletsVariables,
  totalWallets,
  onConnectWallet,
  ...rest
}) {
  const { me } = useAppContext()
  const s = useScopedI18n('user.modal.wallets')
  const [loading, setLoading] = useState(false)
  const [selectedExtension, setSelectedExtension] = useState(null)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [disconnectWallet] = useMutation(disconnectWalletMutation)

  const handleSelectExtension = useCallback(
    extension => {
      if (selectedExtension === extension) {
        setSelectedExtension(null)
      } else {
        setSelectedExtension(extension)
      }
    },
    [selectedExtension],
  )

  const handleDisconnectWallet = useCallback(
    async (provider, identity) => {
      setLoading(true)
      try {
        await disconnectWallet({
          variables: { provider, identity },
          update: disconnectWalletUpdater({
            walletsVariables,
            provider,
            userId: me?.id,
          }),
        })

        toast.success({
          title: 'Disconnect wallet',
          text: 'Wallet has been successfully disconnected',
        })
      } catch (error) {
        toast.error({
          title: 'Disconnect wallet',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [disconnectWallet, me?.id, walletsVariables],
  )

  const walletsByProvider = useMemo(() => keyBy(wallets, 'provider'), [wallets])

  const shownWallets = useCallback(
    walletsArray =>
      map(walletsArray, ({ id, identity, tokenBalances, provider }) => (
        <EntityWalletAdress key={id} mt={2} spaceBetween>
          <Column spaceBetween>
            <Text bold small>
              <Badge>{provider}</Badge> {identity}
            </Text>
            <Row mt={2}>
              {map(tokenBalances, (balance, idx) => (
                <Row key={balance?.id} ml={idx === 0 ? 0 : 4}>
                  <Tip
                    content={`${round(
                      balance?.balance,
                      10,
                    )} ${balance?.token?.toUpperCase()}`}
                  >
                    <Row cursor>
                      {/* logo here */}
                      <Text bold small uppercase>
                        {balance?.token}
                      </Text>
                    </Row>
                  </Tip>
                </Row>
              ))}
            </Row>
          </Column>
          <Button
            plainTextButton
            sixth
            onClick={() => handleDisconnectWallet(provider, identity)}
          >
            {s('actions.disconnect')}
          </Button>
        </EntityWalletAdress>
      )),
    [handleDisconnectWallet, s],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={s('title')}
      onMount={handleMount}
    >
      <Content>
        <Subtitle>
          <Text mb={16} mt={16} primary>
            {s('subtitle')}
          </Text>
        </Subtitle>
        {walletsLoading && <Loader />}
        {map(
          wallets,
          (wallet, idx) =>
            wallet?.provider !== WALLET_PROVIDERS.BSC &&
            wallet?.provider !== WALLET_PROVIDERS.POLYGON && (
              <Fragment key={wallet?.provider}>
                <Entity
                  mt={idx === 0 ? 0 : 2}
                  selectable
                  selectedExtension={selectedExtension === wallet?.provider}
                  onClick={() => handleSelectExtension(wallet?.provider)}
                >
                  <SelectArrow
                    arrowSelected={selectedExtension === wallet?.provider}
                  />
                  {wallet?.provider === WALLET_PROVIDERS.ETHEREUM && (
                    <MetamaskIcon ml={3} />
                  )}
                  {wallet?.provider === WALLET_PROVIDERS.HATHOR && (
                    <HathorNetworkIcon ml={3} />
                  )}
                  {wallet?.provider === WALLET_PROVIDERS.XCM && (
                    <CoinmetroIcon ml={3} />
                  )}
                  <Column ml={4} spaceBetween>
                    <Text middle>{s(`providers.${wallet?.provider}`)}</Text>
                    {wallet?.provider === WALLET_PROVIDERS.ETHEREUM ? (
                      <Text primary small>
                        {wallet?.wallets?.length +
                          (walletsByProvider?.bsc?.wallets?.length || 0) +
                          (walletsByProvider?.polygon?.wallets?.length || 0) ===
                        1
                          ? s('wallet')
                          : s('wallets', {
                              amount:
                                wallet?.wallets?.length +
                                (walletsByProvider?.bsc?.wallets?.length || 0) +
                                (walletsByProvider?.polygon?.wallets?.length ||
                                  0),
                            })}
                      </Text>
                    ) : (
                      <Text primary small>
                        {wallet?.wallets?.length === 1
                          ? s('wallet')
                          : s('wallets', { amount: wallet?.wallets?.length })}
                      </Text>
                    )}
                  </Column>
                </Entity>
                {selectedExtension === wallet?.provider &&
                  shownWallets(
                    wallet?.provider === WALLET_PROVIDERS.ETHEREUM
                      ? [
                          ...wallet?.wallets,
                          ...(walletsByProvider?.bsc?.wallets || []),
                          ...(walletsByProvider?.polygon?.wallets || []),
                        ]
                      : wallet?.wallets,
                  )}
              </Fragment>
            ),
        )}
        {loading ? (
          <Loader />
        ) : (
          <Button
            height={32}
            mt={3}
            outline
            width={125}
            onClick={onConnectWallet}
          >
            {s('actions.add')}
          </Button>
        )}
      </Content>
    </Modal>
  )
}

WalletsModal.defaultProps = {
  totalWallets: 0,
  wallets: null,
  walletsLoading: false,
  walletsVariables: null,
  onConnectWallet: noop,
}

WalletsModal.propTypes = {
  totalWallets: PropTypes.number,
  wallets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  walletsLoading: PropTypes.bool,
  walletsVariables: PropTypes.object,
  onConnectWallet: PropTypes.func,
}

export default WalletsModal
