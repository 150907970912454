import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Row } from 'Components/UI'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
  padding: 0 ${themeGet('space.5')}px ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const MembershipWrapper = styled.div`
  border: 1px solid ${themeGet('colors.gold')};
  border-radius: 4px;
  height: fit-content;
`
export const CustomFollowRow = styled(Row)`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
  }
`
