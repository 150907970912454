import { useMemo } from 'react'

import sumBy from 'lodash/sumBy'

import directChannelsUnreadQuery from 'GraphQL/Queries/Channels/directChannelsUnread.graphql'

import { useQuery } from 'Services/Apollo'

export default function useDirectChannelsUnread() {
  const { data } = useQuery(directChannelsUnreadQuery, {
    variables: { page: 0, limit: 999999 },
  })

  return useMemo(
    () => sumBy(data?.directChannels?.rows, 'unreadCount'),
    [data?.directChannels?.rows],
  )
}
