import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { FiLink2, FiXCircle } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { omit, pick } from '@styled-system/props'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { ErrorWrapper, Wrapper } from '../styles'

const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    color: ${themeGet('colors.font.disabled')};
  `

const nochangeCss = ({ noChange }) =>
  noChange &&
  css`
    background-color: ${themeGet('inputs.background.input.white')};
    border: 1px solid ${themeGet('colors.noChangeSecondary')};

    :focus-within {
      border-color: ${themeGet('colors.primary')};
    }
  `

const Label = styled.div`
  position: absolute;
  color: ${themeGet('colors.font.default')};
  font-weight: 300;
  z-index: 2;
  padding-left: ${themeGet('space.3')}px;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  user-select: none;
  cursor: text;

  ${disabledCss}
`

const Close = styled.div`
  position: absolute;
  cursor: pointer;
  right: 8px;
  line-height: 0;

  & svg {
    stroke: ${themeGet('colors.font.secondary')};
  }
`

const noChangeCaretCss = ({ noChange }) =>
  noChange &&
  css`
    caret-color: ${themeGet('colors.primary')};
  `

const StyledInput = styled.input`
  position: relative;
  width: 100%;
  height: ${mapToTheme('inputs.height')}px;
  margin: 0;
  padding: 0 ${themeGet('space.3')}px;
  border: none;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  background: none;
  caret-color: ${themeGet('colors.font.secondary')};

  ::placeholder {
    color: ${themeGet('colors.font.default')};
    font-weight: 300;
  }

  ${noChangeCaretCss}
`

const labelTopCss = css`
  ${Label} {
    font-size: 12px;
    transform: translateY(-10px);
  }
`

const inputBottomCss = css`
  padding-top: 12px;
`

const withLabelCss = ({ withLabel }) =>
  withLabel &&
  css`
    :focus-within {
      ${labelTopCss}
    }

    > input {
      :focus {
        ${inputBottomCss}
      }
    }
  `

const hasValueCss = ({ hasValue }) =>
  hasValue &&
  css`
    ${StyledInput} {
      margin-right: 32px;
      color: ${themeGet('colors.font.secondary')};
      font-weight: 300;
    }
  `

const nochangeAndHasValueCss = ({ noChange }) =>
  noChange &&
  css`
    ${StyledInput} {
      color: ${themeGet('text.color.blackExtra')};
    }
  `

const hasValueAndLabelCss = ({ hasValue, withLabel }) =>
  hasValue &&
  withLabel &&
  css`
    ${labelTopCss}

    > input {
      ${inputBottomCss}
    }
  `

const withIconCss = ({ withIcon }) =>
  withIcon &&
  css`
    > svg:first-of-type {
      margin-left: 16px;
      margin-right: 0;
      color: ${mapToTheme('inputs.border.input')};
      width: 20px;
      height: 20px;
    }
  `

const searchCss = ({ search }) =>
  search &&
  css`
    background-color: ${mapToTheme('inputs.background.search')};
    border: 1px solid ${mapToTheme('inputs.border.search')};

    ${StyledInput} {
      color: ${themeGet('colors.font.secondary')};
    }
  `

const userUrlCss = ({ userUrl }) =>
  userUrl &&
  css`
    border-radius: 4px;
  `

const alternateStyleCss = ({ alternateStyle }) =>
  alternateStyle &&
  css`
    border-radius: 4px;
  `

const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;

  background-color: ${mapToTheme('inputs.background.input')};
  border: solid 1px ${mapToTheme('inputs.border.input')};
  border-radius: 18px;
  outline: none;
  appearance: none;
  transition: all ease-in-out ${themeGet('transitionTime.default')};
  overflow: hidden;

  :focus-within {
    border-color: ${mapToTheme('inputs.focus.border')};
  }

  ${withLabelCss}
  ${withIconCss}
  ${hasValueCss}
  ${hasValueAndLabelCss}
  ${nochangeCss}
  ${nochangeAndHasValueCss}
  ${searchCss}
  ${userUrlCss}
  ${alternateStyleCss}

  ${space}
  ${layout}
`

const disabledLinkCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: default;
    pointer-events: none;

    & svg {
      stroke: ${themeGet('colors.customSecondary')};
    }
  `

const ExternalLink = styled.a`
  text-decoration: none;
  height: 20px;
  cursor: pointer;
  margin-left: ${themeGet('space.3')}px;

  & svg {
    height: 20px;
    width: 24px;
    stroke: ${themeGet('colors.customDefault')};
    transition: all ${themeGet('transitionTime.default')} ease-in-out;
  }

  &:hover {
    svg {
      stroke: ${themeGet('colors.customMedium')};
    }
  }

  &:active {
    svg {
      stroke: ${themeGet('colors.customDark')};
    }
  }

  ${disabledLinkCss}
`

export const Shadow = styled.div``

function Input({
  alternateStyle,
  defaultValue,
  disabled,
  error,
  icon,
  label,
  userUrl,
  value,
  search,
  noChange,
  onChange,
  onClear,
  ...rest
}) {
  const inputRef = useRef(null)
  const [innerValue, setInnerValue] = useState(defaultValue)
  const styledProps = pick(rest)
  const inputProps = omit(rest)

  const hasValue = !!value || !!innerValue

  const handleChange = onChange || (({ target }) => setInnerValue(target.value))

  const handleClear = useCallback(() => {
    if (inputRef.current) inputRef.current.value = ''
    setInnerValue('')

    if (typeof onClear === 'function') onClear()
  }, [onClear])

  const handleFocus = useCallback(() => inputRef.current?.focus(), [])

  return (
    <Wrapper {...styledProps}>
      <Container
        alternateStyle={alternateStyle}
        disabled={disabled}
        hasValue={hasValue}
        noChange={noChange}
        search={!!search}
        userUrl={userUrl}
        withError={!!error}
        withIcon={!!icon}
        withLabel={!!label}
      >
        {icon}
        {userUrl && (
          <ExternalLink
            disabled={!value}
            href={`//${value}`}
            rel="noopener noreferrer external"
            target="_blank"
          >
            <FiLink2 />
          </ExternalLink>
        )}
        <StyledInput
          ref={inputRef}
          {...inputProps}
          disabled={disabled}
          noChange={noChange}
          value={innerValue || value}
          onChange={handleChange}
        />

        {label && (
          <Label disabled={disabled} onClick={handleFocus}>
            {label}
          </Label>
        )}

        {hasValue && !disabled && (
          <Close onClick={handleClear}>
            <FiXCircle size={16} />
          </Close>
        )}
      </Container>

      {error && <ErrorWrapper>{error}</ErrorWrapper>}
    </Wrapper>
  )
}

Input.defaultProps = {
  alternateStyle: false,
  autoComplete: 'on',
  autoFocus: false,
  defaultValue: null,
  disabled: false,
  error: false,
  icon: null,
  label: null,
  noChange: false,
  placeholder: '',
  search: false,
  secondary: false,
  small: false,
  type: 'text',
  userUrl: false,
  value: undefined,
  onChange: null,
  onClear: null,
}

Input.propTypes = {
  alternateStyle: PropTypes.bool,
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  icon: PropTypes.node,
  label: PropTypes.string,
  noChange: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  type: PropTypes.string,
  userUrl: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
}

export default Input
