import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Row } from 'Components/UI'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 490px;
  padding: 0 ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const GoogleMap = styled.div`
  width: 100%;
  height: 130px;
  border: none;
  border-radius: 4px;
  outline: none;

  ${margin}
`

export const ScanLimitWrapper = styled(Row)`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
  }
`

export const BouncersFrame = styled.div`
  display: grid;
  grid-template-columns: 13fr 1fr;
  align-items: center;
  width: 100%;
  padding: ${themeGet('space.3')}px;
  background: ${themeGet('colors.bg.lightest')};
  border: 1px solid ${themeGet('colors.border.default')};
  border-radius: 4px;
  margin-bottom: ${themeGet('space.1')}px;
`
