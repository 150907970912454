import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

import { flexCenter } from 'Components/Styles'

const outlineCss = ({ outline }) =>
  outline &&
  css`
    color: ${mapToTheme('buttons.outline.color')};
    border-color: ${mapToTheme('buttons.outline.border')};
    background-color: ${mapToTheme('buttons.outline.backgroundColor')};

    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.outline.hover.border')};
      color: ${mapToTheme('buttons.outline.hover.color')};
      background-color: ${mapToTheme('buttons.outline.hover.backgroundColor')};
    }

    &:active {
      border-color: ${mapToTheme('buttons.outline.active.border')};
      color: ${mapToTheme('buttons.outline.active.color')};
      background-color: ${mapToTheme('buttons.outline.active.backgroundColor')};
    }

    &:disabled {
      border-color: ${mapToTheme('buttons.outline.disabled.border')};
      color: ${mapToTheme('buttons.outline.disabled.color')};
    }
  `

const iconCss = ({ icon }) =>
  icon &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
    }
  `

const iconStrokeCss = ({ iconStroke }) =>
  iconStroke &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
      stroke: ${mapToTheme('buttons.color')};
    }

    &:hover {
      svg {
        stroke: ${mapToTheme('buttons.hover.color')};
      }
    }

    &:active {
      svg {
        stroke: ${mapToTheme('buttons.active.color')};
      }
    }
  `

const iconFillCss = ({ iconFill }) =>
  iconFill &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
      fill: ${mapToTheme('buttons.color')};
    }

    &:hover {
      svg {
        fill: ${mapToTheme('buttons.hover.color')};
      }
    }

    &:active {
      svg {
        fill: ${mapToTheme('buttons.active.color')};
      }
    }
  `

const noEffectsIconCss = ({ noEffectsIcon }) =>
  noEffectsIcon &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;
    border: none;
    background: inherit;
    transition: all ${themeGet('transitionTime.default')} ease-in-out;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
      fill: ${p => p.fill && mapToTheme('buttons.color')};
      stroke: ${p => p.stroke && mapToTheme('buttons.color')};
      transition: all ${themeGet('transitionTime.default')} ease-in-out;
    }

    &:hover {
      border: none;
      background: inherit;
      box-shadow: none;

      svg {
        fill: ${p => p.fill && mapToTheme('buttons.hover.color')};
        stroke: ${p => p.stroke && mapToTheme('buttons.hover.color')};
      }
    }

    &:active {
      border: none;
      background: inherit;
      box-shadow: none;

      svg {
        fill: ${p => p.fill && mapToTheme('buttons.active.color')};
        stroke: ${p => p.stroke && mapToTheme('buttons.active.color')};
      }
    }

    &:disabled {
      border: none;
      background: inherit;
      box-shadow: none;

      svg {
        fill: ${p => p.fill && mapToTheme('buttons.disabled.color')};
        stroke: ${p => p.stroke && mapToTheme('buttons.disabled.color')};
      }
    }
  `

const iconLabelCss = ({ iconLabel }) =>
  iconLabel &&
  css`
    flex-shrink: 0;
    width: ${mapToTheme('buttons.height')}px;
    height: ${mapToTheme('buttons.height')}px;
    padding: 0;
    justify-content: space-between;

    svg {
      width: ${mapToTheme('buttons.icon.size')}px;
      height: ${mapToTheme('buttons.icon.size')}px;
    }
  `

const smallCss = ({ small }) =>
  small &&
  css`
    line-height: 0;
  `

const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    pointer-events: none;
    border-color: ${mapToTheme('buttons.disabled.border.color')};
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
    color: ${mapToTheme('buttons.disabled.color')};

    &:active,
    &:focus,
    &:hover {
      border-color: ${mapToTheme('buttons.disabled.border.color')};
      background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
      color: ${mapToTheme('buttons.disabled.color')};
      box-shadow: none;
    }
  `

const verticalCss = ({ vertical }) =>
  vertical &&
  css`
    flex-direction: column;
  `

const squareCss = ({ square }) =>
  square &&
  css`
    border-radius: 5px;
  `

const activeTabCss = ({ activeTab }) =>
  activeTab &&
  css`
    border-color: ${mapToTheme('buttons.active.border.color')};
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
    color: ${mapToTheme('buttons.active.color')};
  `

const plainTextButtonCss = ({ plainTextButton }) =>
  plainTextButton &&
  css`
    border: none;
    background: inherit;
    color: ${mapToTheme('buttons.color')};
    padding: 0;

    &:hover {
      border: none;
      background: inherit;
      color: ${mapToTheme('buttons.hover.color')};
    }

    &:active {
      border: none;
      background: inherit;
      color: ${mapToTheme('buttons.active.color')};
    }
  `

const justifyStartCss = ({ justifyStart }) =>
  justifyStart &&
  css`
    justify-content: flex-start;
  `

const spaceBetweenCss = ({ spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const Button = styled.div`
  display: flex;
  position: relative;
  ${flexCenter};
  flex-shrink: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: none;

  border-style: solid;
  border-radius: ${mapToTheme('buttons.border.radius')}px;
  border-width: ${mapToTheme('buttons.border.width')}px;
  border-color: ${mapToTheme('buttons.border.color')};

  height: ${mapToTheme('buttons.height')}px;
  padding: ${mapToTheme('buttons.padding')};

  font-family: inherit;
  font-size: ${mapToTheme('text.fontSize')}px;
  font-weight: ${mapToTheme('text.fontWeight')};

  color: ${mapToTheme('buttons.color')};
  background-color: ${mapToTheme('buttons.backgroundColor')};

  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  &:focus,
  &:hover {
    border-color: ${mapToTheme('buttons.hover.border.color')};
    background-color: ${mapToTheme('buttons.hover.backgroundColor')};
    color: ${mapToTheme('buttons.hover.color')};
    box-shadow: ${mapToTheme('buttons.hover.shadow')};
  }

  &:active {
    border-color: ${mapToTheme('buttons.active.border.color')};
    background-color: ${mapToTheme('buttons.active.backgroundColor')};
    color: ${mapToTheme('buttons.active.color')};
    box-shadow: ${mapToTheme('buttons.active.shadow')};
  }

  ${outlineCss}
  ${iconCss}
  ${iconStrokeCss}
  ${iconFillCss}
  ${smallCss}
  ${disabledCss}
  ${verticalCss}
  ${activeTabCss}
  ${iconLabelCss}
  ${plainTextButtonCss}
  ${justifyStartCss}
  ${squareCss}
  ${spaceBetweenCss}

  &:disabled {
    cursor: not-allowed;
    border-color: ${mapToTheme('buttons.disabled.border.color')};
    background-color: ${mapToTheme('buttons.disabled.backgroundColor')};
    color: ${mapToTheme('buttons.disabled.color')};
  }

  ${noEffectsIconCss}

  ${layout}
  ${space}
  ${typography}
`

Button.propTypes = {
  activeTab: PropTypes.bool,
  big: PropTypes.bool,
  bigger: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fill: PropTypes.bool,
  icon: PropTypes.bool,
  iconFill: PropTypes.bool,
  iconLabel: PropTypes.bool,
  iconStroke: PropTypes.bool,
  medium: PropTypes.bool,
  middle: PropTypes.bool,
  noEffectsIcon: PropTypes.bool,
  outline: PropTypes.bool,
  plainTextButton: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  squared: PropTypes.bool,
  stroke: PropTypes.bool,
  success: PropTypes.bool,
  vertical: PropTypes.bool,
  onClick: PropTypes.func,
}

Button.defaultProps = {
  activeTab: false,
  big: false,
  bigger: false,
  disabled: false,
  error: false,
  fill: false,
  icon: false,
  iconFill: false,
  iconLabel: false,
  iconStroke: false,
  small: false,
  medium: false,
  middle: false,
  noEffectsIcon: false,
  outline: false,
  plainTextButton: false,
  secondary: false,
  squared: false,
  stroke: false,
  success: false,
  vertical: false,
  onClick: undefined,
}

export default Button
