import { FiChevronLeft } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Button, Row } from 'Components/UI'

const iconCollapsedCss = ({ iconCollapsed }) =>
  iconCollapsed &&
  css`
    transform: rotate(-180deg);
    transition: all ${themeGet('transitionTime.long')} ease-in-out;
  `

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
`

export const ScrollingContainer = styled.div`
  overflow-y: scroll;
  height: calc(100% - 186px);
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const BlockedListButton = styled(Button)`
  border-color: transparent;

  svg {
    margin-right: ${themeGet('space.1')}px;
  }

  &:hover {
    border-color: transparent;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  ${margin}
`

const absolutePositionThirdCss = ({ absolutePositionThird }) =>
  absolutePositionThird &&
  css`
    position: absolute;
    top: 8px;
    right: -12px;
  `

const absolutePositionSecondaryCss = ({ absolutePositionSecondary }) =>
  absolutePositionSecondary &&
  css`
    position: absolute;
    top: 9px;
    right: -6px;
  `

const absolutePositionCss = ({ absolutePosition }) =>
  absolutePosition &&
  css`
    position: absolute;
    top: -9px;
    right: -9px;
  `

export const UnreadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  background: ${themeGet('colors.bg.customDefault')};
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 9px;
  padding: ${themeGet('space.1')}px;

  & p {
    line-height: 15px;
  }

  ${margin}
  ${absolutePositionCss}
  ${absolutePositionSecondaryCss}
  ${absolutePositionThirdCss}
`

export const ButtonWrapper = styled.div`
  position: absolute;
  right: -12px;
  top: 300px;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    display: none;
  }
`

export const CollapseArrow = styled(FiChevronLeft)`
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${iconCollapsedCss}
`

const fullWidthCss = ({ fullWidth }) =>
  fullWidth &&
  css`
    width: 100%;
  `

const activeCss = ({ active }) =>
  active &&
  css`
    color: ${themeGet('colors.customDefault')};
    border-bottom: 2px solid ${themeGet('colors.customDefault')};
  `

export const StyledNavButton = styled(Button)`
  width: 50%;
  display: flex;
  align-items: center;
  jusitfy-content: center;
  position: relative;
  background: none;
  border: none;
  border-bottom: 1px solid ${themeGet('colors.border.default')};
  border-radius: 0;
  padding: ${themeGet('space.2')}px;
  font-size: 24px;
  font-weight: 800;
  color: ${themeGet('text.color.default')};
  line-height: 30px;

  &:focus,
  &:hover {
    background: none;
    color: ${themeGet('colors.customDark')};
    border-bottom: 2px solid ${themeGet('colors.customDark')};
  }

  &:active {
    background: none;
    color: ${themeGet('colors.customDefault')};
    border-bottom: 2px solid ${themeGet('colors.customDefault')};
  }

  ${activeCss}
  ${fullWidthCss}
`

export const ResponsiveRow = styled(Row)`
  justify-content: space-between;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    justify-content: space-evenly;
  }
`
