import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'
import {
  Button,
  Column,
  Link,
  Loader,
  Modal,
  PaginationAnchor,
  Row,
  Text,
} from 'Components/UI'

import { API } from 'Config'

import roomEventStatsLogs from 'GraphQL/Queries/Events/roomEventStatsLogs.graphql'
import { updateEventStatsLogsQueryUpdater } from 'GraphQL/Updaters/events'

import { APP_PROFILE } from 'Router/routes'

import { useLazyQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import { getAccessToken } from 'Services/Store/auth'
import toast from 'Services/Toast'

import { Content, ExportIcon, UsersFrame } from './styles'
import Subscription from './Subscription'

const LOAD_COUNT = 10

function EventStatisticsModal({ event, ...rest }) {
  const s = useScopedI18n('user.modal.eventStats')
  const [page, setPage] = useState(0)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [
    loadEventStats,
    { data, loading, fetchMore, variables: eventStatsLogsVariables },
  ] = useLazyQuery(roomEventStatsLogs)

  const rows = useMemo(
    () => data?.roomEventStatsLogs?.rows ?? {},
    [data?.roomEventStatsLogs?.rows],
  )

  const totalPages = data?.roomEventStatsLogs?.pages ?? 0

  // todo need to update stats
  /* const usersCheckedInPercentage = useMemo(() => {
    const percentage = round(
      (100 / event?.room?.participantsCount) * event?.usersCount,
    )
    return !_isNaN(percentage) ? percentage : 0
  }, [event?.room?.participantsCount, event?.usersCount])

  const usersReccuredEntrancePercentage = useMemo(() => {
    const percentage = round(
      (100 / event?.usersCount) * event?.reEnteredUsersCount,
    )
    return !_isNaN(percentage) ? percentage : 0
  }, [event?.reEnteredUsersCount, event?.usersCount]) */

  const fetchData = useCallback(() => {
    loadEventStats({
      variables: { page, limit: LOAD_COUNT, eventId: event?.id },
    })
  }, [event?.id, loadEventStats, page])

  useEffect(() => {
    if (event && !data) {
      fetchData()
    }
  }, [data, event, fetchData])

  const users = useMemo(
    () =>
      map(rows || [], row => (
        <UsersFrame key={row?.user?.id} mt={1}>
          <Row center>
            <Link clean to={APP_PROFILE(row?.user?.id)}>
              <Avatar
                online={row?.user?.online}
                size={30}
                src={row?.user?.profile?.photoUrl}
                username={row?.user?.username}
              />
            </Link>
            <Text ml={2} small>
              {row?.user?.username}
            </Text>
          </Row>
          <Row center>
            {row?.bouncer ? (
              <>
                {' '}
                <Link clean to={APP_PROFILE(row?.user?.id)}>
                  <Avatar
                    online={row?.bouncer?.online}
                    size={30}
                    src={row?.bouncer?.profile?.photoUrl}
                    username={row?.bouncer?.username}
                  />
                </Link>
                <Text ml={2} small>
                  {row?.bouncer?.username}
                </Text>
              </>
            ) : (
              '--'
            )}
          </Row>
          <Text primary small textAlign="right">
            {DateTime.fromISO(row?.createdAt, {
              setZone: true,
            }).toLocaleString(DateTime.TIME_SIMPLE)}
          </Text>
        </UsersFrame>
      )),
    [rows],
  )

  const handleLoadMore = useCallback(async () => {
    if (page < totalPages - 1) {
      await fetchMore({
        variables: {
          page: page + 1,
          limit: LOAD_COUNT,
          eventId: event?.id,
        },
        updateQuery: (prev, fetchMoreResult) =>
          updateEventStatsLogsQueryUpdater(prev, fetchMoreResult),
      })

      setPage(page + 1)
    }
  }, [page, totalPages, fetchMore, event?.id])

  const handleDownloadFile = useCallback(async () => {
    try {
      const response = await fetch(
        `${API.URL}/v1/private/csv/roomEventUsers?eventId=${event?.id}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${getAccessToken()}`,
          },
        },
      )
      if (response?.status === 200) {
        response.blob().then(blob => {
          const url = window.URL.createObjectURL(blob)
          const a = document.createElement('a')
          a.href = url
          a.download = `event-users-${DateTime.now().toISODate()}.csv`
          a.click()
        })
      } else {
        throw new Error('Something went wrong')
      }
    } catch (error) {
      toast.error({
        title: 'Error occurred',
        text: s(`errors.${error?.message}`),
      })
    }
  }, [event, s])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={event?.name}
      onMount={handleMount}
    >
      <Column pb={5} px={5} width={480}>
        <Text alignSelfCenter mt={2} primary small>
          {s('description')}
        </Text>
        {/*  <StatsContainer spaceBetween width={1}>
          <StatsFrame>
            <EyeIcon />
            <Text big bold>
              {event?.usersCount}
            </Text>
            <Text alignTextCenter primary small>
              {s('acceptedUsers')}
            </Text>
          </StatsFrame>
          <StatsFrame>
            <CircleCheckIcon />
            <Text big bold>
              {usersCheckedInPercentage}%
            </Text>
            <Text alignTextCenter primary small>
              {s('attendance')}
            </Text>
          </StatsFrame>
          <StatsFrame>
            <ClockIcon />
            <Text big bold>
              {usersReccuredEntrancePercentage}%
            </Text>
            <Text alignTextCenter primary small>
              {s('visit')}
            </Text>
          </StatsFrame>
        </StatsContainer> */}
        <Row center spaceBetween>
          <Text bold small>
            {s('users.title')}
          </Text>
          <Button
            disabled={!rows?.length}
            plainTextButton
            sixth
            onClick={handleDownloadFile}
          >
            <Text bold grayLight={!rows?.length} mr={1} small>
              {s('export')}
            </Text>
            <ExportIcon custom={!!rows?.length} grayLight={!rows?.length} />
          </Button>
        </Row>
        <Content>
          <UsersFrame height={32} mt={1}>
            <Text medium small>
              {s('users.member')}
            </Text>
            <Text medium small>
              {s('users.verifiedBy')}
            </Text>
            <Text medium small textAlign="right">
              {s('users.cameAt')}
            </Text>
          </UsersFrame>
          {loading ? (
            <Loader alignSelfCenter mt={1} />
          ) : (
            <>
              {rows?.length ? (
                users
              ) : (
                <Row center fullWidth justifyCenter>
                  <Text bold mt={1}>
                    {s('noEntitiesFound')}
                  </Text>
                </Row>
              )}
            </>
          )}
          <Waypoint onEnter={handleLoadMore}>
            <PaginationAnchor />
          </Waypoint>
        </Content>
      </Column>
      <Subscription eventStatsLogsVariables={eventStatsLogsVariables} />
    </Modal>
  )
}

EventStatisticsModal.defaultProps = {
  event: null,
}

EventStatisticsModal.propTypes = {
  event: PropTypes.object,
}

export default EventStatisticsModal
