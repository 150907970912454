import React from 'react'

import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import get from 'lodash/get'
import includes from 'lodash/includes'

import Text from 'Components/UI/Text'

import { APP_PROFILE } from 'Router/routes'

export const hashtagRegex = new RegExp(/\B(#[a-zA-Z0-9(_)]+\b)/g)

export const cashtagRegex = new RegExp(/\B(\$[a-zA-Z0-9(_)]+\b)/g)

export const userMentionRegex = new RegExp(
  /@\[(?<username>.*?)]\((?<userId>.*?)\)/g,
)

export const urlRegex = new RegExp(
  /(?:http?s?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
)

export const youtubeUrlRegex = new RegExp(
  /(?:http?s?:\/\/)?(?:www.)?(?:m.)?(?:music.)?youtu(?:\.?be)(?:\.com)?(?:(?:\w*.?:\/\/)?\w*.?\w*-?.?\w*\/(?:embed|e|v|watch|.*\/)?\??(?:feature=\w*\.?\w*)?&?(?:v=)?\/?)(?<videoId>[\w\d_-]{11})(?:\S+)?/gm,
)

export const vimeoUrlRegex = new RegExp(
  /(?:http?s?:\/\/)?(?:www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|)(?<videoId>\d+)(?:|\/\?)/gm,
)

export const vimeoEventRegex = new RegExp(
  /(?:http?s?:\/\/)?(?:www\.)?vimeo.com\/(?:event)\/(?<videoId>\d+)(?:|\/\?)/gm,
)

export const wistiaUrlRegex = new RegExp(
  /(?:http?s?:\/\/)?(?:www.)?(?:[^.]+\.)?(?:wistia\.com|wi\.st)\/(?:medias|embed)\/(?<videoId>[a-zA-Z0-9_]*)/gm,
)

export const tiktokUrlRegex = new RegExp(
  /(?:https?:\/\/)(?:m|vt|vm|www)\.tiktok\.com\/(?:\S*?(?:(?:(?:usr|v|embed|user|video)\/|\?shareId=|\&item_id=)\d+))?(?:(?=\w{7})(\w*?[A-Z\d]\w*))/gm,
)

export const TextLink = styled.a`
  color: ${themeGet('colors.blueDark')};
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const alternateColorCss = ({ alternateColor }) =>
  alternateColor &&
  css`
    color: ${themeGet('text.color.brightBlue')};
  `

export const UserLink = styled.a`
  color: ${themeGet('text.color.custom')};
  text-decoration: none;
  line-height: 17px;

  &:hover {
    text-decoration: none;
  }

  ${alternateColorCss}
`

export const postsReplacers = [
  {
    regex: vimeoUrlRegex,
    priority: 1,
    render: () => '',
  },
  {
    regex: vimeoEventRegex,
    priority: 1,
    render: () => '',
  },
  {
    regex: wistiaUrlRegex,
    priority: 1,
    render: () => '',
  },
  {
    regex: urlRegex,
    priority: 0,
    render: replace => {
      const url = get(replace, 0) || ''
      return (
        <TextLink
          href={
            includes(url, 'http') || includes(url, 'https')
              ? url
              : `https://${url}`
          }
          key={get(replace, 'index')}
          rel="noopener noreferrer external"
          target="_blank"
        >
          {url}
        </TextLink>
      )
    },
  },
  {
    regex: hashtagRegex,
    priority: 0,
    render: replace => {
      const hashtag = get(replace, 0) || ''
      return (
        <Text as="span" custom>
          {hashtag}
        </Text>
      )
    },
  },
  {
    regex: cashtagRegex,
    priority: 0,
    render: replace => {
      const cashtag = get(replace, 0) || ''
      return (
        <Text as="span" custom>
          {cashtag}
        </Text>
      )
    },
  },
  {
    regex: userMentionRegex,
    priority: 0,
    render: replace => {
      const username = get(replace, 1) || ''
      const userId = get(replace, 2) || ''
      return <UserLink href={APP_PROFILE(userId)}>@{username}</UserLink>
    },
  },
]

export const directMessagesReplacers = [
  {
    regex: urlRegex,
    priority: 0,
    render: replace => {
      const url = get(replace, 0) || ''
      return (
        <TextLink
          href={
            includes(url, 'http') || includes(url, 'https')
              ? url
              : `https://${url}`
          }
          key={get(replace, 'index')}
          rel="noopener noreferrer external"
          target="_blank"
        >
          {url}
        </TextLink>
      )
    },
  },
  {
    regex: hashtagRegex,
    priority: 0,
    render: replace => {
      const hashtag = get(replace, 0) || ''
      return (
        <Text as="span" brightBlue>
          {hashtag}
        </Text>
      )
    },
  },
  {
    regex: cashtagRegex,
    priority: 0,
    render: replace => {
      const cashtag = get(replace, 0) || ''
      return (
        <Text as="span" brightBlue>
          {cashtag}
        </Text>
      )
    },
  },
  {
    regex: userMentionRegex,
    priority: 0,
    render: replace => {
      const username = get(replace, 1) || ''
      const userId = get(replace, 2) || ''
      return (
        <UserLink alternateColor href={APP_PROFILE(userId)}>
          @{username}
        </UserLink>
      )
    },
  },
]

export const youtubeEmbedReplacer = [
  {
    regex: youtubeUrlRegex,
    priority: 0,
    render: replace => {
      const youtubeUrl = get(replace, 0) || ''
      return youtubeUrl
    },
  },
]

export const urlEmbedReplacer = [
  {
    regex: urlRegex,
    priority: 0,
    render: replace => {
      const url = get(replace, [0]) || ''
      return url
    },
  },
  {
    regex: vimeoUrlRegex,
    priority: 1,
    render: replace => {
      const url = get(replace, [0]) || ''
      const videoId = get(replace, [1]) || ''
      return { url, videoId }
    },
  },
  {
    regex: vimeoEventRegex,
    priority: 1,
    render: replace => {
      const url = get(replace, [0]) || ''
      const videoId = get(replace, [1]) || ''
      return { url, videoId }
    },
  },
  {
    regex: wistiaUrlRegex,
    priority: 1,
    render: replace => {
      const url = get(replace, [0]) || ''
      const videoId = get(replace, [1]) || ''
      return { url, videoId }
    },
  },
  {
    regex: tiktokUrlRegex,
    priority: 1,
    render: replace => {
      const url = get(replace, [0]) || ''
      const videoId = get(replace, [1]) || ''
      return { url, videoId }
    },
  },
]
