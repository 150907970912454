import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import TextEditor from './TextEditor'

import 'react-quill/dist/quill.snow.css'
import 'react-quill-emoji/dist/quill-emoji.css'

function TextEditorField({
  checkErrorIfDirty,
  displayError,
  name,
  validate,
  maxCharactersCount,
  ...rest
}) {
  return (
    <Field name={name}>
      {({ input: { value, onChange: handleChange } }) => (
        <>
          <TextEditor
            maxCharactersCount={maxCharactersCount}
            value={value}
            onChange={handleChange}
          />
        </>
      )}
    </Field>
  )
}

TextEditorField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  maxCharactersCount: null,
  validate: null,
}

TextEditorField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  maxCharactersCount: PropTypes.number,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
}
export default TextEditorField
