import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { Button } from 'Components/UI'

export const Content = styled.div`
  width: 360px;
  max-height: calc(100vh - 220px);
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.bg.default')};
  border-radius: 16px;
`

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 100%;
  padding: ${themeGet('space.4')}px;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
// temporary - for handling notification clicks for notification kinds which don't posses necessary payload
const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: default;
    pointer-events: none;
  `

export const NotificationEntry = styled(NavLink)`
  display: flex;
  flex-direction: column;
  min-height: 125px;
  max-height: 200px;
  width: 100%;
  background: ${themeGet('colors.bg.secondary')};
  border: 1px solid;
  border-color: ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  text-decoration: none;
  padding: ${themeGet('space.4')}px;
  cursor: pointer;

  ${margin}
  ${disabledCss}
`

export const StyledButton = styled(Button)`
  & svg {
    width: 22px !important;
    height: 22px !important;
  }
`
