import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Arrow = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;

  &:before {
    position: absolute;
    width: 10px;
    height: 10px;
    content: '';
    transform: rotate(45deg);
    background: white;
  }
`

export const PopoverBlock = styled.div`
  margin: 0;
  padding: 0;
  background: ${themeGet('colors.bg.primary')};
  box-shadow: 0 4px 32px -4px rgba(70, 70, 70, 0.35);
  border-radius: 18px;
  font-size: 13px;
  color: ${themeGet('text.color.default')};

  &[data-placement^='top'] ${Arrow} {
    bottom: -5px;
  }

  &[data-placement^='bottom'] ${Arrow} {
    top: -5px;
  }

  &[data-placement^='left'] ${Arrow} {
    right: -5px;
  }

  &[data-placement^='right'] ${Arrow} {
    left: -5px;
  }

  ${space}
  ${layout}
`
