import styled, { css } from 'styled-components'
import { margin, space } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { GoBackIcon } from 'Components/Layout/Auth/styles'

export const Container = styled.div`
  ${space}
`

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 10px 10px;
`

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;
  flex: 1 0;
  width: 50%;
  height: 300px;
  margin: 10px 10px 0 0;
  border: 1px solid ${themeGet('colors.border.default')};
`
export const StyledGoBackIcon = styled(GoBackIcon)`
  @media (min-width: ${themeGet('breakpoints.3')}) {
    display: none;
  }
`

const responsiveCss = ({ responsive }) =>
  responsive &&
  css`
    display: none;

    @media (max-width: ${themeGet('breakpoints.3')}) {
      display: flex;
    }
  `

export const GoBackButton = styled.div`
  display: flex;
  align-items: center;

  ${responsiveCss}

  ${margin}
`
