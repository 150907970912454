import React from 'react'
import { BrowserRouter } from 'react-router-dom'

import { ApolloProvider } from '@apollo/client'
import { createBrowserHistory } from 'history'
import { StoreContext } from 'storeon/react'

import { createApolloClient } from 'Services/Apollo'
import { initTranslations } from 'Services/I18n'
import { createStore } from 'Services/Store'

import { GlobalStyle, ThemeProvider } from 'Theme'

import App from './App'

const store = createStore()

initTranslations({ store })

const history = createBrowserHistory()

const client = createApolloClient({ history })

export default function Container() {
  return (
    <ApolloProvider client={client}>
      <StoreContext.Provider value={store}>
        <ThemeProvider>
          <BrowserRouter>
            <GlobalStyle />
            <App />
          </BrowserRouter>
        </ThemeProvider>
      </StoreContext.Provider>
    </ApolloProvider>
  )
}
