import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  background: ${themeGet('colors.bg.primary')};
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;
  border: 1px solid ${themeGet('colors.border.separator')};
  border-radius: 20px;
`

const redCss = ({ red }) =>
  red &&
  css`
    color: red;
  `

export const Emoji = styled.span`
  cursor: pointer;

  ${redCss}
  ${margin}
`
