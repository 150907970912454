import { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export default css`
  .toast-container {
    .toast {
      box-shadow: 12px 11px 44px -12px rgba(0, 0, 0, 0.24);
      padding: 0;
      border-radius: 12px;

      > svg {
        display: none;
      }

      .Toastify__toast-body {
        padding: 0;
      }

      .Toastify__progress-bar {
        height: 2px;
        background: ${themeGet('colors.primary')};
      }

      &.Toastify__toast--default,
      &.Toastify__toast--info,
      &.Toastify__toast--warning,
      &.Toastify__toast--success,
      &.Toastify__toast--error {
        border-color: ${themeGet('colors.bg.default')};
        background-color: ${themeGet('colors.bg.default')};
        color: ${themeGet('colors.font.default')};
      }

      &.Toastify__toast--success {
        .Toastify__progress-bar {
          background: ${themeGet('colors.positive')};
        }
      }

      &.Toastify__toast--error {
        .Toastify__progress-bar {
          background: ${themeGet('colors.negative')};
        }
      }
    }
  }
`
