import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import orderBy from 'lodash/orderBy'

export function filterLayered(rooms) {
  const excludedRoomIds = new Set()

  forEach(rooms, room => {
    if (!room.isDefaultLayered) return
    forEach(room.groupRooms, groupRoom => excludedRoomIds.add(groupRoom.id))
  })

  return orderBy(
    filter(rooms, room => !excludedRoomIds.has(room.id)),
    ['sortOrder'],
    ['asc'],
  )
}
