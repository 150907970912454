import filter from 'lodash/filter'
import omit from 'lodash/omit'
import uniqBy from 'lodash/uniqBy'

import PostFragment from 'GraphQL/Fragments/Post.graphql'
import SavedPostsByUserIdQuery from 'GraphQL/Queries/Posts/savedPostsByUserId.graphql'

export function markSavedUpdater({ postId, saved, savedVariables }) {
  return function updateCache(cache) {
    const id = `Post:${postId}`

    const fragment = {
      id,
      fragmentName: 'PostFields',
      fragment: PostFragment,
    }

    const savedOptions = {
      query: SavedPostsByUserIdQuery,
      variables: { ...savedVariables },
    }

    const data = cache.readFragment(fragment)

    const savedData = cache.readQuery(savedOptions)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: {
          ...data,
          saved,
          savedPostsCount: saved
            ? data?.savedPostsCount + 1
            : data?.savedPostsCount,
        },
      })
    }

    if (savedData) {
      cache.writeQuery({
        ...savedOptions,
        data: {
          ...savedData,
          savedPostsByUserId: {
            ...savedData?.savedPostsByUserId,
            rows: saved
              ? uniqBy(
                  [data, ...(savedData?.savedPostsByUserId?.rows || [])],
                  'id',
                )
              : filter(
                  savedData?.savedPostsByUserId?.rows,
                  post => post?.id !== postId,
                ),
          },
        },
      })
    }
  }
}

export function postCreatedUpdater(cache, data) {
  const createPost = data?.data?.createPost

  const id = `Post:${createPost?.id}`

  cache.writeFragment({
    id,
    fragment: PostFragment,
    fragmentName: 'PostFields',
    data: createPost,
  })

  cache.modify({
    fields: {
      postsByRoomId(existing) {
        return {
          ...existing,
          rows: uniqBy(
            [{ createPost, __ref: id }, ...(existing.rows || [])],
            '__ref',
          ),
        }
      },
    },
  })
}

export function postPinnedUpdater(postId, isPinned) {
  return function updateCache(cache) {
    const id = `Post:${postId}`

    const fragment = {
      id,
      fragmentName: 'PostFields',
      fragment: PostFragment,
    }

    const data = cache.readFragment(fragment)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: {
          ...data,
          isPinned,
        },
      })
    }
  }
}

export function updatePostsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    postsByRoomId: {
      ...prev?.postsByRoomId,
      cursor: fetchMoreResult?.postsByRoomId?.cursor,
      rows: [
        ...(prev?.postsByRoomId?.rows || []),
        ...(fetchMoreResult?.postsByRoomId?.rows || []),
      ],
    },
  }
}

export function updatePostLikesQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    likesByLikeableId: {
      ...prev?.likesByLikeableId,
      cursor: fetchMoreResult?.likesByLikeableId?.cursor,
      rows: [
        ...(prev?.likesByLikeableId?.rows || []),
        ...(fetchMoreResult?.likesByLikeableId?.rows || []),
      ],
    },
  }
}

export function updatePostsByUserIdQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    postsByUserId: {
      ...prev?.postsByUserId,
      cursor: fetchMoreResult?.postsByUserId?.cursor,
      rows: [
        ...(prev?.postsByUserId?.rows || []),
        ...(fetchMoreResult?.postsByUserId?.rows || []),
      ],
    },
  }
}

export function updateSavedPostsByUserIdQueryUpdater(
  prev,
  { fetchMoreResult },
) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    savedPostsByUserId: {
      ...prev?.savedPostsByUserId,
      cursor: fetchMoreResult?.savedPostsByUserId?.cursor,
      rows: [
        ...(prev?.savedPostsByUserId?.rows || []),
        ...(fetchMoreResult?.savedPostsByUserId?.rows || []),
      ],
    },
  }
}

export function updateMyFeedQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    postsFeed: {
      ...prev?.postsFeed,
      cursor: fetchMoreResult?.postsFeed?.cursor,
      rows: [
        ...(prev?.postsFeed?.rows || []),
        ...(fetchMoreResult?.postsFeed?.rows || []),
      ],
    },
  }
}

export function markFlaggedUpdater(postId, flagged) {
  return function updateCache(cache) {
    const id = `Post:${postId}`

    const fragment = {
      id,
      fragmentName: 'PostFields',
      fragment: PostFragment,
    }

    const data = cache.readFragment(fragment)

    if (data) {
      cache.writeFragment({
        ...fragment,
        data: {
          ...data,
          flagged: true,
        },
      })
    }
    cache.modify({
      fields: {
        postsByRoomId(existing) {
          return {
            ...existing,
            rows: flagged
              ? { [postId]: { __ref: id }, ...existing.rows }
              : omit(existing.rows, postId),
          }
        },
      },
    })
  }
}
