import axios from 'axios'

import includes from 'lodash/includes'

import { GOOGLE_API_KEY } from 'Config'

import { ATTACH_TYPES, TIKTOK_OEMBED_BASE_URL } from 'Constants/ids'
import { EMBED_VIDEO_TYPES } from 'Constants/videos'

import {
  tiktokUrlRegex,
  vimeoEventRegex,
  vimeoUrlRegex,
  wistiaUrlRegex,
  youtubeUrlRegex,
} from './Posts'

export function getEmbedVideoType(link) {
  if (link?.match(vimeoUrlRegex)) {
    return EMBED_VIDEO_TYPES.VIMEO
  }
  if (link?.match(wistiaUrlRegex)) {
    return EMBED_VIDEO_TYPES.WISTIA
  }
  if (link?.match(vimeoEventRegex)) {
    return EMBED_VIDEO_TYPES.VIMEO_EVENT
  }
  if (link?.match(tiktokUrlRegex)) {
    return EMBED_VIDEO_TYPES.TIKTOK
  }
  return null
}

export function transformVideoLink(videoId, videoType) {
  switch (videoType) {
    case EMBED_VIDEO_TYPES.VIMEO:
      return `https://player.vimeo.com/video/${videoId}`
    case EMBED_VIDEO_TYPES.WISTIA:
      return `https://fast.wistia.net/embed/iframe/${videoId}`
    case EMBED_VIDEO_TYPES.VIMEO_EVENT:
      return `https://vimeo.com/event/${videoId}`
    case EMBED_VIDEO_TYPES.TIKTOK:
      return `https://www.tiktok.com/embed/v2/${videoId}`
    default:
      return null
  }
}

async function handleGetVideoData({ videoPointer, videoType }) {
  let data = {}

  if (videoType === ATTACH_TYPES.YOUTUBE_LINK) {
    try {
      const response = await axios.get(
        `https://youtube.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&id=${videoPointer}&key=${GOOGLE_API_KEY}`,
      )
      data = {
        duration: `${response?.data?.items[0]?.contentDetails?.duration}`,
        thumbnailWidth: `${response?.data?.items[0]?.snippet?.thumbnails?.standard?.width}`,
        thumbnailHeight: `${response?.data?.items[0]?.snippet?.thumbnails?.standard?.height}`,
        title: response?.data?.items[0]?.snippet?.title,
        thumbnailUrl:
          response?.data?.items[0]?.snippet?.thumbnails?.standard?.url,
      }
    } catch (error) {
      return null
    }
  }

  if (videoType === EMBED_VIDEO_TYPES.VIMEO) {
    try {
      const response = await axios.get(
        `https://vimeo.com/api/oembed.json?url=${videoPointer}`,
      )
      data = {
        duration: `${response?.data?.duration}`,
        thumbnailWidth: `${response?.data?.thumbnail_width}`,
        thumbnailHeight: `${response?.data?.thumbnail_height}`,
        title: response?.data?.title,
        thumbnailUrl: response?.data?.thumbnail_url_with_play_button,
        html: response?.data?.html,
      }
    } catch (error) {
      return null
    }
  }

  if (videoType === EMBED_VIDEO_TYPES.WISTIA) {
    try {
      const response = await axios.get(
        `http://fast.wistia.com/oembed.json?url=${videoPointer}&autoplay=true&videoFoam=true`,
      )
      data = {
        duration: `${response?.data?.duration}`,
        thumbnailWidth: `${response?.data?.thumbnail_width}`,
        thumbnailHeight: `${response?.data?.thumbnail_height}`,
        title: response?.data?.title,
        thumbnailUrl: response?.data?.thumbnail_url,
        html: response?.data?.html,
      }
    } catch (error) {
      return null
    }
  }

  if (videoType === EMBED_VIDEO_TYPES.TIKTOK) {
    try {
      const response = await axios.get(
        `${TIKTOK_OEMBED_BASE_URL}${videoPointer}`,
      )
      data = {
        thumbnailWidth: `${response?.data?.thumbnail_width}`,
        thumbnailHeight: `${response?.data?.thumbnail_height}`,
        title: response?.data?.title,
        thumbnailUrl: response?.data?.thumbnail_url,
        html: response?.data?.html,
        embedProductId: response?.data?.embed_product_id,
      }
    } catch (error) {
      return null
    }
  }

  return data
}

export function getVideoData({ link }) {
  let videoData = {}
  if (link?.match(youtubeUrlRegex)) {
    const match = [...link?.matchAll(youtubeUrlRegex)]
    const videoId = match[0][1]
    videoData = handleGetVideoData({
      videoPointer: videoId,
      videoType: ATTACH_TYPES.YOUTUBE_LINK,
    })
  }

  if (link?.match(vimeoUrlRegex)) {
    videoData = handleGetVideoData({
      videoPointer: link,
      videoType: EMBED_VIDEO_TYPES.VIMEO,
    })
  }

  if (link?.match(wistiaUrlRegex)) {
    videoData = handleGetVideoData({
      videoPointer: link,
      videoType: EMBED_VIDEO_TYPES.WISTIA,
    })
  }
  if (link?.match(tiktokUrlRegex)) {
    videoData = handleGetVideoData({
      videoPointer: link,
      videoType: EMBED_VIDEO_TYPES.TIKTOK,
    })
  }
  return videoData
}

export function transformLink(link) {
  return includes(link, 'http') || includes(link, 'https')
    ? link?.split('/')?.slice(2)?.join('/')
    : link
}

export function addQueryParam(url, param, value) {
  const urlObj = new URL(url)
  urlObj.searchParams.set(param, value)
  return urlObj.toString()
}
