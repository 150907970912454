import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import Utils from 'Utils'

import Select from './Select'

function SelectField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  validate,
  onMutatorsChange,
  ...rest
}) {
  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <Select
            displayError={displayError}
            error={error}
            value={value}
            {...input}
            {...pick(rest)}
            {...rest}
            onChange={onMutatorsChange || handleChange}
          />
        )
      }}
    </Field>
  )
}

SelectField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  input: null,
  validate: null,
  onMutatorsChange: null,
}

SelectField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.object,
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
  onMutatorsChange: PropTypes.func,
}

export default SelectField
