import React, { useCallback } from 'react'
import Joyride from 'react-joyride'
import PropTypes from 'prop-types'

import { Button, Column, Row, Text } from 'Components/UI'

import completeOnboardingStepMutation from 'GraphQL/Mutations/User/completeOnboardingStep.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

function Tutorial({ isOpen, setTutorialModal }) {
  const s = useScopedI18n('user.tutorial')

  const [completeOnboardingStep] = useMutation(completeOnboardingStepMutation)

  const handleOnboarding = useCallback(async () => {
    try {
      await completeOnboardingStep({ variables: { step: 'profile' } })
    } catch (error) {
      toast.error({ title: 'Complete Tutorial', text: error?.message })
    } finally {
      setTutorialModal({ isOpen: false })
    }
  }, [completeOnboardingStep, setTutorialModal])

  const steps = [
    {
      step: 1,
      title: s('steps.roomsTitle'),
      target: '.tutorial-first',
      content: s('steps.rooms'),
      disableBeacon: true,
      placement: 'right',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
    {
      step: 2,
      title: s('steps.feedTitle'),
      target: '.tutorial-second',
      content: s('steps.feed'),
      disableBeacon: true,
      placement: 'left',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
    {
      step: 3,
      title: s('steps.donationTitle'),
      target: '.tutorial-third',
      content: s('steps.donation'),
      disableBeacon: true,
      placement: 'top-end',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
    {
      step: 4,
      title: s('steps.profileTitle'),
      target: '.tutorial-fourth',
      content: s('steps.profile'),
      disableBeacon: true,
      placement: 'left-end',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
    {
      step: 5,
      title: s('steps.notificationsTitle'),
      target: '.tutorial-fifth',
      content: s('steps.notifications'),
      disableBeacon: true,
      placement: 'auto',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
    {
      step: 6,
      title: s('steps.walletTitle'),
      target: '.tutorial-sixth',
      content: s('steps.wallet'),
      disableBeacon: true,
      placement: 'auto',
      offset: 20,
      styles: { spotlight: { borderRadius: 16 } },
    },
  ]

  const renderTutorial = ({
    index,
    step,
    backProps,
    closeProps,
    primaryProps,
    tooltipProps,
  }) => (
    <Content {...tooltipProps} squeeze={step?.step === 2}>
      <Row spaceBetween>
        <Text bolder middle>
          {step?.title}
        </Text>
        {index + 1 < steps.length && (
          <Button
            fourth
            height={22}
            invisible
            width={50}
            {...closeProps}
            onClick={handleOnboarding}
          >
            {s('actions.skip')}
          </Button>
        )}
      </Row>
      <Column mt={9}>
        <Row>
          <Text light>{step?.content}</Text>
        </Row>
        <Row mt={18} spaceBetween>
          <Column mt={2}>
            <Text middle>
              {step?.step}/{steps.length}
            </Text>
          </Column>
          <Column>
            <Row>
              {index > 0 && (
                <Button
                  fourth
                  height={32}
                  invisible
                  mr={24}
                  width={60}
                  {...backProps}
                >
                  {s('actions.back')}
                </Button>
              )}
              {index === steps.length - 1 ? (
                <Button
                  height={32}
                  width={130}
                  {...closeProps}
                  onClick={handleOnboarding}
                >
                  {s('actions.complete')}
                </Button>
              ) : (
                <Button height={32} width={130} {...primaryProps}>
                  {s('actions.next')}
                </Button>
              )}
            </Row>
          </Column>
        </Row>
      </Column>
    </Content>
  )

  if (!isOpen) return null

  return (
    <Joyride
      continuous
      disableOverlayClose
      floaterProps={{ hideArrow: true, disableFlip: true }}
      steps={steps}
      tooltipComponent={renderTutorial}
    />
  )
}

Tutorial.defaultProps = {
  isOpen: false,
  setTutorialModal: null,
}

Tutorial.propTypes = {
  isOpen: PropTypes.object,
  setTutorialModal: PropTypes.func,
}

export default Tutorial
