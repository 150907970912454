import { useMemo } from 'react'

import { getTimeZones } from '@vvo/tzdb'

import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

export default function useTimezones() {
  const uniqTimezones = uniqBy(
    getTimeZones(),
    timezone => timezone.abbreviation,
  )

  return useMemo(
    () =>
      map(uniqTimezones, tz => ({
        label: tz?.rawFormat,
        value: tz?.name,
      })),
    [uniqTimezones],
  )
}
