import React, { useCallback } from 'react'
import { Field, useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import Textarea from './Textarea'

function TextareaField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  validate,
  ...rest
}) {
  const formApi = useForm()

  const handleClear = useCallback(() => {
    formApi.change(name, '')
  }, [formApi, name])

  return (
    <Field name={name} validate={validate}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <Textarea
              error={displayError && hasError && error}
              value={value}
              {...input}
              {...rest}
              onChange={handleChange}
              onClear={handleClear}
            />
          </>
        )
      }}
    </Field>
  )
}

TextareaField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  input: null,
  validate: null,
}

TextareaField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.shape(Textarea.propTypes),
  name: PropTypes.string.isRequired,
  validate: PropTypes.func,
}

export default TextareaField
