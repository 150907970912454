import { useCallback, useEffect, useMemo } from 'react'

import map from 'lodash/map'

import followUserMutation from 'GraphQL/Mutations/User/followUser.graphql'
import unfollowUserMutation from 'GraphQL/Mutations/User/unfollowUser.graphql'
import userFollowersQuery from 'GraphQL/Queries/User/userFollowers.graphql'
import userFollowingQuery from 'GraphQL/Queries/User/userFollowing.graphql'
import { followUpdater } from 'GraphQL/Updaters/followers'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import useAppContext from './useAppContext'

export default function useFollow({ trigger = false }) {
  const { me } = useAppContext()

  const [loadFollowers, { data: myFollowersData }] =
    useLazyQuery(userFollowersQuery)

  const fetchFollowers = useCallback(() => {
    loadFollowers({ variables: { userId: me?.id } })
  }, [loadFollowers, me?.id])

  const myFollowers = useMemo(
    () => new Set(map(myFollowersData?.userFollowers, 'id')),
    [myFollowersData?.userFollowers],
  )

  const [loadFollowing, { data: myFollowingData }] =
    useLazyQuery(userFollowingQuery)

  const fetchFollowing = useCallback(() => {
    loadFollowing({ variables: { userId: me?.id } })
  }, [loadFollowing, me?.id])

  const myFollowing = useMemo(
    () => new Set(map(myFollowingData?.userFollowing, 'id')),
    [myFollowingData?.userFollowing],
  )

  useEffect(() => {
    if (trigger) {
      fetchFollowers()
      fetchFollowing()
    }
  }, [fetchFollowers, fetchFollowing, trigger])

  const [followUser] = useMutation(followUserMutation)
  const [unfollowUser] = useMutation(unfollowUserMutation)

  const handleFollow = useCallback(
    async userId => {
      try {
        await followUser({
          variables: { userId },
          update: followUpdater({ fromUserId: me.id, toUserId: userId }),
        })
      } catch (error) {
        toast.error({
          title: 'Unfollow User',
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [followUser, me],
  )

  const handleUnfollow = useCallback(
    async userId => {
      try {
        await unfollowUser({
          variables: { userId },
          update: followUpdater({
            fromUserId: me.id,
            toUserId: userId,
            shouldRemove: true,
          }),
        })
      } catch (error) {
        toast.error({
          title: 'Unfollow User',
          text: _(`error.${error?.message || 'generic'}`),
        })
      }
    },
    [unfollowUser, me],
  )

  return { handleFollow, handleUnfollow, myFollowers, myFollowing }
}
