import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import { useStoreon } from 'storeon/react'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import noop from 'lodash/noop'
import orderBy from 'lodash/orderBy'
import without from 'lodash/without'

import { Dialog } from 'Components/Blocks/Modals'
import { ChannelModal } from 'Components/Blocks/User/Modals'
import { Column, Text } from 'Components/UI'

import { SORT_ORDER_TYPES } from 'Constants/ids'
import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import deleteChannelCategoryMutation from 'GraphQL/Mutations/Channels/deleteChannelCategory.graphql'
import updateSortOrderMutation from 'GraphQL/Mutations/Sorting/updateSortOrder.graphql'

import { useLocationQueryParams } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import toast from 'Services/Toast'

import Category from './Category'

function Categories({ canChangeChannels, categories, room, onEditCategory }) {
  const { dispatch, ui } = useStoreon(UI_STATE)
  const { channelsCollapsed } = ui

  const queryParams = useLocationQueryParams()

  const [openedCategories, setOpenedCategories] = useState([])
  const [orderedCategories, setOrderedCategories] = useState([])
  const [orderedChannels, setOrderedChannels] = useState([])
  const [isUpdatingCategoriesSortOrder, setIsUpdatingCategoriesSortOrder] =
    useState(false)
  const [isUpdatingChannelsSortOrder, setIsUpdatingChannelsSortOrder] =
    useState(false)

  const [deleteCategoryModal, setDeleteCategoryModal] = useState({
    isOpen: false,
    entity: null,
  })

  const [channelModal, setChannelModal] = useState({
    isEdit: false,
    isOpen: false,
    entity: null,
  })

  const [deleteChannelCategory] = useMutation(deleteChannelCategoryMutation)
  const [updateSortOrder] = useMutation(updateSortOrderMutation)

  const handleOpenCategory = useCallback(
    categoryId => {
      if (includes(openedCategories, categoryId)) {
        setOpenedCategories([...without(openedCategories, categoryId)])
      } else {
        setOpenedCategories([...openedCategories, categoryId])
      }
    },
    [openedCategories],
  )

  const handleResponsiveCollapse = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      leftIsCollapsed: true,
      channelsCollapsed: [...(channelsCollapsed || []), room?.id],
    })
  }, [channelsCollapsed, dispatch, room?.id])

  const handleDeleteCategoryModal = useCallback(entity => {
    setDeleteCategoryModal({ isOpen: true, entity })
  }, [])
  const handleCloseDeleteCategoryModal = useCallback(() => {
    setDeleteCategoryModal({ isOpen: false, entity: null })
  }, [])

  const handleChannelModal = useCallback(entity => {
    setChannelModal({ isEdit: false, isOpen: true, entity })
  }, [])
  const handleCloseChannelModal = useCallback(() => {
    setChannelModal({ isEdit: false, isOpen: false, entity: null })
  }, [])

  const entityIdToDelete = deleteCategoryModal?.entity
  const handleDeleteCategory = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await deleteChannelCategory({
          variables: { id: entityIdToDelete },
        })

        toast.success({
          title: 'Delete Channel Category',
          text: 'Channel category successfully deleted',
        })
      } catch (error) {
        toast.error({
          title: 'Delete Channel Category',
          text: error?.message,
        })
      }
    },
    [deleteChannelCategory, entityIdToDelete],
  )

  const handleUpdateSortOrder = useCallback(async () => {
    if (!orderedCategories) return
    try {
      if (isUpdatingCategoriesSortOrder) {
        await updateSortOrder({
          variables: {
            type: SORT_ORDER_TYPES.CHANNEL_CATEGORY,
            data: map(orderedCategories, (row, index) => ({
              id: row?.id,
              sortOrder: index + 1,
            })),
          },
        })

        setIsUpdatingCategoriesSortOrder(false)
      }

      if (isUpdatingChannelsSortOrder) {
        await updateSortOrder({
          variables: {
            type: SORT_ORDER_TYPES.CHANNEL,
            data: map(orderedChannels, (row, index) => ({
              id: row?.id,
              sortOrder: index + 1,
            })),
          },
        })

        setIsUpdatingChannelsSortOrder(false)
      }
    } catch (error) {
      toast.error({ title: 'Update sort order', text: error?.message })
    }
  }, [
    orderedChannels,
    isUpdatingCategoriesSortOrder,
    isUpdatingChannelsSortOrder,
    orderedCategories,
    updateSortOrder,
  ])

  const handleDragEnd = useCallback(
    result => {
      if (!result?.destination) return

      if (result?.type === SORT_ORDER_TYPES.CATEGORY) {
        const items = [...(orderedCategories || [])]

        const [reorderedItems] = items?.splice(result?.source?.index, 1)
        items?.splice(result?.destination?.index, 0, reorderedItems)

        setOrderedCategories(items)

        setIsUpdatingCategoriesSortOrder(true)
        return
      }

      if (result?.destination?.droppableId === result?.source?.droppableId) {
        const target = orderedCategories[parseInt(result?.type, 10)]
        const items = [...(target?.channels || [])]
        const [reorderedItems] = items?.splice(result?.source?.index, 1)
        items?.splice(result?.destination?.index, 0, reorderedItems)

        const itemsCategories = JSON.parse(JSON.stringify(orderedCategories))

        itemsCategories[parseInt(result?.type, 10)].channels = items

        setOrderedChannels(items)

        setOrderedCategories(itemsCategories)

        setIsUpdatingChannelsSortOrder(true)
      }
    },
    [orderedCategories],
  )

  useEffect(() => {
    if (categories) {
      setOrderedCategories(
        map(orderBy(categories, ['sortOrder'], ['asc']), category => ({
          ...category,
          channels: orderBy(category?.channels, ['sortOrder'], ['asc']),
        })),
      )
      setOpenedCategories(
        map(
          filter(categories, category => !category?.collapse),
          category => category?.id,
        ),
      )
    }
  }, [categories])

  useEffect(() => {
    if (isUpdatingCategoriesSortOrder || isUpdatingChannelsSortOrder) {
      handleUpdateSortOrder()
    }
  }, [
    handleUpdateSortOrder,
    isUpdatingCategoriesSortOrder,
    isUpdatingChannelsSortOrder,
  ])

  const renderCategories = useMemo(
    () =>
      map(orderedCategories, (category, index) => (
        <Draggable
          draggableId={category?.id}
          index={index}
          isDragDisabled={!canChangeChannels}
          key={category?.id}
        >
          {provided => (
            <Category
              canChangeChannels={canChangeChannels}
              category={category}
              index={index}
              isOpened={
                queryParams?.expanded ||
                includes(openedCategories, category?.id)
              }
              provided={provided}
              room={room}
              onAddChannel={handleChannelModal}
              onDeleteCategory={handleDeleteCategoryModal}
              onEditCategory={onEditCategory}
              onOpenCategory={handleOpenCategory}
              onResponsiveCollapse={handleResponsiveCollapse}
            />
          )}
        </Draggable>
      )),
    [
      canChangeChannels,
      handleChannelModal,
      handleDeleteCategoryModal,
      handleOpenCategory,
      handleResponsiveCollapse,
      onEditCategory,
      openedCategories,
      queryParams?.expanded,
      room,
      orderedCategories,
    ],
  )

  const deleteCategoryMessage = (
    <Text
      dangerouslySetInnerHTML={{
        __html: 'Are you sure you want to delete this channel category?',
      }}
    />
  )

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="Category" type={SORT_ORDER_TYPES.CATEGORY}>
          {provided => (
            <Column mt={5} ref={provided.innerRef}>
              {renderCategories}
              {provided.placeholder}
            </Column>
          )}
        </Droppable>
      </DragDropContext>

      <ChannelModal
        data={channelModal?.entity}
        isEdit={channelModal?.isEdit}
        isOpen={channelModal?.isOpen}
        onClose={handleCloseChannelModal}
      />

      <Dialog
        content={deleteCategoryMessage}
        isOpen={deleteCategoryModal.isOpen}
        title="Delete Channel Category"
        onClose={handleCloseDeleteCategoryModal}
        onFinish={handleDeleteCategory}
      />
    </>
  )
}

Categories.defaultProps = {
  canChangeChannels: false,
  categories: [],
  room: null,
  onEditCategory: noop,
}

Categories.propTypes = {
  canChangeChannels: PropTypes.bool,
  categories: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  room: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onEditCategory: PropTypes.func,
}

export default Categories
