import { useMemo } from 'react'

import { DateTime } from 'luxon'

import map from 'lodash/map'
import range from 'lodash/range'

const LOCALE_STRING_FORMAT = { hour: '2-digit', minute: '2-digit' }
const LOCALE_VALUE_STRING_FORMAT = { ...LOCALE_STRING_FORMAT, hourCycle: 'h23' }

const steps = range(96)

export default function useTimeOptions() {
  const startTime = DateTime.local().startOf('day')

  return useMemo(
    () =>
      map(steps, index => {
        const seconds = index * 900
        return {
          label: startTime
            .plus({ seconds })
            .toLocaleString(LOCALE_STRING_FORMAT),
          value: startTime
            .plus({ seconds })
            .toLocaleString(LOCALE_VALUE_STRING_FORMAT),
        }
      }),
    [startTime],
  )
}
