import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import ImageInput from './ImageInput'

import { Label } from '../styles'

function ImageInputField({ name, checkErrorIfDirty, displayError, ...rest }) {
  return (
    <Field name={name}>
      {({ input, meta }) => {
        const { hasError, error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <>
            <ImageInput error={error} {...input} {...rest} />
            {displayError && hasError && (
              <Label error mt={1}>
                {error}
              </Label>
            )}
          </>
        )
      }}
    </Field>
  )
}

ImageInputField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  entityId: null,
}

ImageInputField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  entityId: PropTypes.string,
  name: PropTypes.string.isRequired,
  uploadType: PropTypes.oneOf(['menuItemImage']).isRequired,
}

export default ImageInputField
