import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import EnvelopeGlyph from 'Assets/Svg/envelope.svg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  padding: 0 ${themeGet('space.5')}px ${themeGet('space.6')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const EnvelopeIcon = styled(EnvelopeGlyph)``
