import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import map from 'lodash/map'

import roomsOptionsQuery from 'GraphQL/Queries/Rooms/roomsOptions.graphql'

export default function useRoomOptions(withMentions = false) {
  const client = useApolloClient()

  const fetchRooms = useCallback(
    async (inputValue, callback) => {
      const variables = {
        limit: 999999,
        includeLayered: true,
        withExcludedRooms: true,
      }

      if (inputValue) {
        variables.search = inputValue
      }

      const response = await client.query({
        query: roomsOptionsQuery,
        context: { admin: false },
        fetchPolicy: 'network-only',
        variables,
      })

      const filteredRooms = filter(
        response?.data?.rooms?.rows,
        row => !row?.locked,
      )

      const rooms = map(filteredRooms, room =>
        withMentions
          ? { display: room?.label, id: room?.id }
          : { label: room?.label, value: room?.id },
      )

      callback(rooms)
    },
    [client, withMentions],
  )

  return { fetchRoomsOptions: debounce(fetchRooms, 700) }
}
