import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { text } from 'Components/Styles'
import { Link, Row } from 'Components/UI'

export const Container = styled(Row)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 300px;
  background: ${themeGet('colors.bg.extra')};
  padding: ${themeGet('space.5')}px;
`

export const Image = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
`

export const MenuLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 8px;
  text-decoration: none;

  ${text(14)}
  ${margin}

  color: ${themeGet('colors.white')}!important;

  &.active {
    font-weight: bold;
  }

  > svg {
    margin-right: 4px;
  }
`
