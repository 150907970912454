import { useCallback } from 'react'

import i18n from 'i18n-js'

import get from 'lodash/get'

import { LANGUAGE } from 'Constants/ids'
import { I18N_STATE } from 'Constants/store'

import mainEn from './translations/en.json'
import mainRu from './translations/ru.json'

export const validLanguages = [LANGUAGE.EN, LANGUAGE.RU]

const translationsSet = { en: mainEn, ru: mainRu }

export function initTranslations({ store }) {
  const i18nState = store.get()[I18N_STATE]

  let lang

  const storedLand = get(i18nState, 'locale')

  if (storedLand) {
    lang = storedLand
  } else {
    lang = window.navigator.language || window.navigator.userLanguage
    lang = lang ? lang.substring(0, 2) : ''
  }

  if (!validLanguages.includes(lang)) {
    lang = LANGUAGE.EN
  }

  i18n.locale = lang

  i18n.translations = translationsSet
  i18n.missingTranslation = key => `![${key}]`

  return i18n.locale
}

function _(key, options = null) {
  return i18n.t(key, options)
}

export function useScopedI18n(key) {
  return useCallback(
    (subKey, options = null) => _(`${key}.${subKey}`, options),
    [key],
  )
}

export function LocaleRenderer({ children }) {
  return children
}

export default _
