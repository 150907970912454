import styled from 'styled-components'
import { mapToTheme } from 'styled-map'

import { Content as BaseContent } from '../styles'

export const Content = styled(BaseContent)`
  word-break: break-word;
  text-align: center;
  color: ${mapToTheme('text.color')};
`
