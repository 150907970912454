import get from 'lodash/get'

import systemSettingsQuery from 'GraphQL/Queries/Settings/systemSettings.graphql'
import systemSettingsUpdatedSubscription from 'GraphQL/Subscriptions/systemSettingsUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function SystemSettings() {
  useSubscription(systemSettingsUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const settings = get(subscriptionData, ['data', 'systemSettingsUpdated'])

      const settingsOptions = {
        query: systemSettingsQuery,
      }

      const data = client.readQuery(settingsOptions)

      if (data) {
        client.writeQuery({
          ...settingsOptions,
          data: {
            ...data,
            systemSettings: {
              ...data?.systemSettings,
              maintenanceMode:
                settings?.maintenanceMode ||
                data?.systemSettings?.maintenanceMode,
              iosMaintenanceModeVersions:
                settings?.iosMaintenanceModeVersions ||
                data?.systemSettings?.iosMaintenanceModeVersions,
              androidMaintenanceModeVersions:
                settings?.androidMaintenanceModeVersions ||
                data?.systemSettings?.androidMaintenanceModeVersions,
            },
          },
        })
      }
    },
  })

  return null
}

export default SystemSettings
