import concat from 'lodash/concat'
import get from 'lodash/get'

export function updateList(store, query, object, key) {
  if (!object) return

  const options = {
    query,
  }
  const readData = store.readQuery(options)

  store.writeQuery({
    ...options,
    data: {
      ...readData,
      [key]: {
        ...(get(readData, key) || []),
        rows: concat(get(readData, [key, 'rows']) || [], object),
      },
    },
  })
}
