import React from 'react'

import get from 'lodash/get'

import { CHANNEL_COUNTER_KIND } from 'Constants/channels'

import ChannelFragment from 'GraphQL/Fragments/Channel.graphql'
import channelCountUpdatedSubscription from 'GraphQL/Subscriptions/Channels/channelCountUpdated.graphql'
import channelUpdatedSubscription from 'GraphQL/Subscriptions/Channels/channelUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateFragment({ client, count, channel }) {
  const id = !channel ? `Channel:${count?.channelId}` : `Channel:${channel?.id}`

  const fragment = {
    id,
    fragmentName: 'ChannelFields',
    fragment: ChannelFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    if (count && count?.kind === CHANNEL_COUNTER_KIND.UNREAD_COUNT) {
      client.writeFragment({
        ...fragment,
        data: {
          ...data,
          unreadCount: count?.value,
        },
      })
    }
    if (channel) {
      client.writeFragment({
        ...fragment,
        data: { ...data, ...channel },
      })
    }
  }
}

function DirectChannelsSubscription() {
  useSubscription(channelCountUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const count = get(subscriptionData, ['data', 'channelCountUpdated'])

      updateFragment({ client, count })
    },
  })

  useSubscription(channelUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channel = get(subscriptionData, ['data', 'channelUpdated'])

      updateFragment({ client, channel })
    },
  })

  return <></>
}

export default DirectChannelsSubscription
