import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { FaHeart } from 'react-icons/fa'
import { FiHeart } from 'react-icons/fi'

import Tippy from '@tippyjs/react'

import noop from 'lodash/noop'

import DefaultRoomLogo from 'Assets/Png/ethereum.png'

import { Button, Column, Flex, Row, Text } from 'Components/UI'

import { ROOM_COMMUNITY_BADGES } from 'Constants/ids'

import { useEntranceContext } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import MetaIcons from '../MetaIcons'
import {
  LockIcon,
  Room,
  RoomButton,
  RoomLogo,
  RoomTooltip,
  SelectableText,
  VerifiedRoomIcon,
} from '../styles'

// todo remove style={{ overflow: 'hidden' }} after discussing design for mobile responsive
function SingleRoom({
  darkMode,
  room,
  isCollapsed,
  isSelected,
  onJoin,
  onFavorite,
  onUnread,
  isMyFeed,
  myFeedUnreadCount,
}) {
  const s = useScopedI18n('user.rooms')
  const [{ exclusiveMode }] = useEntranceContext()

  const displayRoomLabel = useMemo(
    () => (isMyFeed ? 'Feed' : room?.label),
    [isMyFeed, room?.label],
  )

  return (
    <Row mt={2}>
      {!isCollapsed ? (
        <Room isMyFeed={isMyFeed} selectedRoom={isSelected}>
          <RoomButton
            spaceBetween
            width={1}
            onClick={() => onJoin(room?.id, room?.locked, room?.slug)}
          >
            <Row spaceBetween width={1}>
              <Row style={{ overflow: 'hidden' }} width={isMyFeed ? 1 : 'auto'}>
                <Column center>
                  {!isMyFeed && (
                    <RoomLogo
                      src={
                        room?.logoUrl || room?.token?.logoUrl || DefaultRoomLogo
                      }
                    />
                  )}

                  {room?.favorite && !isSelected && !exclusiveMode && (
                    <Row center mt={9}>
                      <FaHeart fill={darkMode ? '#e0245e' : '#e63d05'} />
                    </Row>
                  )}
                </Column>
                <Column
                  flexDirection={isMyFeed ? 'row !important' : 'column'}
                  ml={12}
                  style={{ overflow: 'hidden' }}
                  width={isMyFeed ? 1 : 'auto'}
                >
                  <Row
                    center={isMyFeed}
                    spaceBetween={isMyFeed}
                    width={isMyFeed ? 1 : 'auto'}
                  >
                    <SelectableText
                      bold
                      lockedText={!!room?.locked}
                      middle
                      title={displayRoomLabel}
                      uppercase
                    >
                      {displayRoomLabel}
                    </SelectableText>
                    {room?.isVerified && (
                      <Flex ml={1}>
                        <VerifiedRoomIcon />
                      </Flex>
                    )}
                  </Row>
                  {room?.communityBadge &&
                    room?.communityBadge !==
                      ROOM_COMMUNITY_BADGES[2]?.value && (
                      <Text
                        blue={
                          room?.communityBadge ===
                          ROOM_COMMUNITY_BADGES[0]?.value
                        }
                        bold
                        smallest
                        violet={
                          room?.communityBadge ===
                          ROOM_COMMUNITY_BADGES[1]?.value
                        }
                      >
                        {s(`communities.${room?.communityBadge}`)}
                      </Text>
                    )}
                </Column>
              </Row>
              <Column mt={!isMyFeed ? 1 : 0}>
                <Row justifyEnd>
                  {room?.locked ? (
                    <LockIcon />
                  ) : (
                    onUnread(isMyFeed ? myFeedUnreadCount : room?.unreadCount)
                  )}
                </Row>
                {!isMyFeed && (
                  <Row
                    mt={
                      room?.communityBadge &&
                      room?.communityBadge !== ROOM_COMMUNITY_BADGES[2]?.value
                        ? 18
                        : 2
                    }
                  >
                    <Text mr={1} primary>
                      {room?.participantsCount}
                    </Text>
                    {room?.participantsCount === 1 ? (
                      <Text primary>{s('fields.user')}</Text>
                    ) : (
                      <Text primary>{s('fields.users')}</Text>
                    )}
                  </Row>
                )}
              </Column>
            </Row>
          </RoomButton>
          {isSelected && (
            <Row center ml={1} mt={2}>
              {!room?.locked && !isMyFeed && !exclusiveMode && (
                <Button
                  height={12}
                  mr={4}
                  noEffectsIconF
                  warning
                  width={15}
                  onClick={() =>
                    onFavorite(room?.favorite, room?.id, room?.slug)
                  }
                >
                  {room?.favorite ? (
                    <FaHeart strokeWidth={1} />
                  ) : (
                    <FiHeart strokeWidth={1} />
                  )}
                </Button>
              )}
              {room?.locked && room?.favorite && !isMyFeed && !exclusiveMode && (
                <Button
                  height={12}
                  mr={4}
                  noEffectsIcon
                  warning
                  width={15}
                  onClick={() =>
                    onFavorite(room?.favorite, room?.id, room?.slug)
                  }
                >
                  <FaHeart strokeWidth={1} />
                </Button>
              )}
              {!isMyFeed && <MetaIcons links={room?.links} />}
            </Row>
          )}
        </Room>
      ) : (
        <Room collapsed isMyFeed={isMyFeed} selectedRoom={isSelected}>
          <RoomButton
            width={1}
            onClick={() => onJoin(room?.id, room?.locked, room?.slug)}
          >
            <Row justifyCenter>
              <Row center>
                {!isMyFeed && (
                  <Tippy
                    content={<RoomTooltip>{displayRoomLabel}</RoomTooltip>}
                    offset={[0, 30]}
                    placement="right"
                  >
                    <RoomLogo
                      collapsedLogo
                      src={
                        room?.logoUrl || room?.token?.logoUrl || DefaultRoomLogo
                      }
                    />
                  </Tippy>
                )}
                {isMyFeed && (
                  <Row width={!isMyFeed ? 50 : 90}>
                    <SelectableText
                      bold
                      lockedText={!!room?.locked}
                      ml={9}
                      title="Feed"
                      uppercase
                    >
                      Feed
                    </SelectableText>
                    {isMyFeed && (
                      <Column ml={3}>{onUnread(myFeedUnreadCount)}</Column>
                    )}
                  </Row>
                )}
              </Row>
            </Row>
          </RoomButton>
          <Row center justifyCenter mt={16}>
            <Row>
              {!room?.locked && !isMyFeed && !exclusiveMode && (
                <Button
                  height={12}
                  mr={3}
                  noEffectsIcon
                  warning
                  width={15}
                  onClick={() =>
                    onFavorite(room?.favorite, room?.id, room?.slug)
                  }
                >
                  {room?.favorite ? (
                    <FaHeart strokeWidth={1} />
                  ) : (
                    <FiHeart strokeWidth={1} />
                  )}
                </Button>
              )}
            </Row>
            <Row justifyEnd width={40}>
              {room?.locked ? <LockIcon /> : onUnread(room?.unreadCount)}
            </Row>
          </Row>
        </Room>
      )}
    </Row>
  )
}

SingleRoom.defaultProps = {
  darkMode: false,
  isCollapsed: false,
  isSelected: false,
  room: {},
  isMyFeed: false,
  myFeedUnreadCount: 0,
  onFavorite: noop,
  onJoin: noop,
  onUnread: noop,
}

SingleRoom.propTypes = {
  darkMode: PropTypes.bool,
  isCollapsed: PropTypes.bool,
  isMyFeed: PropTypes.bool,
  isSelected: PropTypes.bool,
  myFeedUnreadCount: PropTypes.number,
  room: PropTypes.object,
  onFavorite: PropTypes.func,
  onJoin: PropTypes.func,
  onUnread: PropTypes.func,
}

export default SingleRoom
