import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { BsThreeDots } from 'react-icons/bs'

import map from 'lodash/map'

import { Button, Column, Link, Row } from 'Components/UI'

import {
  CoinGeckoIcon,
  CoinMarketCapIcon,
  CoinMetroIcon,
  DexToolsIcon,
  DiscordIcon,
  EtherscanIcon,
  FacebookIcon,
  InformationIcon,
  InstagramIcon,
  LcxIcon,
  LinkedInIcon,
  NileswapIcon,
  OpenSeaIcon,
  PatreonIcon,
  SantimentIcon,
  ShopifyIcon,
  SubstackIcon,
  SushiswapIcon,
  TelegramIcon,
  TwitterIcon,
  UniswapIcon,
  UnizenIcon,
} from './styles'

const ICONS = {
  coingecko: <CoinGeckoIcon />,
  coinmakertcap: <CoinMarketCapIcon />,
  coinmetro: <CoinMetroIcon />,
  dextools: <DexToolsIcon />,
  discord: <DiscordIcon />,
  etherscan: <EtherscanIcon />,
  facebook: <FacebookIcon />,
  information: <InformationIcon />,
  instagram: <InstagramIcon />,
  lcx: <LcxIcon />,
  linkedin: <LinkedInIcon />,
  nileswap: <NileswapIcon />,
  opensea: <OpenSeaIcon />,
  patreon: <PatreonIcon />,
  santiment: <SantimentIcon />,
  shopify: <ShopifyIcon />,
  substack: <SubstackIcon />,
  sushiswap: <SushiswapIcon />,
  telegram: <TelegramIcon />,
  twitter: <TwitterIcon />,
  uniswap: <UniswapIcon />,
  unizen: <UnizenIcon />,
}

function MetaIcons({ links }) {
  const [shownLinks, setShownLinks] = useState(0)

  const handleShowMoreLinks = useCallback(() => {
    if (
      shownLinks + 4 === links?.length ||
      shownLinks + 4 > links?.length - 1
    ) {
      setShownLinks(0)
    } else {
      setShownLinks(shownLinks + 4)
    }
  }, [links?.length, shownLinks])

  const displayedLinks = useMemo(
    () =>
      links?.length > 5
        ? map(links?.slice(shownLinks, shownLinks + 4), link => (
            <Column key={link?.name} mr={4}>
              <Link clean="true" target="_blank" to={`//${link?.url}`}>
                {ICONS[link?.name]}
              </Link>
            </Column>
          ))
        : map(links, link => (
            <Column key={link?.name} mr={4}>
              <Link clean="true" target="_blank" to={`//${link?.url}`}>
                {ICONS[link?.name]}
              </Link>
            </Column>
          )),
    [links, shownLinks],
  )

  return (
    <Row width={1}>
      {displayedLinks}
      {links?.length > 5 && (
        <Button
          fill
          height={26}
          medium
          mr={4}
          noEffectsIcon
          secondary
          width={26}
          onClick={() => handleShowMoreLinks()}
        >
          <BsThreeDots color="#657786" />
        </Button>
      )}
    </Row>
  )
}

MetaIcons.defaultProps = {
  links: [],
}

MetaIcons.propTypes = {
  links: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default MetaIcons
