import WalletConnect from '@walletconnect/client'
import QRCodeModal from '@walletconnect/qrcode-modal'

import { getWalletConnectClient } from 'Services/Shared'

export async function createSignClient() {
  try {
    const connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org',
      qrcodeModal: QRCodeModal,
      clientMeta: {
        name: 'Sentr3',
        description: 'Sentr3 Token Gating',
        url: 'https://sentr3.com/',
        icons: ['https://sentr3.com/walletconnect-logo.png'],
      },
    })
    subscribe(connector)
    return connector
  } catch {
    // no action
    return null
  }
}

export function subscribe(client) {
  if (!client) throw new Error('WalletConnect is not initialized')
}

export function unsubscribe() {
  const client = getWalletConnectClient()

  if (!client) throw new Error('WalletConnect is not initialized')
}
