import { useCallback, useState } from 'react'
import { matchPath, useHistory, useLocation } from 'react-router'

import { useLogin } from '@privy-io/react-auth'
import axios from 'axios'
import qs from 'qs'
import TrezorConnect from 'trezor-connect'

// import { connect, getSignature, verifySignature } from 'use-hathorpay'
import { API, IS_DEVELOPMENT, IS_PRODUCTION, IS_STAGING } from 'Config'

import {
  AUTH_METHODS,
  ETHEREUM_CHAIN_ID,
  WALLET_PROVIDERS,
} from 'Constants/ids'

import signInByHathorWalletMutation from 'GraphQL/Mutations/Auth/signInByHathorWallet.graphql'
import signInByWeb3Mutation from 'GraphQL/Mutations/Auth/signInByWeb3.graphql'
import connectEthereumWalletMutation from 'GraphQL/Mutations/Wallets/connectEthereumWallet.graphql'
import connectHathorWalletMutation from 'GraphQL/Mutations/Wallets/connectHathorWallet.graphql'
import connectTrezorWalletMutation from 'GraphQL/Mutations/Wallets/connectTrezorWallet.graphql'

import {
  APP_ROOM,
  COINMETRO_AUTH_LINK,
  REDIRECTOR,
  UNLOCK_AUTH_LINK,
} from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import Auth from 'Services/Auth'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { createSignClient } from 'Utils/WalletConnect'

import useAppContext from './useAppContext'
import useEntranceContext from './useEntranceContext'
import useWeb3 from './useWeb3'

export default function useWalletsHandlers({ setLoading, close, refetch }) {
  const s = useScopedI18n('user.modal.connectWallet')
  const history = useHistory()
  const { me, refetchMe } = useAppContext()
  const [{ enteredFrom }] = useEntranceContext()
  const { metamaskProvider, web3, account, wrongNetwork } = useWeb3()

  const { pathname } = useLocation()

  const fromRoomSlug = matchPath(pathname, {
    path: APP_ROOM(),
  })?.params?.roomPointer

  const [isHathorUnlocked, setIsHathorUnlocked] = useState(true)

  const [connectEthereumWallet] = useMutation(connectEthereumWalletMutation)
  const [connectHathorWallet] = useMutation(connectHathorWalletMutation)
  const [connectTrezorWallet] = useMutation(connectTrezorWalletMutation)

  const [signInByWeb3] = useMutation(signInByWeb3Mutation)
  const [signInByHathorWallet] = useMutation(signInByHathorWalletMutation)

  const metamaskHandler = useCallback(async () => {
    setLoading(true)
    try {
      if (wrongNetwork && (IS_DEVELOPMENT || IS_STAGING)) {
        toast.error({
          title: _('error.metamask.title'),
          text: _(`error.metamask.${metamaskProvider}Test`),
        })
      } else if (wrongNetwork && IS_PRODUCTION) {
        toast.error({
          title: _('error.metamask.title'),
          text: _(`error.metamask.${metamaskProvider}Main`),
        })
      } else {
        const response = await axios.get(
          `${
            API.URL
          }/v1/auth/personalSignChallenge?account=${account.toLowerCase()}&provider=${metamaskProvider}&wallet=${
            AUTH_METHODS.METAMASK
          }`,
        )

        const { data: { challenge } = {} } = response

        const signedChallengeResult = await web3?.eth?.personal?.sign(
          web3.utils.fromUtf8(challenge),
          account.toLowerCase(),
          '',
        )

        if (!me) {
          const result = await signInByWeb3({
            variables: {
              challenge,
              signedChallenge: signedChallengeResult,
              provider: metamaskProvider,
              withRefresh: true,
              personalSign: true,
              wallet: AUTH_METHODS.METAMASK,
            },
          })

          await Auth.handleAuth(result?.data?.signInByWeb3)

          history.push(REDIRECTOR)
        } else {
          await connectEthereumWallet({
            variables: {
              challenge,
              signedChallenge: signedChallengeResult,
              provider: metamaskProvider,
              personalSign: true,
              wallet: AUTH_METHODS.METAMASK,
            },
          })

          toast.success({
            title: 'Connect wallet',
            text: 'Wallet successfully connected to your account',
          })

          await refetch()
          await refetchMe()

          close.current()
        }
      }
    } catch (error) {
      toast.error({
        title: 'Connect wallet',
        text: s(`errors.${error?.message}`),
      })
    } finally {
      setLoading(false)
    }
  }, [
    account,
    close,
    connectEthereumWallet,
    history,
    me,
    metamaskProvider,
    refetch,
    refetchMe,
    s,
    setLoading,
    signInByWeb3,
    web3,
    wrongNetwork,
  ])

  const handleConnected = useCallback(
    async (client, accounts) => {
      try {
        const address = accounts[0].toLowerCase()
        const provider = WALLET_PROVIDERS.ETHEREUM
        const wallet = AUTH_METHODS.WALLET_CONNECT

        const queryString = qs.stringify({
          account: address,
          provider,
          wallet,
        })

        const response = await axios.get(
          `${API.URL}/v1/auth/personalSignChallenge?${queryString}`,
        )
        const { data: { challenge } = {} } = response

        toast.info({
          title: 'Wallet',
          text: 'Please sign',
        })

        const signedChallengeResult = await client?.signPersonalMessage([
          web3.utils.fromUtf8(challenge),
          address,
        ])

        if (!me) {
          const signInResult = await signInByWeb3({
            variables: {
              challenge,
              signedChallenge: signedChallengeResult,
              provider,
              withRefresh: true,
              personalSign: true,
              wallet,
            },
          })
          await Auth.handleAuth(signInResult?.data?.signInByWeb3)
          history.push(REDIRECTOR)
        } else {
          await connectEthereumWallet({
            variables: {
              challenge,
              signedChallenge: signedChallengeResult,
              provider,
              personalSign: true,
              wallet,
            },
          })

          toast.success({
            title: 'Connect wallet',
            text: 'Wallet successfully connected to your account',
          })

          await refetch()
          await refetchMe()
        }
      } catch (error) {
        toast.error({
          title: 'WalletConnect',
          text: error?.message,
        })
      } finally {
        client.killSession()
      }
    },
    [
      web3,
      connectEthereumWallet,
      history,
      me,
      refetch,
      refetchMe,
      signInByWeb3,
    ],
  )

  const walletConnectHandler = useCallback(async () => {
    try {
      const client = await createSignClient()
      const chainId = IS_PRODUCTION
        ? ETHEREUM_CHAIN_ID.PRODUCTION
        : ETHEREUM_CHAIN_ID.STAGING

      if (!client?.connected) {
        await client.createSession({
          chainId,
        })
      }
      if (client?.connected) {
        handleConnected(client, client?.accounts)
      }
      client.on('connect', (error, payload) => {
        const { accounts } = payload.params[0]
        handleConnected(client, accounts)

        if (error) {
          throw error
        }
      })
      client.on('disconnect', error => {
        if (error) {
          throw error
        }
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  }, [handleConnected])

  const hathorSignatureCallback = useCallback(
    async (callback, challenge, xPubKey) => {
      if (callback?.signature) {
        const response = '' // verifySignature(
        //   challenge,
        //   xPubKey,
        //   callback?.signature,
        // )
        if (response) {
          try {
            if (!me) {
              const result = await signInByHathorWallet({
                variables: {
                  challenge,
                  signedChallenge: callback?.signature,
                  xPubKey,
                  withRefresh: true,
                },
              })

              await Auth.handleAuth(result?.data?.signInByHathorWallet)

              history.push(REDIRECTOR)
            } else {
              await connectHathorWallet({
                variables: {
                  challenge,
                  signedChallenge: callback?.signature,
                  xPubKey,
                },
              })

              toast.success({
                title: 'Connect wallet',
                text: 'Wallet successfully connected to your account',
              })

              await refetch()
              await refetchMe()
              close.current()
            }
          } catch (error) {
            toast.error({
              title: 'Connect wallet',
              text: s(`errors.${error?.message}`),
            })
          } finally {
            setLoading(false)
          }
        }
      }
    },
    [
      close,
      connectHathorWallet,
      history,
      me,
      refetch,
      refetchMe,
      s,
      setLoading,
      signInByHathorWallet,
    ],
  )

  const handleHathorSignature = useCallback(
    (xPubKey, challenge) => {
      // getSignature(challenge, callback =>
      //   hathorSignatureCallback(callback, challenge, xPubKey),
      // )
    },
    [hathorSignatureCallback],
  )

  const connectHathorCallback = useCallback(
    async callback => {
      if (!callback?.status) {
        setIsHathorUnlocked(false)
        setLoading(false)
      }
      if (callback?.status) {
        setIsHathorUnlocked(true)
        await axios
          .get(
            `${API.URL}/v1/auth/hathor-challenge?account=${callback?.addresses?.[0]?.address}`,
          )
          .then(response => {
            const {
              data: { challenge },
            } = response
            handleHathorSignature(callback?.pk, challenge)
          })
          .catch(error => {
            toast.error({
              title: 'Auth',
              text: error?.message,
            })
            setLoading(false)
          })
      }
    },
    [handleHathorSignature, setLoading],
  )

  const hathorHandler = useCallback(async () => {
    setLoading(true)
    try {
      // await connect(connectHathorCallback)
    } catch (error) {
      toast.error({
        title: 'Hathor Pay',
        text: error?.message,
      })
    }
  }, [connectHathorCallback, setLoading])

  const trezorHandler = useCallback(
    async accountData => {
      // if (isTrezorConnected && trezorAccount) {
      try {
        const response = await axios.get(
          `${API.URL}/v1/auth/trezor-challenge?account=${accountData?.address}`,
        )

        const data = response?.data?.challenge

        const signResult = await TrezorConnect.ethereumSignTypedData({
          path: accountData?.path,
          data,
          metamask_v4_compat: true,
        })

        const challenge = data?.message?.challenge
        const signedChallenge = signResult?.payload?.signature

        await connectTrezorWallet({ variables: { challenge, signedChallenge } })
      } catch (error) {
        toast.error({
          title: 'Trezor',
          text: error?.message,
        })
      }
      // } else {
      //   setNoTrezor(true)
      // }
    },
    [connectTrezorWallet],
  )

  const coinmetroHandler = useCallback(() => {
    window.location.replace(COINMETRO_AUTH_LINK)
  }, [])

  const unlockHandler = useCallback(() => {
    const url = new URL(window.location)
    if (enteredFrom && fromRoomSlug) {
      return window.location.replace(
        `${UNLOCK_AUTH_LINK}?client_id=${url.hostname}&redirect_uri=${url.origin}/oauth/unlock?slug=${fromRoomSlug}`,
      )
    }
    return window.location.replace(
      `${UNLOCK_AUTH_LINK}?client_id=${url.hostname}&redirect_uri=${url.origin}/oauth/unlock`,
    )
  }, [enteredFrom, fromRoomSlug])

  const { login } = useLogin()

  return {
    metamaskHandler,
    hathorHandler,
    isHathorUnlocked,
    trezorHandler,
    coinmetroHandler,
    unlockHandler,
    walletConnectHandler,
    privyConnectHandler: login,
  }
}
