import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

import { border } from 'Components/Styles'

export const ControlWrapper = styled.div`
  display: flex;
  align-items: center;
  height: ${mapToTheme('inputs.height')}px !important;
  padding: 0 ${themeGet('space.3')}px;
  ${border(64)};

  > svg {
    color: ${themeGet('colors.font.primary')};
  }

  &:hover,
  &:focus-within {
    border-color: ${themeGet('colors.primary')};
  }

  ${layout.height}
`
