import get from 'lodash/get'

import RoomFragment from 'GraphQL/Fragments/Room/Room.graphql'
import roomUpdatedSubscription from 'GraphQL/Subscriptions/roomUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function RoomUpdated() {
  useSubscription(roomUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const room = get(subscriptionData, ['data', 'roomUpdated'])

      const fragment = {
        id: `Room:${room?.id}`,
        fragmentName: 'RoomFields',
        fragment: RoomFragment,
      }

      const data = client.readFragment(fragment)

      if (data) {
        client.writeFragment({
          ...fragment,
          data: { ...data, ...(room || []) },
        })
      }
    },
  })

  return null
}

export default RoomUpdated
