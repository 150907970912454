import React from 'react'
import { components } from 'react-select'

import { GroupHeadingWrapper } from './styles'

const GroupHeading = props => (
  <GroupHeadingWrapper as={components.GroupHeading} {...props} />
)

GroupHeading.propTypes = {
  ...components.GroupHeading.propTypes,
}

export default GroupHeading
