import { useMemo } from 'react'

import get from 'lodash/get'
import map from 'lodash/map'

import categoriesQuery from 'GraphQL/Queries/Admin/Categories/categories.graphql'

import { useAdminQuery } from 'Services/Apollo'

export default function useCategoryOptions() {
  const { data } = useAdminQuery(categoriesQuery, {
    variables: { limit: 9999 },
  })

  const categories = get(data, 'categories') || {}

  const { rows } = categories

  return useMemo(
    () =>
      map(rows, row => ({
        label: row?.name,
        value: row?.id,
      })),
    [rows],
  )
}
