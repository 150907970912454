import { useMemo } from 'react'

import { DateTime } from 'luxon'

import map from 'lodash/map'
import range from 'lodash/range'

export default function useMonthOptions() {
  const now = DateTime.now()
  return useMemo(
    () =>
      map(range(13), i => ({
        label:
          i === 0
            ? 'Up Today'
            : `${now.minus({ months: i }).monthShort} ${
                now.minus({ months: i }).year
              }`,
        value: {
          min:
            i === 0
              ? now.minus({ months: 12 })
              : now.minus({ months: i }).startOf('month').plus({ days: 1 }),
          max: i === 0 ? now : now.minus({ months: i }).endOf('month'),
        },
      })),
    [now],
  )
}
