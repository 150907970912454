import React from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import useBlockedUsers from './useBlockedUsers'

function BlockedUserItem({ avatar, username, blockDate, userId, channelId }) {
  const { unblockChannel, unblockingUser } = useBlockedUsers({})
  const s = useScopedI18n('user.profile')

  const blockedAt = DateTime.fromISO(blockDate).toLocaleString(
    DateTime.DATE_SHORT,
  )

  return (
    <Row pb={3} pt={3}>
      <Row width={1}>
        <Avatar mr={2} size={41} src={avatar} username={avatar} />
        <Column>
          <Text bold mb={2}>
            @{username}
          </Text>
          <Text color="font.default" fontSize={12}>
            {s('labels.blocked')}&nbsp;{blockDate && blockedAt}
          </Text>
        </Column>
      </Row>
      <Column alignSelfCenter>
        <Button
          loading={unblockingUser === userId}
          width="110px"
          onClick={() => unblockChannel(userId, channelId)}
        >
          {unblockingUser === userId ? <Loader /> : s('actions.unblock')}
        </Button>
      </Column>
    </Row>
  )
}

BlockedUserItem.defaultProps = {
  userId: null,
  channelId: null,
  avatar: null,
  username: null,
  blockDate: null,
}

BlockedUserItem.propTypes = {
  avatar: PropTypes.string,
  blockDate: PropTypes.string,
  channelId: PropTypes.string,
  userId: PropTypes.string,
  username: PropTypes.string,
}

export default BlockedUserItem
