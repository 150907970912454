import uniqBy from 'lodash/uniqBy'

export function updateDirectChannelsQueryUpdater(prev, { fetchMoreResult }) {
  if (!fetchMoreResult) {
    return prev
  }
  return {
    ...prev,
    directChannels: {
      ...prev?.directChannels,
      rows: uniqBy(
        [
          ...(prev?.directChannels?.rows || []),
          ...(fetchMoreResult?.directChannels?.rows || []),
        ],
        'id',
      ),
    },
  }
}
