import React from 'react'
import { components } from 'react-select'

import { FiChevronDown } from 'react-icons/fi'

import { Dropdown } from './styles'

const DropdownIndicator = props => (
  <Dropdown as={components.DropdownIndicator} {...props}>
    <FiChevronDown />
  </Dropdown>
)

DropdownIndicator.propTypes = {
  ...components.DropdownIndicator.propTypes,
}

export default DropdownIndicator
