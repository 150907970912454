import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

export const Content = styled.div`
  width: 480px;
  min-height: calc(100vh - 184px);
  max-height: calc(100vh - 184px);
  padding: ${themeGet('space.6')}px ${themeGet('space.4')}px
    ${themeGet('space.4')}px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 320px);
`

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: thin;
  }
`
