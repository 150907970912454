import React from 'react'
import { components } from 'react-select'

import { FiX } from 'react-icons/fi'

const MultiValueRemove = props => (
  <components.MultiValueRemove {...props}>
    <FiX />
  </components.MultiValueRemove>
)

MultiValueRemove.propTypes = {
  ...components.MultiValueRemove.propTypes,
}

export default MultiValueRemove
