import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Row } from 'Components/UI/Flex'

import { Container, Label, StyledSlider, Thumb, Track } from './styles'

const RenderThumb = props => <Thumb {...props} />
const RenderTrack = (props, state) => <Track {...props} index={state.index} />

function Slider({
  defaultValue,
  disabled,
  max,
  maxLabel,
  min,
  minLabel,
  step,
  value,
  onAfterChange,
  onChange,
  ...rest
}) {
  const ref = useRef(null)

  useLayoutEffect(() => {
    if (!ref.current) return

    setTimeout(() => {
      ref.current.handleResize()
    }, 100)
  }, [])

  return (
    <Container {...pick(rest)}>
      <Row fullWidth spaceBetween>
        {minLabel && <Label>{minLabel}</Label>}
        <div />
        {maxLabel && <Label>{maxLabel}</Label>}
      </Row>
      <StyledSlider
        defaultValue={defaultValue}
        disabled={disabled}
        max={max}
        min={min}
        ref={ref}
        renderThumb={RenderThumb}
        renderTrack={RenderTrack}
        step={step}
        value={value}
        onAfterChange={onAfterChange}
        onChange={onChange}
      />
    </Container>
  )
}

Slider.defaultProps = {
  defaultValue: 0,
  disabled: false,
  max: 100,
  maxLabel: null,
  min: 0,
  minLabel: null,
  step: 1,
  value: undefined,
  onAfterChange: noop,
  onChange: noop,
}

Slider.propTypes = {
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  disabled: PropTypes.bool,
  max: PropTypes.number,
  maxLabel: PropTypes.string,
  min: PropTypes.number,
  minLabel: PropTypes.string,
  step: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  onAfterChange: PropTypes.func,
  onChange: PropTypes.func,
}

export default Slider
