import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import participantsByRoomIdQuery from 'GraphQL/Queries/Rooms/participantsByRoomId.graphql'
import participantAddedSubscription from 'GraphQL/Subscriptions/participantAdded.graphql'
import participantRemovedSubscription from 'GraphQL/Subscriptions/participantRemoved.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery(client, participant, participantsVariables, remove) {
  const participantsOptions = {
    query: participantsByRoomIdQuery,
    variables: {
      ...participantsVariables,
    },
  }

  const id = `Participant:${participant?.id}`

  const participantsData = client.readQuery(participantsOptions)

  if (remove) {
    client.cache.evict({ id })
    client.cache.gc()
  } else {
    client.writeQuery({
      ...participantsOptions,
      data: {
        ...participantsData,
        participantsByRoomId: {
          ...participantsData?.participantsByRoomId,
          rows: uniqBy(
            [
              participant,
              ...(participantsData?.participantsByRoomId?.rows || []),
            ],
            'id',
          ),
        },
      },
    })
  }
}

function ParticipantsSubscription({ participantsVariables }) {
  useSubscription(participantAddedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const participant = get(subscriptionData, ['data', 'participantAdded'])
      const remove = false

      updateQuery(client, participant, participantsVariables, remove)
    },
  })

  useSubscription(participantRemovedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const participant = get(subscriptionData, ['data', 'participantRemoved'])
      const remove = true

      updateQuery(client, participant, participantsVariables, remove)
    },
  })

  return <></>
}

ParticipantsSubscription.defaultProps = {
  participantsVariables: null,
}

ParticipantsSubscription.propTypes = {
  participantsVariables: PropTypes.object,
}

export default ParticipantsSubscription
