import React from 'react'

import get from 'lodash/get'

import unreadNotificationsCountQuery from 'GraphQL/Queries/Notifications/unreadNotificationsCount.graphql'
import unreadNotificationsCountUpdatedSubscription from 'GraphQL/Subscriptions/unreadNotificationsCountUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery(client, count) {
  const countOptions = {
    query: unreadNotificationsCountQuery,
  }

  const countData = client.readQuery(countOptions)

  client.writeQuery({
    ...countOptions,
    data: {
      ...countData,
      unreadNotificationsCount: count,
    },
  })
}

function NotificationsCountSubscription() {
  useSubscription(unreadNotificationsCountUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const count = get(subscriptionData, [
        'data',
        'unreadNotificationsCountUpdated',
      ])

      updateQuery(client, count)
    },
  })

  return <></>
}

export default NotificationsCountSubscription
