import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'
import round from 'lodash/round'

import { Column, Row } from 'Components/UI/Flex'
import Popover from 'Components/UI/Popover'
import Text from 'Components/UI/Text'

import userTokenKPIsQuery from 'GraphQL/Queries/User/userTokenKPIs.graphql'

import { useLazyQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import { Content } from './styles'

function UserKPIs({ userId, children, trigger, offset, ...rest }) {
  const s = useScopedI18n('user.modal.userKPIs')
  const [loadTokenKPIs, { data }] = useLazyQuery(userTokenKPIsQuery)

  const userKPIs = useMemo(
    () => data?.userTokenKPIs ?? {},
    [data?.userTokenKPIs],
  )

  useEffect(() => {
    if (userId) {
      loadTokenKPIs({ variables: { userId } })
    }
  }, [loadTokenKPIs, userId])

  const renderData = useMemo(
    () =>
      map(userKPIs, ({ token, percentage }) => (
        <Row key={token} mb={9} spaceBetween>
          <Text primary>{token?.toUpperCase()}</Text>
          <Text primary>{round(percentage * 100)} %</Text>
        </Row>
      )),
    [userKPIs],
  )

  return (
    <Popover
      clear
      content={
        <Content>
          <Text bold>{s('title')}</Text>
          {userKPIs?.length === 0 ? (
            <Row mt={16}>
              <Text bold primary small>
                {s('smallBalanceMessage')}
              </Text>
            </Row>
          ) : (
            <Column mt={16}>{renderData}</Column>
          )}
        </Content>
      }
      hideOnClick
      interactive
      offset={offset}
      trigger={trigger}
      {...rest}
    >
      {children}
    </Popover>
  )
}

UserKPIs.defaultProps = {
  children: null,
  offset: [0, 0],
  trigger: 'click',
  userId: null,
}

UserKPIs.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  offset: PropTypes.arrayOf(PropTypes.number),
  trigger: PropTypes.oneOf(['click', 'mouseenter']),
  userId: PropTypes.string,
}

export default UserKPIs
