import { BiCheckCircle } from 'react-icons/bi'
import { FiEye } from 'react-icons/fi'
import { GrDocumentCsv } from 'react-icons/gr'

import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import ClockGlyph from 'Assets/Svg/clock.svg'

import { Flex } from 'Components/UI'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`
export const StatsContainer = styled(Flex)`
  gap: 6px ${margin};
  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
  }
`
export const StatsFrame = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${themeGet('colors.border.default')};
  border-radius: 4px;
  padding: ${themeGet('space.4')}px;

  ${margin}
`

export const EyeIcon = styled(FiEye)`
  width: 22px;
  height: 15px;
  stroke: ${themeGet('colors.customDefault')};
`

export const CircleCheckIcon = styled(BiCheckCircle)`
  fill: ${themeGet('colors.customDefault')};
  height: 20px;
  width: 20px;
`

export const ClockIcon = styled(ClockGlyph)`
  path {
    fill: ${themeGet('colors.customDefault')};
  }
`

export const UsersFrame = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  align-items: center;
  width: 100%;
  background: ${themeGet('colors.bg.lightest')};
  border: 1px solid ${themeGet('colors.border.default')};
  border-radius: 4px;
  padding: ${themeGet('space.2')}px;

  ${layout}
  ${margin}
`

export const ExportIcon = styled(GrDocumentCsv)`
  path {
    stroke: ${mapToTheme('text.color')};
  }
`
