import isEqual from 'lodash/isEqual'

import { UI_ACTIONS, UI_DEFAULTS, UI_STATE } from 'Constants/store'

export default function uiState(store) {
  store.on('@init', () => ({
    [UI_STATE]: UI_DEFAULTS,
  }))

  store.on(UI_ACTIONS.SET, (state, variables) => {
    const currentState = state[UI_STATE]
    const nextState = { ...currentState, ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [UI_STATE]: nextState }
  })
}
