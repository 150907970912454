import React from 'react'
import PropTypes from 'prop-types'

import { FiMic } from 'react-icons/fi'

import pick from 'lodash/pick'

import { Button } from 'Components/UI'

import { Container } from './styles'

function RecordButton({ onClick, ...rest }) {
  return (
    <Container {...pick(rest)}>
      <Button height={150} icon record width={150} onClick={onClick}>
        <FiMic strokeWidth={1} />
      </Button>
    </Container>
  )
}

RecordButton.defaultProps = {
  onClick: null,
}

RecordButton.propTypes = {
  onClick: PropTypes.func,
}

export default RecordButton
