export function hexToRgba(hexColor, alpha) {
  if (!hexColor) {
    return null
  }
  const hex = hexColor?.replace('#', '')

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if ([r, g, b].some(val => val < 0 || val > 255)) {
    throw new Error('RGB values must be within the range of 0 to 255.')
  }

  if (alpha < 0 || alpha > 1) {
    throw new Error(
      'Transparency value (alpha) must be within the range of 0 to 1.',
    )
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}
