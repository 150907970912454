import React, { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import noop from 'lodash/noop'
import replace from 'lodash/replace'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Ellipsis, Row, Text } from 'Components/UI'

import { useAppContext } from 'Hooks'

import { APP_DIRECT_CHANNEL } from 'Router/routes'

import Utils from 'Utils'

import { SingleChannel } from '../styles'

function Channel({ channel, isCollapsed, onChannel, onUnread }) {
  const { me } = useAppContext()
  const { pathname } = useLocation()

  const isDirectChannel = matchPath(pathname, {
    path: APP_DIRECT_CHANNEL(channel?.id),
    exact: true,
    strict: true,
  })

  const channelUser = useMemo(
    () =>
      filter(
        channel?.participants,
        participant => participant?.user?.id !== me?.id,
      ),
    [channel?.participants, me?.id],
  )

  const contentWithMentions = useMemo(
    () =>
      Utils.Strings.buildContentWithMentions(
        channel?.lastMessage?.content,
        channel?.lastMessage?.mentions,
      ),
    [channel?.lastMessage?.content, channel?.lastMessage?.mentions],
  )

  return (
    <Row mt={2}>
      {!isCollapsed ? (
        <SingleChannel
          selectedChannel={isDirectChannel?.isExact}
          onClick={() => onChannel(channel?.id)}
        >
          <Row spaceBetween>
            <Row>
              <Column>
                <Avatar
                  online={
                    !channelUser[0]?.user?.isDeleted
                      ? channelUser[0]?.user?.online
                      : null
                  }
                  size={26}
                  src={
                    !channelUser[0]?.user?.isDeleted
                      ? channelUser[0]?.user?.profile?.photoUrl
                      : null
                  }
                  username={
                    !channelUser[0]?.user?.isDeleted
                      ? channelUser[0]?.user?.username
                      : null
                  }
                />
              </Column>
              <Column ml={2}>
                <Text bold mt={1}>
                  @
                  {!channelUser[0]?.user?.isDeleted
                    ? channelUser[0]?.user?.username
                    : 'Member deleted'}
                </Text>
                <Ellipsis>
                  <Text mt={1} primary small>
                    {channel?.lastMessage?.content?.length > 30
                      ? `${Utils.Strings.stringReplacer(
                          contentWithMentions,
                          Utils.Posts.postsReplacers,
                        )}`
                          .slice(0, 20)
                          .concat('...')
                      : Utils.Strings.stringReplacer(
                          contentWithMentions,
                          Utils.Posts.postsReplacers,
                        )}
                  </Text>
                </Ellipsis>
              </Column>
            </Row>
            <Column spaceBetween>
              <Row alignSelfEnd>{onUnread(channel?.unreadCount)}</Row>
              {channel?.lastMessage && (
                <Text primary small>
                  {replace(
                    Utils.Date.timeAgo(channel?.lastMessage?.createdAt),
                    'ago',
                    '',
                  )}
                </Text>
              )}
            </Column>
          </Row>
        </SingleChannel>
      ) : (
        <SingleChannel
          collapsed
          selectedChannel={isDirectChannel?.isExact}
          onClick={() => onChannel(channel?.id)}
        >
          <Row center>
            <Avatar
              online={
                !channelUser[0]?.user?.isDeleted
                  ? channelUser[0]?.user?.online
                  : null
              }
              size={26}
              src={
                !channelUser[0]?.user?.isDeleted
                  ? channelUser[0]?.user?.profile?.photoUrl
                  : null
              }
              username={
                !channelUser[0]?.user?.isDeleted
                  ? channelUser[0]?.user?.username
                  : null
              }
            />
            <Column ml={1} width={1}>
              <Text bold small>
                @
                {!channelUser[0]?.user?.isDeleted
                  ? `${channelUser[0]?.user?.username?.slice(0, 3)}...`
                  : 'deleted'}
              </Text>
              <Row alignSelfEnd>{onUnread(channel?.unreadCount)}</Row>
            </Column>
          </Row>
        </SingleChannel>
      )}
    </Row>
  )
}

Channel.defaultProps = {
  channel: null,
  isCollapsed: false,
  onChannel: noop,
  onUnread: noop,
}

Channel.propTypes = {
  channel: PropTypes.object,
  isCollapsed: PropTypes.bool,
  onChannel: PropTypes.func,
  onUnread: PropTypes.func,
}

export default Channel
