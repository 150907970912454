import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  height: 95vh;
  width: 95vw;
  padding: 0 ${themeGet('space.4')}px ${themeGet('space.4')}px;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: thin;
  }
`

export const Image = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`
