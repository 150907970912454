import React from 'react'
import { toast as toastify } from 'react-toastify'

import { Toast } from 'Components/UI'

const toast = {
  info: ({ title, text }, options) =>
    toastify.info(<Toast text={text} title={title} />, options),

  error: ({ title, text }, options) =>
    toastify.error(<Toast text={text} title={title} />, options),

  success: ({ title, text }, options) =>
    toastify.success(<Toast text={text} title={title} />, options),
}

export default toast
