import { useCallback, useMemo } from 'react'

export default function useTablePagination({ fetchMore, limit, ...rest }) {
  const variables = useMemo(() => rest ?? {}, [rest])

  const handleFetchMore = useCallback(
    ({ pageIndex, pageSize = limit }) => {
      fetchMore({
        variables: {
          page: pageIndex,
          limit: pageSize,
          ...variables,
        },
      })
    },
    [fetchMore, limit, variables],
  )

  return [handleFetchMore]
}
