import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Column, Loader, Modal, Text } from 'Components/UI'
import { InputField } from 'Components/UI/Forms'

import { PASSWORD_REGEX } from 'Constants/strings'

import updatePasswordMutation from 'GraphQL/Mutations/User/updatePassword.graphql'

import { useMutation } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELDS = {
  CURRENT_PASSWORD: 'currentPassword',
  NEW_PASSWORD: 'newPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
}

function EditPasswordModal({ user, ...rest }) {
  const s = useScopedI18n('user.modal.editPassword')
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [updatePassword] = useMutation(updatePasswordMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.CURRENT_PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.currentPasswordRequired')}`,
        },
      },
      [FIELDS.NEW_PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.passwordRequired')}`,
        },
        format: {
          pattern: PASSWORD_REGEX,
          flags: 'i',
          message: `^${_('auth.shared.passwordInvalid')}`,
        },
      },
      [FIELDS.CONFIRM_NEW_PASSWORD]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.confirmPasswordRequired')}`,
        },
        equality: {
          attribute: FIELDS.NEW_PASSWORD,
          message: `^${_('auth.shared.confirmPasswordNotEqual')}`,
        },
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        await updatePassword({
          variables: {
            currentPassword: values[FIELDS.CURRENT_PASSWORD],
            newPassword: values[FIELDS.NEW_PASSWORD],
          },
        })

        toast.success({
          title: 'User profile',
          text: `Profile updated`,
        })

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error({
          title: 'User profile',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [updatePassword],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle ml={1}>
                <Text alignStart mb={16} mt={24}>
                  {s('subtitle')}
                </Text>
              </Subtitle>
              <InputField
                autoComplete="off"
                label={_('auth.shared.currentPassword')}
                name={FIELDS.CURRENT_PASSWORD}
                type="password"
                width={1}
              />
              <InputField
                autoComplete="off"
                label={_('auth.shared.newPassword')}
                mt={16}
                name={FIELDS.NEW_PASSWORD}
                type="password"
                width={1}
              />
              <InputField
                autoComplete="off"
                label={_('auth.shared.confirmPassword')}
                mt={16}
                name={FIELDS.CONFIRM_NEW_PASSWORD}
                type="password"
                width={1}
              />
            </Content>

            <Column center mt={24} pb={24}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button height={32} mb={3} width={130} onClick={handleSubmit}>
                    {s('actions.save')}
                  </Button>
                  <Button
                    height={32}
                    outline
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditPasswordModal.defaultProps = {
  user: null,
}

EditPasswordModal.propTypes = {
  user: PropTypes.object,
}

export default EditPasswordModal
