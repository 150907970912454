import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: 0 ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const MentionSuggestions = styled.div`
  .mentions__suggestions {
    width: 350px;
    max-height: 160px;
    z-index: 0;
    position: absolute !important;
    top: 104px !important;
    left: 24px !important;
    border: 1px solid ${themeGet('inputs.border.input.content')};
    border-radius: 4px;
    padding: ${themeGet('space.4')}px ${themeGet('space.5')}px;
    overflow: hidden;

    &__list {
      height: 123px;
      overflow-y: scroll;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      color: ${themeGet('text.color.default')};
      margin-top: 8px;

      &:nth-child(1) {
        margin-top: 0;
      }
    }
  }

  @media (max-width: ${themeGet('breakpoints.3')}) {
    .mentions__suggestions {
      width: 100%;
    }
  }

  ${layout}
`
