import React from 'react'
import { components } from 'react-select'

import { Item } from './styles'

const LoadingMessage = ({ innerProps, children }) => (
  <Item textAlign="center" {...innerProps}>
    {children}
  </Item>
)

LoadingMessage.propTypes = {
  ...components.LoadingMessage.propTypes,
}

export default LoadingMessage
