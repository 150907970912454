import React from 'react'
import { components } from 'react-select'

import Loader from 'Components/UI/Loader'

const LoadingIndicator = props => <Loader {...props} />

LoadingIndicator.propTypes = {
  ...components.LoadingIndicator.propTypes,
}

export default LoadingIndicator
