import React, { useEffect, useMemo, useState } from 'react'
import { matchPath, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import { FaHeart } from 'react-icons/fa'
import { FiHeart } from 'react-icons/fi'

import Tippy from '@tippyjs/react'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'
import orderBy from 'lodash/orderBy'

import DefaultRoomLogo from 'Assets/Png/ethereum.png'

import { Button, Column, Flex, Row, Text } from 'Components/UI'

import { ROOM_COMMUNITY_BADGES } from 'Constants/ids'

import { useEntranceContext } from 'Hooks'

import { APP_ROOM } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import MetaIcons from '../MetaIcons'
import {
  LockIcon,
  Room,
  RoomButton,
  RoomLogo,
  RoomTooltip,
  SelectableText,
  SubRoom,
  VerifiedRoomIcon,
} from '../styles'

// todo remove style={{ overflow: 'hidden' }} after discussing design for mobile responsive

function LayeredRoom({
  darkMode,
  isCollapsed,
  room,
  domain,
  isSubDomainMainRoom,
  onFavorite,
  onJoin,
  onUnread,
}) {
  const s = useScopedI18n('user.rooms')
  const location = useLocation()
  const [selected, setSelected] = useState(null)
  const [{ exclusiveMode }] = useEntranceContext()

  const pathname = location?.pathname ?? ''

  const routeParams = useMemo(
    () =>
      matchPath(pathname, {
        path: APP_ROOM(),
      }),
    [pathname],
  )

  const roomPointer = useMemo(
    () => routeParams?.params?.roomPointer ?? '',
    [routeParams?.params?.roomPointer],
  )

  const subRooms = useMemo(
    () => orderBy(room?.groupRooms, ['sortOrder'], ['asc']),
    [room?.groupRooms],
  )

  useEffect(() => {
    const roomsById = keyBy(subRooms, 'id')
    const roomsBySlug = keyBy(subRooms, 'slug')
    if (roomsById[roomPointer] || roomsBySlug[roomPointer]) {
      setSelected(roomPointer)
    } else {
      setSelected(null)
    }
  }, [pathname, roomPointer, subRooms])

  return (
    <Row mt={2}>
      {!isCollapsed ? (
        <Room
          mainLayerRoom
          selectedLayeredRoom={
            !!selected ||
            room?.id === roomPointer ||
            room?.slug === roomPointer ||
            (room?.slug === domain && isSubDomainMainRoom)
          }
        >
          <RoomButton
            spaceBetween
            width={1}
            onClick={() => onJoin(room?.id, room?.locked, room?.slug)}
          >
            <Row spaceBetween width={1}>
              <Row style={{ overflow: 'hidden' }}>
                <Column center>
                  <RoomLogo
                    src={
                      room?.logoUrl || room?.token?.logoUrl || DefaultRoomLogo
                    }
                  />
                  {room?.favorite &&
                    !selected &&
                    room?.id !== roomPointer &&
                    room?.slug !== roomPointer &&
                    room?.slug !== domain &&
                    !isSubDomainMainRoom && (
                      <Row center mt={9}>
                        <FaHeart fill={darkMode ? '#e0245e' : '#e63d05'} />
                      </Row>
                    )}
                </Column>
                <Column ml={12} style={{ overflow: 'hidden' }}>
                  <Row>
                    <SelectableText
                      bold
                      lockedText={!!room?.locked}
                      middle
                      title={room?.label}
                      uppercase
                    >
                      {room?.label}
                    </SelectableText>
                    {room?.isVerified && (
                      <Flex ml={1}>
                        <VerifiedRoomIcon />
                      </Flex>
                    )}
                  </Row>
                  {room?.communityBadge &&
                    room?.communityBadge !==
                      ROOM_COMMUNITY_BADGES[2]?.value && (
                      <Text
                        blue={
                          room?.communityBadge ===
                          ROOM_COMMUNITY_BADGES[0]?.value
                        }
                        bold
                        smallest
                        violet={
                          room?.communityBadge ===
                          ROOM_COMMUNITY_BADGES[1]?.value
                        }
                      >
                        {s(`communities.${room?.communityBadge}`)}
                      </Text>
                    )}
                </Column>
              </Row>
              <Column mt={1}>
                <Row justifyEnd>
                  {room?.locked ? <LockIcon /> : onUnread(room?.unreadCount)}
                </Row>
                <Row
                  mt={
                    room?.communityBadge &&
                    room?.communityBadge !== ROOM_COMMUNITY_BADGES[2]?.value
                      ? 18
                      : 2
                  }
                >
                  <Text mr={1} primary>
                    {room?.participantsCount}
                  </Text>
                  {room?.participantsCount === 1 ? (
                    <Text primary>{s('fields.user')}</Text>
                  ) : (
                    <Text primary>{s('fields.users')}</Text>
                  )}
                </Row>
              </Column>
            </Row>
          </RoomButton>

          {(!!selected ||
            room?.id === roomPointer ||
            room?.slug === roomPointer ||
            (room?.slug === domain && isSubDomainMainRoom)) && (
            <>
              <Row center ml={2} mt={2}>
                {!room?.locked && !exclusiveMode && (
                  <Button
                    height={12}
                    mr={4}
                    noEffectsIcon
                    warning
                    width={15}
                    onClick={() =>
                      onFavorite(room?.favorite, room?.id, room?.slug)
                    }
                  >
                    {room?.favorite ? (
                      <FaHeart strokeWidth={1} />
                    ) : (
                      <FiHeart strokeWidth={1} />
                    )}
                  </Button>
                )}

                <MetaIcons links={room?.links} />
              </Row>
              <Row center flexWrap mt={12} spaceBetween>
                {map(subRooms, subRoom => (
                  <SubRoom
                    activeSub={
                      selected === subRoom?.id || selected === subRoom?.slug
                    }
                    key={subRoom?.id}
                    ml={2}
                    mt={2}
                    onClick={() =>
                      onJoin(subRoom?.id, subRoom?.locked, subRoom?.slug)
                    }
                  >
                    {subRoom?.locked ? (
                      <LockIcon absolutePosition />
                    ) : (
                      onUnread(subRoom?.unreadCount, true)
                    )}
                    <span title={subRoom?.label}>{subRoom?.label}</span>
                  </SubRoom>
                ))}
              </Row>
            </>
          )}
        </Room>
      ) : (
        <Room
          collapsed
          selectedRoom={
            !!selected ||
            room?.id === roomPointer ||
            room?.slug === roomPointer ||
            (room?.slug === domain && isSubDomainMainRoom)
          }
        >
          <RoomButton
            width={1}
            onClick={() => onJoin(room?.id, room?.locked, room?.slug)}
          >
            <Row justifyCenter>
              <Tippy
                content={<RoomTooltip>{room?.label}</RoomTooltip>}
                offset={[0, 30]}
                placement="right"
              >
                <Row center>
                  <RoomLogo
                    collapsedLogo
                    src={
                      room?.logoUrl || room?.token?.logoUrl || DefaultRoomLogo
                    }
                  />
                </Row>
              </Tippy>
            </Row>
          </RoomButton>
          <Row center justifyCenter mt={16}>
            <Row>
              {!room?.locked && !exclusiveMode ? (
                <Button
                  height={12}
                  mr={3}
                  noEffectsIcon
                  warning
                  width={15}
                  onClick={() =>
                    onFavorite(room?.favorite, room?.id, room?.slug)
                  }
                >
                  {room?.favorite ? (
                    <FaHeart strokeWidth={1} />
                  ) : (
                    <FiHeart strokeWidth={1} />
                  )}
                </Button>
              ) : (
                <Column width={15} />
              )}
            </Row>
            <Row justifyEnd width={40}>
              {room?.locked ? <LockIcon /> : onUnread(room?.unreadCount)}
            </Row>
          </Row>
        </Room>
      )}
    </Row>
  )
}

LayeredRoom.defaultProps = {
  darkMode: false,
  domain: null,
  isCollapsed: false,
  isSubDomainMainRoom: false,
  room: {},
  onFavorite: noop,
  onJoin: noop,
  onUnread: noop,
}

LayeredRoom.propTypes = {
  darkMode: PropTypes.bool,
  domain: PropTypes.string,
  isCollapsed: PropTypes.bool,
  isSubDomainMainRoom: PropTypes.bool,
  room: PropTypes.object,
  onFavorite: PropTypes.func,
  onJoin: PropTypes.func,
  onUnread: PropTypes.func,
}

export default LayeredRoom
