import styled from 'styled-components'

const PaginationAnchor = styled.span`
  display: inline-block;
  width: 100%;
  height: 10px;
  max-height: 40px;
`

export default PaginationAnchor
