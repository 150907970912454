import React from 'react'
import { components } from 'react-select'

import { Separator } from './styles'

const IndicatorSeparator = ({ innerProps }) => <Separator {...innerProps} />

IndicatorSeparator.propTypes = {
  ...components.IndicatorSeparator.propTypes,
}

export default IndicatorSeparator
