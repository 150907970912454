import React, { useCallback, useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { FiSliders } from 'react-icons/fi'

import { DateTime } from 'luxon'

import map from 'lodash/map'

import { Loader, PaginationAnchor, Popover, Row, Text } from 'Components/UI'

import { NOTIFICATION_KINDS } from 'Constants/notifications'

import readNotificationsMutation from 'GraphQL/Mutations/Notifications/readNotifications.graphql'
import notificationsQuery from 'GraphQL/Queries/Notifications/notifications.graphql'
import { updateNotificationsQueryUpdater } from 'GraphQL/Updaters/notifications'

import {
  APP_DIRECT_CHANNEL,
  APP_PROFILE,
  APP_ROOM_CHANNEL,
  APP_ROOM_POST,
} from 'Router/routes'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import NotificationsSubscription from './NotificationsSubscription'
import {
  Content,
  NotificationEntry,
  ScrollingContainer,
  StyledButton,
} from './styles'

const LOAD_COUNT = 10

function Notifications({
  children,
  trigger,
  offset,
  handleRules,
  unreadCount,
  ...rest
}) {
  const s = useScopedI18n('user.notifications')
  const [page, setPage] = useState(0)

  const [readNotifications] = useMutation(readNotificationsMutation)

  const [
    loadNotifications,
    { data, loading, fetchMore, variables: notificationsVariables },
  ] = useLazyQuery(notificationsQuery)

  const handleReadNotifications = useCallback(async () => {
    try {
      await readNotifications()
    } catch (error) {
      toast.error({
        title: 'Notifications',
        text: error?.message,
      })
    }
  }, [readNotifications])

  const fetchData = useCallback(() => {
    loadNotifications({ variables: { page: 0, limit: LOAD_COUNT } })
    if (unreadCount > 0) {
      handleReadNotifications()
    }
  }, [handleReadNotifications, loadNotifications, unreadCount])

  const notifications = useMemo(
    () => data?.notifications ?? {},
    [data?.notifications],
  )

  const { rows, pages } = notifications

  const handleLoadMore = useCallback(async () => {
    if (page < pages - 1) {
      await fetchMore({
        variables: { page: page + 1, limit: LOAD_COUNT },
        updateQuery: (prev, fetchMoreResult) =>
          updateNotificationsQueryUpdater(prev, fetchMoreResult),
      })

      setPage(page + 1)
    }
  }, [fetchMore, page, pages])

  const renderNotifications = useMemo(
    () =>
      map(rows, ({ title, createdAt, content, event, rule, id, payload }) => {
        switch (event) {
          case NOTIFICATION_KINDS.RULE_MATCHED:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_ROOM_POST(payload?.roomSlug, payload?.postId)}
              >
                <Text bold>{rule?.name}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
          case NOTIFICATION_KINDS.POST_FLAGGED:
          case NOTIFICATION_KINDS.POST_SAVED:
          case NOTIFICATION_KINDS.POST_LIKED:
          case NOTIFICATION_KINDS.USER_TAGGED_IN_POST:
          case NOTIFICATION_KINDS.USER_TAGGED_IN_COMMENT:
          case NOTIFICATION_KINDS.COMMENT_CREATED:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_ROOM_POST(payload?.roomSlug, payload?.postId)}
              >
                <Text bold>{title}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
          case NOTIFICATION_KINDS.CHANNEL_CREATED:
          case NOTIFICATION_KINDS.CHANNEL_MESSAGE_CREATED:
          case NOTIFICATION_KINDS.USER_TAGGED_IN_MESSAGE:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_ROOM_CHANNEL(payload?.roomSlug, payload?.channelId)}
              >
                <Text bold>{title}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
          case NOTIFICATION_KINDS.USER_FOLLOWED:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_PROFILE(payload?.userId)}
              >
                <Text bold>{title}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
          case NOTIFICATION_KINDS.DIRECT_CHANNEL_MESSAGE_CREATED:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_DIRECT_CHANNEL(payload?.channelId)}
              >
                <Text bold>{title}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
          default:
            return (
              <NotificationEntry
                key={id}
                mb={16}
                to={APP_PROFILE(payload?.userId)}
              >
                <Text bold>{title}</Text>
                <Text mt={9} primary small>
                  {DateTime.fromISO(createdAt).toLocaleString(
                    DateTime.DATETIME_SHORT,
                  )}
                </Text>
                <Text light mt={12} small>
                  {content}
                </Text>
              </NotificationEntry>
            )
        }
      }),
    [rows],
  )

  return (
    <>
      <Popover
        clear
        content={
          <Content>
            <Row borderBottom center p={16} spaceBetween>
              <Text bold middle>
                {s('title')}
              </Text>
              <StyledButton
                iconButton
                noEffectsIcon
                stroke
                onClick={handleRules}
              >
                <FiSliders strokeWidth={1} />
              </StyledButton>
            </Row>
            <ScrollingContainer>
              {loading ? <Loader alignSelfCenter /> : renderNotifications}
              <Waypoint onEnter={handleLoadMore}>
                <PaginationAnchor />
              </Waypoint>
            </ScrollingContainer>
          </Content>
        }
        hideOnClick
        interactive
        offset={offset}
        trigger={trigger}
        onTrigger={fetchData}
        {...rest}
      >
        {children}
      </Popover>
      <NotificationsSubscription
        notificationsVariables={notificationsVariables}
      />
    </>
  )
}

Notifications.defaultProps = {
  children: null,
  handleRules: null,
  offset: [0, 0],
  refetchCounter: null,
  trigger: 'click',
  unreadCount: 0,
}

Notifications.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  handleRules: PropTypes.func,
  offset: PropTypes.arrayOf(PropTypes.number),
  refetchCounter: PropTypes.func,
  trigger: PropTypes.oneOf(['click', 'mouseenter']),
  unreadCount: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
}

export default Notifications
