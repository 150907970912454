import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import {
  PaletteBackground,
  PaletteContainer,
  PaletteIcon,
  PaletteIconChecked,
  PaletteText,
} from './styles'

function RadioPalette({
  value,
  name,
  disabled,
  error,
  label,
  meta,
  blue,
  orange,
  pink,
  purple,
  green,
  yellow,
  denim,
  ...rest
}) {
  return (
    <PaletteContainer
      {...pick(rest)}
      blue={blue}
      denim={denim}
      green={green}
      orange={orange}
      pink={pink}
      purple={purple}
      yellow={yellow}
    >
      <input
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        {...omit(rest)}
      />
      <PaletteBackground>
        <PaletteIcon
          blue={blue}
          denim={denim}
          green={green}
          orange={orange}
          pink={pink}
          purple={purple}
          yellow={yellow}
        />
      </PaletteBackground>
      <PaletteIconChecked />
      <PaletteText>
        {label && <Text small>{label}</Text>}
        {error && (
          <Text small warning>
            {error}
          </Text>
        )}
      </PaletteText>
    </PaletteContainer>
  )
}

RadioPalette.defaultProps = {
  blue: false,
  circle: false,
  denim: false,
  disabled: false,
  error: null,
  green: false,
  label: null,
  meta: {},
  name: null,
  orange: false,
  pink: false,
  purple: false,
  textRadioButton: false,
  value: null,
  yellow: false,
}

RadioPalette.propTypes = {
  blue: PropTypes.bool,
  circle: PropTypes.bool,
  denim: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  green: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  meta: PropTypes.object,
  name: PropTypes.string,
  orange: PropTypes.bool,
  pink: PropTypes.bool,
  purple: PropTypes.bool,
  textRadioButton: PropTypes.bool,
  value: PropTypes.string,
  yellow: PropTypes.bool,
}

export default RadioPalette
