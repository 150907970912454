import isEqual from 'lodash/isEqual'

import {
  CONTENT_ACTIONS,
  CONTENT_DEFAULTS,
  CONTENT_STATE,
} from 'Constants/store'

export default function contentState(store) {
  store.on('@init', () => ({
    [CONTENT_STATE]: CONTENT_DEFAULTS,
  }))

  store.on(CONTENT_ACTIONS.SET, (state, variables) => {
    const currentState = state[CONTENT_STATE]
    const nextState = { ...variables }

    if (isEqual(nextState, currentState)) return null
    return { [CONTENT_STATE]: nextState }
  })
}
