import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import Utils from 'Utils'

import Checkbox from './Checkbox'

// TODO: move field error check to utils of some sort
function CheckboxField({ name, value, ...rest }) {
  return (
    <Field name={name} type="checkbox" value={value || undefined}>
      {({ input, meta }) => {
        const { error } = Utils.Form.hasError(meta)

        return <Checkbox error={error} {...input} {...rest} />
      }}
    </Field>
  )
}

CheckboxField.defaultProps = {
  value: null,
}

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
}

export default CheckboxField
