import { FiChevronDown, FiChevronLeft } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import lockGlyph from 'Assets/Svg/lock.svg'

import { Column, Row, Text } from 'Components/UI'

// change height once lounges implemented
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const DraggableContainer = styled.div``

export const CategoryTitle = styled.div`
  display: flex;
  align-item: center;
  width: 100%;
  cursor: pointer;
`

const channelsOpenedCss = ({ channelsOpened }) =>
  channelsOpened &&
  css`
    margin-top: ${themeGet('space.4')}px;
    max-height: 100%;
  `

export const ChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${channelsOpenedCss}
  ${margin}
`

export const ScrollingContainer = styled.div`
  outline: none;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: ${themeGet('colors.border.customDefault')}
    ${themeGet('colors.bg.secondary')};

  &::-webkit-scrollbar {
    width: 2px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeGet('colors.border.customDefault')};
  }

  ${layout}
  ${margin}
`

const smallerCss = ({ smaller }) =>
  smaller &&
  css`
    width: 16px;
    height: 16px;

    & svg {
      width: 8px;
      height: 8px;
    }
  `

export const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & svg {
    width: 10px;
    height: 10px;
  }

  ${smallerCss}

  ${margin}
`

const arrowSelectedCss = ({ arrowSelected }) =>
  arrowSelected &&
  css`
    transform: rotate(-180deg);
    transition: all ${themeGet('transitionTime.long')} ease-in-out;
  `

export const SelectArrow = styled(FiChevronDown)`
  color: ${themeGet('colors.customDefault')};
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${arrowSelectedCss}
`

const secondaryPositionCss = ({ secondaryPosition }) =>
  secondaryPosition &&
  css`
    left: -12px;
    top: 324px;
  `

export const ButtonWrapper = styled.div`
  position: absolute;
  right: -12px;
  top: 308px;
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  @media (max-width: ${themeGet('breakpoints.3')}) {
    display: none;
  }

  ${secondaryPositionCss}
`

export const CollapseArrow = styled(FiChevronLeft)`
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${arrowSelectedCss}
`

const absolutePositionCss = ({ absolutePosition }) =>
  absolutePosition &&
  css`
    position: absolute;
    top: -9px;
    right: -9px;
  `

export const UnreadWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  min-width: 18px;
  background: ${themeGet('colors.bg.customDefault')};
  border: 1px solid ${themeGet('colors.border.customDefault')};
  border-radius: 9px;
  padding: ${themeGet('space.1')}px;

  & p {
    line-height: 15px;
  }

  ${margin}
  ${absolutePositionCss}
`

const selectedChannelCss = ({ selectedChannel }) =>
  selectedChannel &&
  css`
    background: ${themeGet('colors.bg.customSecondary')};
  `

export const ChannelName = styled(Row)`
  cursor: pointer;
  padding: ${themeGet('space.2')}px ${themeGet('space.1')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
  border-radius: 8px;

  &:hover {
    background: ${themeGet('colors.bg.customSecondary')};
  }

  &:active {
    background: ${themeGet('colors.bg.customDark')};
  }

  ${selectedChannelCss}
`

export const RoomListWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (min-width: ${themeGet('breakpoints.3')}) {
    display: none;
  }

  ${margin}
`

export const LockIcon = styled(lockGlyph)``

export const ChannelsTitleWrapper = styled(Column)`
  @media (max-width: ${themeGet('breakpoints.3')}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const ChannelsTitle = styled(Text)`
  width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: ${themeGet('breakpoints.3')}) {
    width: initial;
  }
`
