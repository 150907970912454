import React from 'react'
import { components } from 'react-select'

import { SelectContainerWrapper } from './styles'

const SelectContainer = props => (
  <SelectContainerWrapper as={components.SelectContainer} {...props} />
)

SelectContainer.propTypes = {
  ...components.SelectContainer.propTypes,
}

export default SelectContainer
