import React, { useCallback, useRef } from 'react'
import QRCode from 'react-qr-code'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Modal, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { CodeWrapper, Content, Subtitle } from './styles'

function DonationQRModal({ donationWallet, ...rest }) {
  const s = useScopedI18n('user.modal.donationQr')

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={s('title')}
      onMount={handleMount}
    >
      <Content>
        <Subtitle ml={1}>
          <Text alignStart mt={24}>
            {s('subtitle')}
          </Text>
        </Subtitle>
        <CodeWrapper mb={24}>
          <QRCode value={donationWallet} />
        </CodeWrapper>
      </Content>
    </Modal>
  )
}

DonationQRModal.defaultProps = {
  donationWallet: null,
}

DonationQRModal.propTypes = {
  donationWallet: PropTypes.string,
}

export default DonationQRModal
