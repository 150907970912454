import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { Picker } from 'emoji-mart'
import { useStoreon } from 'storeon/react'

import noop from 'lodash/noop'

import { UI_STATE } from 'Constants/store'

import { Wrapper } from './styles'

import Popover from '../Popover'

import 'emoji-mart/css/emoji-mart.css'

function EmojiPicker({
  children,
  trigger,
  offset,
  caretPosition,
  value,
  onEmoji,
  ...rest
}) {
  const { ui } = useStoreon(UI_STATE)
  const { darkMode } = ui

  const handleEmoji = useCallback(
    event => {
      const { start, end } = caretPosition
      if (value && start === end) {
        const currentCaret = value?.split('')
        currentCaret?.splice(end, 0, event)
        onEmoji(currentCaret.join(''))
      } else if (value && start !== end) {
        const currentCaret = value?.split('')
        currentCaret?.splice(start, end - start, event)
        onEmoji(currentCaret.join(''))
      } else {
        onEmoji(event)
      }
    },
    [caretPosition, onEmoji, value],
  )

  return (
    <Popover
      clear
      content={
        <Wrapper>
          <Picker
            emoji="point_up"
            emojiSize={14}
            theme={darkMode ? 'dark' : 'light'}
            title="Pick your emoji..."
            onSelect={emoji => handleEmoji(emoji?.native)}
          />
        </Wrapper>
      }
      hideOnClick
      interactive
      offset={offset}
      trigger={trigger}
      {...rest}
    >
      {children}
    </Popover>
  )
}

EmojiPicker.defaultProps = {
  caretPosition: {},
  children: null,
  offset: [0, 0],
  trigger: 'click',
  value: '',
  onEmoji: noop,
}

EmojiPicker.propTypes = {
  caretPosition: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  offset: PropTypes.arrayOf(PropTypes.number),
  trigger: PropTypes.oneOf(['click', 'mouseenter focus']),
  value: PropTypes.string,
  onEmoji: PropTypes.func,
}

export default EmojiPicker
