import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory, useLocation } from 'react-router'
import { renderRoutes } from 'react-router-config'
import { ToastContainer } from 'react-toastify'

import { FiX } from 'react-icons/fi'

import { useTheme } from 'styled-components'

import { PrivyProvider } from '@privy-io/react-auth'
import { useStoreon } from 'storeon/react'

import { Loader } from 'Components/UI'

import { APP_NAME, PALETTE, THEMES } from 'Config'

import { USER_ROLE } from 'Constants/ids'
import { AUTH_STATE, I18N_STATE, UI_ACTIONS, UI_STATE } from 'Constants/store'

import meQuery from 'GraphQL/Queries/User/me.graphql'

import usePrivyHandlers from 'Hooks/usePrivyHandlers'

import router from 'Router'

import { useQuery } from 'Services/Apollo'
import AppContext from 'Services/AppContext'
import EntranceContext from 'Services/Entrance/Context'
import useEntrance from 'Services/Entrance/useEntrance'
import { LocaleRenderer } from 'Services/I18n'
import { SubscriptionManager } from 'Services/SubscriptionManager'
import TrezorProvider from 'Services/Trezor/TrezorProvider'
import Web3Provider from 'Services/Web3/Web3Provider'

import { GlobalStyle } from 'Theme'

export default function App() {
  const { auth, i18n, dispatch } = useStoreon(AUTH_STATE, I18N_STATE, UI_STATE)
  const { action } = useHistory()
  const location = useLocation()
  const [entranceContextValue, setEntranceContextValue] = useEntrance()

  const [historyRoute, setHistoryRoute] = useState({
    from: location?.pathname,
    to: location?.pathname,
    prevAction: action,
    currentAction: action,
  })

  const theme = useTheme()

  const locale = i18n?.locale

  const documentTitle = useMemo(
    () =>
      entranceContextValue?.exclusiveMode &&
      entranceContextValue?.roomGroupMeta?.name
        ? entranceContextValue?.roomGroupMeta?.name
        : APP_NAME,
    [entranceContextValue],
  )

  const faviconHref = useMemo(
    () =>
      entranceContextValue?.exclusiveMode &&
      entranceContextValue?.roomMeta?.logoUrl
        ? entranceContextValue?.roomMeta?.logoUrl
        : '%PUBLIC_URL%/favicon.ico',
    [entranceContextValue],
  )

  const {
    data,
    loading,
    refetch: refetchMe,
  } = useQuery(meQuery, {
    skip: !auth?.accessToken,
    // fetchPolicy: 'no-cache', // TODO: not sure about side-effects
  })

  const me = data?.me

  const routes = useMemo(() => router(me), [me])

  const { privyAppId, privyConfig, handleLoginPrivy } = usePrivyHandlers({
    roomGroupMeta: entranceContextValue?.roomGroupMeta,
    exclusiveMode: entranceContextValue?.exclusiveMode,
    roomMeta: entranceContextValue?.roomMeta,
  })

  const handleUiState = useCallback(
    ({ themeValue, palette }) => {
      dispatch(UI_ACTIONS.SET, {
        darkMode: themeValue === THEMES.DARK,
        palette: PALETTE[palette] || palette,
      })
    },
    [dispatch],
  )

  useEffect(() => {
    setHistoryRoute(prev => ({
      to: location?.pathname,
      from: prev?.to,
      currentAction: action,
      prevAction: prev?.currentAction,
    }))
  }, [action, location])

  useEffect(() => {
    if (me) {
      if (
        entranceContextValue?.exclusiveMode &&
        entranceContextValue?.roomGroupMeta?.customHexColor
      ) {
        dispatch(UI_ACTIONS.SET, {
          darkMode: me?.profile?.theme === THEMES.DARK,
        })
      } else {
        handleUiState({
          themeValue: me?.profile?.theme,
          palette: me?.profile?.palette || PALETTE.blue,
        })
      }
    }
  }, [dispatch, entranceContextValue, handleUiState, me])

  const handleChangeFont = useCallback(async fontUrl => {
    const myFont = new FontFace('CustomFont', `url(${fontUrl})`)
    await myFont.load()
    document.fonts.add(myFont)
    document.body.style.fontFamily = '"CustomFont", Arial'
  }, [])

  useEffect(() => {
    if (entranceContextValue?.exclusiveMode) {
      if (entranceContextValue?.roomGroupMeta?.fontUrl) {
        handleChangeFont(entranceContextValue?.roomGroupMeta?.fontUrl)
      }
      if (entranceContextValue?.roomGroupMeta?.customHexColor) {
        dispatch(UI_ACTIONS.SET, {
          palette: entranceContextValue?.roomGroupMeta?.customHexColor,
        })
      }
    }
  }, [dispatch, entranceContextValue, handleChangeFont])

  if (!me && loading) {
    return <Loader fullScreen text="Loading..." />
  }

  const isAdmin = me?.role === USER_ROLE.ADMIN
  const isSuperAdmin = me?.role === USER_ROLE.SUPER_ADMIN

  return (
    <LocaleRenderer key={locale}>
      <AppContext.Provider
        value={{
          me,
          refetchMe,
          locale,
          isAdmin,
          isSuperAdmin,
          historyRoute,
        }}
      >
        <EntranceContext.Provider
          value={[entranceContextValue, setEntranceContextValue]}
        >
          <Web3Provider>
            <TrezorProvider>
              <Helmet>
                <title>{documentTitle}</title>
                <link href={faviconHref} rel="icon" type="image/png" />
                {theme.webfonts.map(font => (
                  <link
                    href={`https://fonts.googleapis.com/css?family=${font}`}
                    key={font}
                    rel="stylesheet"
                  />
                ))}
                <GlobalStyle />
              </Helmet>
              {!entranceContextValue?.loading ? (
                <PrivyProvider
                  appId={privyAppId}
                  config={privyConfig}
                  onSuccess={user => handleLoginPrivy(user)}
                >
                  {renderRoutes(routes)}
                </PrivyProvider>
              ) : (
                <> {renderRoutes(routes)}</>
              )}
              <ToastContainer
                className="toast-container"
                closeButton={() => <FiX size={10} />}
                progressClassName="toast-progress"
                toastClassName="toast"
              />
              <SubscriptionManager />
            </TrezorProvider>
          </Web3Provider>
        </EntranceContext.Provider>
      </AppContext.Provider>
    </LocaleRenderer>
  )
}
