import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import PropTypes from 'prop-types'

import { FiCheck, FiTrash2, FiUpload } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import { Text } from 'Components/UI'

import { UPLOAD_TYPES } from 'Constants/ids'

import { uploadFile, uploadNFTFile } from 'Services/Api/Queries/upload'
import _, { useScopedI18n } from 'Services/I18n'

import { fileToDataURL } from 'Utils/Files'

import { Container, DeleteButton, Progress, Wrapper } from './styles'

function FileInput({
  disabled,
  value,
  entityId,
  uploadType,
  standard,
  refetch,
  setLoadingRef,
  acceptedFiles,
  onChange,
  ...rest
}) {
  const s = useScopedI18n('dropzone')

  const [uploadingFile, setUploadingFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [percent, setPercent] = useState(0)

  const handleProgress = useCallback(event => setPercent(event?.percent), [])

  const handleUploadFile = useCallback(
    async file =>
      !standard
        ? uploadFile({
            file,
            type: uploadType,
            entityId,
            onProgress: handleProgress,
          })
        : uploadNFTFile({
            file,
            type: uploadType,
            entityId,
            standard,
            onProgress: handleProgress,
          }),
    [uploadType, entityId, standard, handleProgress],
  )

  const handleDrop = useCallback(
    async ([acceptedFile]) => {
      if (!acceptedFile) return

      setLoading(true)
      setLoadingRef && setLoadingRef(true)
      fileToDataURL(acceptedFile, setUploadingFile)

      const response = await handleUploadFile(acceptedFile)

      if (response?.ok) {
        setLoading(false)
        setLoadingRef && setLoadingRef(false)
        setUploadingFile(null)
        setPercent(0)
        if (onChange) {
          onChange(response?.url)
        }
        if (refetch) {
          await refetch()
        }
      }
    },
    [setLoadingRef, handleUploadFile, onChange, refetch],
  )

  const handleDeleteFile = useCallback(
    event => {
      event.stopPropagation()
      onChange(null)
    },
    [onChange],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: acceptedFiles || '.json',
    disabled: loading || disabled,
  })

  const src = uploadingFile || value

  return (
    <Container {...pick(rest)}>
      <Wrapper {...getRootProps()} isDragActive={isDragActive}>
        <input {...getInputProps()} />

        {src ? (
          <>
            <FiCheck size={36} strokeWidth={1} />
            {loading && <Progress value={percent} />}
          </>
        ) : (
          <>
            <FiUpload size={36} strokeWidth={1} />
            <Text alignTextCenter light mt={1} small>
              {s('dragNDrop')} {_('general.orLower')}
            </Text>
            <Text primary small>
              {s('select')}
            </Text>
          </>
        )}
        {value && !loading && (
          <>
            <DeleteButton onClick={handleDeleteFile}>
              <FiTrash2 />
            </DeleteButton>
          </>
        )}
      </Wrapper>
    </Container>
  )
}

FileInput.defaultProps = {
  acceptedFiles: null,
  disabled: false,
  entityId: null,
  height: 120,
  refetch: undefined,
  setLoadingRef: undefined,
  standard: null,
  width: 120,
  onChange: noop,
}

FileInput.propTypes = {
  acceptedFiles: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  disabled: PropTypes.bool,
  entityId: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  refetch: PropTypes.func,
  setLoadingRef: PropTypes.func,
  standard: PropTypes.string,
  uploadType: PropTypes.oneOf(Object.values(UPLOAD_TYPES)).isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
}

export default FileInput
