import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'

import CoinbaseGlyph from 'Assets/Svg/coinbase.svg'
import HathorpayGlyph from 'Assets/Svg/hathorpay.svg'
import CoinmetroGlyph from 'Assets/Svg/metaIcons/coinmetro.svg'
import MetamaskGlyph from 'Assets/Svg/metamask.svg'
import PrivyGlyph from 'Assets/Svg/privy.svg'
import UnlockIconGlyph from 'Assets/Svg/unlock.svg'
import WalletConnectGlyph from 'Assets/Svg/walletConnect.svg'

const sizeCss = ({ size }) => css`
  width: ${size}px;
  height: ${size}px;
`

export const MetamaskIcon = styled(MetamaskGlyph)`
  ${sizeCss};
  ${margin}
`
export const CoinbaseIcon = styled(CoinbaseGlyph)`
  ${sizeCss};
  ${margin}
`
export const CoinmetroIcon = styled(CoinmetroGlyph)`
  ${sizeCss};
  ${margin}
`
export const HathorPayIcon = styled(HathorpayGlyph)`
  ${sizeCss};
  ${margin}
`

export const WalletConnectIcon = styled(WalletConnectGlyph)`
  ${sizeCss};
  ${margin}
`

export const UnlockIcon = styled(UnlockIconGlyph)`
  ${sizeCss};
  ${margin}
`

export const PrivyIcon = styled(PrivyGlyph)`
  ${sizeCss};
  ${margin}
`
