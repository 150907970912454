import React from 'react'
import { components } from 'react-select'

import { FiSearch } from 'react-icons/fi'

import { pick } from '@styled-system/props'

import { ControlWrapper } from './styles'

const Control = ({ children, innerProps, selectProps }) => (
  <ControlWrapper
    {...innerProps}
    {...pick(selectProps)}
    small={selectProps.small}
  >
    {children}
    <FiSearch />
  </ControlWrapper>
)

Control.propTypes = {
  ...components.Control.propTypes,
}

export default Control
