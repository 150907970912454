import loadable from '@loadable/component'

const Admin = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "admin-root" */)),
  Admins: loadable(() =>
    import('./Admins' /* webpackChunkName: "admin-admins" */),
  ),
  Members: loadable(() =>
    import('./Members' /* webpackChunkName: "admin-members" */),
  ),
  Rooms: loadable(() =>
    import('./Rooms' /* webpackChunkName: "admin-rooms" */),
  ),
  Room: loadable(() =>
    import('./Room' /* webpackChunkName: "admin-rooms-room" */),
  ),
  Settings: loadable(() =>
    import('./Settings' /* webpackChunkName: "admin-settings" */),
  ),
  Tokens: loadable(() =>
    import('./Tokens' /* webpackChunkName: "admin-tokens" */),
  ),
  Categories: loadable(() =>
    import('./Categories' /* webpackChunkName: "admin-categories" */),
  ),
  Category: loadable(() =>
    import('./Category' /* webpackChunkName: "admin-categories-category" */),
  ),
  RoomGroup: loadable(() =>
    import('./RoomGroup' /* webpackChunkName: "admin-categories-group" */),
  ),
  SlugBlacklist: loadable(() =>
    import('./SlugBlacklist' /* webpackChunkName: "admin-slug-blacklist" */),
  ),
}

export default Admin
