import React from 'react'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import ChannelFragment from 'GraphQL/Fragments/Channel.graphql'
import channelParticipantCreatedSubscription from 'GraphQL/Subscriptions/Channels/channelParticipantCreated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateFragment(client, participant) {
  const id = `Channel:${participant?.channel?.id}`

  const fragment = {
    id,
    fragmentName: 'ChannelFields',
    fragment: ChannelFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    client.writeFragment({
      ...fragment,
      data: {
        ...data,
        participants: uniqBy(
          [...(data?.participants || []), participant],
          'id',
        ),
      },
    })
  }
}

function ChannelParticipantSubscription() {
  useSubscription(channelParticipantCreatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const participant = get(subscriptionData, [
        'data',
        'channelParticipantCreated',
      ])

      updateFragment(client, participant)
    },
  })

  return <></>
}

export default ChannelParticipantSubscription
