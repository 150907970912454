import React from 'react'
import PropTypes from 'prop-types'

import numeral from 'numeral'

import { Row, Text } from 'Components/UI'

import { UnreadWrapper } from './styles'

export function UnreadCounter({ unreadCount, absolute, ...rest }) {
  if (unreadCount > 999) {
    return (
      <UnreadWrapper absolutePosition={absolute} {...rest}>
        <Text small white>
          {numeral(unreadCount).format('0 a')}
        </Text>
      </UnreadWrapper>
    )
  }
  if (unreadCount > 99) {
    return (
      <UnreadWrapper absolutePosition={absolute} {...rest}>
        <Text small white>
          99+
        </Text>
      </UnreadWrapper>
    )
  }
  if (unreadCount > 0 && unreadCount <= 99) {
    return (
      <UnreadWrapper absolutePosition={absolute} {...rest}>
        <Text small white>
          {unreadCount}
        </Text>
      </UnreadWrapper>
    )
  }
  return <Row height={18} />
}

UnreadCounter.defaultProps = {
  absolute: false,
  unreadCount: 0,
}

UnreadCounter.propTypes = {
  absolute: PropTypes.bool,
  unreadCount: PropTypes.number,
}
