import React, { useCallback, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import quillEmoji from 'react-quill-emoji'
import PropTypes from 'prop-types'

import noop from 'lodash/noop'

import Text from 'Components/UI/Text'

import { useScopedI18n } from 'Services/I18n'

import { Wrapper } from './styles'

import 'react-quill/dist/quill.snow.css'
import 'react-quill-emoji/dist/quill-emoji.css'

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
      ['emoji'],
    ],
  },
  'emoji-toolbar': true,
}

Quill.register(
  {
    'formats/emoji': quillEmoji.EmojiBlot,
    'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
    'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
  },
  true,
)

function TextEditor({ onChange, value, maxCharactersCount }) {
  const s = useScopedI18n('textEditor')

  const reactQuillRef = useRef()

  const checkCharacterCount = useCallback(
    event => {
      const { unprivilegedEditor } = reactQuillRef?.current
      if (
        unprivilegedEditor?.getLength() > maxCharactersCount &&
        event.key !== 'Backspace'
      )
        event.preventDefault()
    },
    [maxCharactersCount],
  )

  return (
    <Wrapper className="editor_wrapper">
      <ReactQuill
        bounds=".editor_wrapper"
        modules={modules}
        ref={reactQuillRef}
        theme="snow"
        value={value}
        onChange={onChange}
        onKeyDown={maxCharactersCount ? checkCharacterCount : noop}
      />
      {maxCharactersCount && (
        <Text alignTextEnd primary smallest>
          {' '}
          {s('maxCharacters', { count: maxCharactersCount })}
        </Text>
      )}
    </Wrapper>
  )
}

TextEditor.defaultProps = {
  maxCharactersCount: null,
  value: null,
  onChange: noop,
}

TextEditor.propTypes = {
  maxCharactersCount: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
}
export default TextEditor
