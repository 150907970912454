import DayPicker from 'react-day-picker'

import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import 'react-day-picker/lib/style.css'

export const Weekday = styled.div``

export const SingleValuePicker = styled(DayPicker)`
  background-color: ${themeGet('pickers.bg.default')};
  box-shadow: ${themeGet('shadow')};
  border-radius: 16px;
  height: 340px;

  .DayPicker-Caption {
    color: ${themeGet('text.color.primary')};
    font-size: 14px;

    & > div {
      font-weight: 600;
    }
  }

  .DayPicker-NavBar {
    position: absolute;
    top: 14px;
    right: 24px;
  }

  .DayPicker-Weekday {
    color: ${themeGet('pickers.primaryColor.default')};
    font-size: 12px;
    font-weight: 600;
  }

  .DayPicker-Day {
    font-size: 13px;
    color: ${themeGet('pickers.secondaryColor.default')};

    &:hover {
      background-color: ${themeGet('pickers.primaryColor.default')} !important;
      color: ${themeGet('pickers.hover.secondaryColor.default')};
    }

    &--outside {
      color: ${themeGet('pickers.additionalColor.default')};
    }

    &--selected {
      border: none !important;
      outline: none !important;
      background-color: ${themeGet('pickers.primaryColor.default')} !important;
    }
  }
`

export const Picker = styled(DayPicker)`
  background-color: ${themeGet('pickers.bg.default')};
  box-shadow: ${themeGet('shadow')};
  border-radius: 16px;
  height: 340px;

  .DayPicker-Caption {
    color: ${themeGet('text.color.primary')};
    font-size: 14px;

    & > div {
      font-weight: 600;
    }
  }

  .DayPicker-NavBar {
    position: absolute;
    top: 14px;
    right: 24px;
  }

  .DayPicker-Weekday {
    color: ${themeGet('pickers.primaryColor.default')};
    font-size: 12px;
    font-weight: 600;
  }

  .DayPicker-Day {
    font-size: 13px;
    color: ${themeGet('pickers.secondaryColor.default')};

    &:hover {
      background-color: ${themeGet('pickers.primaryColor.default')} !important;
      color: ${themeGet('pickers.hover.secondaryColor.default')};
    }

    &--outside {
      color: ${themeGet('pickers.additionalColor.default')};
    }

    &--start,
    &--end {
      background-color: ${themeGet('pickers.primaryColor.default')} !important;
    }

    &--start:not(.DayPicker-Day--end) {
      border-radius: 50px 0 0 50px;
    }

    &--end:not(.DayPicker-Day--start) {
      border-radius: 0 50px 50px 0;
    }

    &--selected {
      border: none !important;
      outline: none !important;
    }

    &--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
      border-radius: 0 !important;
      background-color: ${themeGet(
        'pickers.selectionColor.default',
      )} !important;
      color: ${themeGet('pickers.secondaryColor.default')} !important;
    }
  }
`
