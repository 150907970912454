import React, { useMemo } from 'react'
import ReactSelect from 'react-select'
import ReactSelectAsync from 'react-select/async'
import ReactSelectAsyncCreatable from 'react-select/async-creatable'
import ReactSelectCreatable from 'react-select/creatable'
import PropTypes, { bool } from 'prop-types'

import styled, { useTheme } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'

import * as Components from './Components'

function getSelectType({ async, creatable }) {
  if (async && creatable) {
    return ReactSelectAsyncCreatable
  }
  if (async) {
    return ReactSelectAsync
  }
  if (creatable) {
    return ReactSelectCreatable
  }

  return ReactSelect
}

const StyledSelect = styled.div.attrs(({ async, creatable }) => ({
  as: getSelectType({ async, creatable }),
}))`
  min-height: ${mapToTheme('spacing.controlHeight')}px;
  font-size: 14px;

  ${margin}
  ${layout.width}
`

StyledSelect.defaultProps = {
  className: 'react-select-container',
  classNamePrefix: 'react-select',
}

StyledSelect.propTypes = {
  margin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

const THEME = theme => {
  const { negative, primary } = theme?.colors || {}

  return {
    borderRadius: 14,
    colors: {
      danger: negative,
      dangerLight: `${negative}80`,
      neutral0: theme?.colors?.bg?.primary,
      neutral5: 'hsl(0, 0%, 95%)',
      neutral10: 'hsl(0, 0%, 90%)',
      neutral20: 'hsl(0, 0%, 80%)',
      neutral30: 'hsl(0, 0%, 70%)',
      neutral40: 'hsl(0, 0%, 60%)',
      neutral50: 'hsl(0, 0%, 50%)',
      neutral60: 'hsl(0, 0%, 40%)',
      neutral70: 'hsl(0, 0%, 30%)',
      neutral80: 'hsl(0, 0%, 20%)',
      neutral90: 'hsl(0, 0%, 10%)',
      primary,
      primary25: `${primary}40`,
      primary50: `${primary}80`,
      primary75: `${primary}C0`,
    },
    spacing: {
      baseUnit: 2,
      controlHeight: theme?.inputs?.height,
      menuGutter: 4,
    },
  }
}

function Select({
  async,
  components,
  creatable,
  helper,
  withPortal,
  monthPicker,
  timePicker,
  alternateStyle,
  ...rest
}) {
  const theme = useTheme()

  const selectTheme = useMemo(() => THEME(theme), [theme])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const secondaryStyle = {
    control: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme?.colors?.bg?.default,
      borderRadius: '5px',
      border: `1px solid ${theme?.colors?.secondary}`,
      padding: theme?.space[2],
      cursor: 'pointer',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      svg: {
        stroke: theme?.text?.color?.default,
      },
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '14px',
    }),
    valueContainer: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '14px',
      padding: '0 !important',
      marginLeft: theme?.space[2],
    }),
    singleValue: provided => ({
      ...provided,
      color: theme?.text?.color?.default,
    }),
    menu: provided => ({
      ...provided,
      margin: 0,
      borderRadius: '5px',
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: '5px',
      scrollbarWidth: 'thin',
      backgroundColor: theme?.colors?.bg?.default,
      scrollbarColor: `${theme?.colors?.border?.customDefault} ${theme?.colors?.bg?.secondary}`,
      '::-webkit-scrollbar': {
        width: '2px',
        height: '100%',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.colors?.border?.customDefault,
      },
    }),
    option: (provided, state) => {
      const selected = state?.getValue()
      return {
        ...provided,
        color:
          selected[0]?.label === state?.data?.label
            ? theme?.text?.color?.custom
            : theme?.text?.color?.default,
        fontFamily: theme?.font,
        fontSize: '14px',
        backgroundColor: 'inherit',
        ':hover': {
          backgroundColor: theme?.colors?.bg?.customSecondary,
          color: theme?.text?.color?.custom,
        },
      }
    },
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const monthPickerStyle = {
    control: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme?.colors?.border?.customDefault}`,
      borderRadius: '5px',
      padding: theme?.space[2],
      cursor: 'pointer',
      svg: {
        width: '22px',
        height: '22px',
        stroke: theme?.colors?.border?.customDefault,
      },
      ':hover': {
        backgroundColor: theme?.colors?.bg?.customSecondary,
        border: `1px solid ${theme?.colors?.border?.customDefault}`,
      },
      ':active': {
        backgroundColor: theme?.colors?.bg?.customDefault,
        border: `1px solid ${theme?.colors?.border?.customDefault}`,
        svg: {
          stroke: theme?.colors?.white,
        },
      },
    }),
    dropdownIndicator: provided => ({
      ...provided,
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '14px',
    }),
    valueContainer: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '14px',
      padding: '0 !important',
      marginLeft: theme?.space[2],
    }),
    singleValue: provided => ({
      ...provided,
      color: theme?.text?.color?.default,
    }),
    menu: provided => ({
      ...provided,
      margin: 0,
      borderRadius: '5px',
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: '5px',
      scrollbarWidth: 'thin',
      backgroundColor: theme?.colors?.bg?.default,
      scrollbarColor: `${theme?.colors?.border?.customDefault} ${theme?.colors?.bg?.secondary}`,
      '::-webkit-scrollbar': {
        width: '2px',
        height: '100%',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.colors?.border?.customDefault,
      },
    }),
    option: (provided, state) => {
      const selected = state?.getValue()
      return {
        ...provided,
        color:
          selected[0]?.label === state?.data?.label
            ? theme?.text?.color?.custom
            : theme?.text?.color?.default,
        fontFamily: theme?.font,
        fontSize: '14px',
        backgroundColor: 'inherit',
        ':hover': {
          backgroundColor: theme?.colors?.bg?.customSecondary,
          color: theme?.text?.color?.custom,
        },
      }
    },
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timePickerStyle = {
    control: provided => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: `1px solid ${theme?.colors?.border?.default}`,
      borderRadius: '5px',
      padding: theme?.space[2],
      cursor: 'pointer',
    }),
    dropdownIndicator: provided => ({
      ...provided,
      svg: {
        stroke: theme?.text?.color?.default,
      },
    }),
    placeholder: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '12px',
      color: theme?.text?.color?.default,
    }),
    valueContainer: provided => ({
      ...provided,
      fontFamily: theme?.font,
      fontSize: '12px',
      padding: '0 !important',
      marginLeft: theme?.space[2],
    }),
    singleValue: provided => ({
      ...provided,
      color: theme?.text?.color?.default,
    }),
    menu: provided => ({
      ...provided,
      margin: '0 0 4px 0',
      borderRadius: '5px',
      top: 'auto',
      bottom: '100%',
    }),
    menuList: provided => ({
      ...provided,
      borderRadius: '5px',
      height: '250px',
      scrollbarWidth: 'thin',
      backgroundColor: theme?.colors?.bg?.default,
      scrollbarColor: `${theme?.colors?.border?.customDefault} ${theme?.colors?.bg?.secondary}`,
      '::-webkit-scrollbar': {
        width: '2px',
        height: '100%',
      },
      '::-webkit-scrollbar-thumb': {
        backgroundColor: theme?.colors?.border?.customDefault,
      },
    }),
    option: (provided, state) => {
      const selected = state?.getValue()
      return {
        ...provided,
        color:
          selected[0]?.label === state?.data?.label
            ? theme?.text?.color?.custom
            : theme?.text?.color?.default,
        fontFamily: theme?.font,
        fontSize: '14px',
        backgroundColor: 'inherit',
        ':hover': {
          backgroundColor: theme?.colors?.bg?.customSecondary,
          color: theme?.text?.color?.custom,
        },
      }
    },
  }

  const styles = useMemo(() => {
    if (monthPicker) return monthPickerStyle
    if (timePicker) return timePickerStyle
    if (alternateStyle) return secondaryStyle
    return {}
  }, [
    alternateStyle,
    monthPicker,
    monthPickerStyle,
    secondaryStyle,
    timePicker,
    timePickerStyle,
  ])

  return (
    <StyledSelect
      {...rest}
      async={async}
      components={{ ...Components, ...components }}
      creatable={creatable}
      menuPortalTarget={withPortal ? document.body : undefined}
      monthPicker={monthPicker}
      styles={
        styles
        // withPortal
        //   ? {
        //       menuPortal: base => ({
        //         ...base,
        //         zIndex: 9999,
        //       }),
        //     }
        //   : {}
      }
      theme={selectTheme}
      timePicker={timePicker}
    />
  )
}

Select.defaultProps = {
  alternateStyle: false,
  async: false,
  components: null,
  creatable: false,
  helper: null,
  monthPicker: false,
  small: false,
  timePicker: false,
  width: 1,
  withPortal: false,
}

Select.propTypes = {
  alternateStyle: bool,
  async: PropTypes.bool,
  components: PropTypes.object,
  creatable: PropTypes.bool,
  helper: PropTypes.node,
  monthPicker: PropTypes.bool,
  small: PropTypes.bool,
  timePicker: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  withPortal: PropTypes.bool,
}

export default Select
