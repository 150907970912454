import React from 'react'
import { components } from 'react-select'

import { InputWrapper } from './styles'

const Input = props => {
  const { isHidden } = props

  if (isHidden) {
    return <components.Input {...props} />
  }

  return <InputWrapper as={components.Input} {...props} />
}

Input.propTypes = {
  ...components.Input.propTypes,
}

export default Input
