import styled, { css } from 'styled-components'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import OnlineStatusGlyph from 'Assets/Svg/onlineStatus.svg'

import { flexCenter } from 'Components/Styles'
import { Text } from 'Components/UI'

export const Wrapper = styled.div`
  ${flexCenter};
  position: relative;
  border-radius: 50%;
  background-color: ${themeGet('colors.noChangeSecondary')};
  cursor: pointer;
  color: ${themeGet('colors.primary')};
  flex-shrink: 0;

  ${layout.size}
  ${margin}
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
`

// TODO: temporary properties until comments area re-designed also
const altPlacementCss = ({ altPlacement }) =>
  altPlacement &&
  css`
    top: -6px;
    left: 20px;
  `

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border-radius: 50%;

  ${altPlacementCss}
`

export const Empty = styled.div`
  ${flexCenter};
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid ${themeGet('colors.noChangeSecondary')};
  background-color: ${themeGet('colors.noChangeSecondary')};
`

const TenCss = ({ topTen }) =>
  topTen &&
  css`
    font-size: 7px;
    top: 4px;
    left: 4px;
  `

export const UserRank = styled(Text)`
  position: absolute;
  font-size: 8px;
  top: 3px;
  left: 6px;

  ${TenCss}
`

export const OnlineIcon = styled(OnlineStatusGlyph)`
  position: absolute;
  bottom: 0;
  right: 0;

  & circle {
    stroke: ${themeGet('colors.bg.primary')};
  }
`
