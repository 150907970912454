const palette = {
  black: '#000000',
  blackSecondary: '#191919',

  white: '#ffffff',

  primaryDark: '#14171a',
  primary: '#657786',
  primaryLight: '#826ed8',

  negative: '#c0c7cd',

  secondaryDark: '#eee6f0',
  secondary: '#e6ecf0',
  secondaryLight: '#f5f8fa',

  violetDarkest: '#b04293',
  violetDark: '#b7549d',
  violet: '#c068a9',
  violetLight: '#c77ab3',
  violetLightest: '#dfb3d4',
  violetExtra: '#bd66a8',

  blueDarkest: '#0591e6',
  blueDark: '#1e9ce8',
  blue: '#37a7e8',
  blueLight: '#50b2ed',
  blueLightest: '#9bd3f5',

  gray: '#f4f7f9',
  grayLight: '#a5afb7',

  greenDark: '#009345',
  green: '#05e67d',
  greenLight: '#77c863',

  gold: '#e6bd05',

  warning: '#e63d05',
  warningDark: '#9c2b06',

  guest: '#e6ecf0',
  zen: '#6b7c8a',
  solo: '#ae5433',
  pro: '#866e5e',
  apex: '#647867',

  brightBlue: '#00f8f8',

  palettes: {
    pink: '#ce24a2',
    blue: '#358ac4',
    orange: '#eba22f',
    purple: '#7d2ac5',
    green: '#66de65',
    denim: '#358ac4',
    yellow: '#ffd400',
  },
}

export default palette
