import React from 'react'
import { components } from 'react-select'

import { FiCalendar } from 'react-icons/fi'

import get from 'lodash/get'

import { ControlWrapper } from './styles'

const Control = ({ children, ...props }) => (
  <ControlWrapper
    as={components.Control}
    {...props}
    small={get(props, ['selectProps', 'small'])}
  >
    {props?.selectProps?.monthPicker ? (
      <>
        <FiCalendar strokeWidth={1} />
        {children}
      </>
    ) : (
      children
    )}
  </ControlWrapper>
)

Control.propTypes = {
  ...components.Control.propTypes,
}

export default Control
