import React from 'react'
import PropTypes from 'prop-types'

import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

import { Weekday } from './styles'

import Button from '../Button'
import { Row } from '../Flex'

export function Navbar({ onPreviousClick, onNextClick, className }) {
  return (
    <Row className={className}>
      <Button arrows icon mr={9} small onClick={() => onPreviousClick()}>
        <FiChevronLeft strokeWidth={2} />
      </Button>
      <Button arrows icon small onClick={() => onNextClick()}>
        <FiChevronRight strokeWidth={2} />
      </Button>
    </Row>
  )
}

Navbar.defaultProps = {
  className: '',
  onNextClick: null,
  onPreviousClick: null,
}

Navbar.propTypes = {
  className: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
}

export function Weekdays({ weekday, className, localeUtils, locale }) {
  const weekdayName = localeUtils.formatWeekdayLong(weekday, locale)
  return (
    <Weekday className={className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </Weekday>
  )
}

Weekdays.defaultProps = {
  className: '',
  locale: '',
  localeUtils: null,
  weekday: null,
}

Weekdays.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
  localeUtils: PropTypes.object,
  weekday: PropTypes.number,
}
