import loadable from '@loadable/component'

const Pages = {
  Content: loadable(() =>
    import('./Posts' /* webpackChunkName: "room-content" */),
  ),
  Events: loadable(() =>
    import('./Events' /* webpackChunkName: "room-events" */),
  ),
  Store: loadable(() => import('./Store' /* webpackChunkName: "room-store" */)),
}

export default Pages
