import customPalettes from './customPalettes'
import palette from './palette'

const text = color => ({
  fontSize: {
    bigger: 40,
    big: 24,
    middle: 16,
    small: 12,
    smallest: 9,
    micro: 6,
    default: 14,
  },
  fontWeight: {
    bolder: 900,
    bold: 600,
    medium: 500,
    light: 300,
    default: 'normal',
  },
  color: {
    default: palette.white,
    primary: palette.primary,
    custom: customPalettes[color]?.primary || color,
    blueDarkest: palette.blueDarkest,
    blueDark: palette.blueDark,
    blue: palette.blue,
    blueLight: palette.blueLight,
    blueLightest: palette.blueLightest,
    violetDarkest: palette.violetDarkest,
    violetDark: palette.violetDark,
    violet: palette.violet,
    violetLight: palette.violetLight,
    violetLightest: palette.violetLightest,
    white: palette.white,
    warning: palette.warning,
    gold: palette.gold,
    grayLight: palette.grayLight,
    greenDark: palette.greenDark,
    green: palette.green,
    greenLight: palette.greenLight,
    blackExtra: palette.blackExtra,
    brightBlue: palette.brightBlue,
  },
})

export default text
