import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import { Row } from 'Components/UI'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const CustomScheduleRow = styled(Row)`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const RadioButtonsWrapper = styled.div`
  display: flex;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    margin-bottom: 10px;
  }
`

export const DailyWrapper = styled.div`
  display: flex;
  width: 100%;
`
