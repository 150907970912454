import React from 'react'
import { components } from 'react-select'

import { Item } from './styles'

const Option = props => <Item as={components.Option} {...props} />

Option.propTypes = {
  ...components.Option.propTypes,
}

export default Option
