import { hexToRgba } from 'Utils/Color'

import buttons from './buttons'
import customPalettes from './customPalettes'
import inputs from './inputs'
import memberships from './memberships'
import palette from './palette'
import pickers from './pickers'
import selects from './selects'
import switches from './switches'
import text from './text'

import defaultTheme from '../defaultTheme'

const theme = color => ({
  ...defaultTheme,

  name: 'Main theme',
  font: '"Plus Jakarta Sans", Arial, Helvetica, sans-serif;',
  webfonts: ['Roboto:400,600,700,900'],

  transitionTime: {
    long: '400ms',
    default: '250ms',
    modal: '150ms',
  },

  colors: {
    customDefault: customPalettes[color]?.primary || color,
    customSecondary: customPalettes[color]?.secondary || hexToRgba(color, 0.5),
    customDark: customPalettes[color]?.dark || color,
    customMedium: customPalettes[color]?.medium || color,
    customLight: customPalettes[color]?.light || hexToRgba(color, 0.2),
    black: palette.black,
    blackSecondary: palette.blackSecondary,
    white: palette.white,
    primaryDark: palette.primaryDark,
    primary: palette.primary,
    primaryLight: palette.primaryLight,
    secondaryDark: palette.secondaryDark,
    secondary: palette.secondary,
    secondaryLight: palette.secondaryLight,
    violetDarkest: palette.violetDarkest,
    violetDark: palette.violetDark,
    violet: palette.violet,
    violetLight: palette.violetLight,
    violetLightest: palette.violetLightest,
    blueDarkest: palette.blueDarkest,
    blueDark: palette.blueDark,
    blue: palette.blue,
    blueLight: palette.blueLight,
    blueLightest: palette.blueLightest,
    green: palette.green,
    greenDark: palette.greenDark,
    gold: palette.gold,
    negative: palette.negative,
    warning: palette.warning,
    noChange: palette.secondaryDark,
    noChangeSecondary: palette.secondary,
    brightBlue: palette.brightBlue,

    bg: {
      default: palette.white,
      customDefault: customPalettes[color]?.primary || color,
      customSecondary:
        customPalettes[color]?.secondary || hexToRgba(color, 0.5),
      customDark: customPalettes[color]?.dark || color,
      customMedium: customPalettes[color]?.medium || hexToRgba(color, 0.8),
      black: palette.black,
      dark: palette.blackSecondary,
      light: palette.secondaryDark,
      lightest: palette.secondaryLight,
      warning: palette.warning,
      primary: palette.white,
      secondary: palette.secondaryLight,
      third: palette.secondaryDark,
      extra: palette.primaryDark,
      audio: palette.secondaryLight,
      public: palette.secondaryLight,
      post: palette.gray,
      neutral: palette.secondary,
    },

    border: {
      default: palette.secondary,
      customDefault: customPalettes[color]?.primary || color,
      customSecondary:
        customPalettes[color]?.secondary || hexToRgba(color, 0.5),
      customDark: customPalettes[color]?.dark || color,
      customMedium: customPalettes[color]?.medium || hexToRgba(color, 0.8),
      primary: palette.primary,
      dark: palette.secondaryDark,
      warning: palette.warning,
      secondary: palette.secondaryLight,
      third: palette.secondaryDark,
      separator: palette.secondaryLight,
      white: palette.white,
      black: palette.primaryDark,
    },

    font: {
      default: palette.primary,
      disabled: palette.primaryLight,
      secondary: palette.primaryDark,
      disabledBox: palette.primaryLight,
    },
    palettes: {
      pink: palette.palettes.pink,
      blue: palette.palettes.blue,
      orange: palette.palettes.orange,
      purple: palette.palettes.purple,
      green: palette.palettes.green,
      denim: palette.palettes.denim,
      yellow: palette.palettes.yellow,
    },
  },

  buttons: buttons(color),
  pickers: pickers(color),
  text: text(color),
  selects,
  switches,
  inputs,
  memberships,
})

export default theme
