import apiCall from 'Services/Api'

export function uploadFile({ file, type, entityId, onProgress }) {
  return apiCall({
    endpoint: '/private/uploadFile',
    method: 'POST',
    query: { type, entityId },
    file,
    onProgress,
  })
}

export function uploadNFTFile({ file, type, entityId, standard, onProgress }) {
  return apiCall({
    endpoint: '/private/uploadFile',
    method: 'POST',
    query: { type, entityId, standard },
    file,
    onProgress,
  })
}

export function uploadLogo({ file, type, onProgress }) {
  return apiCall({
    endpoint: '/private/uploadFile',
    method: 'POST',
    query: { type },
    file,
    onProgress,
  })
}
