import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import ArrowLeftGlyph from 'Assets/Svg/arrowLeft.svg'
import LinkedInGlyph from 'Assets/Svg/linkedin.svg'
import TwitterGlyph from 'Assets/Svg/twitter.svg'

import { Column, Row } from 'Components/UI'

export const Container = styled.div.attrs({
  as: 'section',
})`
  display: flex;
  width: 100vw;
  height: 100%;
  background-color: ${themeGet('colors.bg.default')};
  overflow-y: auto;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    flex-direction: column;
  }
`

const backgroundContainerBlackCss = ({ black }) =>
  black &&
  css`
    background-color: ${themeGet('colors.blackSecondary')};

    @media (max-width: ${themeGet('breakpoints.1')}) {
      height: 70%;
    }
  `

const backgroundContainerWhiteCss = ({ white }) =>
  white &&
  css`
    background-color: ${themeGet('colors.white')};

    @media (max-width: ${themeGet('breakpoints.1')}) {
      background-color: ${themeGet('colors.blackSecondary')};
    }
  `

export const BackgroundContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: 100%;
  }

  ${backgroundContainerBlackCss}
  ${backgroundContainerWhiteCss}
`

export const PrimaryIntroText = styled(Column)`
  & > p:nth-child(1) {
    font-weight: 900;
  }

  @media (max-width: ${themeGet('breakpoints.1')}) {
    & > p:nth-child(1) {
      font-weight: 600;
    }

    & > p:nth-child(2) {
      display: none;
    }
  }
`

export const Links = styled(Row)`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    display: none;
  }
`

export const SecondaryIntroText = styled(Column)`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    & > p {
      display: none;
    }
  }
`

export const ResponsiveWrapper = styled.div`
  @media (max-width: ${themeGet('breakpoints.1')}) {
    display: none;
  }
`

export const TwitterIcon = styled(TwitterGlyph)`
  ${margin}
`

export const LinkedInIcon = styled(LinkedInGlyph)`
  ${margin}
`

export const GoBackIcon = styled(ArrowLeftGlyph)`
  ${margin}
`

export const ExtrernalLink = styled.a``

export const Content = styled.div`
  display: flex;
  z-index: 1;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-height: 580px;
  padding: ${themeGet('space.4')}px ${themeGet('space.6')}px;
  background-color: ${themeGet('colors.white')};
  border-radius: 24px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    background-color: ${themeGet('colors.blackSecondary')};
    padding-top: 0;
    min-height: 0;
    height: 160px;
    border-radius: 0;
  }
`
