import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { Loader } from 'Components/UI'

import { useMaintenance } from 'Hooks'

import Maintenance from 'Pages/Maintenance'

import { Container } from './styles'

function Root({ route }) {
  const [maintenance, maintenanceLoading] = useMaintenance()

  if (maintenanceLoading) return <Loader />
  if (maintenance) return <Maintenance />

  return <Container>{renderRoutes(route.routes)}</Container>
}

Root.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Root
