import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const squeezeCss = ({ squeeze }) =>
  squeeze &&
  css`
    max-width: 300px;
  `

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  background-color: ${themeGet('colors.bg.default')};
  border: none;
  outline: none;
  border-radius: 16px;
  padding: ${themeGet('space.5')}px;

  ${squeezeCss}
`
