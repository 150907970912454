import React from 'react'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'
import uniqBy from 'lodash/uniqBy'

import { CHANNEL_COUNTER_KIND } from 'Constants/ids'

import CategorizedChannelsFragment from 'GraphQL/Fragments/CategorizedChannels.graphql'
import ChannelFragment from 'GraphQL/Fragments/Channel.graphql'
import categorizedChannelsQuery from 'GraphQL/Queries/Channels/categorizedChannels.graphql'
import channelCountUpdatedSubscription from 'GraphQL/Subscriptions/Channels/channelCountUpdated.graphql'
import channelCreatedSubscription from 'GraphQL/Subscriptions/Channels/channelCreated.graphql'
import channelDeletedSubscription from 'GraphQL/Subscriptions/Channels/channelDeleted.graphql'
import channelUnlockedSubscription from 'GraphQL/Subscriptions/Channels/channelUnlocked.graphql'
import channelUpdatedSubscription from 'GraphQL/Subscriptions/Channels/channelUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery({ client, channel, channelCategoriesVariables }) {
  const channelCategoriesOptions = {
    query: categorizedChannelsQuery,
    variables: {
      ...channelCategoriesVariables,
    },
  }

  const data = client.readQuery(channelCategoriesOptions)

  if (data && channelCategoriesVariables?.roomId === channel?.roomId) {
    client.writeQuery({
      ...channelCategoriesOptions,
      data: {
        ...data,
        categorizedChannels: map(data?.categorizedChannels, category => {
          if (channel?.categoryId !== category?.id) {
            return {
              ...category,
              channels: filter(
                category?.channels,
                sourceChannel => sourceChannel?.id !== channel?.id,
              ),
            }
          }
          if (channel?.categoryId === category?.id) {
            return {
              ...category,
              channels: uniqBy([...(category?.channels || []), channel], 'id'),
            }
          }
          return { ...category }
        }),
      },
    })
  }
}

function updateCategoryFragment({ client, channel, remove }) {
  const id = `Channel:${channel?.id}`

  const fragment = {
    id: `CategorizedChannels:${channel?.categoryId}`,
    fragmentName: 'CategorizedChannelsFields',
    fragment: CategorizedChannelsFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    if (remove) {
      client.cache.evict({ id })
      client.cache.gc()
    } else {
      client.writeFragment({
        ...fragment,
        data: {
          ...data,
          channels: uniqBy([...(data?.channels || []), channel], 'id'),
        },
      })
    }
  }
}

function updateChannelFragment({ client, channel, update, count }) {
  const id = !count ? `Channel:${channel?.id}` : `Channel:${count?.channelId}`

  const fragment = {
    id,
    fragmentName: 'ChannelFields',
    fragment: ChannelFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    if (count && data?.id === count?.channelId) {
      switch (count?.kind) {
        case CHANNEL_COUNTER_KIND.UNREAD_COUNT:
          client.writeFragment({
            ...fragment,
            data: {
              ...data,
              unreadCount: count?.value,
            },
          })
          break
        case CHANNEL_COUNTER_KIND.PINNED_MESSAGES_COUNT:
          client.writeFragment({
            ...fragment,
            data: {
              ...data,
              pinnedMessagesCount: count?.value,
            },
          })
          break
        default:
          client.writeFragment({
            ...fragment,
            data: { ...data },
          })
      }
    }
    if (update) {
      client.writeFragment({
        ...fragment,
        data: { ...data, ...channel },
      })
    }
  }
}

function ChannelsSubscription({ channelCategoriesVariables }) {
  useSubscription(channelCreatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channel = get(subscriptionData, ['data', 'channelCreated'])

      updateCategoryFragment({ client, channel })
    },
  })

  useSubscription(channelDeletedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channel = get(subscriptionData, ['data', 'channelDeleted'])

      updateCategoryFragment({ client, channel, remove: true })
    },
  })

  useSubscription(channelUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channel = get(subscriptionData, ['data', 'channelUpdated'])

      updateChannelFragment({ client, channel, update: true })
      updateQuery({ client, channel, channelCategoriesVariables })
    },
  })

  useSubscription(channelUnlockedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const channel = get(subscriptionData, ['data', 'channelUnlocked'])

      updateChannelFragment({ client, channel, update: true })
    },
  })

  useSubscription(channelCountUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const count = get(subscriptionData, ['data', 'channelCountUpdated'])

      updateChannelFragment({ client, count })
    },
  })

  return <></>
}

ChannelsSubscription.defaultProps = {
  channelCategoriesVariables: null,
}

ChannelsSubscription.propTypes = {
  channelCategoriesVariables: PropTypes.object,
}

export default ChannelsSubscription
