import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Link, Modal, Row, Text } from 'Components/UI'

import { APP_PROFILE } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

import { Content, UsersFrame } from './styles'

function BouncersModal({
  eventBouncerIds,
  onBack,
  roomBouncers,
  onSelectEventBouncers,
  ...rest
}) {
  const s = useScopedI18n('user.modal.events')

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      handleBack={onBack}
      noCloseButton
      shouldCloseOnOverlayClick={false}
      title={s('fields.verifiers')}
      withBackButton
      onMount={handleMount}
    >
      <Content mt={3}>
        {map(roomBouncers, roomBouncer => (
          <UsersFrame mt={1}>
            <Row center>
              <Column mr={2}>
                <Link clean to={APP_PROFILE(roomBouncer?.id)}>
                  <Avatar
                    online={roomBouncer?.online}
                    size={40}
                    src={roomBouncer?.profile?.photoUrl}
                    username={roomBouncer?.username}
                  />
                </Link>
              </Column>
              <Column>
                <Text bold mb={1}>
                  @{roomBouncer?.username || '--'}
                </Text>
                <Text primary small>
                  {s('fields.memberSince')}{' '}
                  {DateTime.fromISO(roomBouncer?.createdAt, {
                    setZone: true,
                  }).toLocaleString(DateTime.DATE_SHORT)}
                </Text>
              </Column>
            </Row>
            <Button
              outline={!eventBouncerIds.has(roomBouncer?.id)}
              width={90}
              onClick={() => onSelectEventBouncers(roomBouncer?.id)}
            >
              {eventBouncerIds.has(roomBouncer?.id)
                ? s('actions.unselect')
                : s('actions.select')}
            </Button>
          </UsersFrame>
        ))}
      </Content>
    </Modal>
  )
}

BouncersModal.defaultProps = {
  eventBouncerIds: null,
  roomBouncers: null,
  onBack: noop,
  onSelectEventBouncers: noop,
}

BouncersModal.propTypes = {
  eventBouncerIds: PropTypes.array,
  roomBouncers: PropTypes.array,
  onBack: PropTypes.func,
  onSelectEventBouncers: PropTypes.func,
}

export default BouncersModal
