import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { BiPause, BiPlay } from 'react-icons/bi'

import getBlobDuration from 'get-blob-duration'

import { secondsToDuration } from 'Utils/Date'

import { AudioTrack, Container, ProgressBar, RecordPlayer } from './styles'

import Button from '../Button'
import { Column, Row } from '../Flex'
import Text from '../Text'

function AudioPlayer({ height, width, src }) {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)

  const audioRef = useRef()
  const progressBar = useRef()
  const animationRef = useRef()

  useEffect(() => {
    async function checkDuration() {
      const seconds = (await getBlobDuration(src)) * 1000
      setDuration(seconds)
      progressBar.current.max = seconds
    }

    checkDuration()
  }, [src])

  const handlePlayerCurrentTime = useCallback(() => {
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(progressBar?.current?.value / duration) * 100}%`,
    )
    setCurrentTime(progressBar?.current?.value / 1000)
  }, [duration])

  const playing = useCallback(() => {
    if (progressBar.current) {
      progressBar.current.value = audioRef?.current?.currentTime * 1000
      handlePlayerCurrentTime()
      animationRef.current = requestAnimationFrame(playing)
    }
  }, [handlePlayerCurrentTime])

  const handlePlay = useCallback(() => {
    const prevValue = isPlaying
    setIsPlaying(!prevValue)
    if (!prevValue) {
      audioRef?.current?.play()
      animationRef.current = requestAnimationFrame(playing)
    } else {
      audioRef?.current?.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }, [isPlaying, playing])

  const changeProgress = useCallback(() => {
    audioRef.current.currentTime = progressBar.current.value / 1000 || 0
    handlePlayerCurrentTime()
  }, [handlePlayerCurrentTime])

  return (
    <Container height={height} width={width}>
      <RecordPlayer ref={audioRef} src={src} onEnded={handlePlay}>
        <AudioTrack kind="captions" />
      </RecordPlayer>
      <Button
        height={`calc(${height - 16}px)`}
        icon
        width={`calc(${height - 16}px)`}
        onClick={handlePlay}
      >
        {isPlaying ? <BiPause /> : <BiPlay />}
      </Button>
      <Column height={`calc(${height - 16}px)`} ml={16} spaceBetween width={1}>
        <Row justifyEnd>
          <Text primary>
            {isPlaying && `${secondsToDuration(currentTime)} / `}
            {duration && !isNaN(duration)
              ? secondsToDuration(duration / 1000)
              : '00:00'}
          </Text>
        </Row>
        <Row mb={2} style={{ borderRadius: 4, overflow: 'hidden' }}>
          <ProgressBar
            defaultValue="0"
            ref={progressBar}
            onChange={changeProgress}
          />
        </Row>
      </Column>
    </Container>
  )
}

AudioPlayer.defaultProps = {
  height: 56,
  src: '',
  width: 300,
}

AudioPlayer.propTypes = {
  height: PropTypes.number,
  src: PropTypes.string,
  width: PropTypes.number,
}

export default AudioPlayer
