import React from 'react'

import { Column, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { BackgroundContainer } from './styles'

function Maintenance() {
  const s = useScopedI18n('public.maintenance')
  return (
    <BackgroundContainer>
      <Column center width={1}>
        <Text bigger bold white>
          {s('title')}
        </Text>
        <Text big mt={24} primary>
          {s('subtitle')}
        </Text>
      </Column>
    </BackgroundContainer>
  )
}

export default Maintenance
