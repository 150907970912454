import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { FiSliders } from 'react-icons/fi'

import get from 'lodash/get'
import map from 'lodash/map'

import { Dialog } from 'Components/Blocks/Modals'
import {
  Button,
  Column,
  Dropdown,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'
import { Switch } from 'Components/UI/Switch'

import deleteNotificationRuleMutation from 'GraphQL/Mutations/Notifications/deleteNotificationRule.graphql'
import disableNotificationRuleMutation from 'GraphQL/Mutations/Notifications/disableNotificationRule.graphql'
import enableNotificationRuleMutation from 'GraphQL/Mutations/Notifications/enableNotificationRule.graphql'
import notificationRulesQuery from 'GraphQL/Queries/Notifications/notificationRules.graphql'
import { updateNotificationRulesQueryUpdater } from 'GraphQL/Updaters/notifications'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import {
  Content,
  FirefoxPaginationAnchor,
  NotificationRule,
  ScrollingContainer,
} from './styles'

const LOAD_COUNT = 10

function NotificationRulesModal({ handleCreate, ...rest }) {
  const s = useScopedI18n('user.notificationRules')
  const [page, setPage] = useState(0)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [deleteModal, setDeleteModal] = useState({
    isOpen: false,
    entity: null,
  })

  const [deleteNotificationRule] = useMutation(deleteNotificationRuleMutation)

  const [enableNotificationRule] = useMutation(enableNotificationRuleMutation)
  const [disableNotificationRule] = useMutation(disableNotificationRuleMutation)

  const [
    loadRules,
    { data, loading, refetch, fetchMore, variables: rulesVariables },
  ] = useLazyQuery(notificationRulesQuery)

  const notificationRules = useMemo(
    () => data?.notificationRules ?? {},
    [data?.notificationRules],
  )

  const { rows, pages } = notificationRules

  const fetchData = useCallback(() => {
    loadRules({ variables: { page: 0, limit: LOAD_COUNT } })
  }, [loadRules])

  const handleLoadMore = useCallback(async () => {
    if (page < pages - 1) {
      await fetchMore({
        variables: { page: page + 1, limit: LOAD_COUNT },
        updateQuery: (prev, fetchMoreResult) =>
          updateNotificationRulesQueryUpdater(prev, fetchMoreResult),
      })

      setPage(page + 1)
    }
  }, [page, pages, fetchMore])

  const handleDeleteRuleModal = useCallback(entity => {
    setDeleteModal({ isOpen: true, entity })
  }, [])
  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModal({ isOpen: false, entity: null })
  }, [])

  const filters = useCallback(
    (rooms, users) => {
      if (rooms?.length > 0 && users?.length > 0) {
        return (
          <Text primary small>
            {s('filters')} &bull; 2
          </Text>
        )
      }
      if (
        (rooms?.length > 0 && users?.length === 0) ||
        (rooms?.length === 0 && users?.length > 0)
      ) {
        return (
          <Text primary small>
            {s('filters')} &bull; 1
          </Text>
        )
      }
      return null
    },
    [s],
  )

  const schedule = useCallback(
    (scheduleType, scheduleDailyConfig) => {
      if (scheduleType === 'live') {
        return (
          <Row>
            <Text primary small>
              {s('schedule')} &bull;
            </Text>
            <Text lowercase ml={1} primary small>
              {scheduleType}
            </Text>
          </Row>
        )
      }
      if (scheduleType === 'daily') {
        return (
          <Row>
            <Text primary small>
              {s('schedule')} &bull;
            </Text>
            <Text lowercase ml={1} primary small>
              {scheduleType},
            </Text>
            <Text ml={1} primary small>
              {scheduleDailyConfig?.time?.slice(0, 5)},{' '}
              {scheduleDailyConfig?.timezone}
            </Text>
          </Row>
        )
      }
      return null
    },
    [s],
  )

  const handleNotificationRuleStatus = useCallback(
    async (enabled, id) => {
      try {
        if (enabled) {
          await disableNotificationRule({ variables: { id } })

          toast.success({
            title: 'Notification Rule',
            text: 'Notification Rule successfully disabled',
          })
          refetch()
        } else {
          await enableNotificationRule({ variables: { id } })

          toast.success({
            title: 'Notification Rule',
            text: 'Notification Rule successfully enabled',
          })
          refetch()
        }
      } catch (error) {
        toast.error({
          title: 'Notification Rule',
          text: error?.message,
        })
      }
    },
    [disableNotificationRule, enableNotificationRule, refetch],
  )

  const entityIdToDelete = deleteModal.entity
  const handleDeleteRule = useCallback(
    async success => {
      if (!success) {
        return
      }

      try {
        await deleteNotificationRule({
          variables: { id: entityIdToDelete },
        })

        toast.success({
          title: 'Delete Notification Rule',
          text: 'Notification rule successfully deleted',
        })

        refetch()
      } catch (error) {
        toast.error({
          title: 'Delete Notification Rule',
          text: error?.message,
        })
      }
    },
    [deleteNotificationRule, entityIdToDelete, refetch],
  )

  const deleteRuleMessage = (
    <Text
      dangerouslySetInnerHTML={{
        __html: 'Are you sure you want to delete this notification rule?',
      }}
    />
  )

  useEffect(() => {
    if (rest?.isOpen && !data) {
      fetchData()
    }
  }, [data, fetchData, rest?.isOpen])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      renderTitleAppendix
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onCreate={() => handleCreate({ close, rulesVariables })}
      onMount={handleMount}
    >
      <Content>
        <ScrollingContainer>
          {loading && <Loader alignSelfCenter />}
          {map(rows, row => (
            <NotificationRule key={row?.id} mt={9}>
              <Row center spaceBetween>
                <Text bold middle>
                  {row?.name}
                </Text>
                <Dropdown
                  items={[
                    {
                      type: Dropdown.ItemType.Button,
                      content: 'Edit',
                      action: () =>
                        handleCreate({ close, entity: row, rulesVariables }),
                    },
                    {
                      type: Dropdown.ItemType.Button,
                      content: 'Delete',
                      action: () => handleDeleteRuleModal(row?.id),
                    },
                  ]}
                  offset={[0, -100]}
                >
                  <Button iconButton noEffectsIcon stroke>
                    <FiSliders strokeWidth={1} />
                  </Button>
                </Dropdown>
              </Row>
              <Row center mt={12}>
                <Text light primary small>
                  {s('triggerType')}
                </Text>
              </Row>
              <Row center>
                <Text light lowercase>
                  {row?.triggerType}:
                </Text>
                {row?.triggerType === 'keyword' && (
                  <Text light ml={1}>
                    {row?.keyword}
                  </Text>
                )}
              </Row>
              <Row center mt={16} spaceBetween>
                <Row center>
                  <Column>{filters(row?.rooms, row?.users)}</Column>
                  <Column ml={16}>
                    {schedule(row?.scheduleType, row?.scheduleDailyConfig)}
                  </Column>
                </Row>
                <Column>
                  <Switch
                    checked={row?.enabled}
                    onChange={() =>
                      handleNotificationRuleStatus(row?.enabled, row?.id)
                    }
                  />
                </Column>
              </Row>
            </NotificationRule>
          ))}
          <Waypoint onEnter={handleLoadMore}>
            <FirefoxPaginationAnchor />
          </Waypoint>
        </ScrollingContainer>
      </Content>

      <Dialog
        content={deleteRuleMessage}
        isOpen={deleteModal.isOpen}
        title="Delete Notification Rule"
        onClose={handleCloseDeleteModal}
        onFinish={handleDeleteRule}
      />
    </Modal>
  )
}

NotificationRulesModal.defaultProps = {
  handleCreate: undefined,
}

NotificationRulesModal.propTypes = {
  handleCreate: PropTypes.func,
}

export default NotificationRulesModal
