import { useCallback } from 'react'

import { useStoreon } from 'storeon/react'

import { ROUTE_ACTIONS, ROUTE_STATE } from 'Constants/store'

export default function useRouteState() {
  const { route, dispatch } = useStoreon(ROUTE_STATE)

  const isDirectLink = route?.directLink

  const setIsDirectLink = useCallback(
    value => {
      dispatch(ROUTE_ACTIONS.SET, {
        directLink: value,
      })
    },
    [dispatch],
  )

  return { isDirectLink, setIsDirectLink }
}
