export const TRIGGERS = {
  KEYWORD: 'keyword',
}

export const LOGICAL_OPERATORS = {
  AND: 'and',
  OR: 'or',
}

export const SCHEDULES = {
  DAILY: 'daily',
  LIVE: 'live',
}

export const NOTIFICATION_KINDS = {
  RULE_MATCHED: 'ruleMatched',
  POST_FLAGGED: 'postFlagged',
  POST_SAVED: 'postSaved',
  POST_LIKED: 'postLiked',
  COMMENT_CREATED: 'commentCreated',
  USER_FOLLOWED: 'userFollowed',
  ROOM_ADDED: 'roomAdded',
  USER_TAGGED_IN_POST: 'userTaggedInPost',
  USER_TAGGED_IN_COMMENT: 'userTaggedInComment',
  USER_TAGGED_IN_MESSAGE: 'userTaggedInMessage',
  USER_TAGGED: 'userTagged',
  CHANNEL_CREATED: 'channelCreated',
  CHANNEL_MESSAGE_CREATED: 'channelMessageCreated',
  DIRECT_CHANNEL_MESSAGE_CREATED: 'directChannelMessageCreated',
}
