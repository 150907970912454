import { FRONTEND_URL, IS_PRODUCTION } from 'Config'

export const ROOT = `/`

export const REDIRECTOR = `${ROOT}redirector`

export const APP_ROOT = `${ROOT}`
export const APP_MY_FEED = `${APP_ROOT}myFeed`
export const APP_ENTER = `${APP_ROOT}enter`
export const APP_PROFILE = userId => `${APP_ROOT}profile/${userId || ':userId'}`
export const APP_ROOM = roomPointer =>
  `${APP_ROOT}room/${roomPointer || ':roomPointer'}`
export const APP_ROOM_EVENTS = roomPointer => `${APP_ROOM(roomPointer)}/events`
export const APP_ROOM_STORE = roomPointer => `${APP_ROOM(roomPointer)}/store`
export const APP_ROOM_POSTS = roomPointer => `${APP_ROOM(roomPointer)}/posts`
export const APP_ROOM_POST = (roomPointer, postId) =>
  `${APP_ROOM(roomPointer)}/post/${postId || ':postId'}`
export const APP_ROOM_CHANNEL = (roomPointer, channelPointer) =>
  `${APP_ROOM(roomPointer)}/channels/${channelPointer || ':channelPointer'}`
export const APP_DIRECT_CHANNEL = channelId =>
  `${APP_ROOT}inbox/${channelId || ':channelId'}`

export const ROOM_LINK = `${ROOT}room/:roomPointer`
export const ROOM_POST_LINK = `${ROOT}room/:roomPointer/post/:postId`

export const AUTH_ROOT = `${ROOT}auth`
export const AUTH_SIGN_UP = `${AUTH_ROOT}/sign-up`
export const AUTH_REQUEST_CHANGE_PASSWORD = `${AUTH_ROOT}/request-change-email-password`
export const AUTH_CHANGE_PASSWORD = `${AUTH_ROOT}/change-email-password`
export const AUTH_CONFIRM_EMAIL = `${AUTH_ROOT}/confirm-email`
export const AUTH_SET_USERNAME = `${AUTH_ROOT}/set-username`
export const AUTH_SIGN_IN_ADMIN = `${AUTH_ROOT}/sign-in-admin`
export const AUTH_FROM_MOBILE = `${AUTH_ROOT}/username`

export const OAUTH_CALLBACK = provider =>
  `${ROOT}oauth/${provider || ':provider'}`

export const TEKEN_CALLBACK = `${ROOT}teken/callback`

export const AUTH_MAGIC_LINK = `${AUTH_ROOT}/magic-link`

export const SOCIAL = `${ROOT}connect-social`

export const SIGN_OUT = `${ROOT}sign-out`

export const ADMIN_ROOT = `${ROOT}admin`
export const ADMIN_ADMINS = `${ADMIN_ROOT}/admins`
export const ADMIN_MEMBERS = `${ADMIN_ROOT}/members`
export const ADMIN_ROOMS = `${ADMIN_ROOT}/rooms`
export const ADMIN_ROOMS_CREATE = roomGroupId =>
  `${ADMIN_ROOT}/rooms/${roomGroupId || ':roomGroupId'}`
export const ADMIN_ROOMS_EDIT = (roomGroupId, roomId) =>
  `${ADMIN_ROOT}/rooms/${roomGroupId || ':roomGroupId'}/${roomId || ':roomId'}`
export const ADMIN_SETTINGS = `${ADMIN_ROOT}/settings`
export const ADMIN_TOKENS = `${ADMIN_ROOT}/tokens`
export const ADMIN_CATEGORIES = `${ADMIN_ROOT}/categories`
export const ADMIN_CATEGORY = categoryName =>
  `${ADMIN_ROOT}/categories/${categoryName || ':categoryName'}`
export const ADMIN_ROOM_GROUP = roomGroupId =>
  `${ADMIN_ROOT}/categories/group/${roomGroupId || ':roomGroupId'}`
export const ADMIN_SLUG_BLACKLIST = `${ADMIN_ROOT}/slug-blacklist`

export const TERMS_OF_SERVICE = `${ROOT}terms`

export const UI_KIT = `${ROOT}ui`

export const NOT_FOUND = `${ROOT}404`

export const TELEGRAM_ROOT = `${ROOT}telegram`
export const TELEGRAM_AUTH = `${TELEGRAM_ROOT}/auth`
export const TELEGRAM_LOGIN_CALLBACK = `${TELEGRAM_ROOT}/login-callback`
export const TELEGRAM_LOGIN_REDIRECT = `${TELEGRAM_ROOT}/login-redirect`

export const TWITTER_AUTH_LINK = `https://twitter.com/i/oauth2/authorize`

export const LINKEDIN_AUTH_LINK = `https://www.linkedin.com/oauth/v2/authorization`

export const SUBDOMAIN_ROOT = roomPointer =>
  `//${roomPointer || ':roomPointer'}.${FRONTEND_URL}`

export const SUB_APP_POST = postId => `${APP_ROOT}post/${postId || ':postId'}`

export const COINMETRO_AUTH_LINK = `https://go.coinmetro.com/oauth?client_id=${
  IS_PRODUCTION ? 'sentr3' : `sentr3-staging`
}&redirect_uri=${FRONTEND_URL}/oauth/coinmetro&scopes=base,identity,balances`

export const UNLOCK_AUTH_LINK = `https://app.unlock-protocol.com/checkout`
