import React from 'react'
import { Field } from 'react-final-form'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import TimezoneSelectInput from 'Components/UI/Forms/TimezoneSelectInput/TimezoneSelectInput'

import Utils from 'Utils'

function TimezoneSelectField({
  checkErrorIfDirty,
  displayError,
  input,
  name,
  ...rest
}) {
  return (
    <Field name={name}>
      {({ input: { value, onChange: handleChange }, meta }) => {
        const { error } = Utils.Form.hasError(meta, checkErrorIfDirty)

        return (
          <TimezoneSelectInput
            displayError={displayError}
            error={error}
            value={value}
            {...input}
            {...pick(rest)}
            {...rest}
            onChange={handleChange}
          />
        )
      }}
    </Field>
  )
}

TimezoneSelectField.defaultProps = {
  checkErrorIfDirty: false,
  displayError: true,
  input: null,
}

TimezoneSelectField.propTypes = {
  checkErrorIfDirty: PropTypes.bool,
  displayError: PropTypes.bool,
  input: PropTypes.object,
  name: PropTypes.string.isRequired,
}

export default TimezoneSelectField
