import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import TrezorConnect, { DEVICE, DEVICE_EVENT } from 'trezor-connect'

// import { TREZOR_ETH_PATH } from 'Constants/ids'
// import toast from 'Services/Toast'
import TrezorContext from 'Services/TrezorContext'

TrezorConnect.manifest({
  email: 'info@sentr3.com',
  appUrl: 'https://app.sentr3.com',
})

const trezorInitializedError = 'Init_AlreadyInitialized'

export default function TrezorProvider({ children }) {
  const [isTrezorInitialized, setIsTrezorInitialized] = useState(false)
  const [isTrezorConnected, setIsTrezorConnected] = useState(false)
  const [trezorAccounts, setTrezorAccounts] = useState(null)
  const [isTrezorUnlocking, setIsTrezorUnlocking] = useState(false)
  const [noTrezor, setNoTrezor] = useState(false)

  const handleTrezorEvent = useCallback(event => {
    if (event.type === DEVICE.CONNECT) {
      setIsTrezorConnected(true)
      setNoTrezor(false)
    } else if (event.type === DEVICE.DISCONNECT) {
      setIsTrezorConnected(false)
      setTrezorAccounts(null)
    }
  }, [])

  useEffect(() => {
    if (isTrezorInitialized) return () => {}

    TrezorConnect.init({
      connectSrc: 'https://localhost:8088/',
      lazyLoad: true,
      manifest: {
        email: 'info@app.sentr3.com',
        appUrl: 'https://app.sentr3.com',
      },
    })?.catch(error => {
      if (error?.code === trezorInitializedError) {
        setIsTrezorInitialized(true)
      }
    })

    setIsTrezorInitialized(true)

    TrezorConnect.on(DEVICE_EVENT, handleTrezorEvent)

    return () => {
      TrezorConnect.off(DEVICE_EVENT, handleTrezorEvent)
    }
  }, [isTrezorInitialized, handleTrezorEvent])

  // useEffect(() => {
  //   async function checkIfTrezorConnected() {
  //     TrezorConnect.resetDevice().then(async result => {
  //       if (result?.success) {
  //         setIsTrezorConnected(true)
  //         setIsTrezorUnlocking(true)

  //         try {
  //           const addressResponse = await TrezorConnect.ethereumGetAddress({
  //             path: TREZOR_ETH_PATH,
  //           })

  //           const address = addressResponse?.payload?.address

  //           setTrezorAccount(address)
  //         } catch (error) {
  //           toast.error({
  //             title: 'Check Trezor connection',
  //             text: error?.message,
  //           })
  //         } finally {
  //           setIsTrezorUnlocking(false)
  //         }
  //       } else {
  //         setIsTrezorConnected(false)
  //       }
  //     })
  //   }

  //   checkIfTrezorConnected()
  // }, [])

  const value = useMemo(
    () => ({
      isTrezorConnected,
      trezorAccounts,
      setTrezorAccounts,
      isTrezorUnlocking,
      setIsTrezorUnlocking,
      noTrezor,
      setNoTrezor,
    }),
    [
      isTrezorConnected,
      trezorAccounts,
      setTrezorAccounts,
      isTrezorUnlocking,
      setIsTrezorUnlocking,
      noTrezor,
      setNoTrezor,
    ],
  )

  return (
    <TrezorContext.Provider value={value}>{children}</TrezorContext.Provider>
  )
}

TrezorProvider.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}
