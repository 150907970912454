import React from 'react'
import PropTypes from 'prop-types'

import uniqBy from 'lodash/uniqBy'

import roomEventStatsLogsQuery from 'GraphQL/Queries/Events/roomEventStatsLogs.graphql'
import roomEventStatsLogsUpdatedSubscription from 'GraphQL/Subscriptions/Events/roomEventStatsLogsUpdated.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery({ client, eventStatsLogs, eventStatsLogsVariables }) {
  const eventStatsOptions = {
    query: roomEventStatsLogsQuery,
    variables: { ...eventStatsLogsVariables },
  }

  const eventStatsData = client.readQuery(eventStatsOptions)

  if (eventStatsData) {
    client.writeQuery({
      ...eventStatsOptions,
      data: {
        ...eventStatsData,
        roomEventStatsLogs: {
          ...eventStatsData?.roomEventStatsLogs,
          rows: uniqBy(
            [
              eventStatsLogs,
              ...(eventStatsData?.roomEventStatsLogs?.rows || []),
            ],
            'id',
          ),
        },
      },
    })
  }
}

function Subscription({ eventStatsLogsVariables }) {
  useSubscription(roomEventStatsLogsUpdatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const eventStatsLogs =
        subscriptionData?.data?.roomEventStatsLogsUpdated ?? {}
      updateQuery({ client, eventStatsLogs, eventStatsLogsVariables })
    },
  })

  return <></>
}
Subscription.defaultProps = {
  eventStatsLogsVariables: null,
}

Subscription.propTypes = {
  eventStatsLogsVariables: PropTypes.object,
}

export default Subscription
