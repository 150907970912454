import React from 'react'

import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
} from 'recharts'

const data = [
  {
    subject: 'Frontend',
    A: 20,
    B: 100,
    fullMark: 150,
  },
  {
    subject: 'Mobile',
    A: 30,
    B: 130,
    fullMark: 150,
  },
  {
    subject: 'Backend',
    A: 140,
    B: 30,
    fullMark: 150,
  },
  {
    subject: 'Algo',
    A: 120,
    B: 50,
    fullMark: 150,
  },
  {
    subject: 'Language',
    A: 60,
    B: 90,
    fullMark: 150,
  },
  {
    subject: 'Social',
    A: 30,
    B: 85,
    fullMark: 150,
  },
]

function Chart() {
  return (
    <RadarChart
      data={data}
      fontSize={10}
      height={300}
      outerRadius={90}
      width={300}
    >
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={30} domain={[0, 150]} />

      <Radar
        dataKey="A"
        fill="#8dd1e1"
        fillOpacity={0.5}
        name="Mike"
        stroke="none"
      />

      <Radar
        dataKey="B"
        fill="#a4de6c"
        fillOpacity={0.5}
        name="Lily"
        stroke="none"
      />

      <Legend />
    </RadarChart>
  )
}

export default Chart
