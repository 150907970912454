import { hexToRgba } from 'Utils/Color'

import customPalettes from './customPalettes'
import palette from './palette'

const buttons = color => ({
  height: {
    bigger: 54,
    big: 40,
    small: 24,
    default: 32,
  },
  fontSize: {
    small: 12,
    default: 14,
  },
  fontWeight: {
    bold: 'bold',
    small: 'normal',
    default: 'normal',
  },
  padding: {
    big: `0 32px`,
    small: `0 16px`,
    default: `0 24px`,
  },
  icon: {
    size: {
      bigger: 32,
      big: 26,
      middle: 18,
      medium: 16,
      small: 14,
      smallest: 10,
      default: 22,
      record: 80,
      notification: 16,
      social: 16,
    },
  },
  border: {
    radius: {
      squared: 10,
      default: 24,
      record: 100,
    },
    width: { default: 1, notification: 3 },
    color: {
      default: customPalettes[color]?.primary || color,
      secondary: palette.secondaryDark,
      third: palette.violet,
      seventh: palette.primaryDark,
      dark: palette.primary,
      arrows: palette.primaryDark,
      notification: palette.secondaryDark,
      clearAttach: palette.primary,
      warning: palette.primaryDark,
      promote: palette.blue,
      ban: palette.warning,
    },
  },
  color: {
    default: palette.white,
    secondary: palette.primary,
    fourth: palette.violet,
    fifth: palette.primary,
    sixth: customPalettes[color]?.primary || color,
    seventh: palette.primary,
    dark: palette.primary,
    sorting: customPalettes[color]?.primary || color,
    arrows: customPalettes[color]?.primary || color,
    iconButton: customPalettes[color]?.primary || color,
    notification: palette.primary,
    modeDark: customPalettes[color]?.primary || color,
    neutral: palette.primary,
    warning: palette.warning,
    roomUsers: palette.primary,
  },
  backgroundColor: {
    default: customPalettes[color]?.primary || color,
    secondary: palette.secondaryDark,
    third: palette.violet,
    seventh: palette.primaryDark,
    dark: palette.secondaryDark,
    arrows: palette.primaryDark,
    notification: palette.secondary,
    clearAttach: palette.primary,
    warning: palette.primaryDark,
    promote: palette.blue,
    ban: palette.warning,
  },
  active: {
    border: {
      color: {
        default: customPalettes[color]?.dark || color,
        secondary: customPalettes[color]?.secondary || color,
        third: palette.violetDarkest,
        seventh: customPalettes[color]?.dark || color,
        dark: customPalettes[color]?.primary || color,
        notification: customPalettes[color]?.primary || color,
        warning: palette.primaryDark,
        promote: palette.blueDarkest,
      },
    },
    color: {
      default: palette.white,
      secondary: customPalettes[color]?.primary || color,
      third: palette.violetDarkest,
      fourth: palette.violetDarkest,
      fifth: palette.primaryExtra,
      sixth: customPalettes[color]?.dark || color,
      seventh: customPalettes[color]?.dark || color,
      plainTextButton: customPalettes[color]?.dark || color,
      arrows: customPalettes[color]?.primary || color,
      iconButton: customPalettes[color]?.primary || color,
      notification: palette.white,
      modeDark: customPalettes[color]?.primary || color,
      neutral: palette.primary,
      clearAttach: customPalettes[color]?.dark || color,
      warning: palette.warning,
      roomUsers: customPalettes[color]?.dark || color,
    },
    backgroundColor: {
      default: customPalettes[color]?.dark || color,
      secondary: customPalettes[color]?.secondary || color,
      third: palette.violetDarkest,
      seventh: palette.primaryDark,
      dark: customPalettes[color]?.primary || color,
      arrows: customPalettes[color]?.secondary || color,
      notification: customPalettes[color]?.primary || color,
      clearAttach: palette.primary,
      warning: palette.primaryDark,
      promote: palette.blueDarkest,
    },
    shadow: { default: 'none' },
  },
  hover: {
    border: {
      color: {
        default: customPalettes[color]?.medium || hexToRgba(color, 0.5),
        secondary: customPalettes[color]?.secondary || hexToRgba(color, 0.5),
        third: palette.violetDark,
        seventh: customPalettes[color]?.primary || hexToRgba(color, 0.5),
        arrows: customPalettes[color]?.primary,
        notification: customPalettes[color]?.primary || hexToRgba(color, 0.5),
        clearAttach: customPalettes[color]?.primary || hexToRgba(color, 0.5),
        warning: palette.primaryDark,
        promote: palette.blueDark,
        ban: palette.warningDark,
      },
    },
    color: {
      default: palette.white,
      secondary: customPalettes[color]?.primary || color,
      fourth: palette.violetDark,
      fifth: palette.secondary,
      sixth: customPalettes[color]?.medium || color,
      seventh: customPalettes[color]?.primary || color,
      plainTextButton: customPalettes[color]?.medium || color,
      sorting: customPalettes[color]?.primary || color,
      arrows: customPalettes[color]?.primary || color,
      iconButton: customPalettes[color]?.dark || color,
      notification: customPalettes[color]?.primary || color,
      modeDark: customPalettes[color]?.primary || color,
      neutral: palette.primary,
      clearAttach: customPalettes[color]?.primary || color,
      warning: palette.warningDark,
      roomUsers: customPalettes[color]?.primary || color,
    },
    backgroundColor: {
      default: customPalettes[color]?.medium || hexToRgba(color, 0.5),
      secondary: customPalettes[color]?.secondary || hexToRgba(color, 0.5),
      third: palette.violetDark,
      seventh: palette.primaryDark,
      sorting: customPalettes[color]?.secondary || hexToRgba(color, 0.3),
      arrows: palette.primaryDark || hexToRgba(color, 0.3),
      notification: palette.secondary,
      clearAttach: palette.primary,
      warning: palette.primaryDark,
      fourth: palette.primaryDark,
      promote: palette.blueDark,
      ban: palette.warningDark,
      primary: palette.secondaryDark,
    },
    shadow: { default: 'none' },
  },
  outline: {
    color: {
      default: customPalettes[color]?.primary || color,
      third: palette.violet,
    },
    backgroundColor: {
      default: palette.primaryDark,
      third: palette.white,
    },
    border: {
      default: customPalettes[color]?.primary || color,
      third: palette.violet,
    },
    active: {
      color: {
        default: palette.white,
      },
      backgroundColor: {
        default: customPalettes[color]?.primary || color,
        third: palette.violetDarkest,
      },
      border: {
        default: customPalettes[color]?.primary || color,
        third: palette.violetDarkest,
      },
    },
    hover: {
      color: {
        default: customPalettes[color]?.primary || color,
        third: palette.violetDark,
      },
      backgroundColor: {
        default: customPalettes[color]?.secondary || hexToRgba(color, 0.5),
        third: palette.violetLightest,
      },
      border: {
        default: customPalettes[color]?.primary || hexToRgba(color, 0.5),
        third: palette.violetDark,
      },
    },
    disabled: {
      color: {
        default: customPalettes[color]?.light || color,
        third: palette.violetLightest,
      },
      border: {
        default: customPalettes[color]?.light || hexToRgba(color, 0.2),
        third: palette.violetLightest,
      },
    },
  },
  disabled: {
    border: {
      color: {
        default: customPalettes[color]?.light || hexToRgba(color, 0.2),
        promote: palette.blueLightest,
        third: palette.violetLightest,
      },
    },
    backgroundColor: {
      default: customPalettes[color]?.light || hexToRgba(color, 0.2),
      promote: palette.blueLightest,
      third: palette.violetLightest,
    },
    color: {
      default: palette.primary,
      sixth: customPalettes[color]?.light || color,
      plainTextButton: customPalettes[color]?.light || color,
      third: palette.white,
      iconButton: customPalettes[color]?.light || color,
      warning: palette.warning,
    },
  },
})

export default buttons
