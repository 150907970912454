import { HostLayout } from 'Components/Layout'

import Admin from 'Pages/Admin'

import * as ROUTES from './routes'

export const HOST_ROUTES = {
  component: HostLayout,
  path: ROUTES.ADMIN_ROOMS,
  routes: [
    {
      component: Admin.Rooms,
      path: ROUTES.ADMIN_ROOMS,
      exact: true,
    },
    {
      path: ROUTES.ADMIN_ROOMS_EDIT(),
      component: Admin.Room,
      exact: true,
    },
  ],
}
