import { FiCheck } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

const noChangeCss = ({ noChange }) =>
  noChange &&
  css`
    background-color: ${themeGet('inputs.background.input.noChange')};
    border: 1px solid ${themeGet('colors.noChangeSecondary')};
  `

// TODO: map to theme
export const Icon = styled(FiCheck)`
  display: none;
  position: absolute;
  width: 14px;
  height: 14px;
  stroke-width: 3px;
  color: ${themeGet('colors.white')};
  left: 3px;
  top: 3px;
  pointer-events: none;
`

export const Background = styled.div`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  background-color: ${themeGet('colors.bg.primary')};
  border: 1px solid ${themeGet('colors.customDefault')};
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  ${noChangeCss}
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2px;

  ${margin}
`

export const Container = styled.label`
  --size: 20px;

  position: relative;
  display: flex;
  align-items: flex-start;
  width: fit-content;
  min-height: var(--size);
  user-select: none;

  > input {
    width: var(--size);
    height: var(--size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${Icon} {
      display: block;
    }

    &:checked ~ ${Background} {
      background-color: ${themeGet('colors.customDefault')};
      border: 1px solid ${themeGet('colors.customDefault')};
    }

    &:disabled ~ ${Background} {
      border-color: ${themeGet('colors.border.customSecondary')};
      background: ${themeGet('colors.bg.customSecondary')};
      cursor: not-allowed;
    }

    &:disabled ~ ${TextContent} p:first-child {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }

  ${typography}
  ${margin}
`

const checkedCSS = ({ checked }) =>
  checked &&
  css`
    border-color: ${mapToTheme('buttons.border.color')};
  `

export const GatingContainer = styled.label`
  --size: 20px;
  width: 255px;
  height: 48px;
  border-radius: 16px;
  border-style: solid;
  border-width: ${mapToTheme('buttons.border.width')}px;
  border-color: ${themeGet('colors.secondaryLight')};
  background-color: ${themeGet('colors.secondaryLight')};

  position: relative;
  display: flex;
  align-items: center;
  min-height: var(--size);
  user-select: none;
  padding: 15px;

  ${Background} {
    top: 13px;
    right: 15px;
  }
  ${Icon} {
    top: 16px;
    left: initial;
    right: 18px;
  }
  > input {
    width: var(--size);
    height: var(--size);
    margin: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${Icon} {
      display: block;
    }

    &:checked ~ ${Background} {
      background-color: ${themeGet('colors.customDefault')};
      border: 1px solid ${themeGet('colors.customDefault')};
    }

    &:disabled ~ ${Background} {
      border-color: ${themeGet('colors.border.customSecondary')};
      background: ${themeGet('colors.bg.customSecondary')};
      cursor: not-allowed;
    }

    &:disabled ~ ${TextContent} p:first-child {
      color: ${themeGet('colors.font.disabledBox')};
      cursor: not-allowed;
    }
  }
  ${checkedCSS}
  ${typography}
  ${margin}
`
