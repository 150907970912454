import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import noop from 'lodash/noop'

import { Button, Column, Loader, Modal, Row, Text } from 'Components/UI'
import { InputField, SelectField, SwitchField } from 'Components/UI/Forms'

import createChannelMutation from 'GraphQL/Mutations/Channels/createChannel.graphql'
import updateChannelMutation from 'GraphQL/Mutations/Channels/updateChannel.graphql'

import { useChannelCategoriesOptions } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const FIELDS = {
  NAME: 'name',
  CATEGORY_ID: 'categoryId',
  READ_ONLY: 'readOnly',
  FREE: 'free',
}

function ChannelModal({ data, isEdit, ...rest }) {
  const s = useScopedI18n('user.modal.createChannel')
  const [loading, setLoading] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.NAME]: null,
    [FIELDS.CATEGORY_ID]: null,
    [FIELDS.READ_ONLY]: true,
    [FIELDS.FREE]: false,
  })

  const categoriesOptions = useChannelCategoriesOptions(data?.roomId)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const categoriesOptionsById = useMemo(
    () => keyBy(categoriesOptions, 'value'),
    [categoriesOptions],
  )

  useEffect(() => {
    if (isEdit) {
      const { name, categoryId, readOnly, free } = data

      setFormInitialValues({
        name,
        readOnly,
        free,
        categoryId: {
          value: categoryId,
          label: categoriesOptionsById[categoryId]?.label,
        },
      })
    } else {
      setFormInitialValues({
        [FIELDS.NAME]: null,
        [FIELDS.READ_ONLY]: true,
        [FIELDS.CATEGORY_ID]: {
          value: data?.categoryId,
          label: categoriesOptionsById[data?.categoryId]?.label,
        },
        [FIELDS.FREE]: false,
      })
    }
  }, [categoriesOptionsById, data, isEdit])

  const [createChannel] = useMutation(createChannelMutation)
  const [updateChannel] = useMutation(updateChannelMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.NAME]: {
        presence: true,
        length: {
          minimum: 3,
          tooShort: 'should be at least 3 characters long',
        },
      },
      [FIELDS.CATEGORY_ID]: {
        presence: true,
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        const variables = {
          name: values[FIELDS.NAME] || ' ',
          categoryId: values[FIELDS.CATEGORY_ID]?.value,
          readOnly: values[FIELDS.READ_ONLY],
          free: values[FIELDS.FREE],
        }

        if (isEdit) {
          await updateChannel({ variables: { id: data?.id, ...variables } })
        } else {
          await createChannel({
            variables: { roomId: data?.roomId, ...variables },
          })
        }

        toast.success({
          title: 'Channel',
          text: isEdit ? `Channel updated` : `Channel created`,
        })

        close.current()
      } catch (error) {
        toast.error({
          title: isEdit ? 'Update channel' : 'Create channel',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [createChannel, data?.id, data?.roomId, isEdit, updateChannel],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={isEdit ? s('titleEdit') : s('titleCreate')}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <InputField
                label={s('fields.name')}
                mt={6}
                name={FIELDS.NAME}
                width={1}
              />
              <Row alignSelfStart>
                <Text mt={2} small>
                  {s('fields.nameExample')}
                </Text>
              </Row>
              <SelectField
                mt={4}
                name={FIELDS.CATEGORY_ID}
                options={categoriesOptions}
                placeholder={s('fields.category')}
                width={1}
              />
              <Row center mt={4} width={1}>
                <SwitchField name={FIELDS.READ_ONLY} />
                <Text ml={2}>{s('fields.readOnly')}</Text>
              </Row>
              {/* <Row center mt={4} width={1}>
                <SwitchField invertedValue name={FIELDS.FREE} />
                <Text ml={2}>{s('fields.free')}</Text>
              </Row> */}
            </Content>
            <Column center mt={4} pb={5}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button height={32} mb={3} width={130} onClick={handleSubmit}>
                    {isEdit ? s('actions.save') : s('actions.create')}
                  </Button>
                  <Button
                    height={32}
                    outline
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

ChannelModal.defaultProps = {
  categoriesOptions: [],
  data: null,
  isEdit: false,
}

ChannelModal.propTypes = {
  categoriesOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isEdit: PropTypes.bool,
}

export default ChannelModal
