import * as DateUtils from './Date'
import * as FormUtils from './Form'
import * as GraphQLUtils from './GraphQL'
import * as LinksUtils from './Links'
import * as MeUtils from './Me'
import * as NumbersUtils from './Numbers'
import * as ObjectUtils from './Object'
import * as PostsUtils from './Posts'
import * as PromiseUtils from './Promises'
import * as StringsUtils from './Strings'
import * as TablesUtils from './Tables'
import * as WalletConnectUtils from './WalletConnect'

const Utils = {
  Date: DateUtils,
  Form: FormUtils,
  GraphQL: GraphQLUtils,
  Links: LinksUtils,
  Me: MeUtils,
  Numbers: NumbersUtils,
  Object: ObjectUtils,
  Promise: PromiseUtils,
  Strings: StringsUtils,
  Posts: PostsUtils,
  Tables: TablesUtils,
  WalletConnect: WalletConnectUtils,
}

export default Utils
