import palette from './palette'

const inputs = {
  height: {
    default: 40,
  },
  background: {
    search: {
      default: palette.primaryDark,
      secondary: palette.secondary,
      disabled: palette.secondary,
    },
    input: {
      default: palette.primaryDark,
      disabled: palette.secondary,
      noChange: palette.white,
      post: palette.primaryDark,
    },
  },
  border: {
    search: {
      default: palette.primaryDark,
      secondary: palette.secondary,
      disabled: palette.secondary,
      withError: palette.warning,
    },
    input: {
      default: palette.primaryExtra,
      disabled: palette.primary,
      withError: palette.warning,
      noChange: palette.secondaryExtraTwo,
      content: palette.secondary,
    },
  },
  focus: {
    border: {
      default: palette.white,
      noChange: palette.primary,
    },
  },
}

export default inputs
