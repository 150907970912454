import React from 'react'

import PostFragment from 'GraphQL/Fragments/Post.graphql'
import postLikedSubscription from 'GraphQL/Subscriptions/postLiked.graphql'
import postSavedSubscription from 'GraphQL/Subscriptions/postSaved.graphql'
import postUnlikedSubscription from 'GraphQL/Subscriptions/postUnliked.graphql'

import { useSubscription } from 'Services/Apollo'

function updateFragment(client, post) {
  const fragment = {
    id: `Post:${post?.id}`,
    fragmentName: 'PostFields',
    fragment: PostFragment,
  }

  const data = client.readFragment(fragment)

  if (data) {
    client.writeFragment({ ...fragment, data: { ...data, ...post } })
  }
}

function updateLikedFragment({ client, like, liked }) {
  const fragment = {
    id: `Post:${like?.likeableId}`,
    fragmentName: 'PostFields',
    fragment: PostFragment,
  }

  const data = client?.readFragment(fragment)

  if (data) {
    client.writeFragment({
      ...fragment,
      data: {
        ...data,
        liked,
        likesCount: liked ? data?.likesCount + 1 : data?.likesCount - 1,
      },
    })
  }
}

function PostsLikesSaves() {
  useSubscription(postLikedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const like = subscriptionData?.data?.postLiked

      updateLikedFragment({ client, like, liked: true })
    },
  })

  useSubscription(postUnlikedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const like = subscriptionData?.data?.postUnliked

      updateLikedFragment({ client, like, liked: false })
    },
  })

  useSubscription(postSavedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const saved = subscriptionData?.data?.postSaved

      updateFragment(client, saved?.post)
    },
  })

  return <></>
}

export default PostsLikesSaves
