import styled from 'styled-components'
import { layout } from '@styled-system/layout'

export const Container = styled.div`
  position: relative;
  flex-shrink: 0;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  line-height: 0;

  ${layout.width}
  ${layout.height}
`

export const LogoImage = styled.img`
  object-fit: cover;
  line-height: 0;

  ${layout.width}
  ${layout.height}
`
