import React from 'react'
import { components } from 'react-select'

import { ValueContainerWrapper } from './styles'

const ValueContainer = props => (
  <ValueContainerWrapper as={components.ValueContainer} {...props} />
)

ValueContainer.propTypes = {
  ...components.ValueContainer.propTypes,
}

export default ValueContainer
