import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 480px;
  padding: 0 ${themeGet('space.5')}px ${themeGet('space.6')}px;
  min-height: auto;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const ExternalLink = styled.a`
  color: ${themeGet('colors.customDefault')};
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    color: ${themeGet('colors.customMedium')};
  }

  :active {
    color: ${themeGet('colors.customDefault')};
  }
`

const disabledCss = ({ disabled }) =>
  disabled &&
  css`
    cursor: not-allowed;
    opacity: 0.5;
  `

export const ConnectWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  ${margin}

  ${disabledCss}
`

export const Image = styled.img`
  height: 32px;
  width: 32px;
  border-radius: 50%;
`
