import { useCallback, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router'

import { useApolloClient } from '@apollo/client'

import { APP_ROOM } from 'Router/routes'

import { checkIfDomain, checkSlug, getRoomMetaByDomain } from './utils'

export default function useEntrance() {
  const apolloClient = useApolloClient()
  const { pathname } = useLocation()

  const [contextValue, setContextValue] = useState({
    roomGroupMeta: null,
    roomMeta: null,
    roomIds: [],
    exclusiveMode: false,
    loading: true,
    enteredFrom: null,
  })

  const checkData = useCallback(async () => {
    const fromDomain = checkIfDomain()

    if (fromDomain) {
      const roomGroupMeta = await getRoomMetaByDomain(fromDomain, apolloClient)

      return {
        roomGroupMeta,
        roomMeta: roomGroupMeta?.rooms?.[0],
        roomIds: null,
        exclusiveMode: true,
        loading: false,
        enteredFrom: pathname,
      }
    }

    const fromRoomSlug = matchPath(pathname, {
      path: APP_ROOM(),
    })?.params?.roomPointer

    if (fromRoomSlug) {
      const { roomMeta, roomIds } = await checkSlug(fromRoomSlug, apolloClient)

      if (roomMeta)
        return {
          roomGroupMeta: null,
          roomMeta,
          roomIds,
          exclusiveMode: false,
          loading: false,
          enteredFrom: pathname,
        }
    }

    return {
      roomGroupMeta: null,
      roomMeta: null,
      roomIds: [],
      exclusiveMode: false,
      loading: false,
      enteredFrom: pathname === '/' ? null : pathname,
    }
    // NOTE: should be called only on initial path loading
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apolloClient])

  useEffect(() => {
    checkData().then(setContextValue)
  }, [checkData])

  return [contextValue, setContextValue]
}
