import Area from './Area'
import Funnel from './Funnel'
import Pie from './Pie'
import Radar from './Radar'
import RadialBar from './RadialBar'

const Charts = {
  Area,
  Funnel,
  Pie,
  Radar,
  RadialBar,
}

export default Charts
