import React from 'react'
import PropTypes from 'prop-types'

import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'
import { Column, Link, Row, Text } from 'Components/UI'

import { APP_PROFILE } from 'Router/routes'

import { useScopedI18n } from 'Services/I18n'

function Team({ owners, moderators }) {
  const s = useScopedI18n('room.channels')

  return (
    <Column mt={5}>
      {owners?.length > 0 && (
        <Text bold small>
          {s('fields.hosts', { members: owners?.length })}
        </Text>
      )}
      {moderators?.length > 0 && (
        <Text bold small>
          {s('fields.moderators', { members: moderators?.length })}
        </Text>
      )}
      <Column mt={4}>
        {map(owners?.length > 0 ? owners : moderators, (user, idx) => (
          <Row key={user?.id} mt={idx === 0 ? 0 : 3}>
            <Link clean to={APP_PROFILE(user?.id)}>
              <Avatar
                online={user?.online}
                size={22}
                src={user?.profile?.photoUrl}
                username={user?.username}
              />
            </Link>
            <Text lineHeight="20px" ml={2} small>
              @{user?.username}
            </Text>
          </Row>
        ))}
      </Column>
    </Column>
  )
}

Team.defaultProps = {
  moderators: [],
  owners: [],
}

Team.propTypes = {
  moderators: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  owners: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Team
