import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Column, Loader, Modal, Text } from 'Components/UI'
import { InputField } from 'Components/UI/Forms'

import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELDS = {
  DONATION_WALLET: 'donationWalletAddress',
}

function EditDonationWalletModal({ user, ...rest }) {
  const s = useScopedI18n('user.modal.editDonation')
  const [loading, setLoading] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.DONATION_WALLET]: null,
  })

  useEffect(() => {
    if (user?.profile?.donationWalletAddress) {
      const { profile: { donationWalletAddress } = {} } = user

      setFormInitialValues({ donationWalletAddress })
    } else {
      setFormInitialValues({ [FIELDS.DONATION_WALLET]: null })
    }
  }, [user])

  const close = useRef(null)

  const [updateProfile] = useMutation(updateProfileMutation)

  const formConstraints = useMemo(() => ({}), [])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        const variables = {
          donationWalletAddress: get(values, FIELDS.DONATION_WALLET, null),
        }

        await updateProfile({
          variables: {
            photoUrl: user?.profile?.photoUrl,
            ...variables,
          },
        })

        toast.success({
          title: 'User profile',
          text: `Profile updated`,
        })

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error(error?.message)

        setLoading(false)
      }
    },
    [updateProfile, user?.profile?.photoUrl],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle ml={1}>
                <Text alignStart mb={16} mt={24}>
                  {s('subtitle')}
                </Text>
              </Subtitle>
              <InputField
                name={FIELDS.DONATION_WALLET}
                placeholder={s('placeholder')}
                width={1}
              />
            </Content>

            <Column center mt={24} pb={24}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button height={32} mb={3} width={130} onClick={handleSubmit}>
                    {s('actions.save')}
                  </Button>
                  <Button
                    height={32}
                    outline
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditDonationWalletModal.defaultProps = {
  user: null,
}

EditDonationWalletModal.propTypes = {
  user: PropTypes.object,
}

export default EditDonationWalletModal
