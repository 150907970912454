import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import {
  ProviderBackground,
  ProviderContainer,
  ProviderIcon,
  ProviderIconChecked,
  ProviderLogo,
  ProviderText,
} from './styles'

function RadioWalletProvider({
  value,
  name,
  disabled,
  error,
  logo,
  label,
  meta,
  ...rest
}) {
  return (
    <ProviderContainer {...pick(rest)}>
      <input
        disabled={disabled}
        name={name}
        type="radio"
        value={value}
        {...omit(rest)}
      />
      <ProviderBackground>
        <ProviderLogo>{logo}</ProviderLogo>
        <ProviderIcon />
      </ProviderBackground>
      <ProviderIconChecked />
      <ProviderText>
        {label && <Text>{label}</Text>}
        {error && (
          <Text small warning>
            {error}
          </Text>
        )}
      </ProviderText>
    </ProviderContainer>
  )
}

RadioWalletProvider.defaultProps = {
  circle: false,
  disabled: false,
  error: null,
  label: null,
  logo: null,
  meta: {},
  name: null,
  textRadioButton: false,
  value: null,
}

RadioWalletProvider.propTypes = {
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  logo: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
  meta: PropTypes.object,
  name: PropTypes.string,
  textRadioButton: PropTypes.bool,
  value: PropTypes.string,
}

export default RadioWalletProvider
