import { nanoid } from 'nanoid'

const actions = {
  balance: {
    update: 'balance/update',
  },
  textarea: {
    focus: '/textarea/focus',
  },
}

function EventBus() {
  const subscriptions = new Map()

  return {
    on(event, callback) {
      if (!subscriptions.has(event)) {
        subscriptions.set(event, new Map())
      }

      const subscriptionId = nanoid()

      subscriptions.get(event)?.set(subscriptionId, {
        callback,
      })

      return {
        clear: () => {
          const eventSubscriptions = subscriptions.get(event)
          eventSubscriptions?.delete(subscriptionId)
          if (eventSubscriptions?.size === 0) {
            subscriptions.delete(event)
          }
        },
      }
    },
    trigger(event, ...args) {
      subscriptions.get(event)?.forEach(subscription => {
        subscription.callback(...args)
      })
    },
    actions,
  }
}

export default EventBus()
