import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'

import UserFragment from 'GraphQL/Fragments/User.graphql'
import tokenBalancesQuery from 'GraphQL/Queries/Tokens/tokenBalances.graphql'
import userGroupedWalletsQuery from 'GraphQL/Queries/Wallets/userGroupedWallets.graphql'

export function disconnectWalletUpdater({
  walletsVariables,
  provider,
  userId,
}) {
  return function updateCache(cache) {
    const walletsOptions = {
      query: userGroupedWalletsQuery,
      variables: {
        ...walletsVariables,
      },
    }

    const balancesOptions = {
      query: tokenBalancesQuery,
      variables: {},
    }

    const userFragment = {
      id: `User:${userId}`,
      fragmentName: 'UserFields',
      fragment: UserFragment,
    }

    const walletsData = cache.readQuery(walletsOptions)

    const balancesData = cache.readQuery(balancesOptions)

    const userData = cache.readFragment(userFragment)

    const walletByProvider = keyBy(walletsData?.userGroupedWallets, 'provider')

    const walletId = `Wallet:${walletByProvider[provider]?.wallets[0]?.id}`

    const balanceId = `TokenBalance:${walletByProvider[provider]?.wallets[0]?.tokenBalances[0]?.id}`

    if (walletsData) {
      cache.evict({ id: walletId })
      cache.gc()
      cache.writeQuery({
        ...walletsOptions,
        data: {
          ...walletsData,
          userGroupedWallets: filter(
            walletsData?.userGroupedWallets,
            wallet => wallet?.provider !== provider,
          ),
        },
      })
    }

    if (userData) {
      cache.writeFragment({
        ...userFragment,
        data: {
          ...userData,
          wallets: filter(
            userData?.wallets,
            wallet => wallet?.provider !== provider,
          ),
        },
      })
    }

    if (balancesData) {
      cache.evict({ id: balanceId })
      cache.gc()
      cache.writeQuery({
        ...balancesOptions,
        data: {
          ...balancesData,
          tokenBalances: filter(
            balancesData?.tokenBalances,
            balance =>
              balance?.id !==
              walletByProvider[provider]?.wallets[0]?.tokenBalances[0]?.id,
          ),
        },
      })
    }
  }
}
