import React, { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'

import { Column } from 'Components/UI'

import { useRoomData } from 'Hooks'

import { APP_ROOM } from 'Router/routes'

import Channels from './Channels'
// import Lounges from './Lounges'

function ChannelsAndLounges() {
  const { pathname } = useLocation()

  const routeParams = useMemo(
    () =>
      matchPath(pathname, {
        path: APP_ROOM(),
      }),
    [pathname],
  )

  const roomPointer = routeParams?.params?.roomPointer ?? ''

  const room = useRoomData(roomPointer)

  return (
    <Column height="100%">
      <Channels room={room} />
      {/* <Lounges /> */}
    </Column>
  )
}

export default ChannelsAndLounges
