import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import { VscClose } from 'react-icons/vsc'

import { Wrapper } from '@googlemaps/react-wrapper'
import { DateTime } from 'luxon'
import validate from 'validate.js'

import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'

import Avatar from 'Components/Blocks/Avatar'
import BouncersModal from 'Components/Blocks/User/Modals/BouncersModal'
import {
  Button,
  Column,
  DateInput,
  Link,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'
import {
  Input,
  InputField,
  RadioField,
  Select,
  SelectField,
  TimezoneSelectInput,
} from 'Components/UI/Forms'

import { GOOGLE_API_KEY } from 'Config'

import createRoomEventMutation from 'GraphQL/Mutations/Events/createRoomEvent.graphql'
import updateRoomEventMutation from 'GraphQL/Mutations/Events/updateRoomEvent.graphql'

import { useTimeOptions } from 'Hooks'

import { APP_PROFILE } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Map from './Map'
import { BouncersFrame, Content, ScanLimitWrapper } from './styles'

const FIELDS = {
  IS_LIFETIME: 'isLifetime',
  NAME: 'name',
  SCAN_LIMIT: 'scanLimit',
  SCAN_LIMIT_PERIOD: 'scanLimitPeriod',
}

function EventModal({ event, targetId, roomBouncers, ...rest }) {
  const s = useScopedI18n('user.modal.events')

  const [eventBouncerIds, setEventBouncerIds] = useState(new Set())
  const [bouncersModal, setBouncersModal] = useState({
    isOpen: false,
  })

  const handleBouncersModal = useCallback(() => {
    setBouncersModal({
      isOpen: true,
    })
  }, [])

  const handleBouncersModalBack = useCallback(() => {
    setBouncersModal({ isOpen: false, entity: null })
  }, [])

  const handleSelectEventBouncer = useCallback(
    bouncerId => {
      if (eventBouncerIds.has(bouncerId)) {
        setEventBouncerIds(
          prevState => new Set(filter([...prevState], id => id !== bouncerId)),
        )
      } else {
        setEventBouncerIds(prevState => new Set(prevState.add(bouncerId)))
      }
    },
    [eventBouncerIds],
  )

  const handleDeleteEventBouncer = useCallback(
    eventBouncerId =>
      setEventBouncerIds(
        prevState =>
          new Set(filter([...prevState], id => id !== eventBouncerId)),
      ),
    [],
  )

  const userTimezone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    [],
  )

  const [loading, setLoading] = useState(false)

  const [startDay, setStartDay] = useState(null)
  const [endDay, setEndDay] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [endTime, setEndTime] = useState(null)
  const [timezone, setTimezone] = useState(userTimezone)

  const [search, setSearch] = useState('')

  const [mapZoom, setMapZoom] = useState(3)
  const [mapClick, setMapClick] = useState(null)
  const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 })
  const [addressOnClick, setAddressOnClick] = useState('')

  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const [addressInputValue, setAddressInputValue] = useState('')

  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.NAME]: null,
    [FIELDS.IS_LIFETIME]: null,
    [FIELDS.SCAN_LIMIT]: 1,
  })

  const close = useRef(null)

  const timeOptions = useTimeOptions()

  const periodsOptions = useMemo(
    () => [
      { value: 'day', label: s('fields.day') },
      { value: 'month', label: s('fields.month') },
      { value: 'year', label: s('fields.year') },
    ],
    [s],
  )

  useEffect(() => {
    if (event) {
      const {
        name,
        isLifetime,
        accessType,
        codeword,
        latitude,
        longitude,
        timezone: initialTimezone,
        scanLimit,
        scanLimitPeriod,
      } = event
      setFormInitialValues({
        name,
        [FIELDS.IS_LIFETIME]: `${isLifetime}`,
        accessType,
        codeword,
        scanLimit,
        [FIELDS.SCAN_LIMIT_PERIOD]: scanLimitPeriod
          ? {
              label: find(periodsOptions, ['value', scanLimitPeriod])?.label,
              value: scanLimitPeriod,
            }
          : null,
      })
      setEventBouncerIds(new Set(map(event?.bouncers, bouncer => bouncer?.id)))

      setMapClick({ lat: latitude, lng: longitude })

      setTimezone(initialTimezone || userTimezone)

      setAddressInputValue(addressOnClick)

      if (!isLifetime) {
        const timeOptionsByValue = keyBy(timeOptions, 'value')
        setStartDay(
          DateTime.fromISO(event?.startTime)
            .setZone(initialTimezone)
            .toISODate(),
        )
        setEndDay(
          DateTime.fromISO(event?.endTime).setZone(initialTimezone).toISODate(),
        )
        const startTimeValue = DateTime.fromISO(event?.startTime)
          .setZone(initialTimezone)
          .toISOTime()
          .slice(0, 5)

        setStartTime({
          label: timeOptionsByValue[startTimeValue]?.label,
          value: startTimeValue,
        })

        const endTimeValue = DateTime.fromISO(event?.endTime)
          .setZone(initialTimezone)
          .toISOTime()
          .slice(0, 5)

        setEndTime({
          label: timeOptionsByValue[endTimeValue]?.label,
          value: endTimeValue,
        })
      } else {
        setStartDay(null)
        setStartTime(null)
        setEndDay(null)
        setEndTime(null)
      }
    } else {
      setIsInitialLoad(false)
      setFormInitialValues({
        [FIELDS.NAME]: null,
        [FIELDS.IS_LIFETIME]: null,
        [FIELDS.SCAN_LIMIT]: 1,
      })

      setStartDay(null)
      setStartTime(null)
      setEndDay(null)
      setEndTime(null)

      setMapClick(null)
      setMapZoom(3)
      setMapCenter({ lat: 0, lng: 0 })

      setAddressInputValue('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  const [createRoomEvent] = useMutation(createRoomEventMutation)
  const [updateRoomEvent] = useMutation(updateRoomEventMutation)

  const eventStart = useMemo(() => {
    const startString = startDay?.concat(
      '',
      `T${startTime?.value ?? '00:00'}:00`,
    )
    return DateTime.fromISO(startString, {
      zone: timezone?.value,
    })
      .toUTC()
      .toISO()
  }, [startDay, startTime?.value, timezone?.value])

  const eventEnd = useMemo(() => {
    const endString = endDay?.concat('', `T${endTime?.value ?? '00:00'}:00`)
    return DateTime.fromISO(endString, { zone: timezone?.value })
      .toUTC()
      .toISO()
  }, [endDay, endTime?.value, timezone?.value])

  const formConstraints = useMemo(
    () => ({
      [FIELDS.NAME]: {
        presence: true,
      },
      [FIELDS.SCAN_LIMIT]: {
        presence: true,
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        const variables = {
          isLifetime: values[FIELDS.IS_LIFETIME] === 'true',
          active: true,
          longitude: parseFloat(mapClick?.lng) || null,
          latitude: parseFloat(mapClick?.lat) || null,
          googlePlacesAddress: addressInputValue || addressOnClick || null,
          startTime:
            values[FIELDS.IS_LIFETIME] === 'false' ? eventStart : undefined,
          endTime:
            values[FIELDS.IS_LIFETIME] === 'false' ? eventEnd : undefined,
          name: values[FIELDS.NAME] || '',
          timezone: timezone?.value || userTimezone,
          scanLimit: get(values, FIELDS.SCAN_LIMIT)
            ? Number(get(values, FIELDS.SCAN_LIMIT))
            : null,
          scanLimitPeriod: get(values, FIELDS.SCAN_LIMIT_PERIOD)?.value || null,
          bouncers: [...eventBouncerIds] || null,
        }

        if (!event) {
          await createRoomEvent({
            variables: { ...variables, roomId: targetId },
          })

          toast.success({
            title: 'Room event',
            text: 'Room event successfully created',
          })
        } else {
          await updateRoomEvent({ variables: { ...variables, id: targetId } })
          toast.success({
            title: 'Room event',
            text: 'Room event successfully updated',
          })
        }

        close.current()
      } catch (error) {
        toast.error({
          title: 'Room event',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [
      addressInputValue,
      addressOnClick,
      createRoomEvent,
      event,
      eventBouncerIds,
      eventEnd,
      eventStart,
      mapClick?.lat,
      mapClick?.lng,
      targetId,
      timezone?.value,
      updateRoomEvent,
      userTimezone,
    ],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      setAddressInputValue(value)
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleChangeStartDay = useCallback(value => {
    if (!value) {
      setStartDay(null)
    } else {
      setStartDay(value)
    }
  }, [])

  const handleChangeEndDay = useCallback(value => {
    if (!value) {
      setEndDay(null)
    } else {
      setEndDay(value)
    }
  }, [])

  const handleChangeStartTime = useCallback(value => {
    if (!value) {
      setStartTime(null)
    } else {
      setStartTime({
        label: value?.label,
        value: value?.value,
      })
    }
  }, [])

  const handleChangeEndTime = useCallback(value => {
    if (!value) {
      setEndTime(null)
    } else {
      setEndTime({
        label: value?.label,
        value: value?.value,
      })
    }
  }, [])

  const handleMapClick = useCallback(e => {
    setMapClick({
      lat: e?.latLng?.lat(),
      lng: e?.latLng?.lng(),
    })
  }, [])

  const handleMapSearchMarker = useCallback(place => {
    setMapClick({
      lat: place?.geometry?.location?.lat(),
      lng: place?.geometry?.location?.lng(),
    })
  }, [])

  const handleMapIdle = useCallback(mapValue => {
    setMapZoom(mapValue?.getZoom())
    setMapCenter(mapValue?.getCenter()?.toJSON())
  }, [])

  const roomBouncersById = useMemo(
    () => keyBy(roomBouncers, 'id'),
    [roomBouncers],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={!event ? s('titleCreate') : s('titleEdit')}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit, values }) => (
          <>
            <Content>
              <Column mt={5}>
                <Text>{s('fields.title')}</Text>
                <InputField
                  alternateStyle
                  label={s('fields.titleEx')}
                  mt={2}
                  name={FIELDS.NAME}
                  width={1}
                />
              </Column>
              <Column mt={4}>
                <Text>{s('fields.schedule.title')}</Text>
                <RadioField
                  alternateCircle
                  label={s('fields.schedule.lifetime')}
                  mt={3}
                  name={FIELDS.IS_LIFETIME}
                  value="true"
                />
                <RadioField
                  alternateCircle
                  label={s('fields.schedule.timeSpecific')}
                  mt={3}
                  name={FIELDS.IS_LIFETIME}
                  value="false"
                />
              </Column>
              {values?.isLifetime === 'false' && (
                <Column mt={4}>
                  <ScanLimitWrapper spaceBetween width={1}>
                    <Row center mb={4} mr={3}>
                      <Text mr={3}>{s('fields.maximumScanCount')}</Text>
                      <InputField
                        alternateStyle
                        name={FIELDS.SCAN_LIMIT}
                        noChange
                        step="1"
                        type="number"
                        width={90}
                      />
                    </Row>
                    <Row center mb={4}>
                      <Text mr={3}>{s('fields.successfulScanPeriod')}</Text>
                      <SelectField
                        alternateStyle
                        name={FIELDS.SCAN_LIMIT_PERIOD}
                        options={periodsOptions}
                        width={144}
                      />
                    </Row>
                  </ScanLimitWrapper>
                  <Text>{s('fields.startTitle')}</Text>
                  <Row center mt={2} spaceBetween width={1}>
                    <DateInput
                      offset={[208, -70]}
                      placeholder={s('fields.startDay')}
                      value={startDay}
                      width={208}
                      onChange={handleChangeStartDay}
                    />
                    <Select
                      height={40}
                      options={timeOptions}
                      placeholder={s('fields.startTime')}
                      timePicker
                      value={startTime}
                      width={208}
                      onChange={value => handleChangeStartTime(value)}
                    />
                  </Row>
                  <Text mt={4}>{s('fields.endTitle')}</Text>
                  <Row center mt={2} spaceBetween width={1}>
                    <DateInput
                      offset={[208, -70]}
                      placeholder={s('fields.endDay')}
                      value={endDay}
                      width={208}
                      onChange={handleChangeEndDay}
                    />
                    <Select
                      height={40}
                      options={timeOptions}
                      placeholder={s('fields.endTime')}
                      timePicker
                      value={endTime}
                      width={208}
                      onChange={value => handleChangeEndTime(value)}
                    />
                  </Row>
                  <Text mt={4}>{s('fields.timezoneTitle')}</Text>
                  <TimezoneSelectInput
                    alternateStyle
                    value={timezone}
                    onChange={setTimezone}
                  />
                </Column>
              )}
              <Column mt={4}>
                <Text>{s('fields.location')}</Text>
                <Text mt={1} primary small>
                  {s('fields.locationHint')}
                </Text>
                <Input
                  alternateStyle
                  label={s('fields.locationEx')}
                  mt={2}
                  name="search"
                  value={addressInputValue}
                  width={1}
                  onChange={handleChangeSearch}
                />
                <Wrapper apiKey={GOOGLE_API_KEY} libraries={['places']}>
                  <Map
                    center={mapCenter}
                    click={mapClick}
                    isInitialLoad={isInitialLoad}
                    search={search}
                    zoom={mapZoom}
                    onAddressInputValue={setAddressInputValue}
                    onClick={handleMapClick}
                    onIdle={handleMapIdle}
                    onInitialLoad={setIsInitialLoad}
                    onMapSearchMarker={handleMapSearchMarker}
                    onSetAddressByClick={setAddressOnClick}
                  />
                </Wrapper>
              </Column>
              <Row alignItems="center" fullWidth mb={3} mt={4} spaceBetween>
                <Column>
                  <Row>
                    <Text medium> {s('fields.verifiers')} </Text>
                    <Text primary> {s('fields.optional')}</Text>
                  </Row>

                  <Text primary>{s('fields.assignVerifiers')}</Text>
                </Column>
                <Button dark height={32} onClick={handleBouncersModal}>
                  {s('actions.assign')}
                </Button>
              </Row>
              {map([...eventBouncerIds], eventBouncerId => (
                <BouncersFrame mt={1}>
                  <Row center>
                    <Column mr={2}>
                      <Link clean to={APP_PROFILE(eventBouncerId)}>
                        <Avatar
                          online={roomBouncersById[eventBouncerId]?.online}
                          size={40}
                          src={
                            roomBouncersById[eventBouncerId]?.profile?.photoUrl
                          }
                          username={roomBouncersById[eventBouncerId]?.username}
                        />
                      </Link>
                    </Column>
                    <Column>
                      <Text bold mb={1}>
                        {roomBouncersById[eventBouncerId]?.username || '--'}
                      </Text>
                      <Text primary small>
                        {s('fields.memberSince')}{' '}
                        {DateTime.fromISO(
                          roomBouncersById[eventBouncerId]?.createdAt,
                          {
                            setZone: true,
                          },
                        ).toLocaleString(DateTime.DATE_SHORT)}
                      </Text>
                    </Column>
                  </Row>
                  <Button
                    neutral
                    noEffectsIcon
                    stroke
                    onClick={() => handleDeleteEventBouncer(eventBouncerId)}
                  >
                    <VscClose />
                  </Button>
                </BouncersFrame>
              ))}
            </Content>

            <Column center mt={4} pb={5}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <Row spaceBetween width={272}>
                  <Button
                    height={32}
                    mr={3}
                    outline
                    width={150}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                  <Button height={32} width={150} onClick={handleSubmit}>
                    {!event ? s('actions.create') : s('actions.update')}
                  </Button>
                </Row>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
      {bouncersModal?.isOpen && (
        <BouncersModal
          eventBouncerIds={eventBouncerIds}
          isOpen={bouncersModal?.isOpen}
          roomBouncers={roomBouncers}
          onBack={handleBouncersModalBack}
          onSelectEventBouncers={handleSelectEventBouncer}
        />
      )}
    </Modal>
  )
}

EventModal.defaultProps = {
  event: null,
  roomBouncers: null,
  targetId: null,
}

EventModal.propTypes = {
  event: PropTypes.object,
  roomBouncers: PropTypes.array,
  targetId: PropTypes.string,
}

export default EventModal
