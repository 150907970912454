import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { IoCloseSharp } from 'react-icons/io5'

import filter from 'lodash/filter'
import find from 'lodash/find'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'

import { Dialog } from 'Components/Blocks/Modals'
import { ConnectSocialModal } from 'Components/Blocks/User/Modals'
import { Button, Flex, Row, Text } from 'Components/UI'

import { VERIFICATION_METHODS } from 'Constants/ids'

import disconnectSocialAccountMutation from 'GraphQL/Mutations/Social/disconnectSocialAccount.graphql'

import { useAppContext, useEntranceContext } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { CheckIcon, ReconnectIcon, SocialButtonWrapper } from './styles'

import { LinkedInBigIcon, TelegramBigIcon, TwitterIcon } from '../styles'

function SocialAccounts({ user, isCollapsed }) {
  const s = useScopedI18n('user.profile')
  const { socialAccounts } = user
  const [{ exclusiveMode, roomGroupMeta }] = useEntranceContext()
  const { refetchMe } = useAppContext()

  const [disconnectSocialAccount] = useMutation(disconnectSocialAccountMutation)

  const [isTelegramConnected, setIsTelegramConnected] = useState(false)
  const [isNeedTelegramReconnect, setIsNeedTelegramReconnect] = useState(false)
  const [isNeedTwitterReconnect, setIsNeedTwitterReconnect] = useState(false)
  const [isTwitterConnected, setIsTwitterConnected] = useState(false)
  const [isNeedLinkedInReconnect, setIsNeedLinkedInReconnect] = useState(false)
  const [isLinkedInConnected, setIsLinkedInConnected] = useState(false)
  const [connectModal, setConnectModal] = useState({
    isOpen: false,
    entity: null,
  })

  const [disconnectModal, setDisconnectModal] = useState({
    isOpen: false,
    entity: null,
  })

  const sortedSocialAccounts = useMemo(() => {
    if (exclusiveMode && roomGroupMeta?.socialLinks) {
      const sortedArray = sortBy(
        filter(roomGroupMeta?.socialLinks, { show: true }),
        'sortOrder',
      )

      return map(sortedArray, 'kind')
    }
    return [
      VERIFICATION_METHODS.TELEGRAM,
      VERIFICATION_METHODS.TWITTER,
      VERIFICATION_METHODS.LINKEDIN,
    ]
  }, [exclusiveMode, roomGroupMeta])

  useEffect(() => {
    if (socialAccounts) {
      map(socialAccounts, ({ socialNetwork, slug, meta }) => {
        if (socialNetwork === VERIFICATION_METHODS.TELEGRAM && !!slug) {
          setIsTelegramConnected(true)
          setIsNeedTelegramReconnect(false)
        } else if (socialNetwork === VERIFICATION_METHODS.TWITTER && !!slug) {
          setIsTwitterConnected(true)
          setIsNeedTwitterReconnect(false)
        } else if (
          socialNetwork === VERIFICATION_METHODS.LINKEDIN &&
          meta?.firstName &&
          meta?.lastName
        ) {
          setIsLinkedInConnected(true)
          setIsNeedLinkedInReconnect(false)
        } else if (socialNetwork === VERIFICATION_METHODS.TELEGRAM && !slug) {
          setIsNeedTelegramReconnect(true)
        } else if (socialNetwork === VERIFICATION_METHODS.TWITTER && !slug) {
          setIsNeedTwitterReconnect(true)
        } else if (
          socialNetwork === VERIFICATION_METHODS.LINKEDIN &&
          (!meta?.firstName || !!meta?.lastName)
        ) {
          setIsNeedLinkedInReconnect(true)
        } else {
          setIsTelegramConnected(false)
          setIsTwitterConnected(false)
          setIsLinkedInConnected(false)
        }
      })
    }
  }, [socialAccounts])

  const handleConnectSocial = useCallback(entity => {
    setConnectModal({ isOpen: true, entity })
  }, [])
  const handleCloseConnectSocialModal = useCallback(() => {
    setConnectModal({ isOpen: false, entity: null })
  }, [])

  const handleOpenDisconnectModal = useCallback(
    (event, social) => {
      event.stopPropagation()
      setDisconnectModal({
        isOpen: true,
        entity: find(
          socialAccounts,
          socialAccount => socialAccount?.socialNetwork === social,
        ),
      })
    },

    [socialAccounts],
  )

  const handleCloseDisconnectModal = useCallback(() => {
    setDisconnectModal({ isOpen: false, entity: null })
  }, [])

  const handleDisconnectSocial = useCallback(
    async success => {
      if (success) {
        try {
          await disconnectSocialAccount({
            variables: { id: disconnectModal.entity?.id },
          })

          await refetchMe()
          toast.success({
            title: 'Disconnect account',
            text: 'Account was successfully disconnected',
          })
          if (
            disconnectModal?.entity?.socialNetwork ===
            VERIFICATION_METHODS.TELEGRAM
          ) {
            setIsNeedTelegramReconnect(false)
            setIsTelegramConnected(false)
          }
          if (
            disconnectModal?.entity?.socialNetwork ===
            VERIFICATION_METHODS.LINKEDIN
          ) {
            setIsNeedLinkedInReconnect(false)
            setIsLinkedInConnected(false)
          }
          if (
            disconnectModal?.entity?.socialNetwork ===
            VERIFICATION_METHODS.TWITTER
          ) {
            setIsNeedTwitterReconnect(false)
            setIsTwitterConnected(false)
          }
        } catch (error) {
          toast.error({ title: 'Disconnect account', text: error?.message })
        }
      }
      handleCloseDisconnectModal()
    },
    [
      disconnectModal,
      disconnectSocialAccount,
      handleCloseDisconnectModal,
      refetchMe,
    ],
  )

  const renderSocialAccounts = useMemo(
    () =>
      map(sortedSocialAccounts, account => {
        switch (account) {
          case VERIFICATION_METHODS.TELEGRAM:
            return exclusiveMode ? null : (
              <SocialButtonWrapper
                borderTop={isCollapsed}
                justifyCenter={isCollapsed}
                py={isCollapsed ? 2 : 0}
              >
                {isTelegramConnected && (
                  <IoCloseSharp
                    className="closeIcon"
                    onClick={event =>
                      handleOpenDisconnectModal(
                        event,
                        VERIFICATION_METHODS.TELEGRAM,
                      )
                    }
                  />
                )}
                <Button
                  disabled={isTelegramConnected && !isNeedTelegramReconnect}
                  noEffectsIcon
                  social
                  onClick={() => handleConnectSocial(user)}
                >
                  <TelegramBigIcon />
                  {!isNeedTelegramReconnect ? (
                    <>{isTelegramConnected && <CheckIcon />}</>
                  ) : (
                    <ReconnectIcon />
                  )}
                </Button>
              </SocialButtonWrapper>
            )
          case VERIFICATION_METHODS.TWITTER:
            return (
              <SocialButtonWrapper
                borderTop={isCollapsed}
                justifyCenter={isCollapsed}
                py={isCollapsed ? 2 : 0}
              >
                {isTwitterConnected && (
                  <IoCloseSharp
                    className="closeIcon"
                    onClick={event =>
                      handleOpenDisconnectModal(
                        event,
                        VERIFICATION_METHODS.TWITTER,
                      )
                    }
                  />
                )}
                <Button
                  disabled={isTwitterConnected && !isNeedTwitterReconnect}
                  noEffectsIcon
                  social
                  onClick={() => handleConnectSocial(user)}
                >
                  <TwitterIcon />
                  {!isNeedTwitterReconnect ? (
                    <>{isTwitterConnected && <CheckIcon />}</>
                  ) : (
                    <ReconnectIcon />
                  )}
                </Button>
              </SocialButtonWrapper>
            )
          case VERIFICATION_METHODS.LINKEDIN:
            return (
              <SocialButtonWrapper
                borderTop={isCollapsed}
                justifyCenter={isCollapsed}
                py={isCollapsed ? 2 : 0}
              >
                {isLinkedInConnected && (
                  <IoCloseSharp
                    className="closeIcon"
                    onClick={event =>
                      handleOpenDisconnectModal(
                        event,
                        VERIFICATION_METHODS.LINKEDIN,
                      )
                    }
                  />
                )}
                <Button
                  disabled={isLinkedInConnected && !isNeedLinkedInReconnect}
                  noEffectsIcon
                  social
                  onClick={() => handleConnectSocial(user)}
                >
                  <LinkedInBigIcon />
                  {!isNeedLinkedInReconnect ? (
                    <>{isLinkedInConnected && <CheckIcon />}</>
                  ) : (
                    <ReconnectIcon />
                  )}
                </Button>
              </SocialButtonWrapper>
            )
          default:
            return null
        }
      }),
    [
      exclusiveMode,
      handleConnectSocial,
      handleOpenDisconnectModal,
      isCollapsed,
      isLinkedInConnected,
      isNeedLinkedInReconnect,
      isNeedTelegramReconnect,
      isNeedTwitterReconnect,
      isTelegramConnected,
      isTwitterConnected,
      sortedSocialAccounts,
      user,
    ],
  )

  return (
    <Row center>
      <Flex
        alignItems="center"
        borderTop={isCollapsed}
        flexDirection={isCollapsed ? 'column' : 'row'}
        mb={isCollapsed ? 0 : 1}
        mx={isCollapsed ? 0 : 16}
        width={1}
      >
        {!isCollapsed && (
          <Text custom mr={1}>
            {s('social.title')}
          </Text>
        )}

        {renderSocialAccounts}
      </Flex>

      <Dialog
        content={`Are you sure you want to disconnect ${disconnectModal?.entity?.socialNetwork}?`}
        isOpen={disconnectModal?.isOpen}
        secondary
        title="Disconnect social account"
        warning
        onClose={handleCloseDisconnectModal}
        onFinish={handleDisconnectSocial}
      />

      <ConnectSocialModal
        isOpen={connectModal.isOpen}
        sortedSocialAccounts={sortedSocialAccounts}
        user={connectModal.entity}
        onClose={handleCloseConnectSocialModal}
      />
    </Row>
  )
}

SocialAccounts.defaultProps = {
  isCollapsed: false,
}

SocialAccounts.propTypes = {
  isCollapsed: PropTypes.bool,
  user: PropTypes.object.isRequired,
}

export default SocialAccounts
