import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { BsDot } from 'react-icons/bs'

import map from 'lodash/map'

import { useScopedI18n } from 'Services/I18n'

import { SortArrowsIcon, StyledButton } from './styles'

import Dropdown from '../Dropdown'

const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

function Sort({
  sortOptions,
  translationKey,
  setSort,
  offset,
  sortOrderException,
  setPage,
  defaultKey,
  ...rest
}) {
  const s = useScopedI18n(translationKey)

  const [column, setColumn] = useState(defaultKey)
  const [order, setOrder] = useState(
    column !== sortOrderException ? SORT_ORDER.DESC : SORT_ORDER.ASC,
  )

  useEffect(() => {
    if (!column) {
      return
    }
    setSort({ column, order })
  }, [column, order, setSort])

  const handleSort = useCallback(
    value => {
      if (setPage) {
        setPage(0)
      }
      if (column === value && order === SORT_ORDER.DESC) {
        setOrder(SORT_ORDER.ASC)
        return
      }
      if (column === value && order === SORT_ORDER.ASC) {
        setOrder(SORT_ORDER.DESC)
        return
      }
      setColumn(value)
      if (value === sortOrderException) {
        setOrder(SORT_ORDER.ASC)
        return
      }
      setOrder(SORT_ORDER.DESC)
    },
    [column, order, setPage, sortOrderException],
  )

  const renderIcon = useCallback(
    option => {
      if (column === option?.value) {
        return <BsDot />
      }
      return null
    },
    [column],
  )

  const dropdownSortOptions = map(sortOptions, option => ({
    type: Dropdown.ItemType.Button,
    content: s(`fields.sort.${option?.name}`),
    action: () => handleSort(option?.value),
    Icon: () => renderIcon(option),
  }))

  return (
    <Dropdown
      items={dropdownSortOptions}
      offset={offset}
      title={s('fields.sort.title')}
    >
      <StyledButton
        icon
        sortAsc={order === SORT_ORDER.ASC}
        sortDesc={order === SORT_ORDER.DESC}
        sorting
        {...rest}
      >
        <SortArrowsIcon />
      </StyledButton>
    </Dropdown>
  )
}

Sort.defaultProps = {
  defaultKey: '',
  offset: [],
  setPage: null,
  setSort: null,
  sortOptions: [],
  sortOrderException: '',
  translationKey: '',
}

Sort.propTypes = {
  defaultKey: PropTypes.string,
  offset: PropTypes.array,
  setPage: PropTypes.func,
  setSort: PropTypes.func,
  sortOptions: PropTypes.array,
  sortOrderException: PropTypes.string,
  translationKey: PropTypes.string,
}

export default Sort
