import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@styled-system/props'

import noop from 'lodash/noop'

import Select from 'Components/UI/Select'

import _ from 'Services/I18n'

import Control from './Control'

// TODO: add logic to pass search suggestions
function Search({ inputValue, placeholder, small, onChange, ...rest }) {
  return (
    <Select
      components={{
        DropdownIndicator: null,
        Control,
      }}
      isSearchable
      menuIsOpen={false}
      placeholder={placeholder || _('search.placeholder')}
      {...pick(rest)}
      inputValue={inputValue}
      small={small}
      onInputChange={onChange}
    />
  )
}

Search.defaultProps = {
  inputValue: undefined,
  placeholder: undefined,
  small: false,
  onChange: noop,
}

Search.propTypes = {
  inputValue: PropTypes.string,
  placeholder: PropTypes.string,
  small: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Search
