import React, { useCallback, useMemo } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import noop from 'lodash/noop'

import logo from 'Assets/Png/logo.png'

import { Button, Column, Text } from 'Components/UI'
import InputField from 'Components/UI/Forms/Input/InputField'

import getMagicLinkMutation from 'GraphQL/Mutations/MagicLink/getMagicLink.graphql'

import { useEntranceContext, useRouteState } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import _, { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { ConnectWrapper, Image } from '../styles'

export const FIELD = {
  EMAIL: 'email',
}

function SignInByEmail({ onStep, loading, setLoading, onGetMagicLink }) {
  const s = useScopedI18n('public.modal.inviteToRoom')
  const [getMagicLink] = useMutation(getMagicLinkMutation)
  const { setIsDirectLink } = useRouteState()
  const [{ roomMeta, exclusiveMode, enteredFrom }] = useEntranceContext()

  const formConstraints = useMemo(
    () => ({
      [FIELD.EMAIL]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.emailRequired')}`,
        },
        email: {
          email: true,
          message: `^${_('auth.shared.emailInvalid')}`,
        },
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        const result = await getMagicLink({
          variables: {
            email: values?.[FIELD.EMAIL],
            roomId:
              !!enteredFrom || (exclusiveMode && roomMeta?.id)
                ? roomMeta?.id
                : undefined,
          },
        })

        setIsDirectLink(false)

        if (result?.data?.getMagicLink?.ok) {
          onGetMagicLink(values?.[FIELD.EMAIL])
        }
      } catch (error) {
        toast.error({
          title: 'Sign In',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [
      setLoading,
      getMagicLink,
      enteredFrom,
      exclusiveMode,
      roomMeta?.id,
      setIsDirectLink,
      onGetMagicLink,
    ],
  )

  return (
    <Column center fullWidth>
      <Form
        render={({ handleSubmit }) => (
          <>
            <InputField
              alternateStyle
              label={_('auth.shared.email')}
              mt={4}
              name={FIELD.EMAIL}
              noChange
              width={1}
            />
            <Button
              disabled={loading}
              height={48}
              mt={24}
              type="submit"
              width={230}
              onClick={handleSubmit}
            >
              {_('auth.signIn.action')}
            </Button>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
      {!exclusiveMode && (
        <>
          {' '}
          <Text blueDark mb={3} mt={6}>
            {s('actions.or')}
          </Text>
          <ConnectWrapper disabled={loading} mb={2} onClick={() => onStep(2)}>
            <Image src={logo} />
            <Text bold ml={3}>
              {s('actions.signInPassword')}
            </Text>
          </ConnectWrapper>
        </>
      )}
    </Column>
  )
}

SignInByEmail.defaultProps = {
  loading: false,
  setLoading: noop,
  onGetMagicLink: noop,
  onStep: noop,
}

SignInByEmail.propTypes = {
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  onGetMagicLink: PropTypes.func,
  onStep: PropTypes.func,
}

export default SignInByEmail
