import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { FiRefreshCw } from 'react-icons/fi'

import ms from 'ms'

import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import round from 'lodash/round'
import sumBy from 'lodash/sumBy'

import { Button, Column, Loader, Row, Text } from 'Components/UI'

import updateUserBalanceMutation from 'GraphQL/Mutations/User/updateUserBalance.graphql'

import { useAppContext, useTheForce } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Utils from 'Utils'

import { Container, Content, ScrollingContainer } from './styles'

import { IconWrapper } from '../styles'

function Balances({ isCollapsed, updatedAt }) {
  const { me, refetchMe } = useAppContext()

  const refresh = useTheForce()

  const s = useScopedI18n('user.profile')
  const [loading, setLoading] = useState(false)

  const balances = useMemo(() => {
    const allBalances = []

    me?.wallets?.forEach(wallet => {
      allBalances.push(...(wallet?.tokenBalances || []))
    })

    return allBalances
  }, [me])

  useEffect(() => {
    const interval = setInterval(refresh, ms('10s'))

    return () => {
      clearInterval(interval)
    }
  }, [refresh, updatedAt])

  // Function to sum up balances with same token symbols (but different wallets) to
  // display as summarized balance by that token symbol
  const displayedBalances = useMemo(
    () =>
      map(groupBy(balances, 'token'), token => ({
        ...token[0],
        balance: sumBy(token, 'balance'),
      })),
    [balances],
  )

  const [updateUserBalance] = useMutation(updateUserBalanceMutation)

  const handleUpdateUserBalance = useCallback(async () => {
    setLoading(true)

    try {
      await updateUserBalance()
      await refetchMe()
    } catch (error) {
      toast.error({
        title: 'Update user balance',
        text: s(`errors.${error?.message}`),
      })
    } finally {
      setLoading(false)
    }
  }, [refetchMe, s, updateUserBalance])

  return (
    <Container collapsed={isCollapsed}>
      {!isCollapsed ? (
        <Content>
          <Row spaceBetween>
            <Column>
              <Text bold>{s('fields.balance')}</Text>
              {loading ? (
                <Loader />
              ) : (
                <Text mt={1} primary small>
                  {updatedAt
                    ? s('fields.balanceUpdate', {
                        update: Utils.Date.timeAgo(updatedAt),
                      })
                    : 'Never updated'}
                </Text>
              )}
            </Column>
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={handleUpdateUserBalance}
            >
              <IconWrapper>
                <FiRefreshCw />
              </IconWrapper>
            </Button>
          </Row>
          <Content borderTop mt={16} pt={12}>
            <ScrollingContainer>
              {map(displayedBalances, ({ id, token, balance }) => (
                <Row key={id} mt={balances[0]?.id === id ? 0 : 11} spaceBetween>
                  <Text bold small>
                    {token?.toUpperCase()}
                  </Text>
                  <Text small>{round(balance, 10) || 'N/A'}</Text>
                </Row>
              ))}
            </ScrollingContainer>
          </Content>
        </Content>
      ) : (
        <Content collapsedContent>
          <Column>
            <Text bold small>
              {s('fields.balance')}
            </Text>
            <Row justifyCenter>
              <Button
                height={22}
                iconButton
                mt={2}
                noEffectsIcon
                smallest
                stroke
                width={22}
                onClick={handleUpdateUserBalance}
              >
                <IconWrapper>
                  <FiRefreshCw />
                </IconWrapper>
              </Button>
            </Row>
          </Column>
        </Content>
      )}
    </Container>
  )
}

Balances.defaultProps = {
  isCollapsed: false,
  updatedAt: null,
}

Balances.propTypes = {
  isCollapsed: PropTypes.bool,
  updatedAt: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
}

export default Balances
