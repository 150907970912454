import React from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

const Container = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

function Ellipsis({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>
}

Ellipsis.defaultProps = {
  children: 0,
}

Ellipsis.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default Ellipsis
