import React from 'react'
import PropTypes from 'prop-types'

import { Column } from 'Components/UI/Flex'

import { AnimatedIcon, Text } from './styles'

function Loader({
  text,
  fullHeight,
  fullScreen,
  fullWidth,
  alignSelfCenter,
  currentColor,
  ml,
  mr,
  mb,
  mt,
  size,
}) {
  return (
    <Column
      alignSelfCenter={alignSelfCenter}
      center
      fullHeight={fullHeight}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      justifyCenter
      mb={mb}
      ml={ml}
      mr={mr}
      mt={mt}
      squeeze={!fullWidth}
    >
      <AnimatedIcon currentColor size={size} />
      {text && <Text>{text}</Text>}
    </Column>
  )
}

Loader.defaultProps = {
  alignSelfCenter: undefined,
  fullHeight: undefined,
  fullScreen: undefined,
  fullWidth: undefined,
  currentColor: false,
  mb: undefined,
  ml: undefined,
  mr: undefined,
  mt: undefined,
  size: 24,
  text: undefined,
}

Loader.propTypes = {
  alignSelfCenter: PropTypes.bool,
  currentColor: PropTypes.bool,
  fullHeight: PropTypes.bool,
  fullScreen: PropTypes.bool,
  fullWidth: PropTypes.bool,
  mb: PropTypes.number,
  ml: PropTypes.number,
  mr: PropTypes.number,
  mt: PropTypes.number,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
}

export default Loader
