import React, { useCallback, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'

import { useModalStatus } from '@privy-io/react-auth'

import get from 'lodash/get'
import includes from 'lodash/includes'
import noop from 'lodash/noop'

import { Loader, Modal, Row, Text } from 'Components/UI'

import { AUTH_METHODS } from 'Constants/ids'

import { useGlobalAuthMethods } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import ConfirmMagicLinkModal from './ConfirmMagicLinkModal'
import JoinRoomHeader from './JoinRoomHeader'
import SignInByEmail from './SignInByEmail'
import SignInByPassword from './SignInByPassword'
import SignInOptions from './SignInOptions'
import { Content, ExternalLink } from './styles'

function SignInModal({ closable, onClose, roomMeta, ...rest }) {
  const s = useScopedI18n('public.modal.signIn')
  const { isOpen } = useModalStatus()
  const [autGlobalMethods, loadingAuthGlobalMethods] = useGlobalAuthMethods()

  const [loading, setLoading] = useState(false)
  const [confirmMagicLinkModal, setConfirmMagicLinkModal] = useState({
    isOpen: false,
    email: null,
  })

  const [step, setStep] = useState(0)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleClose = useCallback(() => {
    onClose?.()
    close.current?.()
  }, [onClose])

  const handleCloseConfirmMagicLink = useCallback(() => {
    setConfirmMagicLinkModal({ isOpen: false, email: null })
    handleClose()
  }, [handleClose])

  const authMethods = useMemo(
    () =>
      roomMeta?.authSettings?.override
        ? roomMeta?.authSettings?.methods
        : autGlobalMethods,
    [
      autGlobalMethods,
      roomMeta?.authSettings?.methods,
      roomMeta?.authSettings?.override,
    ],
  )

  const hideInternalAuth = useMemo(
    () =>
      includes(authMethods, AUTH_METHODS.PRIVY) ||
      roomMeta?.authSettings?.hideInternalAuth ||
      false,
    [authMethods, roomMeta],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      handleBack={() => setStep(step - 1)}
      hidden={isOpen}
      logo={roomMeta?.logoUrl}
      noCloseButton={!closable}
      shouldCloseOnOverlayClick={closable}
      title={!roomMeta ? s('title') : undefined}
      withBackButton={step !== 0}
      withLogo
      onClose={handleClose}
      onMount={handleMount}
    >
      <Content>
        {loading || loadingAuthGlobalMethods ? (
          <Loader mt={6} />
        ) : (
          <>
            <>
              {roomMeta && <JoinRoomHeader />}

              {step === 0 && (
                <SignInOptions
                  authMethods={authMethods}
                  hideInternalAuth={hideInternalAuth}
                  loading={loading}
                  setLoading={setLoading}
                  onStep={setStep}
                />
              )}

              {step === 1 && (
                <SignInByEmail
                  loading={loading}
                  setLoading={setLoading}
                  onGetMagicLink={email => {
                    setConfirmMagicLinkModal({ isOpen: true, email })
                  }}
                  onStep={setStep}
                />
              )}

              {step === 2 && (
                <SignInByPassword
                  loading={loading}
                  setLoading={setLoading}
                  onStep={setStep}
                />
              )}

              <ConfirmMagicLinkModal
                email={confirmMagicLinkModal?.email}
                isOpen={confirmMagicLinkModal?.isOpen}
                onClose={handleCloseConfirmMagicLink}
              />
            </>
          </>
        )}

        <Row mt={5}>
          <Text grayLight light small>
            {s('termsOfServiceTitle')}
            <ExternalLink
              href="https://sentr3.com/terms-of-service"
              rel="noopener noreferrer external"
              target="_blank"
            >
              {s('termsOfService')}
            </ExternalLink>
          </Text>
        </Row>
      </Content>
    </Modal>
  )
}

SignInModal.defaultProps = {
  closable: false,
  roomMeta: null,
  onClose: noop,
}

SignInModal.propTypes = {
  closable: PropTypes.bool,
  roomMeta: PropTypes.object,
  onClose: PropTypes.func,
}

export default SignInModal
