import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { RiSearchLine } from 'react-icons/ri'

import { DateTime } from 'luxon'

import debounce from 'lodash/debounce'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'
import {
  Button,
  Column,
  Dropdown,
  Link,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'
import { Input } from 'Components/UI/Forms'

import { ROOM_MANAGER_TYPES } from 'Constants/rooms'

import addRoomManagerMutation from 'GraphQL/Mutations/Managers/addRoomManager.graphql'
import removeRoomManagerMutation from 'GraphQL/Mutations/Managers/removeRoomManager.graphql'
import participantsByRoomIdQuery from 'GraphQL/Queries/Rooms/participantsByRoomId.graphql'
import { updateParticipantsQueryUpdater } from 'GraphQL/Updaters/rooms'

import { useAppContext, useFollow } from 'Hooks'

import { APP_PROFILE } from 'Router/routes'

import { useLazyQuery, useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import ParticipantsSubscription from './ParticipantsSubscription'
import { Content, FirefoxPaginationAnchor, ScrollingContainer } from './styles'

const sort = { column: 'internalId', order: 'desc' }

function RoomUserListModal({
  roomId,
  owners,
  moderators,
  bouncers,
  refetch,
  participantsCount,
  onlineParticipantsCount,
  locked,
  ...rest
}) {
  const s = useScopedI18n('user.modal.userList')
  const { me } = useAppContext()
  const { myFollowing, handleFollow, handleUnfollow } = useFollow({
    trigger: rest?.isOpen,
  })

  const [search, setSearch] = useState('')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  useEffect(() => {
    if (search) {
      setLimit(9999)
    } else {
      setLimit(10)
    }
  }, [search])

  const [ownersById, moderatorsById, bouncersById] = useMemo(
    () => [keyBy(owners, 'id'), keyBy(moderators, 'id'), keyBy(bouncers, 'id')],
    [owners, moderators, bouncers],
  )

  const isOwnedByMe = useMemo(() => ownersById[me?.id], [me?.id, ownersById])

  const [
    loadParticipants,
    { data, loading, fetchMore, variables: participantsVariables },
  ] = useLazyQuery(participantsByRoomIdQuery)

  const [addRoomManager] = useMutation(addRoomManagerMutation)
  const [removeRoomManager] = useMutation(removeRoomManagerMutation)

  const participants = useMemo(
    () => data?.participantsByRoomId ?? {},
    [data?.participantsByRoomId],
  )

  const { rows, pages } = participants

  const fetchData = useCallback(() => {
    loadParticipants({
      variables: { page: 0, limit, roomId, sort },
    })
  }, [limit, loadParticipants, roomId])

  useEffect(() => {
    if (roomId && !search && !locked) {
      fetchData()
    } else if (roomId && search) {
      const variables = { page: 0, limit, roomId, sort, search }

      loadParticipants({ variables })
    }
  }, [fetchData, limit, locked, loadParticipants, roomId, search])

  const handleLoadMore = useCallback(async () => {
    if (page < pages - 1) {
      await fetchMore({
        variables: { page: page + 1, limit, roomId, sort },
        updateQuery: (prev, fetchMoreResult) =>
          updateParticipantsQueryUpdater(prev, fetchMoreResult),
      })

      setPage(page + 1)
    }
  }, [fetchMore, limit, page, pages, roomId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleAddManager = useCallback(
    async ({ userId, type }) => {
      try {
        await addRoomManager({
          variables: { roomId, userId, type },
        })

        toast.success({
          title: 'Add room manager',
          text: 'Room manager successfully added',
        })

        if (refetch) {
          await refetch()
        }
      } catch (error) {
        toast.error({
          title: 'Add room manager',
          text: error?.message,
        })
      }
    },
    [addRoomManager, refetch, roomId],
  )

  const handleRemoveManager = useCallback(
    async ({ userId, type }) => {
      try {
        await removeRoomManager({
          variables: { roomId, userId, type },
        })

        toast.success({
          title: 'Remove room manager',
          text: 'Room manager successfully removed',
        })
        if (refetch) {
          await refetch()
        }
      } catch (error) {
        toast.error({
          title: 'Remove room manager',
          text: error?.message,
        })
      }
    },
    [refetch, removeRoomManager, roomId],
  )

  const renderOwnersModerators = useCallback(
    userId => {
      const owner = ownersById[userId]
      const moderator = moderatorsById[userId]
      const bouncer = bouncersById[userId]
      if (owner) {
        return (
          <Row center justifyCenter ml={16} width={150}>
            <Text custom>{s('owner')}</Text>
          </Row>
        )
      }
      if (!owner && !moderator && bouncer) {
        return (
          <Dropdown
            items={[
              moderators?.length < 5 && {
                type: Dropdown.ItemType.Button,
                content: s('moderator'),
                action: () =>
                  handleAddManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.MODERATOR,
                  }),
              },
              {
                type: Dropdown.ItemType.Button,
                content: s('removeBouncer'),
                action: () =>
                  handleRemoveManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.BOUNCER,
                  }),
              },
            ]}
          >
            <Button height={23} ml={16} small width={150}>
              {s('bouncer')}
            </Button>
          </Dropdown>
        )
      }
      if (!owner && moderator && !bouncer) {
        return (
          <Dropdown
            items={[
              {
                type: Dropdown.ItemType.Button,
                content: s('removeModerator'),
                action: () =>
                  handleRemoveManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.MODERATOR,
                  }),
              },
              {
                type: Dropdown.ItemType.Button,
                content: s('bouncer'),
                action: () =>
                  handleAddManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.BOUNCER,
                  }),
              },
            ]}
          >
            <Button height={23} ml={16} small width={150}>
              {s('moderator')}
            </Button>
          </Dropdown>
        )
      }
      if (!owner && moderator && bouncer) {
        return (
          <Dropdown
            items={[
              {
                type: Dropdown.ItemType.Button,
                content: s('removeModerator'),
                action: () =>
                  handleRemoveManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.MODERATOR,
                  }),
              },
              {
                type: Dropdown.ItemType.Button,
                content: s('removeBouncer'),
                action: () =>
                  handleRemoveManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.BOUNCER,
                  }),
              },
            ]}
          >
            <Button height={23} ml={16} small width={150}>
              {s('bouncerModerator')}
            </Button>
          </Dropdown>
        )
      }
      if (!owner && !moderator && !bouncer) {
        return (
          <Dropdown
            items={[
              // {
              //   type: Dropdown.ItemType.Button,
              //   content: s('owner'),
              //   action: () =>
              //     handleAddManager({ userId, type: ROOM_MANAGER_TYPES.OWNER }),
              // },
              moderators?.length < 5 && {
                type: Dropdown.ItemType.Button,
                content: s('moderator'),
                action: () =>
                  handleAddManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.MODERATOR,
                  }),
              },
              {
                type: Dropdown.ItemType.Button,
                content: s('bouncer'),
                action: () =>
                  handleAddManager({
                    userId,
                    type: ROOM_MANAGER_TYPES.BOUNCER,
                  }),
              },
            ]}
            offset={[0, 0]}
          >
            <Button height={23} ml={16} outline small width={150}>
              {s('setManager')}
            </Button>
          </Dropdown>
        )
      }
      return <Row ml={16} width={130} />
    },
    [
      bouncersById,
      handleAddManager,
      handleRemoveManager,
      moderators?.length,
      moderatorsById,
      ownersById,
      s,
    ],
  )

  return (
    <>
      <Modal
        {...Modal.pickProps(rest)}
        shouldCloseOnOverlayClick
        title={s('title')}
        onMount={handleMount}
      >
        <Content>
          <Row justifyCenter>
            <Text mt={1} primary>
              {participantsCount}{' '}
              {participantsCount === 1 ? s('user') : s('users')} &bull;{' '}
              {onlineParticipantsCount} {s('online')}
            </Text>
          </Row>
          {!!isOwnedByMe && (
            <Text alignSelfCenter mt={24} primary>
              {s('subtitle')}
            </Text>
          )}
          <Row center mt={24} width={1}>
            <Input
              icon={<RiSearchLine fill="#657786" />}
              name="search"
              placeholder={s('fields.search')}
              search
              secondary
              width={1}
              onChange={handleChangeSearch}
            />
          </Row>
          <ScrollingContainer mt={24}>
            {loading && (
              <Row justifyCenter width={1}>
                <Loader text="Loading..." />
              </Row>
            )}
            {map(rows, ({ id, user }) => (
              <Row center key={id} mt={24} spaceBetween width={1}>
                <Row>
                  <Column>
                    <Link clean to={APP_PROFILE(user?.id)}>
                      <Avatar
                        online={user?.online}
                        size={41}
                        src={user?.profile?.photoUrl}
                        username={user?.username}
                      />
                    </Link>
                  </Column>
                  <Column ml={9} spaceBetween width={180}>
                    <Text bold mb={2}>
                      @{user?.username}
                    </Text>
                    <Row>
                      <Text primary small>
                        {s('member')}
                      </Text>
                      <Text ml={1} primary small>
                        {DateTime.fromISO(user?.createdAt).toLocaleString(
                          DateTime.DATE_SHORT,
                        )}
                      </Text>
                    </Row>
                  </Column>
                  {/* <Row mt={1}>
                    {me?.membership?.tier !== TIER.guest && (
                      <MembershipWrap tier={user?.membership?.tier} />
                    )}
                  </Row> */}
                </Row>
                <Row>
                  {me?.id !== user?.id && (
                    <>
                      {!myFollowing.has(user?.id) ? (
                        <Button
                          height={23}
                          outline
                          width={90}
                          onClick={() => handleFollow(user?.id)}
                        >
                          {s('actions.follow')}
                        </Button>
                      ) : (
                        <Button
                          height={23}
                          width={90}
                          onClick={() => handleUnfollow(user?.id)}
                        >
                          {s('actions.unfollow')}
                        </Button>
                      )}
                    </>
                  )}
                  {!!isOwnedByMe && renderOwnersModerators(user?.id)}
                </Row>
              </Row>
            ))}
            <Waypoint onEnter={handleLoadMore}>
              <FirefoxPaginationAnchor />
            </Waypoint>
          </ScrollingContainer>
        </Content>
      </Modal>
      <ParticipantsSubscription participantsVariables={participantsVariables} />
    </>
  )
}

RoomUserListModal.defaultProps = {
  bouncers: [],
  locked: true,
  moderators: [],
  onlineParticipantsCount: 0,
  owners: [],
  participantsCount: 0,
  refetch: null,
  roomId: null,
}

RoomUserListModal.propTypes = {
  bouncers: PropTypes.array,
  locked: PropTypes.bool,
  moderators: PropTypes.array,
  onlineParticipantsCount: PropTypes.number,
  owners: PropTypes.array,
  participantsCount: PropTypes.number,
  refetch: PropTypes.func,
  roomId: PropTypes.string,
}

export default RoomUserListModal
