import React from 'react'

import { Cell, Pie, PieChart, Tooltip } from 'recharts'

import shuffle from 'lodash/shuffle'

const data1 = [
  { name: 'Group A', value: 40 },
  { name: 'Group B', value: 30 },
  { name: 'Group C', value: 30 },
  { name: 'Group D', value: 20 },
  { name: 'Group E', value: 20 },
  { name: 'Group F', value: 20 },
]
const data2 = [
  { name: 'Group A', value: 50 },
  { name: 'Group B', value: 30 },
  { name: 'Group C', value: 10 },
  { name: 'Group D', value: 10 },
  { name: 'Group E', value: 10 },
  { name: 'Group F', value: 10 },
  { name: 'Group G', value: 10 },
  { name: 'Group H', value: 10 },
]
const data3 = [
  { name: 'Group A', value: 30 },
  { name: 'Group B', value: 30 },
  { name: 'Group C', value: 20 },
  { name: 'Group D', value: 20 },
]

const COLORS = [
  '#8884d8',
  '#83a6ed',
  '#8dd1e1',
  '#82ca9d',
  '#a4de6c',
  '#d0ed57',
  '#ffc658',
]

function key(index) {
  return `cell${index}`
}

function Chart() {
  const colors1 = shuffle(COLORS)
  const colors2 = shuffle(COLORS)
  const colors3 = shuffle(COLORS)

  return (
    <PieChart cx={150} cy={150} height={300} margin={0} width={300}>
      <Pie
        data={data1}
        dataKey="value"
        innerRadius={74}
        outerRadius={80}
        paddingAngle={4}
      >
        {data1.map((entry, index) => (
          <Cell
            fill={colors1[index % COLORS.length]}
            key={key(index)}
            stroke="none"
          />
        ))}
      </Pie>

      <Pie
        data={data2}
        dataKey="value"
        innerRadius={104}
        outerRadius={110}
        paddingAngle={4}
      >
        {data2.map((entry, index) => (
          <Cell
            fill={colors2[index % COLORS.length]}
            key={key(index)}
            stroke="none"
          />
        ))}
      </Pie>

      <Pie
        data={data3}
        dataKey="value"
        innerRadius={134}
        outerRadius={140}
        paddingAngle={4}
      >
        {data3.map((entry, index) => (
          <Cell
            fill={colors3[index % COLORS.length]}
            key={key(index)}
            stroke="none"
          />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  )
}

export default Chart
