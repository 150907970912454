import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { FiEye } from 'react-icons/fi'

import filter from 'lodash/filter'
import keyBy from 'lodash/keyBy'
import sumBy from 'lodash/sumBy'

import { ConnectWalletModal, WalletsModal } from 'Components/Blocks/User/Modals'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import { WALLET_PROVIDERS } from 'Constants/ids'

import userGroupedWalletsQuery from 'GraphQL/Queries/Wallets/userGroupedWallets.graphql'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import { Container, Content } from './styles'

import { IconWrapper } from '../styles'

function Wallets({ isCollapsed, me }) {
  const s = useScopedI18n('user.profile')
  const [walletsModal, setWalletsModal] = useState({ isOpen: false })
  const [connectWalletModal, setConnectWalletModal] = useState({
    isOpen: false,
  })

  const { data, loading, refetch, variables } = useQuery(
    userGroupedWalletsQuery,
  )

  const groupedWallets = useMemo(
    () => data?.userGroupedWallets ?? {},
    [data?.userGroupedWallets],
  )

  const totalWallets = useMemo(
    () => sumBy(groupedWallets, provider => provider?.wallets?.length),
    [groupedWallets],
  )

  const metamaskWalletsByIdentity = useMemo(() => {
    const ethereumWallets = filter(
      groupedWallets,
      entity => entity?.provider === WALLET_PROVIDERS.ETHEREUM,
    )
    return keyBy(ethereumWallets[0]?.wallets, 'identity')
  }, [groupedWallets])

  const handleWallets = useCallback(() => {
    setWalletsModal({ isOpen: true })
  }, [])
  const handleCloseWalletsModal = useCallback(() => {
    setWalletsModal({ isOpen: false })
  }, [])

  const handleConnectWallet = useCallback(() => {
    setConnectWalletModal({ isOpen: true })
  }, [])
  const handleCloseConnectWalletModal = useCallback(() => {
    setConnectWalletModal({ isOpen: false })
  }, [])

  return (
    <Container collapsed={isCollapsed} mt={2}>
      {!isCollapsed ? (
        <Content>
          <Row spaceBetween>
            <Column>
              <Text bold>{s('fields.wallets')}</Text>
              {loading ? (
                <Loader />
              ) : (
                <Text mt={1} primary small>
                  {s('fields.walletsConnected', {
                    wallets: totalWallets,
                  })}
                </Text>
              )}
            </Column>
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={handleWallets}
            >
              <IconWrapper>
                <FiEye />
              </IconWrapper>
            </Button>
          </Row>
        </Content>
      ) : (
        <Content collapsedContent>
          <Column>
            <Text bold small>
              {s('fields.wallets')}
            </Text>
            <Row justifyCenter>
              <Button
                height={22}
                iconButton
                mt={2}
                noEffectsIcon
                smallest
                stroke
                width={22}
                onClick={handleWallets}
              >
                <IconWrapper>
                  <FiEye />
                </IconWrapper>
              </Button>
            </Row>
          </Column>
        </Content>
      )}

      <WalletsModal
        isOpen={walletsModal?.isOpen}
        totalWallets={totalWallets}
        wallets={groupedWallets}
        walletsLoading={loading}
        walletsVariables={variables}
        onClose={handleCloseWalletsModal}
        onConnectWallet={handleConnectWallet}
      />

      <ConnectWalletModal
        isOpen={connectWalletModal?.isOpen}
        me={me}
        metamaskWallets={metamaskWalletsByIdentity}
        refetch={refetch}
        onClose={handleCloseConnectWalletModal}
      />
    </Container>
  )
}

Wallets.defaultProps = {
  isCollapsed: false,
  me: {},
}

Wallets.propTypes = {
  isCollapsed: PropTypes.bool,
  me: PropTypes.object,
}

export default Wallets
