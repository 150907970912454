import { NavLink } from 'react-router-dom'

import styled, { css } from 'styled-components'
import themeGet from '@styled-system/theme-get'

import Text from '../Text'

export const Items = styled.div`
  min-width: 150px;
  max-height: 200px;
  background: ${themeGet('colors.bg.primary')};
  box-shadow: ${themeGet('shadow')};
  border-radius: 12px;
  overflow-y: scroll;
  width: 100%;
  scrollbar-width: thin;
  scrollbar-color: ${themeGet('colors.border.customDefault')}
    ${themeGet('colors.bg.secondary')};

  &::-webkit-scrollbar {
    width: 2px;
    height: 100%;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${themeGet('colors.border.customDefault')};
  }
`

export const ItemsTitle = styled(Text)`
  font-size: 12px;
  color: ${themeGet('colors.font.default')};
  padding: 16px 16px 0;
`

const warningCss = ({ warning }) =>
  warning &&
  css`
    color: ${themeGet('text.color.warning')};

    svg {
      stroke: ${themeGet('colors.warning')};
    }

    :hover,
    :focus {
      color: ${themeGet('text.color.warning')};
    }
  `

const Item = styled.div`
  height: 32px;
  display: flex;
  flex: 1 0;
  width: 100%;
  cursor: pointer;
  background: ${themeGet('colors.bg.primary')};
  color: ${themeGet('text.color.default')};
  align-items: center;
  padding: 16px;

  svg {
    margin-right: 4px;
  }

  :hover,
  :focus {
    background: ${themeGet('colors.customSecondary')};
    color: ${themeGet('colors.customDefault')};
  }

  :last-child {
    border-bottom: 0;
  }

  ${warningCss}
`

export const ItemLink = styled(Item).attrs({
  as: NavLink,
})`
  text-decoration: none;
  color: ${themeGet('colors.font.default')};
`

export const ItemButton = styled(Item)``

export const ItemTrigger = styled(Item)`
  svg {
    margin-right: 0;
  }

  :hover,
  :focus {
    p {
      color: ${themeGet('colors.customDefault')};
    }
  }
`
