import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    display: none;
  `

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border: 1px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.secondary')};
  padding: ${themeGet('space.2')}px ${themeGet('space.4')}px;

  ${margin}
  ${collapsedCss}
`

export const ExternalLink = styled.a`
  color: ${themeGet('colors.customDefault')};
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.3s ease;

  &:hover {
    color: ${themeGet('colors.customMedium')};
  }

  :active {
    color: ${themeGet('colors.customDefault')};
  }
`
