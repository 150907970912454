import loadable from '@loadable/component'

const Public = {
  Root: loadable(() => import('./Root' /* webpackChunkName: "public-root" */)),
  OAuthCallback: loadable(() =>
    import('./OAuthCallback' /* webpackChunkName: "public-oauth-callback" */),
  ),
  UnlockProtocolAuthCallback: loadable(() =>
    import(
      './UnlockProtocolAuthCallback' /* webpackChunkName: "unlock-oauth-callback" */
    ),
  ),
  TekenAuthCallback: loadable(() =>
    import(
      './TekenAuthCallback' /* webpackChunkName: "teken-oauth-callback" */
    ),
  ),
  ConnectSocialAccounts: loadable(() =>
    import(
      './ConnectSocialAccounts' /* webpackChunkName: "public-connect-social" */
    ),
  ),
  TwitterCallback: loadable(() =>
    import(
      './TwitterCallback' /* webpackChunkName: "public-twitter-callback" */
    ),
  ),
  LinkedInCallback: loadable(() =>
    import(
      './LinkedInCallback' /* webpackChunkName: "public-linkedin-callback" */
    ),
  ),
  TekenCallback: loadable(() =>
    import('./TekenCallback' /* webpackChunkName: "teken-callback" */),
  ),
  MagicLink: loadable(() =>
    import('./MagicLink' /* webpackChunkName: "public-magic-link" */),
  ),
  Enter: loadable(() =>
    import('./Enter' /* webpackChunkName: "public-enter" */),
  ),
  SignInFromMobile: loadable(() =>
    import('./SignInFromMobile' /* webpackChunkName: "sign-in-from-mobile" */),
  ),
}

export default Public
