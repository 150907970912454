import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import get from 'lodash/get'
import map from 'lodash/map'

import Avatar from 'Components/Blocks/Avatar'
import { Button, Column, Link, Loader, Modal, Row, Text } from 'Components/UI'

import { LIKEABLE_TYPES } from 'Constants/ids'

import likesByLikeableIdQuery from 'GraphQL/Queries/Likes/likesByLikeableId.graphql'
import { updatePostLikesQueryUpdater } from 'GraphQL/Updaters/posts'

import { useAppContext, useFollow } from 'Hooks'

import { APP_PROFILE } from 'Router/routes'

import { useLazyQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import { Content, FirefoxPaginationAnchor, ScrollingContainer } from './styles'

const LOAD_COUNT = 10

const sort = { column: 'createdAt', order: 'desc' }

function PostLikedUsersModal({
  postId,
  likesCount,
  isForChannelMessages,
  ...rest
}) {
  const s = useScopedI18n('user.modal.postLikedUsers')
  const { me } = useAppContext()
  const { myFollowing, handleFollow, handleUnfollow } = useFollow({
    trigger: rest?.isOpen,
  })

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [loadLikes, { data, loading, fetchMore }] = useLazyQuery(
    likesByLikeableIdQuery,
  )

  const likes = useMemo(
    () => data?.likesByLikeableId ?? {},
    [data?.likesByLikeableId],
  )

  const { rows, cursor } = likes

  const hasNext = cursor?.hasNext

  const next = cursor?.next

  const fetchData = useCallback(() => {
    loadLikes({
      variables: {
        sort,
        limit: LOAD_COUNT,
        likeableId: postId,
        likeableType: !isForChannelMessages
          ? LIKEABLE_TYPES.POST
          : LIKEABLE_TYPES.MESSAGE,
      },
    })
  }, [isForChannelMessages, loadLikes, postId])

  useEffect(() => {
    if (postId) {
      fetchData()
    }
  }, [fetchData, postId])

  const handleLoadMore = useCallback(async () => {
    if (hasNext) {
      await fetchMore({
        variables: {
          sort,
          limit: LOAD_COUNT,
          cursor: next,
          likeableId: postId,
          likeableType: !isForChannelMessages
            ? LIKEABLE_TYPES.POST
            : LIKEABLE_TYPES.MESSAGE,
        },
        updateQuery: (prev, fetchMoreResult) =>
          updatePostLikesQueryUpdater(prev, fetchMoreResult),
      })
    }
  }, [isForChannelMessages, fetchMore, hasNext, next, postId])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={
        likesCount === 1
          ? s('titleTwo', { likesCount })
          : s('title', { likesCount })
      }
      onMount={handleMount}
    >
      <Content>
        <ScrollingContainer mt={24}>
          {loading && (
            <Row justifyCenter width={1}>
              <Loader text="Loading..." />
            </Row>
          )}
          {map(
            rows,
            ({
              id,
              createdBy: { id: userId, createdAt, profile, username } = {},
            }) => (
              <Row center key={id} mt={24} spaceBetween width={1}>
                <Row>
                  <Column>
                    <Link clean to={APP_PROFILE(userId)}>
                      <Avatar
                        size={41}
                        src={profile?.photoUrl}
                        username={username}
                      />
                    </Link>
                  </Column>
                  <Column ml={9} spaceBetween width={180}>
                    <Text bold mb={2}>
                      @{username}
                    </Text>
                    <Row>
                      <Text primary small>
                        {s('member')}
                      </Text>
                      <Text ml={1} primary small>
                        {DateTime.fromISO(createdAt).toLocaleString(
                          DateTime.DATE_SHORT,
                        )}
                      </Text>
                    </Row>
                  </Column>
                  {/* <Row mt={1}>
                    {me?.membership?.tier !== TIER.guest && (
                      <MembershipWrap tier={membership?.tier} />
                    )}
                  </Row> */}
                </Row>
                {me?.id !== userId && (
                  <Row>
                    {!myFollowing.has(userId) ? (
                      <Button
                        height={23}
                        outline
                        width={90}
                        onClick={() => handleFollow(userId)}
                      >
                        {s('actions.follow')}
                      </Button>
                    ) : (
                      <Button
                        height={23}
                        width={90}
                        onClick={() => handleUnfollow(userId)}
                      >
                        {s('actions.unfollow')}
                      </Button>
                    )}
                  </Row>
                )}
              </Row>
            ),
          )}
          <Waypoint onEnter={handleLoadMore}>
            <FirefoxPaginationAnchor />
          </Waypoint>
        </ScrollingContainer>
      </Content>
    </Modal>
  )
}

PostLikedUsersModal.defaultProps = {
  isForChannelMessages: false,
  likesCount: 0,
  postId: null,
}

PostLikedUsersModal.propTypes = {
  isForChannelMessages: PropTypes.bool,
  likesCount: PropTypes.number,
  postId: PropTypes.string,
}

export default PostLikedUsersModal
