import React from 'react'
import PropTypes from 'prop-types'

import { omit, pick } from '@styled-system/props'

import Text from 'Components/UI/Text'

import {
  Background,
  Container,
  IconCheck,
  IconCircle,
  TextContainer,
  TextContent,
  TextOption,
} from './styles'

function Radio({
  alternate,
  alternateCircle,
  circle,
  textRadioButton,
  value,
  name,
  disabled,
  error,
  label,
  meta,
  ...rest
}) {
  return (
    <>
      {!textRadioButton ? (
        <Container
          {...pick(rest)}
          alternateCircle={alternateCircle}
          circle={circle}
        >
          <input
            disabled={disabled}
            name={name}
            type="radio"
            value={value}
            {...omit(rest)}
          />
          <Background />
          {circle || alternateCircle ? <IconCircle /> : <IconCheck />}
          <TextContent ml={2}>
            {label && (
              <Text primary={alternateCircle} small={alternateCircle}>
                {label}
              </Text>
            )}
            {error && (
              <Text small warning>
                {error}
              </Text>
            )}
          </TextContent>
        </Container>
      ) : (
        <TextContainer alternate={alternate} {...pick(rest)}>
          <input
            disabled={disabled}
            name={name}
            type="radio"
            value={value}
            {...omit(rest)}
          />
          <TextOption>{label}</TextOption>
          {error && (
            <Text small warning>
              {error}
            </Text>
          )}
        </TextContainer>
      )}
    </>
  )
}

Radio.defaultProps = {
  alternate: false,
  alternateCircle: false,
  circle: false,
  disabled: false,
  error: null,
  label: null,
  meta: {},
  name: null,
  textRadioButton: false,
  value: null,
}

Radio.propTypes = {
  alternate: PropTypes.bool,
  alternateCircle: PropTypes.bool,
  circle: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]),
  meta: PropTypes.object,
  name: PropTypes.string,
  textRadioButton: PropTypes.bool,
  value: PropTypes.string,
}

export default Radio
