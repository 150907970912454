import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { RiSearchLine } from 'react-icons/ri'

import debounce from 'lodash/debounce'
import noop from 'lodash/noop'

import { Loader, Row, Sort } from 'Components/UI'
import { Input } from 'Components/UI/Forms'

import categorizedRoomsMenuQuery from 'GraphQL/Queries/Rooms/categorizedRoomsMenu.graphql'
import favoriteRoomsListQuery from 'GraphQL/Queries/Rooms/favoriteRoomsList.graphql'
import roomsQuery from 'GraphQL/Queries/Rooms/rooms.graphql'

// import { updateFavoriteRoomsListQueryUpdater } from 'GraphQL/Updaters/rooms'
import { useEntranceContext } from 'Hooks'

import { useLazyQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import RoomsList from './RoomsList'
import { Subscriptions } from './Subscriptions'
import Tabs from './Tabs'
import { filterLayered } from './utils'

import { Content } from '../styles'

const SORT = [
  {
    name: 'alphabet',
    value: 'alphabetically',
  },
  // {
  //   name: 'value',
  //   value: 'TOTAL_VALUE',
  // },
  {
    name: 'messages',
    value: 'recentMessages',
  },
  {
    name: 'added',
    value: 'createdAt',
  },
]

function Rooms({ isCollapsed, onCollapsed }) {
  const [{ exclusiveMode, roomGroupMeta }] = useEntranceContext()

  const s = useScopedI18n('user.rooms')
  const [displayFavorites, setDisplayFavorites] = useState(false)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState({
    column: SORT[2]?.value,
    order: 'desc',
  })
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)

  const [
    loadRoomCategories,
    {
      data: categoriesData,
      loading: categoriesLoading,
      refetch,
      variables: categoriesVariables,
    },
  ] = useLazyQuery(categorizedRoomsMenuQuery)

  const roomIds = useMemo(
    () => roomGroupMeta?.rooms?.map(room => room.id) || [],
    [roomGroupMeta],
  )

  const [loadRooms, { data: exclusiveData, loading: exclusiveLoading }] =
    useLazyQuery(roomsQuery, {
      variables: { roomIds, sort: [{ column: 'sortOrder', order: 'asc' }] },
    })

  const [
    loadFavoriteRooms,
    {
      data: favoriteData,
      loading: favoriteLoading,
      fetchMore: fetchMoreFavorite,
    },
  ] = useLazyQuery(favoriteRoomsListQuery)

  useEffect(() => {
    if (!exclusiveMode) return
    loadRooms().then()
  }, [loadRooms, exclusiveMode])

  useEffect(() => {
    if (search) {
      setLimit(9999)
    } else {
      setLimit(10)
    }
  }, [search])

  const fetchData = useCallback(() => {
    let load
    let variables

    if (displayFavorites) {
      load = loadFavoriteRooms
      variables = { page: 0, limit, sort, includeLayered: true }
    } else {
      load = loadRoomCategories
      variables = {}
    }

    load({ variables })
  }, [displayFavorites, limit, loadFavoriteRooms, loadRoomCategories, sort])

  const roomsData = useMemo(() => {
    if (exclusiveMode) return filterLayered(exclusiveData?.rooms?.rows) ?? []
    if (displayFavorites) return favoriteData?.favoriteRoomsList?.rows ?? []
    return categoriesData?.categorizedRooms ?? []
  }, [
    exclusiveMode,
    displayFavorites,
    exclusiveData,
    favoriteData,
    categoriesData,
  ])

  const totalPages = useMemo(() => {
    if (exclusiveMode) return exclusiveData?.rooms?.pages ?? 0
    if (displayFavorites) return favoriteData?.favoriteRoomsList?.pages ?? 0
    return categoriesData?.rooms?.pages ?? 0
  }, [
    exclusiveMode,
    categoriesData,
    exclusiveData,
    displayFavorites,
    favoriteData,
  ])

  const handleLoadMore = useCallback(async () => {
    if (displayFavorites && page < totalPages - 1) {
      await fetchMoreFavorite({
        variables: { page: page + 1, limit, sort, includeLayered: true },
        // updateQuery: updateFavoriteRoomsListQueryUpdater,
      })

      setPage(page + 1)
    }
  }, [displayFavorites, page, totalPages, fetchMoreFavorite, limit, sort])

  const loadFavorite = useCallback(async () => {
    const response = await loadFavoriteRooms({
      variables: { page: 0, limit, sort, includeLayered: true },
    })
    if (response?.data?.favoriteRoomsList?.count > 0) {
      setDisplayFavorites(true)
    }
  }, [limit, loadFavoriteRooms, sort])

  useEffect(() => {
    loadFavorite().then()
  }, [loadFavorite])

  useEffect(() => {
    if (!search) {
      fetchData()
    } else {
      const variables = displayFavorites
        ? { page: 0, limit, sort, includeLayered: true }
        : {}

      if (search) variables.search = search

      const load = displayFavorites ? loadFavoriteRooms : loadRoomCategories

      load({ variables })
    }
  }, [
    displayFavorites,
    fetchData,
    limit,
    loadFavoriteRooms,
    loadRoomCategories,
    search,
    sort,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value)
    }, 700),
    [],
  )

  const handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      debouncedSearch(value)
    },
    [debouncedSearch],
  )

  const handleDisplayFavorites = useCallback(value => {
    setDisplayFavorites(value)
    setPage(0)
  }, [])

  return (
    <>
      {exclusiveMode || (
        <>
          <Tabs
            displayFavorites={displayFavorites}
            isCollapsed={isCollapsed}
            onCollapsed={onCollapsed}
            onDisplayFavorites={handleDisplayFavorites}
          />
          {!isCollapsed ? (
            <Row center mt={24} spaceBetween>
              <Input
                icon={<RiSearchLine fill="#657786" />}
                name="search"
                placeholder={s('fields.search')}
                search
                width={1}
                onChange={handleChangeSearch}
              />

              {displayFavorites && (
                <Row ml={16}>
                  <Sort
                    defaultKey={SORT[2]?.value}
                    offset={[-32, -170]}
                    setPage={setPage}
                    setSort={setSort}
                    sortOptions={SORT}
                    sortOrderException={SORT[0]?.value}
                    translationKey="user.rooms"
                  />
                </Row>
              )}
            </Row>
          ) : (
            <Row justifyCenter mt={24} width={1}>
              {displayFavorites && (
                <Sort
                  defaultKey={SORT[2]?.value}
                  offset={[-32, -170]}
                  setPage={setPage}
                  setSort={setSort}
                  sortOptions={SORT}
                  translationKey="user.rooms"
                />
              )}
            </Row>
          )}
        </>
      )}

      <Content mt={4}>
        {/* TODO: hide feed */}
        {!categoriesLoading && !favoriteLoading && !exclusiveLoading ? (
          <RoomsList
            displayFavorites={displayFavorites}
            isCollapsed={isCollapsed}
            loading={displayFavorites ? favoriteLoading : categoriesLoading}
            roomsData={roomsData}
            onDisplayFavorites={setDisplayFavorites}
            onLoadMore={handleLoadMore}
            onRefetchCategories={refetch}
          />
        ) : (
          <Loader alignSelfCenter mt={50} text="Loading..." />
        )}
      </Content>
      <Subscriptions categoriesVariables={categoriesVariables} />
    </>
  )
}

Rooms.defaultProps = {
  isCollapsed: false,
  onCollapsed: noop,
}

Rooms.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapsed: PropTypes.func,
}

export default Rooms
