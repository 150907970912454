import React from 'react'
import PropTypes from 'prop-types'

import { Container, ExternalLink } from './styles'

function TermsAndPrivacy({ isCollapsed }) {
  return (
    <Container collapsed={isCollapsed}>
      <ExternalLink
        href="https://sentr3.com/terms-of-service"
        rel="noopener noreferrer external"
        target="_blank"
      >
        Terms of Service
      </ExternalLink>
      <ExternalLink
        href="https://sentr3.com/privacy"
        rel="noopener noreferrer external"
        target="_blank"
      >
        Privacy Policy
      </ExternalLink>
    </Container>
  )
}

TermsAndPrivacy.defaultProps = {
  isCollapsed: false,
}

TermsAndPrivacy.propTypes = {
  isCollapsed: PropTypes.bool,
}

export default TermsAndPrivacy
