import { FiChevronDown } from 'react-icons/fi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import HathorNetworkGlyph from 'Assets/Svg/hathorNetwork.svg'
import CoinmetroGlyph from 'Assets/Svg/metaIcons/coinmetro.svg'
import MetamaskWalletGlyph from 'Assets/Svg/metamaskWallet.svg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 550px;
  padding: 0 ${themeGet('space.6')}px ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const Subtitle = styled.div`
  text-align: center;

  ${margin}
`

export const MetamaskIcon = styled(MetamaskWalletGlyph)`
  ${margin}
`

export const HathorNetworkIcon = styled(HathorNetworkGlyph)`
  ${margin}
`

export const CoinmetroIcon = styled(CoinmetroGlyph)`
  width: 43px;
  height: 43px;

  ${margin}
`

const spaceBetweenCss = ({ spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;
  `

const selectableCss = ({ selectable }) =>
  selectable &&
  css`
    cursor: pointer;
  `

const selectedExtensionCss = ({ selectedExtension }) =>
  selectedExtension &&
  css`
    background: ${themeGet('colors.bg.customSecondary')};
  `

export const Entity = styled.div`
  display: flex;
  align-items: center;
  height: 67px;
  width: 100%;
  background: ${themeGet('colors.bg.lightest')};
  border-radius: 16px;
  padding: ${themeGet('space.3')}px;
  transition: all ${themeGet('transitionTime.default')} ease-in-out;

  ${selectableCss}
  ${selectedExtensionCss}
  ${spaceBetweenCss}

  ${margin}
`

export const EntityWalletAdress = styled(Entity)`
  overflow-x: auto;

  @media (max-width: ${themeGet('breakpoints.2')}) {
    flex-direction: column;
    height: 80px;
    align-items: baseline;
  }
`

const arrowSelectedCss = ({ arrowSelected }) =>
  arrowSelected &&
  css`
    transform: rotate(-180deg);
    transition: all ${themeGet('transitionTime.long')} ease-in-out;
  `

export const SelectArrow = styled(FiChevronDown)`
  transition: all ${themeGet('transitionTime.long')} ease-in-out;

  ${arrowSelectedCss}
`

export const Badge = styled.span`
  color: ${mapToTheme('buttons.color')};
  background-color: ${mapToTheme('buttons.backgroundColor')};
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  padding: 0 4px 1px 4px;
  border-radius: 4px;
`
