import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import { useHistory } from 'react-router'

import validate from 'validate.js'

import get from 'lodash/get'

import { Button, Modal, Text } from 'Components/UI'
import { InputField } from 'Components/UI/Forms/Input'

import { USERNAME_REGEX } from 'Constants/strings'

// import { PASSWORD_REGEX } from 'Constants/strings'
import createUsernameCredentialsMutation from 'GraphQL/Mutations/Auth/createUsernameCredentials.graphql'
import updateUserBalanceMutation from 'GraphQL/Mutations/User/updateUserBalance.graphql'

import { useAppContext } from 'Hooks'

import { ROOT } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import _ from 'Services/I18n'
import toast from 'Services/Toast'

import { Content } from './styles'

const FIELD = {
  USERNAME: 'username',
  PASSWORD: 'password',
  CONFIRM_PASSWORD: 'confirmPassword',
}

function SetUsernameModal(props) {
  const { refetchMe } = useAppContext()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [setUsernameCredentials] = useMutation(
    createUsernameCredentialsMutation,
  )
  const [updateUserBalance] = useMutation(updateUserBalanceMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELD.USERNAME]: {
        presence: {
          presence: true,
          message: `^${_('auth.shared.usernameRequired')}`,
        },
        length: {
          minimum: 2,
          maximum: 20,
        },
        format: {
          pattern: USERNAME_REGEX,
          flags: 'i',
          message: '^Can only contain numbers and letters',
        },
      },
      // [FIELD.PASSWORD]: {
      //   presence: {
      //     presence: true,
      //     message: `^${_('auth.shared.passwordRequired')}`,
      //   },
      //   format: {
      //     pattern: PASSWORD_REGEX,
      //     flags: 'i',
      //     message: `^${_('auth.shared.passwordInvalid')}`,
      //   },
      // },
      // [FIELD.CONFIRM_PASSWORD]: {
      //   presence: {
      //     presence: true,
      //     message: `^${_('auth.shared.confirmPasswordRequired')}`,
      //   },
      //   equality: {
      //     attribute: FIELD.PASSWORD,
      //     message: `^${_('auth.shared.confirmPasswordNotEqual')}`,
      //   },
      // },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      try {
        setLoading(true)

        await setUsernameCredentials({
          variables: {
            username: values[FIELD.USERNAME],
            // password: values[FIELD.PASSWORD],
          },
        })

        await updateUserBalance()

        // TODO: mutation should return credential or user to set newly created credential as username
        await refetchMe()
        close.current?.()
      } catch (error) {
        toast.error({
          title: 'Set username',
          text: _(`error.${error?.message || 'generic'}`),
        })
      } finally {
        setLoading(false)
      }
    },
    [refetchMe, setUsernameCredentials, updateUserBalance],
  )

  const handleBack = useCallback(() => {
    history.push(ROOT)
  }, [history])

  return (
    <Modal
      {...Modal.pickProps(props)}
      handleBack={handleBack}
      noCloseButton
      shouldCloseOnOverlayClick={false}
      title={_('auth.setUsername.title')}
      withBackButton
      onMount={handleMount}
    >
      <Form
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Text mt={4} primary>
                {_('auth.setUsername.subtitle')}
              </Text>
              <InputField
                label={_('auth.shared.username')}
                mt={4}
                name={FIELD.USERNAME}
                noChange
                width={1}
              />
              {/* <InputField */}
              {/*  label={_('auth.shared.password')} */}
              {/*  mt={3} */}
              {/*  name={FIELD.PASSWORD} */}
              {/*  noChange */}
              {/*  type="password" */}
              {/*  width={1} */}
              {/* /> */}
              {/* <InputField */}
              {/*  autoComplete="off" */}
              {/*  label={_('auth.shared.confirmPassword')} */}
              {/*  mt={3} */}
              {/*  name={FIELD.CONFIRM_PASSWORD} */}
              {/*  noChange */}
              {/*  type="password" */}
              {/*  width={1} */}
              {/* /> */}
              <Button
                disabled={loading}
                height={30}
                mb={24}
                mt={24}
                type="submit"
                width={150}
                onClick={handleSubmit}
              >
                {_('auth.setUsername.action')}
              </Button>
            </Content>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

export default SetUsernameModal
