import { useCallback, useEffect, useMemo } from 'react'

import find from 'lodash/find'
import keyBy from 'lodash/keyBy'

import categorizedRoomsHookQuery from 'GraphQL/Queries/Rooms/categorizedRoomsHook.graphql'

import { useLazyQuery } from 'Services/Apollo'

export default function useCategoryByRoomId(roomId) {
  const [loadCategories, { data }] = useLazyQuery(categorizedRoomsHookQuery)

  const fetchData = useCallback(() => {
    loadCategories()
  }, [loadCategories])

  useEffect(() => {
    if (roomId && !data) {
      fetchData()
    }
  }, [data, fetchData, roomId])

  const categories = useMemo(
    () => data?.categorizedRooms ?? {},
    [data?.categorizedRooms],
  )

  const targetCategory = useMemo(
    () =>
      find(categories, category => {
        const roomsById = keyBy(category?.rooms, 'id')
        if (roomsById[roomId]) return category
        return null
      }),
    [categories, roomId],
  )

  return targetCategory
}
