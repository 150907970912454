import styled, { css } from 'styled-components'
import { margin, padding } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const collapsedCss = ({ collapsed }) =>
  collapsed &&
  css`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    justify-content: center;
  `

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const ModesContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  border: 1px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.secondary')};

  ${padding}
  ${margin}
  ${collapsedCss}
`

export const ControlButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border: 1px solid ${themeGet('colors.border.secondary')};
  border-radius: 16px;
  background: ${themeGet('colors.bg.secondary')};

  ${margin}
`
