import { useMemo } from 'react'

import keyBy from 'lodash/keyBy'
import map from 'lodash/map'
import noop from 'lodash/noop'

import { AUTH_METHODS } from 'Constants/ids'

import { useScopedI18n } from 'Services/I18n'

import useWalletsHandlers from './useWalletsHandlers'
import useWeb3 from './useWeb3'

// todo unblock coinbase after connection 68, 74

export default function useMetaWalletsList({
  authMethods,
  setLoading,
  loading,
}) {
  const s = useScopedI18n('public.modal.inviteToRoom')
  const { isMetaMaskConnected, isMetaMaskConnecting, connect } = useWeb3()
  const {
    metamaskHandler,
    unlockHandler,
    isHathorUnlocked,
    hathorHandler,
    coinmetroHandler,
    walletConnectHandler,
    privyConnectHandler,
  } = useWalletsHandlers({
    setLoading,
  })

  const metaWalletsList = useMemo(
    () => [
      {
        key: AUTH_METHODS.METAMASK,
        text: !isMetaMaskConnected
          ? s('actions.connectMetamask')
          : s('actions.signInMetamask'),
        disabled: isMetaMaskConnected ? loading : isMetaMaskConnecting,
        warning: undefined,
        onClick: !isMetaMaskConnected ? connect : metamaskHandler,
      },
      {
        key: AUTH_METHODS.HATHORPAY,
        text: !isHathorUnlocked
          ? s('warnings.hathor')
          : s('actions.connectHathorpay'),
        disabled: !isHathorUnlocked,
        warning: !isHathorUnlocked ? s('warnings.hathor') : undefined,
        onClick: hathorHandler,
      },
      {
        key: AUTH_METHODS.COINMETRO,
        text: s('actions.connectCoinmetro'),
        disabled: loading,
        onClick: coinmetroHandler,
      },
      {
        key: AUTH_METHODS.UNLOCK_PROTOCOL,
        text: s('actions.connectUnlock'),
        disabled: loading,
        onClick: unlockHandler,
      },
      {
        key: AUTH_METHODS.COINBASE,
        text: s('actions.connectCoinbase'),
        disabled: true,
        onClick: noop,
      },
      {
        key: AUTH_METHODS.WALLET_CONNECT,
        text: s('actions.connectWalletConnect'),
        disabled: loading,
        onClick: walletConnectHandler,
      },
      {
        key: AUTH_METHODS.PRIVY,
        text: s('actions.connectPrivy'),
        disabled: loading,
        onClick: privyConnectHandler,
      },
    ],
    [
      isMetaMaskConnected,
      s,
      loading,
      isMetaMaskConnecting,
      connect,
      metamaskHandler,
      isHathorUnlocked,
      hathorHandler,
      coinmetroHandler,
      unlockHandler,
      walletConnectHandler,
      privyConnectHandler,
    ],
  )
  const orderedMetaWalletsList = useMemo(
    () => keyBy(metaWalletsList, 'key'),
    [metaWalletsList],
  )

  return map(authMethods, authMethod => orderedMetaWalletsList[authMethod])
}
