import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import debounce from 'awesome-debounce-promise'

import map from 'lodash/map'

import { USER_ROLE } from 'Constants/ids'

import usersQuery from 'GraphQL/Queries/Admin/users.graphql'
import participatedUsersQuery from 'GraphQL/Queries/User/participatedUsers.graphql'
import userFollowersListQuery from 'GraphQL/Queries/User/userFollowersList.graphql'

import useAppContext from './useAppContext'

export default function useUserOptions() {
  const client = useApolloClient()
  const { me } = useAppContext()

  const fetchUsers = useCallback(
    async (inputValue, callback) => {
      const variables = { roles: [USER_ROLE.USER], limit: 20 }

      if (inputValue) {
        variables.search = inputValue
      }

      const response = await client.query({
        query: usersQuery,
        context: { admin: true },
        fetchPolicy: 'network-only',
        variables,
      })

      const users = map(response?.data?.users?.rows, row => ({
        label: row?.username,
        value: row?.id,
      }))

      callback(users)
    },
    [client],
  )

  const fetchParticipatedUsers = useCallback(
    async (search, callback, roomId) => {
      const variables = { limit: 20, roomId }

      if (search) {
        variables.search = search
      }

      const response = await client.query({
        query: participatedUsersQuery,
        context: { admin: false },
        fetchPolicy: 'network-only',
        variables,
      })

      const participatedUsers = map(
        response?.data?.participatedUsers?.rows,
        row => ({
          display: row?.username,
          id: row?.id,
          photoUrl: row?.profile?.photoUrl,
        }),
      )

      callback(participatedUsers)
    },
    [client],
  )

  const fetchFollowers = useCallback(
    async (search, callback) => {
      const variables = { limit: 20, userId: me?.id }

      if (search) {
        variables.search = search
      }

      const response = await client.query({
        query: userFollowersListQuery,
        context: { admin: false },
        fetchPolicy: 'network-only',
        variables,
      })

      const followers = map(response?.data?.userFollowersList?.rows, row => ({
        display: row?.username,
        id: row?.id,
        photoUrl: row?.profile?.photoUrl,
      }))

      callback(followers)
    },
    [client, me?.id],
  )

  return {
    fetchUsersData: debounce(fetchUsers, 700),
    fetchParticipatedUsersData: debounce(fetchParticipatedUsers, 700),
    fetchFollowersData: debounce(fetchFollowers, 700),
  }
}
