import { useCallback, useEffect, useMemo } from 'react'

import topRoomUsersQuery from 'GraphQL/Queries/User/topRoomUsers.graphql'

import { useLazyQuery } from 'Services/Apollo'

function useTopRoomUsers(roomId) {
  const [loadTopUsers, { data }] = useLazyQuery(topRoomUsersQuery)

  const fetchData = useCallback(() => {
    loadTopUsers({ variables: { roomId } })
  }, [loadTopUsers, roomId])

  useEffect(() => {
    if (roomId && !data) {
      fetchData()
    }
  }, [data, fetchData, roomId])

  return useMemo(() => data?.topRoomUsers ?? {}, [data?.topRoomUsers])
}

export default useTopRoomUsers
