import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

const valueCss = ({ value }) => css`
  width: ${Math.max(Math.min(value || 0, 100), 0)}%;
`

export const Progress = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 16px;

  width: 140px;
  height: 8px;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 4px;

  :after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background: ${themeGet('colors.primary')};
    border-radius: 3px;
    width: 100%;
    transition: all 1s ease;
    max-width: 136px;

    ${valueCss};
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 ${themeGet('space.5')}px ${themeGet('space.5')}px;
`

export const AnimationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75%;
  width: 75%;
  max-height: 100%;
  max-width: 100%;
  border-radius: 50%;
  background: ${themeGet('colors.violetLightest')};
  transition: all ${themeGet('transitionTime.default')} ease-in-out;
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px;
  background: none;
`
