import { BiRefresh } from 'react-icons/bi'
import { HiCheckCircle } from 'react-icons/hi'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { margin, padding } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

const borderTopCss = ({ borderTop }) =>
  borderTop &&
  css`
    border-top: solid 1px ${mapToTheme('colors.border')};
  `

export const SocialButtonWrapper = styled.div`
  position: relative;
  display: flex;

  .closeIcon {
    position: absolute;
    right: -3px;
    top: 2px;
    height: 12px;
    fill: ${themeGet('text.color.primary')};
    display: none;
    cursor: pointer;
  }

  &:hover .closeIcon {
    display: inline-block;
  }

  ${borderTopCss}
  ${margin}
  ${padding}
`

export const CheckIcon = styled(HiCheckCircle)`
  position: absolute;
  right: -4px;
  bottom: 3px;
  width: 13px !important;
  fill: ${themeGet('colors.greenDark')};
`

export const ReconnectIcon = styled(BiRefresh)`
  position: absolute;
  right: -4px;
  bottom: 3px;
  width: 13px !important;
  fill: ${themeGet('colors.gold')};
`
