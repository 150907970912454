import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'
import { typography } from '@styled-system/typography'

export const Clear = styled.div`
  font-size: 14px;
  color: ${themeGet('colors.selects.color.default')};
  cursor: pointer;
  line-height: 10px;
`

export const Dropdown = styled.div`
  font-size: 14px;
  cursor: pointer;

  svg {
    color: ${themeGet('colors.selects.color.default')};
    transition: all 0.2s ease;
  }
`

export const ControlWrapper = styled.div`
  min-height: ${mapToTheme('spacing.controlHeight')}px !important;
  outline: none !important;
  box-shadow: none !important;

  &:hover {
    border-color: ${themeGet('colors.selects.border.default')} !important;
  }

  &.react-select__control--menu-is-open {
    ${Dropdown} {
      svg {
        transform: rotate(-180deg);
      }
    }
  }
`

export const GroupWrapper = styled.div``

export const GroupHeadingWrapper = styled.div``

export const Indicators = styled.div`
  display: flex;
  flex-shrink: 0;
`

export const Separator = styled.div``

export const InputWrapper = styled.div`
  height: 16px;
`

export const MenuWrapper = styled.div`
  border: none !important;
  z-index: 9 !important;
`

export const MenuListWrapper = styled.div`
  background-color: white;
  border-radius: 16px;
  padding: 0 !important;
`

export const Item = styled.div`
  display: flex !important;
  align-items: center;
  min-height: 32px;
  padding: 4px 12px !important;
  cursor: pointer !important;

  ${typography.textAlign};
`

export const MultiValueWrapper = styled.div``

export const MultiValueContainerWrapper = styled.div``

export const MultiValueLabelWrapper = styled.div`
  padding: 0 4px;
`

export const PlaceholderWrapper = styled.div``

export const SelectContainerWrapper = styled.div`
  outline: none !important;
  box-shadow: none !important;
  line-height: 16px;
`

export const Value = styled.div``

export const ValueContainerWrapper = styled.div`
  padding: 4px 12px !important;

  .react-select__multi-value {
    &__label {
      padding: 0 4px;
    }
    &__remove {
      cursor: pointer;
    }
  }
`
