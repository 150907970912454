import { useCallback } from 'react'

import { LIKEABLE_TYPES } from 'Constants/ids'

import likeMutation from 'GraphQL/Mutations/Likes/like.graphql'
import unlikeMutation from 'GraphQL/Mutations/Likes/unlike.graphql'
import flagPostMutation from 'GraphQL/Mutations/Posts/flagPost.graphql'
import savePostMutation from 'GraphQL/Mutations/Posts/savePost.graphql'
import unsavePostMutation from 'GraphQL/Mutations/Posts/unsavePost.graphql'
import { markFlaggedUpdater, markSavedUpdater } from 'GraphQL/Updaters/posts'

import { useMutation } from 'Services/Apollo'
import toast from 'Services/Toast'

export default function usePostActions(isForChannelMessages = false) {
  const [likePost] = useMutation(likeMutation)
  const [unlikePost] = useMutation(unlikeMutation)
  const [savePost] = useMutation(savePostMutation)
  const [unsavePost] = useMutation(unsavePostMutation)
  const [flagPost] = useMutation(flagPostMutation)

  const handleFlagPost = useCallback(
    async (id, flagged) => {
      try {
        await flagPost({
          variables: {
            postId: id,
          },
          update: markFlaggedUpdater(id, flagged),
        })
        toast.success({
          title: 'Flag post',
          text: "You've successfully flagged this post",
        })
      } catch (error) {
        toast.error({ title: 'Flag post', text: error?.message })
      }
    },
    [flagPost],
  )

  const handleLikePost = useCallback(
    async (id, liked) => {
      try {
        if (liked) {
          await unlikePost({
            variables: {
              likeableId: id,
              likeableType: !isForChannelMessages
                ? LIKEABLE_TYPES.POST
                : LIKEABLE_TYPES.MESSAGE,
            },
          })
        } else {
          await likePost({
            variables: {
              likeableId: id,
              likeableType: !isForChannelMessages
                ? LIKEABLE_TYPES.POST
                : LIKEABLE_TYPES.MESSAGE,
            },
          })
        }
      } catch (error) {
        toast.error({ title: 'Like post', text: error?.message })
      }
    },
    [isForChannelMessages, likePost, unlikePost],
  )

  const handleSavePost = useCallback(
    async (id, saved, savedVariables) => {
      try {
        if (saved) {
          await unsavePost({
            variables: { postId: id },
            update: markSavedUpdater({
              postId: id,
              saved: false,
              savedVariables,
            }),
          })
        } else {
          await savePost({
            variables: { postId: id },
            update: markSavedUpdater({
              postId: id,
              saved: true,
              savedVariables,
            }),
          })
        }
      } catch (error) {
        toast.error({ title: 'Save post', text: error?.message })
      }
    },
    [savePost, unsavePost],
  )

  return { handleFlagPost, handleLikePost, handleSavePost }
}
