const customPalettes = {
  blue: {
    primary: '#358ac4',
    secondary: '#253b4a',

    dark: '#2d6891',
    medium: '#27516e',
    light: '#213b4c',
  },
  orange: {
    primary: '#eba22f',
    secondary: '#43341d',

    dark: '#ac7928',
    medium: '#825e24',
    light: '#584220',
  },
  pink: {
    primary: '#ce24a2',
    secondary: '#3d1b34',

    dark: '#982179',
    medium: '#741f5d',
    light: '#4f1c42',
  },
  purple: {
    primary: '#ae5af6',
    secondary: '#372645',

    dark: '#8146b4',
    medium: '#633987',
    light: '#462c5b',
  },
  green: {
    primary: '#66de65',
    secondary: '#2f4a2f',

    dark: '#4fa34e',
    medium: '#3f7b3f',
    light: '#305430',
  },
  yellow: {
    primary: '#ffd400',
    secondary: '#594e15',

    dark: '#ba9c08',
    medium: '#8c760d',
    light: '#5e5112',
  },
  denim: {
    primary: '#358ac4',
    secondary: '#253b4a',

    dark: '#2d6891',
    medium: '#27516e',
    light: '#213b4c',
  },
}

export default customPalettes
