import React, { useCallback, useRef } from 'react'
import PropTypes from 'prop-types'

import { Column, Modal, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Content, EnvelopeIcon } from './styles'

function ConfirmMagicLinkModal({ email, ...rest }) {
  const s = useScopedI18n('public.modal.magicLink')

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = instance?.handleClose
  }, [])

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      onMount={handleMount}
    >
      <Content>
        <EnvelopeIcon />
        <Text bold custom middle mt={5}>
          {s('checkEmail')}
        </Text>
        <Column mt={4}>
          <Text alignTextCenter grayLight light mb={2}>
            {s('emailedTo')}
          </Text>
          <Text alignTextCenter custom mb={2}>
            {email}
          </Text>
          <Text alignTextCenter grayLight light mb={2}>
            {s('clickLink')}
          </Text>
          <Text alignTextCenter custom small>
            {s('expirationAware')}
          </Text>
        </Column>
      </Content>
    </Modal>
  )
}

ConfirmMagicLinkModal.defaultProps = {
  email: null,
}

ConfirmMagicLinkModal.propTypes = {
  email: PropTypes.string,
}

export default ConfirmMagicLinkModal
