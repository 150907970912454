import React, { useCallback } from 'react'
import { isMobile } from 'react-device-detect'
import { useHistory } from 'react-router'
import { Waypoint } from 'react-waypoint'
import PropTypes from 'prop-types'

import { useTheme } from 'styled-components'

import map from 'lodash/map'
import noop from 'lodash/noop'

import { PaginationAnchor, Row, Text } from 'Components/UI'

import { APP_DIRECT_CHANNEL } from 'Router/routes'

import Channel from './Channel'

import { ScrollingContainer, UnreadWrapper } from '../../styles'

function Channels({
  channelsData,
  isCollapsed,
  onLoadMore,
  onResponsiveCollapse,
}) {
  const history = useHistory()
  const { breakpoints } = useTheme()

  const handleGoChannel = useCallback(
    channelId => {
      if (isMobile || window?.innerWidth < parseInt(breakpoints[3], 10)) {
        onResponsiveCollapse()
      }
      history.push(APP_DIRECT_CHANNEL(channelId))
    },
    [breakpoints, history, onResponsiveCollapse],
  )

  const unread = useCallback((unreadCount, absolute = false) => {
    if (unreadCount > 25) {
      return (
        <UnreadWrapper absolutePosition={absolute} mt={1}>
          <Text small white>
            25+
          </Text>
        </UnreadWrapper>
      )
    }
    if (unreadCount > 0 && unreadCount <= 25) {
      return (
        <UnreadWrapper absolutePosition={absolute} mt={1}>
          <Text small white>
            {unreadCount}
          </Text>
        </UnreadWrapper>
      )
    }
    return <Row height={18} />
  }, [])

  return (
    <ScrollingContainer>
      {map(channelsData, channel => (
        <Channel
          channel={channel}
          isCollapsed={isCollapsed}
          key={channel?.id}
          onChannel={handleGoChannel}
          onResponsiveCollapse={onResponsiveCollapse}
          onUnread={unread}
        />
      ))}
      <Waypoint onEnter={onLoadMore}>
        <PaginationAnchor />
      </Waypoint>
    </ScrollingContainer>
  )
}

Channels.defaultProps = {
  channelsData: {},
  isCollapsed: false,
  onLoadMore: noop,
  onResponsiveCollapse: noop,
}

Channels.propTypes = {
  channelsData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isCollapsed: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onResponsiveCollapse: PropTypes.func,
}

export default Channels
