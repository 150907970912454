import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import QRCode from 'react-qr-code'
import PropTypes from 'prop-types'

import get from 'lodash/get'

import { Loader, Modal } from 'Components/UI'

import roomEventTokenQuery from 'GraphQL/Queries/Events/roomEventToken.graphql'

import { useLazyQuery } from 'Services/Apollo'

import { CodeWrapper, Content } from './styles'

function EventQRModal({ eventId, eventTitle, isOpen, ...rest }) {
  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const [loadRoomEventToken, { data, loading }] =
    useLazyQuery(roomEventTokenQuery)

  const fetchRoomEventToken = useCallback(() => {
    loadRoomEventToken({ variables: { eventId } })
  }, [eventId, loadRoomEventToken])

  useEffect(() => {
    if (eventId && isOpen) {
      fetchRoomEventToken()
    }
  }, [eventId, fetchRoomEventToken, isOpen])

  const token = useMemo(
    () => data?.roomEventToken?.token || '',
    [data?.roomEventToken?.token],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      title={eventTitle}
      onMount={handleMount}
    >
      <Content>
        <CodeWrapper mb={24}>
          {loading ? <Loader /> : <QRCode value={token} />}
        </CodeWrapper>
      </Content>
    </Modal>
  )
}

EventQRModal.defaultProps = {
  eventId: null,
  eventTitle: null,
  isOpen: false,
}

EventQRModal.propTypes = {
  eventId: PropTypes.string,
  eventTitle: PropTypes.string,
  isOpen: PropTypes.bool,
}

export default EventQRModal
