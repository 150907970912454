import { USER_ROLE } from 'Constants/ids'

import { ADMIN_ROOMS, ADMIN_ROOT, APP_PROFILE, ROOT } from 'Router/routes'

export function getRootRoute(user) {
  switch (user?.role) {
    case USER_ROLE.ADMIN:
    case USER_ROLE.SUPER_ADMIN:
      return ADMIN_ROOT
    case USER_ROLE.HOST:
      return ADMIN_ROOMS
    case USER_ROLE.USER:
      return APP_PROFILE(user?.id)
    default:
      return ROOT
  }
}
