import React from 'react'
import { components } from 'react-select'

import { FiX } from 'react-icons/fi'

import { Clear } from './styles'

const ClearIndicator = ({ innerProps: { ref, ...restInnerProps } }) => (
  <Clear {...restInnerProps} ref={ref}>
    <FiX />
  </Clear>
)

ClearIndicator.propTypes = {
  ...components.ClearIndicator.propTypes,
}

export default ClearIndicator
