import styled from 'styled-components'
import { layout } from '@styled-system/layout'
import { themeGet } from '@styled-system/theme-get'

export const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${themeGet('colors.bg.audio')};
  border-radius: 16px;
  padding: ${themeGet('space.2')}px ${themeGet('space.3')}px;

  @media (max-width: ${themeGet('breakpoints.2')}) {
    width: 240px;
  }

  ${layout.width}
  ${layout.height}
`

export const ProgressBar = styled.input.attrs({
  type: 'range',
})`
  --bar-bg: ${themeGet('colors.customMedium')};
  --seek-before-width: 0;
  --knobby: ${themeGet('colors.customMedium')};
  --selectedKnobby: ${themeGet('colors.customDark')};

  appearance: none;
  background: none;
  border-radius: 4px;
  position: relative;
  width: 100%;
  height: 8px;
  outline: none;
  cursor: pointer;

  &::-webkit-slider-runnable-track {
    background: var(--bar-bg);
    border-radius: 4px;
    position: relative;
    width: 100%;
    height: 2px;
    outline: none;
  }

  &::-moz-range-track {
    background: var(--bar-bg);
    border-radius: 4px;
    position: relative;
    width: 100%;
    height: 2px;
    outline: none;
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::after {
    background: var(--bar-bg);
    top: 3px;
    height: 2px;
    left: 0;
    right: 0;
    border-radius: 1px;
  }

  &::before {
    content: '';
    height: 8px;
    overflow: hidden;
    width: var(--seek-before-width);
    background: var(--bar-bg);
    border-radius: 4px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }

  &::-moz-range-progress {
    background: var(--bar-bg);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    height: 8px;
    overflow: hidden;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    overflow: hidden;
    border-radius: 4px;
    border: none;
    background: var(--knobby);
    cursor: pointer;
    position: relative;
    margin-top: -3px;
    z-index: 3;
    box-sizing: border-box;

    &:active {
      background: var(--selectedKnobby);
    }
  }

  &::-moz-range-thumb {
    height: 8px;
    width: 8px;
    overflow: hidden;
    border-radius: 4px;
    border: transparent;
    background: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;

    &:active {
      background: var(--selectedKnobby);
    }
  }
`

export const RecordPlayer = styled.audio``

export const AudioTrack = styled.track``
