import styled from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 650px;
  min-height: 200px;
  max-height: 600px;
  padding: 0 ${themeGet('space.5')}px ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

export const MembershipWrapper = styled.div`
  border: 1px solid ${themeGet('colors.gold')};
  border-radius: 4px;
  height: fit-content;
`

export const ScrollingContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  ${margin}
`

export const FirefoxPaginationAnchor = styled.span``
