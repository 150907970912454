import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { layout } from '@styled-system/layout'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { border, text } from 'Components/Styles'

import { Row } from '../Flex'

export const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: ${mapToTheme('inputs.height')}px;
  padding: ${themeGet('space.1')}px ${themeGet('space.2')}px;
  background-color: ${themeGet('colors.bg.primary')};
  ${border(4)};
  color: ${themeGet('colors.font.default')};
  cursor: pointer;

  ${layout.width}
  ${margin}
`

export const Value = styled.div`
  ${text()};
  white-space: nowrap;
  color: ${themeGet('text.color.default')};

  ${margin}
`

export const Controls = styled(Row)`
  position: absolute;
  bottom: 24px;
`
