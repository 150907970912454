import { isMobile } from 'react-device-detect'

import { PALETTE } from 'Config'

import { LANGUAGE } from './ids'

export const AUTH_TOKEN_DEFAULTS = {
  accessToken: null,
  refreshToken: null,
}

export const I18N_DEFAULTS = {
  locale: LANGUAGE.EN,
}

export const UI_DEFAULTS = {
  darkMode: true,
  asideMenuDisplay: 'rooms',
  leftIsCollapsed: isMobile,
  rightIsCollapsed: isMobile,
  channelsCollapsed: [],
  channelsPanelCollapsed: isMobile,
  displayBalance: false,
  palette: PALETTE.blue,
}

export const CONTENT_DEFAULTS = {
  display: 'posts',
}

export const ROUTE_DEFAULTS = {
  directLink: false,
}

export const AUTH_STATE = 'auth'

export const AUTH_ACTIONS = {
  SET: `${AUTH_STATE}/set`,
  CLEAR: `${AUTH_STATE}/clear`,
}

export const I18N_STATE = 'i18n'

export const I18N_ACTIONS = {
  SET: `${I18N_STATE}/set`,
}

export const UI_STATE = 'ui'

export const UI_ACTIONS = {
  SET: `${UI_STATE}/set`,
}

export const CONTENT_STATE = 'content'

export const CONTENT_ACTIONS = {
  SET: `${CONTENT_STATE}/set`,
}

export const ROUTE_STATE = 'route'

export const ROUTE_ACTIONS = {
  SET: `${ROUTE_STATE}/set`,
}
