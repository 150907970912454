import React from 'react'

import { Legend, RadialBar, RadialBarChart, Tooltip } from 'recharts'

const data = [
  {
    name: '18-24',
    percent: 31.47,
    fill: '#8884d8',
  },
  {
    name: '25-29',
    percent: 26.69,
    fill: '#83a6ed',
  },
  {
    name: '30-34',
    percent: 15.69,
    fill: '#8dd1e1',
  },
  {
    name: '35-39',
    percent: 8.22,
    fill: '#82ca9d',
  },
  {
    name: '40-49',
    percent: 8.63,
    fill: '#a4de6c',
  },
  {
    name: '50+',
    percent: 2.63,
    fill: '#d0ed57',
  },
  {
    name: 'unknown',
    percent: 6.67,
    fill: '#ffc658',
  },
]

function Chart() {
  return (
    <RadialBarChart
      barSize={10}
      cx={100}
      cy={100}
      data={data}
      fontSize={8}
      height={200}
      innerRadius={20}
      margin={0}
      outerRadius={100}
      width={300}
    >
      <RadialBar
        background
        clockWise
        dataKey="percent"
        label={{ position: 'insideStart', fill: '#fff' }}
        minAngle={15}
      />
      <Legend
        iconSize={10}
        layout="vertical"
        verticalAlign="middle"
        width={120}
        wrapperStyle={{
          top: 4,
          left: 208,
          lineHeight: '24px',
        }}
      />
      <Tooltip labelFormatter={(value, items) => items?.[0]?.payload?.name} />
    </RadialBarChart>
  )
}

export default Chart
