import coingecko from './coingecko.svg'
import coinmakertcap from './coinmakertcap.svg'
import coinmetro from './coinmetro.svg'
import dextools from './dextools.svg'
import discord from './discord.svg'
import etherscan from './etherscan.svg'
import facebook from './facebook.svg'
import information from './information.svg'
import instagram from './instagram.svg'
import lcx from './lcx.svg'
import linkedin from './linkedin.svg'
import nileswap from './nileswap.svg'
import opensea from './opensea.svg'
import patreon from './patreon.svg'
import santiment from './santiment.svg'
import shopify from './shopify.svg'
import substack from './substack.svg'
import sushiswap from './sushiswap.svg'
import telegram from './telegram.svg'
import twitter from './twitter.svg'
import uniswap from './uniswap.svg'
import unizen from './unizen.svg'

export const metaIcons = {
  coingecko,
  coinmakertcap,
  coinmetro,
  dextools,
  discord,
  etherscan,
  facebook,
  information,
  instagram,
  lcx,
  linkedin,
  nileswap,
  opensea,
  patreon,
  santiment,
  shopify,
  substack,
  sushiswap,
  telegram,
  twitter,
  uniswap,
  unizen,
}
