import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

import OnlineStatusGlyph from 'Assets/Svg/onlineStatus.svg'

import { Row } from 'Components/UI'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  padding: ${themeGet('space.4')}px ${themeGet('space.4')}px
    ${themeGet('space.5')}px;

  @media (max-width: ${themeGet('breakpoints.1')}) {
    width: calc(100vw - 30px);
  }
`

const secondaryCss = ({ secondary }) =>
  secondary &&
  css`
    & circle {
      fill: ${themeGet('colors.noChangeSecondary')};
    }
  `

export const OnlineIcon = styled(OnlineStatusGlyph)`
  & circle {
    stroke: ${themeGet('colors.bg.primary')};
  }

  ${secondaryCss}
`

export const ResponsiveButtonRow = styled(Row)`
  @media (max-width: ${themeGet('breakpoints.0')}) {
    flex-direction: column;
    max-width: 100%;
    align-items: center;
  }
`
