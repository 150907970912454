import React, { useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'

import { useStoreon } from 'storeon/react'

import { Button, Column, Row, Text } from 'Components/UI'

import { ASIDE_MENU_STATES } from 'Constants/ids'
import { UI_ACTIONS, UI_STATE } from 'Constants/store'

import { useDirectChannelsUnread } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import DirectChannelsSubscription from './DirectChannelsSubscription'
import Inbox from './Inbox'
import Rooms from './Rooms'
import {
  ButtonWrapper,
  CollapseArrow,
  Container,
  StyledNavButton,
  UnreadWrapper,
} from './styles'

function NavMenu({ isCollapsed }) {
  const { ui, dispatch } = useStoreon(UI_STATE)
  const history = useHistory()
  const { pathname, state } = useLocation()
  const s = useScopedI18n('user.nav')

  const asideMenuDisplay = ui?.asideMenuDisplay

  const unreadCount = useDirectChannelsUnread()

  const handleNavChange = useCallback(
    value => {
      dispatch(UI_ACTIONS.SET, {
        asideMenuDisplay: value,
      })
    },
    [dispatch],
  )

  const handleNavMenuCollapse = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      leftIsCollapsed: !isCollapsed,
    })
  }, [dispatch, isCollapsed])

  const handleResponsiveCollapse = useCallback(() => {
    dispatch(UI_ACTIONS.SET, {
      leftIsCollapsed: true,
      channelsPanelCollapsed: true,
    })
  }, [dispatch])

  useEffect(() => {
    if (state?.link) {
      handleNavChange(ASIDE_MENU_STATES.ROOMS)
      history.replace(pathname)
    }
  }, [handleNavChange, history, pathname, state])

  return (
    <Container>
      {!isCollapsed ? (
        <Column justifyCenter>
          <Row center spaceBetween width={1}>
            <StyledNavButton
              active={asideMenuDisplay === ASIDE_MENU_STATES.ROOMS}
              onClick={() => handleNavChange(ASIDE_MENU_STATES.ROOMS)}
            >
              {s('titleRooms')}
            </StyledNavButton>
            <StyledNavButton
              active={asideMenuDisplay === ASIDE_MENU_STATES.INBOX}
              onClick={() => handleNavChange(ASIDE_MENU_STATES.INBOX)}
            >
              {s('titleInbox')}
              {unreadCount > 0 && (
                <UnreadWrapper absolutePositionSecondary>
                  <Text small white>
                    {unreadCount > 25 ? '25+' : unreadCount}
                  </Text>
                </UnreadWrapper>
              )}
            </StyledNavButton>
          </Row>
          {asideMenuDisplay === ASIDE_MENU_STATES.ROOMS && (
            <Rooms
              isCollapsed={isCollapsed}
              onCollapsed={handleNavMenuCollapse}
              onResponsiveCollapse={handleResponsiveCollapse}
            />
          )}
          {asideMenuDisplay === ASIDE_MENU_STATES.INBOX && (
            <Inbox
              isCollapsed={isCollapsed}
              onCollapsed={handleNavMenuCollapse}
              onResponsiveCollapse={handleResponsiveCollapse}
            />
          )}
        </Column>
      ) : (
        <Column width={1}>
          <Row width={1}>
            {asideMenuDisplay === ASIDE_MENU_STATES.ROOMS ? (
              <StyledNavButton
                active={asideMenuDisplay === ASIDE_MENU_STATES.ROOMS}
                fullWidth
                onClick={() => handleNavChange(ASIDE_MENU_STATES.INBOX)}
              >
                {s('titleRooms')}
              </StyledNavButton>
            ) : (
              <StyledNavButton
                active={asideMenuDisplay === ASIDE_MENU_STATES.INBOX}
                fullWidth
                onClick={() => handleNavChange(ASIDE_MENU_STATES.ROOMS)}
              >
                {s('titleInbox')}
                {unreadCount > 0 && (
                  <UnreadWrapper absolutePositionThird>
                    <Text small white>
                      {unreadCount > 25 ? '25+' : unreadCount}
                    </Text>
                  </UnreadWrapper>
                )}
              </StyledNavButton>
            )}
          </Row>
          {asideMenuDisplay === ASIDE_MENU_STATES.ROOMS && (
            <Rooms
              isCollapsed={isCollapsed}
              onCollapsed={handleNavMenuCollapse}
              onResponsiveCollapse={handleResponsiveCollapse}
            />
          )}
          {asideMenuDisplay === ASIDE_MENU_STATES.INBOX && (
            <Inbox
              isCollapsed={isCollapsed}
              onCollapsed={handleNavMenuCollapse}
              onResponsiveCollapse={handleResponsiveCollapse}
            />
          )}
        </Column>
      )}
      <ButtonWrapper>
        <Button arrows icon small onClick={handleNavMenuCollapse}>
          <CollapseArrow iconCollapsed={isCollapsed} strokeWidth={2} />
        </Button>
      </ButtonWrapper>
      <DirectChannelsSubscription />
    </Container>
  )
}

NavMenu.defaultProps = {
  isCollapsed: false,
}

NavMenu.propTypes = {
  isCollapsed: PropTypes.bool,
}

export default NavMenu
