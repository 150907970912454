import React from 'react'
import { components } from 'react-select'

import { MenuWrapper } from './styles'

const Menu = props => <MenuWrapper as={components.Menu} {...props} />

Menu.propTypes = {
  ...components.Menu.propTypes,
}

export default Menu
