import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { margin } from '@styled-system/space'
import { themeGet } from '@styled-system/theme-get'

import { flexCenter, text } from 'Components/Styles'
import Button from 'Components/UI/Button'

const OVERLAY_BACKGROUND_COLOR = 'transparent'
const MODAL_SCALE = '0.5, 0.5, 0.5'

function ReactModalAdapter({
  className,
  modalClassName,
  overlayClassName,
  ...props
}) {
  return (
    <ReactModal
      className={modalClassName}
      overlayClassName={overlayClassName}
      portalClassName={className}
      {...props}
    />
  )
}

ReactModalAdapter.propTypes = {
  className: PropTypes.string.isRequired,
  modalClassName: PropTypes.object.isRequired,
  overlayClassName: PropTypes.object.isRequired,
}

const hiddenCss = ({ hidden }) =>
  hidden &&
  css`
    display: none;
  `

export const StyledModal = styled(ReactModalAdapter).attrs({
  modalClassName: {
    base: 'Modal',
    afterOpen: 'Modal--after-open',
    beforeClose: 'Modal--before-close',
  },
  overlayClassName: {
    base: 'Overlay',
    afterOpen: 'Overlay--after-open',
    beforeClose: 'Overlay--before-close',
  },
})`
  .Overlay {
    position: fixed;
    ${flexCenter};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${OVERLAY_BACKGROUND_COLOR};
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    z-index: 1000;

    &--after-open {
      background-color: rgba(0, 0, 0, 0.14);
    }

    &--before-close {
      background-color: ${OVERLAY_BACKGROUND_COLOR};
      backdrop-filter: none;
    }
  }

  .Modal {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: ${themeGet('colors.bg.primary')};
    border-radius: 12px;
    box-shadow: ${themeGet('shadow')};
    outline: none;
    transform: scale3d(${MODAL_SCALE});
    transition: all ${themeGet('transitionTime.modal')} ease-in;
    max-height: 98vh;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: ${themeGet('colors.border.customDefault')}
      ${themeGet('colors.bg.secondary')};

    &::-webkit-scrollbar {
      width: 2px;
      height: 100%;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${themeGet('colors.border.customDefault')};
    }

    &--after-open {
      transform: scale3d(1, 1, 1);
    }

    &--before-close {
      transform: scale3d(${MODAL_SCALE});
      opacity: 0;
    }
  }

  ${hiddenCss}
`

export const Title = styled.div`
  ${text(16)};
  font-weight: 600;
  white-space: nowrap;
`

export const CloseButton = styled.div`
  ${flexCenter};
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 100;

  & svg {
    width: 20px;
    height: 20px;
    stroke: ${themeGet('colors.font.secondary')};
  }

  ${margin}
`

export const AddButton = styled(Button)`
  ${flexCenter};
  flex-shrink: 0;
  position: absolute;
  top: 24px;
  right: 24px;
  font-size: 16px;

  & svg {
    width: 14px;
    height: 14px;
  }
`

export const Image = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
`
