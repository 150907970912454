import { FRONTEND_URL } from 'Config'

import roomGroupMetaByCustomDomainQuery from 'GraphQL/Queries/RoomGroups/roomGroupMetaByCustomDomain.graphql'
import roomGroupMetaBySlugQuery from 'GraphQL/Queries/RoomGroups/roomGroupMetaBySlug.graphql'
import roomMetaBySlugQuery from 'GraphQL/Queries/Rooms/roomMetaBySlug.graphql'

const baseURL = new URL(FRONTEND_URL)

export function checkIfDomain() {
  const currentURL = new URL(window.location)

  if (currentURL.hostname === baseURL.hostname) return null

  if (currentURL.hostname.includes(baseURL.hostname)) {
    const subdomainSlug = currentURL.hostname.replace(
      `.${baseURL.hostname}`,
      '',
    )
    return { subdomainSlug, domain: null }
  }

  return { subdomainSlug: null, domain: currentURL.hostname }
}

export async function getRoomMetaByDomain(
  { subdomainSlug, domain },
  apolloClient,
) {
  try {
    if (subdomainSlug) {
      const { data } = await apolloClient.query({
        query: roomGroupMetaBySlugQuery,
        variables: { slug: subdomainSlug },
      })

      return data?.roomGroupMetaBySlug
    }

    const { data } = await apolloClient.query({
      query: roomGroupMetaByCustomDomainQuery,
      variables: { customDomain: domain },
    })

    return data?.roomGroupMetaByCustomDomain
  } catch (error) {
    return {}
  }
}

export async function checkSlug(slug, apolloClient) {
  try {
    const { data } = await apolloClient.query({
      query: roomMetaBySlugQuery,
      variables: { slug },
    })

    return {
      roomMeta: data?.roomMetaBySlug,
      roomIds: data?.roomMetaBySlug ? [data?.roomMetaBySlug?.id] : [],
    }
  } catch (error) {
    return {}
  }
}
