import customPalettes from './customPalettes'
import palette from './palette'

const pickers = color => ({
  primaryColor: {
    default: customPalettes[color]?.primary || color,
  },
  secondaryColor: {
    default: palette.white,
  },
  additionalColor: {
    default: palette.secondary,
  },
  selectionColor: {
    default: customPalettes[color]?.secondary || color,
  },
  bg: {
    default: palette.primaryDark,
  },
  hover: {
    secondaryColor: {
      default: palette.white,
    },
  },
})

export default pickers
