import keyBy from 'lodash/keyBy'

function isRoomOwnedByMe(room, me) {
  const ownersById = keyBy(room?.owners, 'id')
  return ownersById[me?.id]
}

function isRoomModeratedByMe(room, me) {
  const moderatorsById = keyBy(room?.moderators, 'id')
  return moderatorsById[me?.id]
}

function canChangeChannels(room, me) {
  return isRoomOwnedByMe(room, me)
}

const RoomPolicy = {
  canChangeChannels,
  isRoomModeratedByMe,
  isRoomOwnedByMe,
}

export default RoomPolicy
