import { useMemo } from 'react'

import { Dropdown } from 'Components/UI'

import { ATTACH_TYPES } from 'Constants/ids'

import useAppContext from './useAppContext'

export default function useDropdownPostOptions({
  message = null,
  post,
  owner,
  moderator,
  onEdit,
  onDelete,
  onPinPost,
  onReply,
  onFlag,
  onRequestBan,
  postsFlaggedFilter = false,
}) {
  const { me } = useAppContext()

  const isMessagedByMe =
    !!message && me?.id === message?.user?.id && !owner && !moderator
  const isMessagedByMeWithOwnership =
    !!message && me?.id === message?.user?.id && (owner || moderator)
  const isNotMessagedByMe =
    !!message && me?.id !== message?.user?.id && !owner && !moderator
  const isNotMessagedByMeWithOwnership =
    !!message && me?.id !== message?.user?.id && (owner || moderator)

  return useMemo(() => {
    if (isMessagedByMe) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: 'Edit',
            action: () =>
              onEdit({
                id: message?.id,
                content: message?.content,
                mentions: message?.mentions,
                attachments: message?.attachments,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(message?.id),
          },
        ],
        [-32, -92],
      ]
    }
    if (isMessagedByMeWithOwnership) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !message?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(message?.id, message?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Edit',
            action: () =>
              onEdit({
                id: message?.id,
                content: message?.content,
                mentions: message?.mentions,
                attachments: message?.attachments,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(message?.id),
          },
        ],
        [-32, -122],
      ]
    }
    if (isNotMessagedByMe) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () => onReply(message),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Flag',
            action: () => null,
          },
        ],
        [-32, -92],
      ]
    }
    if (isNotMessagedByMeWithOwnership) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !message?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(message?.id, message?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () => onReply(message),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Flag',
            action: () => null,
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Request a ban',
            action: () => onRequestBan(message?.user?.id),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(message?.id),
          },
        ],
        [-32, -152],
      ]
    }
    if (
      !owner &&
      me?.id === post?.createdBy?.id &&
      (!post?.attachments ||
        (post?.attachments[0]?.kind !== ATTACH_TYPES.POLL &&
          post?.attachments[0]?.kind !== ATTACH_TYPES.AUDIO))
    ) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Edit',
            action: () =>
              onEdit({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
        ],
        [0, -122],
      ]
    }
    if (
      !owner &&
      me?.id === post?.createdBy?.id &&
      post?.attachments &&
      (post?.attachments[0]?.kind === ATTACH_TYPES.POLL ||
        post?.attachments[0]?.kind === ATTACH_TYPES.AUDIO)
    ) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
        ],
        [-32, -92],
      ]
    }
    if (owner && me?.id !== post?.createdBy?.id) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !post?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(post?.id, post?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
          !post?.flagged && {
            type: Dropdown.ItemType.Button,
            content: 'Flag',
            action: () => onFlag(post?.id, postsFlaggedFilter),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Request a ban',
            action: () => onRequestBan(post?.createdBy?.id),
          },
        ],
        [0, -186],
      ]
    }
    if ((owner || moderator) && me?.id !== post?.createdBy?.id) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !post?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(post?.id, post?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
          !post?.flagged && {
            type: Dropdown.ItemType.Button,
            content: 'Flag',
            action: () => onFlag(post?.id, postsFlaggedFilter),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Request a ban',
            action: () => onRequestBan(post?.createdBy?.id),
          },
        ],
        [0, -154],
      ]
    }
    if (
      owner &&
      me?.id === post?.createdBy?.id &&
      post?.attachments &&
      (post?.attachments[0]?.kind === ATTACH_TYPES.POLL ||
        post?.attachments[0]?.kind === ATTACH_TYPES.AUDIO)
    ) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !post?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(post?.id, post?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
        ],
        [0, -124],
      ]
    }
    if (
      owner &&
      me?.id === post?.createdBy?.id &&
      (!post?.attachments ||
        (post?.attachments[0]?.kind !== ATTACH_TYPES.POLL &&
          post?.attachments[0]?.kind !== ATTACH_TYPES.AUDIO))
    ) {
      return [
        [
          {
            type: Dropdown.ItemType.Button,
            content: !post?.isPinned ? 'Pin' : 'Unpin',
            action: () => onPinPost(post?.id, post?.isPinned),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Reply',
            action: () =>
              onReply({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                username: post?.createdBy?.username,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Edit',
            action: () =>
              onEdit({
                id: post?.id,
                content: post?.content,
                roomId: post?.room?.id,
                attachments: post?.attachments,
                mentions: post?.mentions,
              }),
          },
          {
            type: Dropdown.ItemType.Button,
            content: 'Delete',
            action: () => onDelete(post?.id),
          },
        ],
        [0, -154],
      ]
    }
    return [
      [
        {
          type: Dropdown.ItemType.Button,
          content: 'Reply',
          action: () =>
            onReply({
              id: post?.id,
              content: post?.content,
              roomId: post?.room?.id,
              attachments: post?.attachments,
              username: post?.createdBy?.username,
              mentions: post?.mentions,
            }),
        },
        !post?.flagged && {
          type: Dropdown.ItemType.Button,
          content: 'Flag',
          action: () => onFlag(post?.id, postsFlaggedFilter),
        },
      ],
      [0, -92],
    ]
  }, [
    isMessagedByMe,
    isMessagedByMeWithOwnership,
    isNotMessagedByMe,
    isNotMessagedByMeWithOwnership,
    owner,
    me?.id,
    post,
    moderator,
    onEdit,
    message,
    onDelete,
    onPinPost,
    onReply,
    onRequestBan,
    onFlag,
    postsFlaggedFilter,
  ])
}
