import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const Wrapper = styled.div`
  .emoji-mart {
    font-size: 10px;
  }

  .emoji-mart-anchor-selected {
    color: ${themeGet('colors.customDefault')} !important;
  }

  .emoji-mart-anchor-bar {
    background-color: ${themeGet('colors.customDefault')} !important;
  }

  .emoji-mart-scroll {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .emoji-mart-title-label {
    font-weight: 400;
    font-size: 10px;
  }

  .emoji-mart-preview-name {
    fonst-weight: 400;
    font-size: 12px;
  }

  .emoji-mart-preview-shortnames {
    display: none;
  }

  .emoji-mart-preview-emoticons {
    display: none;
  }
`
