import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'

import { FiEdit } from 'react-icons/fi'

import size from 'lodash/size'

import { DevicesModal } from 'Components/Blocks/Modals'
import { Button, Column, Loader, Row, Text } from 'Components/UI'

import userDevicesQuery from 'GraphQL/Queries/Devices/userDevices.graphql'

import { useQuery } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'

import { Container, Content } from './styles'

import { IconWrapper } from '../styles'

function Devices({ isCollapsed, userId }) {
  const s = useScopedI18n('user.profile')
  const [devicesModal, setDevicesModal] = useState({ isOpen: false })

  const { data, loading } = useQuery(userDevicesQuery)

  const userDevices = useMemo(
    () => data?.userDevices ?? {},
    [data?.userDevices],
  )

  const handleDevices = useCallback(() => {
    setDevicesModal({ isOpen: true })
  }, [])
  const handleCloseDevicesModal = useCallback(() => {
    setDevicesModal({ isOpen: false })
  }, [])

  return (
    <Container collapsed={isCollapsed} mt={2}>
      {!isCollapsed ? (
        <Content>
          <Row spaceBetween>
            <Column>
              <Text bold>{s('fields.devices')}</Text>
              {loading ? (
                <Loader />
              ) : (
                <Text mt={1} primary small>
                  {s('fields.devicesConnected', {
                    devices: size(userDevices),
                  })}
                </Text>
              )}
            </Column>
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={handleDevices}
            >
              <IconWrapper>
                <FiEdit />
              </IconWrapper>
            </Button>
          </Row>
        </Content>
      ) : (
        <Content collapsedContent>
          <Column>
            <Text bold small>
              {s('fields.devices')}
            </Text>
            <Row justifyCenter>
              <Button
                height={22}
                iconButton
                mt={2}
                noEffectsIcon
                smallest
                stroke
                width={22}
                onClick={handleDevices}
              >
                <IconWrapper>
                  <FiEdit />
                </IconWrapper>
              </Button>
            </Row>
          </Column>
        </Content>
      )}

      <DevicesModal
        devices={userDevices}
        isOpen={devicesModal.isOpen}
        userId={userId}
        onClose={handleCloseDevicesModal}
      />
    </Container>
  )
}

Devices.defaultProps = {
  isCollapsed: false,
  userId: null,
}

Devices.propTypes = {
  isCollapsed: PropTypes.bool,
  userId: PropTypes.string,
}

export default Devices
