import styled from 'styled-components'
import { mapToTheme } from 'styled-map'
import { themeGet } from '@styled-system/theme-get'

export const Content = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  padding: ${themeGet('space.4')}px;
  background-color: ${mapToTheme('inputs.background.search')};
  border: 1px solid ${mapToTheme('inputs.border.search')};
  border-radius: 16px;
`
