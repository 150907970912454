import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import map from 'lodash/map'
import noop from 'lodash/noop'
import pick from 'lodash/pick'

import { Button, Column, Loader, Modal, Text } from 'Components/UI'
import { ImageInputField, InputField, TextareaField } from 'Components/UI/Forms'

import { UPLOAD_TYPES } from 'Constants/ids'

import updateProfileMutation from 'GraphQL/Mutations/User/updateProfile.graphql'

import { useMentions, useUserOptions } from 'Hooks'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import Utils from 'Utils'

import { Content, MentionSuggestions } from './styles'

const FIELDS = {
  DESCRIPTION: 'description',
  PAGE_URL: 'pageUrl',
  PHOTO_URL: 'photoUrl',
}

function EditProfileModal({ user, onCompleteOnboarding, ...rest }) {
  const s = useScopedI18n('user.modal.editProfile')
  const [loading, setLoading] = useState(false)
  const suggestionsRef = useRef(null)
  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.DESCRIPTION]: null,
    [FIELDS.PAGE_URL]: null,
    [FIELDS.PHOTO_URL]: null,
  })

  const { mentionsList, setMentionsList, handleMentionsList } = useMentions()
  const { fetchFollowersData } = useUserOptions()

  useEffect(() => {
    if (user) {
      const { profile: { description, pageUrl, mentions, photoUrl } = {} } =
        user

      const contentWithMentions = Utils.Strings.buildContentWithMentions(
        description,
        mentions,
      )

      setMentionsList(
        map(mentions, mention =>
          pick(mention, ['entityId', 'kind', 'content']),
        ),
      )

      setFormInitialValues({
        [FIELDS.DESCRIPTION]: contentWithMentions,
        [FIELDS.PHOTO_URL]: photoUrl,
        pageUrl,
      })
    } else {
      setFormInitialValues({
        [FIELDS.DESCRIPTION]: null,
        [FIELDS.PAGE_URL]: null,
        [FIELDS.PHOTO_URL]: null,
      })
    }
  }, [setMentionsList, user])

  const close = useRef(null)

  const [updateProfile] = useMutation(updateProfileMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.DESCRIPTION]: {
        length: {
          maximum: 160,
          message: '^should not be longer than 160 characters',
        },
      },
    }),
    [],
  )

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const submit = useCallback(
    async values => {
      setLoading(true)

      const description = Utils.Strings.cleanupMentions(
        get(values, FIELDS.DESCRIPTION),
      )

      const url = Utils.Strings.urlStringExtractor(
        get(values, FIELDS.PAGE_URL),
        [Utils.Posts.urlEmbedReplacer[0]],
      )

      try {
        await updateProfile({
          variables: {
            description,
            pageUrl: Utils.Strings.urlTransformer(url),
            mentions: mentionsList,
            photoUrl: values[FIELDS.PHOTO_URL],
            donationWalletAddress: user?.profile?.donationWalletAddress,
          },
        })

        toast.success({
          title: 'User profile',
          text: `Profile updated`,
        })

        if (onCompleteOnboarding) {
          onCompleteOnboarding()
        }

        close.current()
      } catch (error) {
        toast.error({
          title: 'Edit profile',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [
      mentionsList,
      onCompleteOnboarding,
      updateProfile,
      user?.profile?.donationWalletAddress,
    ],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit }) => (
          <>
            <Content />
            <Column center mt={6} width={1}>
              <ImageInputField
                entityId={user?.profile?.userId}
                mb={3}
                name={FIELDS.PHOTO_URL}
                setLoadingRef={setLoading}
                uploadType={UPLOAD_TYPES.profilePhoto}
              />
            </Column>
            <Column mt={4} px={24} relative width={1}>
              <Text bold>{s('fields.bio.title')}</Text>
              <TextareaField
                fetchFollowers={fetchFollowersData}
                input={{
                  disabled: loading,
                }}
                mentions={mentionsList}
                mt={1}
                name={FIELDS.DESCRIPTION}
                suggestionsRef={suggestionsRef}
                userBio
                withEmojis
                onChangeMentions={handleMentionsList}
              />
              <MentionSuggestions ref={suggestionsRef} />
              <Text mt={2} primary small>
                {s('fields.bio.description')}
              </Text>
              <Text bold mt={4}>
                {s('fields.url')}
              </Text>
              <InputField
                disabled={loading}
                mt={1}
                name={FIELDS.PAGE_URL}
                userUrl
              />
            </Column>
            <Column center mt={24} pb={18}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button
                    disabled={loading}
                    height={32}
                    mb={3}
                    width={130}
                    onClick={handleSubmit}
                  >
                    {s('actions.save')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

EditProfileModal.defaultProps = {
  user: null,
  onCompleteOnboarding: noop,
}

EditProfileModal.propTypes = {
  user: PropTypes.object,
  onCompleteOnboarding: PropTypes.func,
}

export default EditProfileModal
