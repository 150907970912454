import { useCallback, useContext } from 'react'

import TrezorConnect from 'trezor-connect'

import { TREZOR_ETH_BUNDLE } from 'Constants/ids'

import toast from 'Services/Toast'
import TrezorContext from 'Services/TrezorContext'

export default function useTrezor() {
  const {
    trezorAccounts,
    isTrezorConnected,
    setTrezorAccounts,
    setIsTrezorUnlocking,
    setNoTrezor,
    ...rest
  } = useContext(TrezorContext)

  const unlockTrezor = useCallback(async () => {
    // if (isTrezorConnected) {
    setIsTrezorUnlocking(true)
    //   setNoTrezor(false)

    try {
      const addressResponse = await TrezorConnect.ethereumGetAddress({
        bundle: TREZOR_ETH_BUNDLE,
      })

      const addresses = addressResponse?.payload ?? []

      setTrezorAccounts(addresses)
    } catch (error) {
      toast.error({
        title: 'Unlock Trezor',
        text: error?.message,
      })
    } finally {
      setIsTrezorUnlocking(false)
    }
    // }
    // } else {
    //   setNoTrezor(true)
    // }
  }, [setIsTrezorUnlocking, setTrezorAccounts])

  return { ...rest, trezorAccounts, isTrezorConnected, unlockTrezor }
}
