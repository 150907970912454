import { useMemo } from 'react'
import { useLocation } from 'react-router'

import qs from 'qs'

import get from 'lodash/get'
import trimStart from 'lodash/trimStart'

export default function useLocationQueryParams() {
  const locationParams = useLocation()

  return useMemo(
    () =>
      qs.parse(trimStart(get(locationParams, 'search'), '?'), {
        plainObjects: true,
      }),
    [locationParams],
  )
}
