import React from 'react'
import { renderRoutes } from 'react-router-config'
import PropTypes from 'prop-types'

import { BiChat, BiCustomize, BiShield, BiUser, BiWrench } from 'react-icons/bi'
import { FaBan } from 'react-icons/fa'
import { GiToken } from 'react-icons/gi'

import Aside from 'Components/Blocks/Admin/Aside'
import { Flex } from 'Components/UI'

import {
  ADMIN_ADMINS,
  ADMIN_CATEGORIES,
  ADMIN_MEMBERS,
  ADMIN_ROOMS,
  ADMIN_SETTINGS,
  ADMIN_SLUG_BLACKLIST,
  ADMIN_TOKENS,
} from 'Router/routes'

import { Container, Content, Menu, MenuLink, PageContent } from './styles'

function Admin({ route }) {
  return (
    <Container>
      <Aside />
      <Content>
        <Menu>
          <Flex>
            <MenuLink to={ADMIN_ADMINS}>
              <BiShield /> Admins
            </MenuLink>
            <MenuLink to={ADMIN_MEMBERS}>
              <BiUser /> Members
            </MenuLink>
            <MenuLink to={ADMIN_ROOMS}>
              <BiChat /> Rooms
            </MenuLink>
            <MenuLink to={ADMIN_CATEGORIES}>
              <BiCustomize /> Room Categories
            </MenuLink>
            <MenuLink to={ADMIN_SETTINGS}>
              <BiWrench /> Settings
            </MenuLink>
            <MenuLink to={ADMIN_TOKENS}>
              <GiToken /> Tokens
            </MenuLink>
            <MenuLink to={ADMIN_SLUG_BLACKLIST}>
              <FaBan /> Slug Blacklist
            </MenuLink>
          </Flex>
        </Menu>
        <PageContent>{renderRoutes(route.routes)}</PageContent>
      </Content>
    </Container>
  )
}

Admin.propTypes = {
  route: PropTypes.object.isRequired,
}

export default Admin
