import React from 'react'
import PropTypes from 'prop-types'

import Popover from '../Popover'

function Tip({ content, children, ...rest }) {
  return (
    <Popover content={content} delay={Popover.delay} px={2} py={1} {...rest}>
      {children}
    </Popover>
  )
}

Tip.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
}

export default Tip
