import { useCallback, useState } from 'react'

import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'

import { MENTION_KINDS } from 'Constants/mentions'

export default function useMentions() {
  const [mentionsList, setMentionsList] = useState([])

  const handleMentionsList = useCallback(
    (id, display, remove) => {
      if (remove) {
        setMentionsList([
          ...(filter(mentionsList, mention => mention?.entityId !== id) || []),
        ])
      } else {
        setMentionsList([
          ...(uniqBy(
            [
              ...mentionsList,
              { kind: MENTION_KINDS?.USER, entityId: id, content: display },
            ],
            'entityId',
          ) || []),
        ])
      }
    },
    [mentionsList],
  )

  return { mentionsList, setMentionsList, handleMentionsList }
}
