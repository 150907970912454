import React from 'react'
import PropTypes from 'prop-types'

import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'

import categorizedRoomsMenuQuery from 'GraphQL/Queries/Rooms/categorizedRoomsMenu.graphql'
import roomCreatedSubscription from 'GraphQL/Subscriptions/roomCreated.graphql'
import roomDeletedSubscription from 'GraphQL/Subscriptions/roomDeleted.graphql'

import { useSubscription } from 'Services/Apollo'

function updateQuery(client, room, categoriesVariables, remove) {
  const categoriesOptions = {
    query: categorizedRoomsMenuQuery,
    variables: {
      ...categoriesVariables,
    },
  }

  const id = `Room:${room?.id}`

  const categoriesData = client.readQuery(categoriesOptions)

  if (remove) {
    client.cache.evict({ id })
    client.cache.gc()
  } else {
    client.writeQuery({
      ...categoriesOptions,
      data: {
        ...categoriesData,
        categorizedRooms: {
          ...categoriesData?.categorizedRooms,
          rooms: uniqBy(
            [room, ...(categoriesData?.categorizedRooms?.rooms || [])],
            'id',
          ),
        },
      },
    })
  }
}

function RoomsSubscription({ categoriesVariables }) {
  useSubscription(roomCreatedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const room = get(subscriptionData, ['data', 'roomCreated'])
      const remove = false

      updateQuery(client, room, categoriesVariables, remove)
    },
  })

  useSubscription(roomDeletedSubscription, {
    onSubscriptionData({ client, subscriptionData }) {
      const room = get(subscriptionData, ['data', 'roomDeleted'])
      const remove = true

      updateQuery(client, room, categoriesVariables, remove)
    },
  })

  return <></>
}

RoomsSubscription.defaultProps = {
  categoriesVariables: null,
}

RoomsSubscription.propTypes = {
  categoriesVariables: PropTypes.object,
}

export default RoomsSubscription
