import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { DateTime } from 'luxon'

import noop from 'lodash/noop'

import { Navbar, Weekdays } from './Shared'
import { SingleValuePicker } from './styles'

function DatePicker({
  numberOfMonths,
  timeZone,
  initialValue,
  onFinish,
  reset,
}) {
  const [selectedDay, setSelectedDay] = useState(initialValue)

  useEffect(() => {
    if (reset) {
      setSelectedDay(null)
    }
  }, [reset])

  const dayClick = useCallback(
    day => {
      setSelectedDay(day)

      onFinish(
        DateTime.fromJSDate(day, { zone: timeZone }).toFormat('yyyy-MM-dd'),
      )
    },
    [timeZone, onFinish],
  )

  return (
    <SingleValuePicker
      fixedWeeks
      mode="single"
      navbarElement={<Navbar />}
      numberOfMonths={numberOfMonths}
      selectedDays={selectedDay}
      weekdayElement={<Weekdays />}
      onDayClick={dayClick}
    />
  )
}

DatePicker.defaultProps = {
  initialValue: null,
  numberOfMonths: 1,
  reset: null,
  timeZone: null,
  onFinish: noop,
}

DatePicker.propTypes = {
  initialValue: PropTypes.instanceOf(Date),
  numberOfMonths: PropTypes.number,
  reset: PropTypes.object,
  timeZone: PropTypes.string,
  onFinish: PropTypes.func,
}

export default DatePicker
