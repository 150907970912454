import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { mapToTheme } from 'styled-map'
import { color } from '@styled-system/color'
import { layout } from '@styled-system/layout'
import { space } from '@styled-system/space'
import { typography } from '@styled-system/typography'

const clampedCss = ({ clamped }) =>
  clamped &&
  css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `

const uppercaseCss = ({ uppercase }) =>
  uppercase &&
  css`
    text-transform: uppercase;
  `

const lowercaseCss = ({ lowercase }) =>
  lowercase &&
  css`
    text-transform: lowercase;
  `

const capitalizeCss = ({ capitalize }) =>
  capitalize &&
  css`
    text-transform: capitalize;
  `

const overflowWrapCss = ({ overflowWrap }) =>
  overflowWrap &&
  css`
    overflow-wrap: anywhere;
    white-space: pre-wrap;
  `

const pointerCss = ({ pointer }) =>
  pointer &&
  css`
    cursor: pointer;
  `

const absoluteCss = ({ absolute }) =>
  absolute &&
  css`
    position: absolute;
  `

const alignSelfCenterCss = ({ alignSelfCenter }) =>
  alignSelfCenter &&
  css`
    align-self: center;
  `

const alignSelfEndCss = ({ alignSelfEnd }) =>
  alignSelfEnd &&
  css`
    align-self: flex-end;
  `

const alignTextCenterCss = ({ alignTextCenter }) =>
  alignTextCenter &&
  css`
    text-align: center;
  `

const alignTextEndCss = ({ alignTextEnd }) =>
  alignTextEnd &&
  css`
    text-align: end;
  `

const Text = styled.p`
  margin: 0;
  padding: 0;
  position: relative;

  font-size: ${mapToTheme('text.fontSize')}px;
  font-weight: ${mapToTheme('text.fontWeight')};
  line-height: 1.35em;

  color: ${mapToTheme('text.color')};

  ${uppercaseCss}
  ${lowercaseCss}
  ${capitalizeCss}
  ${overflowWrapCss}
  ${pointerCss}
  ${absoluteCss}
  ${alignSelfCenterCss}
  ${alignSelfEndCss}
  ${alignTextCenterCss}
  ${alignTextEndCss}
  ${clampedCss}

  ${color}
  ${layout}
  ${space}
  ${typography}
`

Text.propTypes = {
  big: PropTypes.bool,
  bigger: PropTypes.bool,
  bold: PropTypes.bool,
  bolder: PropTypes.bool,
  light: PropTypes.bool,
  medium: PropTypes.bool,
  middle: PropTypes.bool,
  negative: PropTypes.bool,
  positive: PropTypes.bool,
  primary: PropTypes.bool,
  small: PropTypes.bool,
  smallest: PropTypes.bool,
}

Text.defaultProps = {
  big: false,
  bigger: false,
  bold: false,
  bolder: false,
  light: false,
  medium: false,
  middle: false,
  negative: false,
  positive: false,
  primary: false,
  small: false,
  smallest: false,
}

export default Text
