import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import noop from 'lodash/noop'

import { Button, Column, Loader, Modal, Row, Text } from 'Components/UI'
import { InputField, SwitchField } from 'Components/UI/Forms'

import createChannelCategoryMutation from 'GraphQL/Mutations/Channels/createChannelCategory.graphql'
import updateChannelCategoryMutation from 'GraphQL/Mutations/Channels/updateChannelCategory.graphql'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import { Content, Subtitle } from './styles'

const FIELDS = {
  NAME: 'name',
  COLLAPSE: 'collapse',
}

function ChannelCategoryModal({ data, isEdit, ...rest }) {
  const s = useScopedI18n('user.modal.createChannelCategory')
  const [loading, setLoading] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState({
    [FIELDS.NAME]: null,
    [FIELDS.COLLAPSE]: false,
  })

  const close = useRef(null)

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  useEffect(() => {
    if (isEdit) {
      const { name, collapse } = data

      setFormInitialValues({ name, collapse })
    } else {
      setFormInitialValues({ [FIELDS.NAME]: null, [FIELDS.COLLAPSE]: false })
    }
  }, [data, isEdit])

  const [createChannelCategory] = useMutation(createChannelCategoryMutation)
  const [updateChannelCategory] = useMutation(updateChannelCategoryMutation)

  const formConstraints = useMemo(
    () => ({
      [FIELDS.NAME]: {
        presence: true,
        length: {
          minimum: 3,
          tooShort: 'should be at least 3 characters long',
        },
      },
    }),
    [],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)

      try {
        if (isEdit) {
          await updateChannelCategory({
            variables: {
              name: values[FIELDS.NAME] || ' ',
              collapse: values[FIELDS.COLLAPSE],
              id: data?.id,
            },
          })
        } else {
          await createChannelCategory({
            variables: {
              name: values[FIELDS.NAME] || ' ',
              collapse: values[FIELDS.COLLAPSE],
              roomId: data,
            },
          })
        }
        toast.success({
          title: 'Channel category',
          text: isEdit
            ? `Channel category updated`
            : `Channel category created`,
        })

        close.current()
      } catch (error) {
        toast.error({
          title: isEdit ? 'Update channel category' : 'Create channel category',
          text: error?.message,
        })
      } finally {
        setLoading(false)
      }
    },
    [createChannelCategory, data, isEdit, updateChannelCategory],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={isEdit ? s('titleEdit') : s('titleCreate')}
      onMount={handleMount}
    >
      <Form
        initialValues={formInitialValues}
        render={({ handleSubmit }) => (
          <>
            <Content>
              <Subtitle ml={1}>
                <Text alignStart mb={4} mt={6}>
                  {s('subtitle')}
                </Text>
              </Subtitle>
              <InputField name={FIELDS.NAME} width={1} />
              <Row center mt={4} width={1}>
                <SwitchField name={FIELDS.COLLAPSE} />
                <Text ml={2}>{s('collapse')}</Text>
              </Row>
            </Content>

            <Column center mt={6} pb={5}>
              {loading ? (
                <>
                  <Loader />
                </>
              ) : (
                <>
                  <Button height={32} mb={3} width={130} onClick={handleSubmit}>
                    {isEdit ? s('actions.save') : s('actions.create')}
                  </Button>
                  <Button
                    height={32}
                    outline
                    width={130}
                    onClick={() => (close.current ? close.current() : noop())}
                  >
                    {s('actions.cancel')}
                  </Button>
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

ChannelCategoryModal.defaultProps = {
  data: null,
  isEdit: false,
}

ChannelCategoryModal.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  isEdit: PropTypes.bool,
}

export default ChannelCategoryModal
