import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import TelegramLoginButton from 'react-telegram-login'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import get from 'lodash/get'
import map from 'lodash/map'

import { Button, Column, Loader, Modal, Text } from 'Components/UI'

import { LINKEDIN_CLIENT_ID, TELEGRAM_BOT, TWITTER_CLIENT_ID } from 'Config'

import { VERIFICATION_METHODS } from 'Constants/ids'

import connectTelegramAccountMutation from 'GraphQL/Mutations/Auth/connectTelegramAccount.graphql'

import { useAppContext, useEntranceContext } from 'Hooks'

import { LINKEDIN_AUTH_LINK, TWITTER_AUTH_LINK } from 'Router/routes'

import { useMutation } from 'Services/Apollo'
import { useScopedI18n } from 'Services/I18n'
import toast from 'Services/Toast'

import {
  ButtonWrapper,
  Content,
  LinkedInIcon,
  Subtitle,
  TwitterIcon,
} from './styles'

function ConnectSocialModal({ user, sortedSocialAccounts, ...rest }) {
  const s = useScopedI18n('user.modal.connectSocial')
  const [{ exclusiveMode }] = useEntranceContext()
  const { refetchMe } = useAppContext()

  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const [connectTelegramAccount] = useMutation(connectTelegramAccountMutation)

  const formConstraints = useMemo(() => ({}), [])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const handleTelegramVerification = useCallback(
    async response => {
      setLoading(true)

      try {
        const profileString = JSON.stringify(response)
        const profileEncoded = Buffer.from(profileString).toString('base64')

        await connectTelegramAccount({ variables: { profile: profileEncoded } })
        await refetchMe()

        toast.success({
          title: 'Connect telegram account',
          text: `Telegram account successfully verified`,
        })

        setLoading(false)

        close.current()
      } catch (error) {
        toast.error({
          title: 'Telegram connection error',
          text: error?.message,
        })

        setLoading(false)
      }
    },
    [connectTelegramAccount, refetchMe],
  )

  const handleTwitterConnect = useCallback(() => {
    const url = new URL(window.location)
    return window.location.replace(
      `${TWITTER_AUTH_LINK}?response_type=code&client_id=${TWITTER_CLIENT_ID}&redirect_uri=${url.origin}/oauth/twitter/callback&scope=tweet.read%20users.read%20offline.access&state=state&code_challenge=challenge&code_challenge_method=plain`,
    )
  }, [])

  const handleLinkedInConnect = useCallback(() => {
    const url = new URL(window.location)
    return window.location.replace(
      `${LINKEDIN_AUTH_LINK}?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${url.origin}/oauth/linkedin/callback&state=state&scope=r_liteprofile`,
    )
  }, [])

  const renderSocialOptions = useMemo(
    () =>
      map(sortedSocialAccounts, account => {
        switch (account) {
          case VERIFICATION_METHODS.TELEGRAM:
            return exclusiveMode ? null : (
              <ButtonWrapper mt={24}>
                <TelegramLoginButton
                  botName={TELEGRAM_BOT}
                  dataOnauth={handleTelegramVerification}
                  usePic={false}
                />
              </ButtonWrapper>
            )
          case VERIFICATION_METHODS.TWITTER:
            return (
              <Button
                height={40}
                icon
                mt={24}
                width={218}
                onClick={handleTwitterConnect}
              >
                <TwitterIcon mr={16} />
                {s('twitter')}
              </Button>
            )
          case VERIFICATION_METHODS.LINKEDIN:
            return (
              <Button
                height={40}
                icon
                mt={24}
                width={218}
                onClick={handleLinkedInConnect}
              >
                <LinkedInIcon mr={16} />
                {s('linkedIn')}
              </Button>
            )
          default:
            return null
        }
      }),
    [
      exclusiveMode,
      handleLinkedInConnect,
      handleTelegramVerification,
      handleTwitterConnect,
      s,
      sortedSocialAccounts,
    ],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick={false}
      title={s('title')}
      onMount={handleMount}
    >
      <Form
        initialValues={user}
        render={() => (
          <>
            <Content>
              <Subtitle ml={1}>
                <Text alignStart mb={16} mt={24}>
                  {s('subtitle')}
                </Text>
              </Subtitle>
              {renderSocialOptions}
            </Content>

            <Column center mt={24} pb={24}>
              {loading && (
                <>
                  <Loader />
                </>
              )}
            </Column>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={handleTelegramVerification}
      />
    </Modal>
  )
}

ConnectSocialModal.defaultProps = {
  sortedSocialAccounts: [],
  user: null,
}

ConnectSocialModal.propTypes = {
  sortedSocialAccounts: PropTypes.array,
  user: PropTypes.object,
}

export default ConnectSocialModal
