import React from 'react'

import RoomsSubscription from './RoomsSubscription'

export function Subscriptions(categoriesVariables) {
  return (
    <>
      <RoomsSubscription categoriesVariables={categoriesVariables} />
    </>
  )
}
