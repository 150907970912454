import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Form } from 'react-final-form'
import PropTypes from 'prop-types'

import validate from 'validate.js'

import filter from 'lodash/filter'
import get from 'lodash/get'
import map from 'lodash/map'

import {
  AuthMethodIcon,
  Button,
  Column,
  Loader,
  Modal,
  Row,
  Text,
} from 'Components/UI'
import { CheckboxField, RadioField } from 'Components/UI/Forms'

import { AUTH_METHODS, WALLET_PROVIDERS } from 'Constants/ids'

import { useTrezor, useWalletsHandlers, useWeb3 } from 'Hooks'

import { useScopedI18n } from 'Services/I18n'

import { forEachPromise } from 'Utils/Promises'

import {
  CoinmetroIcon,
  ConnectWalletTitle,
  Content,
  Description,
  HathorNetworkIcon,
  MetamaskIcon,
  Subtitle,
  TrezorIcon,
  UnlockIcon,
} from './styles'

const FIELDS = {
  PROVIDER: 'provider',
  TREZOR_ADDRESSES: 'trezorAddresses',
}

const LOGOS = {
  ethereum: <MetamaskIcon />,
  htr: <HathorNetworkIcon />,
  trezor: <TrezorIcon />,
  xcm: <CoinmetroIcon />,
  unlockProtocol: <UnlockIcon />,
  walletConnect: <AuthMethodIcon kind={AUTH_METHODS.WALLET_CONNECT} />,
}

// mock data for Trezor testing
// const payload = [
//   { address: '1', path: 'path1', serializedPath: 'path1Ser' },
//   { address: '2', path: 'path2', serializedPath: 'path2Ser' },
//   { address: '3', path: 'path3', serializedPath: 'path3Ser' },
// ]

function ConnectWalletModal({ me, metamaskWallets, refetch, ...rest }) {
  const s = useScopedI18n('user.modal.connectWallet')
  const [loading, setLoading] = useState(false)

  const close = useRef(null)

  const { isMetaMaskConnected, isMetaMaskConnecting, connect } = useWeb3()
  const {
    isTrezorUnlocking,
    noTrezor,
    unlockTrezor,
    trezorAccounts,
    // isTrezorConnected,
  } = useTrezor()

  const {
    metamaskHandler,
    // hathorHandler,
    // isHathorUnlocked,
    trezorHandler,
    coinmetroHandler,
    unlockHandler,
    walletConnectHandler,
  } = useWalletsHandlers({ setLoading, close, refetch })

  const formConstraints = useMemo(() => ({}), [])

  const handleMount = useCallback(instance => {
    close.current = get(instance, 'handleClose')
  }, [])

  const selectableTrezorAccounts = useMemo(() => {
    if (trezorAccounts && trezorAccounts?.length > 0) {
      return map(trezorAccounts, account => (
        <Row key={account?.address} mt={2}>
          <CheckboxField
            disabled={!!metamaskWallets[account?.address?.toLowerCase()]}
            label={account?.address}
            name={FIELDS.TREZOR_ADDRESSES}
            value={account}
          />
        </Row>
      ))
    }
    return null
  }, [metamaskWallets, trezorAccounts])

  const button = useCallback(
    (values, handleSubmit) => {
      switch (values?.provider) {
        case WALLET_PROVIDERS.ETHEREUM:
          if (!isMetaMaskConnected) {
            return (
              <Button
                disabled={isMetaMaskConnecting}
                height={32}
                mt={3}
                outline
                width={150}
                onClick={connect}
              >
                {s(`providers.${values?.provider}.enable`)}
              </Button>
            )
          }
          return (
            <Button
              height={32}
              mt={3}
              outline
              width={205}
              onClick={handleSubmit}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          )
        case WALLET_PROVIDERS.TREZOR:
          if (!trezorAccounts) {
            return (
              <Button
                disabled={isTrezorUnlocking}
                height={32}
                mt={3}
                outline
                width={150}
                onClick={unlockTrezor}
              >
                {s(`providers.${values?.provider}.enable`)}
              </Button>
            )
          }
          return (
            <Button
              disabled={
                !values?.trezorAddresses ||
                values?.trezorAddresses?.length === 0
              }
              height={32}
              mt={3}
              outline
              width={205}
              onClick={handleSubmit}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          )
        /* case WALLET_PROVIDERS.HATHOR:
          return (
            <Button
              height={32}
              mt={3}
              outline
              width={205}
              onClick={handleSubmit}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          ) */
        case WALLET_PROVIDERS.XCM:
          return (
            <Button
              height={32}
              mt={3}
              outline
              width={205}
              onClick={coinmetroHandler}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          )
        case WALLET_PROVIDERS.UNLOCK_PROTOCOL:
          return (
            <Button
              height={32}
              mt={3}
              outline
              width={205}
              onClick={unlockHandler}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          )
        case WALLET_PROVIDERS.WALLET_CONNECT:
          return (
            <Button
              height={32}
              mt={3}
              outline
              width={205}
              onClick={walletConnectHandler}
            >
              {s(`providers.${values?.provider}.connect`)}
            </Button>
          )
        default:
          return null
      }
    },
    [
      coinmetroHandler,
      connect,
      isMetaMaskConnected,
      isMetaMaskConnecting,
      isTrezorUnlocking,
      s,
      trezorAccounts,
      unlockHandler,
      unlockTrezor,
      walletConnectHandler,
    ],
  )

  const submit = useCallback(
    async values => {
      setLoading(true)
      if (values?.provider === WALLET_PROVIDERS.ETHEREUM) {
        await metamaskHandler()
      }
      /* if (values?.provider === WALLET_PROVIDERS.HATHOR) {
        await hathorHandler()
      } */
      if (values?.provider === WALLET_PROVIDERS.TREZOR) {
        const filteredAddresses = filter(
          values?.trezorAddresses,
          entity => !metamaskWallets[entity?.address?.toLowerCase()],
        )
        await forEachPromise(filteredAddresses, trezorHandler)
      }
      await refetch()
      setLoading(false)
    },
    [metamaskHandler, metamaskWallets, refetch, trezorHandler],
  )

  return (
    <Modal
      {...Modal.pickProps(rest)}
      shouldCloseOnOverlayClick
      title={<ConnectWalletTitle>{s('title')}</ConnectWalletTitle>}
      onMount={handleMount}
    >
      <Form
        render={({ values, handleSubmit }) => (
          <>
            <Content>
              <Subtitle>
                <Text mb={2} mt={4} primary>
                  {s('extension')}
                </Text>
              </Subtitle>
              <Row flexWrap>
                {map(
                  WALLET_PROVIDERS,
                  provider =>
                    (provider === WALLET_PROVIDERS.ETHEREUM ||
                      provider === WALLET_PROVIDERS.TREZOR ||
                      provider === WALLET_PROVIDERS.XCM ||
                      provider === WALLET_PROVIDERS.UNLOCK_PROTOCOL ||
                      provider === WALLET_PROVIDERS.WALLET_CONNECT) && (
                      <RadioField
                        key={provider}
                        label={s(`providers.${provider}.title`)}
                        logo={LOGOS[provider]}
                        ml={2}
                        mt={2}
                        name={FIELDS.PROVIDER}
                        provider
                        value={provider}
                      />
                    ),
                )}
              </Row>
              {!!values?.provider && (
                <Description mt={4}>
                  <Text primary>
                    {s(`providers.${values?.provider}.description`)}
                  </Text>
                  {values?.provider === WALLET_PROVIDERS.TREZOR && noTrezor && (
                    <Text small warning>
                      {s('errors.trezor')}
                    </Text>
                  )}
                </Description>
              )}
              {values?.provider === WALLET_PROVIDERS.TREZOR && (
                <Column>{selectableTrezorAccounts}</Column>
              )}
              {loading ? (
                <Row alignSelfCenter>
                  <Loader />
                </Row>
              ) : (
                <Row alignSelfCenter>{button(values, handleSubmit)}</Row>
              )}
            </Content>
          </>
        )}
        validate={values => validate(values, formConstraints)}
        onSubmit={submit}
      />
    </Modal>
  )
}

ConnectWalletModal.defaultProps = {
  metamaskWallets: [],
  refetch: null,
}

ConnectWalletModal.propTypes = {
  me: PropTypes.object.isRequired,
  metamaskWallets: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  refetch: PropTypes.func,
}

export default ConnectWalletModal
