import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { FiEdit } from 'react-icons/fi'

import { EditDonationWalletModal } from 'Components/Blocks/User/Modals'
import { Button, Column, Row, Text } from 'Components/UI'

import { useScopedI18n } from 'Services/I18n'

import { Container, Content } from './styles'

import { IconWrapper } from '../styles'

function DonationWallet({ donationWallet, me, isCollapsed }) {
  const s = useScopedI18n('user.profile')

  const [editModal, setEditModal] = useState({ isOpen: false, entity: null })

  const handleEditDonation = useCallback(entity => {
    setEditModal({ isOpen: true, entity })
  }, [])
  const handleCloseEditModal = useCallback(() => {
    setEditModal({ isOpen: false, entity: null })
  }, [])

  return (
    <Container className="tutorial-sixth" collapsed={isCollapsed} mt={2}>
      {!isCollapsed ? (
        <Content>
          <Row spaceBetween>
            <Column>
              <Text bold>{s('fields.donation')}</Text>
              <Text mt={1} primary small>
                {donationWallet
                  ? `${donationWallet?.slice(0, 8)}...${donationWallet?.slice(
                      34,
                    )}`
                  : s('fields.notSet')}
              </Text>
            </Column>
            <Button
              iconButton
              noEffectsIcon
              smallest
              stroke
              onClick={() => handleEditDonation(me)}
            >
              <IconWrapper>
                <FiEdit />
              </IconWrapper>
            </Button>
          </Row>
        </Content>
      ) : (
        <Content collapsedContent>
          <Column>
            <Text bold small>
              {s('fields.eth')}
            </Text>
            <Text bold small>
              {s('fields.don')}
            </Text>
            <Text bold small>
              {s('fields.wallet')}
            </Text>
            <Row justifyCenter>
              <Button
                height={22}
                iconButton
                mt={2}
                noEffectsIcon
                smallest
                stroke
                width={22}
                onClick={() => handleEditDonation(me)}
              >
                <IconWrapper>
                  <FiEdit />
                </IconWrapper>
              </Button>
            </Row>
          </Column>
        </Content>
      )}

      <EditDonationWalletModal
        isOpen={editModal.isOpen}
        user={editModal.entity}
        onClose={handleCloseEditModal}
      />
    </Container>
  )
}

DonationWallet.defaultProps = {
  donationWallet: null,
  isCollapsed: false,
}

DonationWallet.propTypes = {
  donationWallet: PropTypes.string,
  isCollapsed: PropTypes.bool,
  me: PropTypes.object.isRequired,
}

export default DonationWallet
