import palette from './palette'

const memberships = {
  color: {
    guest: palette.guest,
    zen: palette.zen,
    solo: palette.solo,
    pro: palette.pro,
    prime: palette.gold,
    apex: palette.apex,
  },
  textColor: {
    guest: palette.primary,
    zen: palette.primaryDark,
    solo: palette.solo,
    pro: palette.pro,
    prime: palette.gold,
    apex: palette.apex,
  },
}

export default memberships
