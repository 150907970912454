import { persistState } from '@storeon/localstorage'
import { createStoreon } from 'storeon'

import camelCase from 'lodash/camelCase'

import { APP_NAME } from 'Config'

import {
  AUTH_STATE,
  CONTENT_STATE,
  I18N_STATE,
  ROUTE_STATE,
  UI_STATE,
} from 'Constants/store'

import { CookieStorage } from 'Services/Cookie'
import { setStore } from 'Services/Shared'

import authState from './auth'
import contentState from './content'
import i18nState from './i18n'
import routeState from './route'
import uiState from './ui'

export function createStore() {
  const store = createStoreon([
    authState,
    i18nState,
    uiState,
    contentState,
    routeState,
    persistState([UI_STATE, I18N_STATE, CONTENT_STATE, ROUTE_STATE], {
      key: camelCase(APP_NAME),
    }),
    persistState([AUTH_STATE], {
      key: camelCase(APP_NAME),
      storage: CookieStorage,
    }),
  ])

  setStore(store)

  return store
}
