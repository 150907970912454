import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import Web3 from 'web3'

import includes from 'lodash/includes'

import { IS_PRODUCTION } from 'Config'

import {
  BINANCE_CHAIN_ID,
  ETHEREUM_CHAIN_ID,
  POLYGON_CHAIN_ID,
  WALLET_PROVIDERS,
} from 'Constants/ids'

import { useAppContext } from 'Hooks'

import Auth from 'Services/Auth'
import Web3Context from 'Services/Web3Context'

const allowedChain = IS_PRODUCTION
  ? [
      ETHEREUM_CHAIN_ID.PRODUCTION,
      BINANCE_CHAIN_ID.PRODUCTION,
      POLYGON_CHAIN_ID.PRODUCTION,
    ]
  : [
      ETHEREUM_CHAIN_ID.STAGING,
      BINANCE_CHAIN_ID.STAGING,
      POLYGON_CHAIN_ID.STAGING,
    ]

export default function Web3Provider({ children }) {
  const { me } = useAppContext()

  const history = useHistory()

  const [web3, setWeb3] = useState(null)
  const [account, setAccount] = useState(null)
  const [noMetamask, setNoMetamask] = useState(false)
  const [chainId, setChainId] = useState(null)
  const [metamaskProvider, setMetamaskProvider] = useState(null)

  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false)
  const [isMetaMaskConnecting, setIsMetaMaskConnecting] = useState(false)

  useEffect(() => {
    async function checkIfMetaMaskConnected() {
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await window.ethereum.request({
          method: 'eth_accounts',
        })

        setIsMetaMaskConnected(accounts.length > 0)
        setIsMetaMaskConnecting(false)
      }
    }

    checkIfMetaMaskConnected()
  }, [])

  useEffect(() => {
    async function checkMetamaskNetwork() {
      if (includes(ETHEREUM_CHAIN_ID, parseInt(chainId, 10))) {
        setMetamaskProvider(WALLET_PROVIDERS.ETHEREUM)
      } else if (includes(BINANCE_CHAIN_ID, parseInt(chainId, 10))) {
        setMetamaskProvider(WALLET_PROVIDERS.BSC)
      } else if (includes(POLYGON_CHAIN_ID, parseInt(chainId, 10))) {
        setMetamaskProvider(WALLET_PROVIDERS.POLYGON)
      } else {
        setMetamaskProvider(null)
      }
    }

    if (isMetaMaskConnected) {
      checkMetamaskNetwork()
    }
  }, [chainId, isMetaMaskConnected])

  useEffect(() => {
    if (noMetamask) return

    setWeb3(new Web3(window.ethereum))
  }, [noMetamask, account])

  useEffect(() => {
    if (!web3 || noMetamask) return
    web3.eth.getAccounts().then(([address]) => setAccount(address))
    web3.eth.getChainId().then(id => setChainId(id.toString()))
  }, [web3, setAccount, noMetamask])

  const handleAccountChange = useCallback(
    accounts => setAccount(accounts[0]),
    [setAccount],
  )
  const handleNetworkChange = useCallback(id => setChainId(id), [setChainId])

  useEffect(() => {
    setNoMetamask(!window.ethereum)

    if (!window.ethereum) return

    window.ethereum.on('accountsChanged', handleAccountChange)
    window.ethereum.on('networkChanged', handleNetworkChange)
  }, [handleAccountChange, handleNetworkChange])

  useEffect(() => {
    if (!me || !me?.walletAddress || !account) return

    if (me.walletAddress.toLowerCase() !== account.toLowerCase()) {
      Auth.logOut(history)
    }
  }, [me, account, history])

  const wrongNetwork = useMemo(
    () => !includes(allowedChain, parseInt(chainId, 10)),
    [chainId],
  )

  return (
    <Web3Context.Provider
      value={{
        web3,
        account,
        chainId,
        noMetamask,
        wrongNetwork,
        setAccount,
        metamaskProvider,
        isMetaMaskConnected,
        isMetaMaskConnecting,
        setIsMetaMaskConnected,
        setIsMetaMaskConnecting,
      }}
    >
      {children}
    </Web3Context.Provider>
  )
}

Web3Provider.propTypes = {
  children: PropTypes.element.isRequired,
}
